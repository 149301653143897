import { DateUtils } from '@eo-storefronts/eo-core'
import { Box } from '@mui/material'
import { MouseEvent, useEffect, useState } from 'react'
import OrderingMethodsDateButtonGroup from '~/src/components/ordering-methods/OrderingMethodsDateButtonGroup'
import { useUpdateCheckoutTime } from '~/src/hooks/checkout/useUpdateCheckoutTime'
import { useEoState } from '~/src/hooks/useEoState'
import { CHECKOUT_FORM_STATE, CheckoutFormState } from '~/src/stores/checkout'
import { useCanOrderOnDay } from '~/src/hooks/ordering-methods/useCanOrderOnDay'
import OrderingMethodsDatePicker from '~/src/components/ordering-methods/ordering-methods-date-picker'

const OrderingMethodsDateContainer = () => {
  const [ checkoutForm, setCheckoutForm ] = useEoState(CHECKOUT_FORM_STATE)
  const { updateCheckoutTime } = useUpdateCheckoutTime()
  const [ selectedButtonValue, setSelectedButtonValue ] = useState<string>('')
  const { canOrderOnDay } = useCanOrderOnDay()

  const handleOnChange = (event: MouseEvent, value: string) => {
    if (!value) {
      return
    }

    setSelectedButtonValue(value)

    if (value !== 'today') {
      setCheckoutForm((state: CheckoutFormState) => ({
        ...state,
        orderingMethod: {
          ...state.orderingMethod,
          asap: false
        }
      }))
    }

    if (value === 'today' || value === 'tomorrow') {
      updateCheckoutTime(value === 'today' ? new Date() : DateUtils.addDays(1))
    }
  }

  useEffect(() => {
    if (!checkoutForm.orderingMethod.time) {
      return
    }

    const checkoutDate = new Date(checkoutForm.orderingMethod.time)

    if (DateUtils.isSameDate(new Date(), checkoutDate) && canOrderOnDay(new Date())) {
      return setSelectedButtonValue('today')
    }

    if (DateUtils.isSameDate(DateUtils.addDays(1), checkoutDate) && canOrderOnDay(DateUtils.addDays(1))) {
      return setSelectedButtonValue('tomorrow')
    }

    setSelectedButtonValue('pick')
  }, [ checkoutForm.orderingMethod.time, checkoutForm.orderingMethod.pickupPoint ])

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 1,
        flexShrink: 0,
        flexWrap: 'wrap'
      }}
    >
      <OrderingMethodsDateButtonGroup onChange={handleOnChange} value={selectedButtonValue} />
      {selectedButtonValue === 'pick' && <OrderingMethodsDatePicker />}
    </Box>
  )
}

export default OrderingMethodsDateContainer
