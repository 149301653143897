import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'
import ProductConfigurationModalTemplateServiceStyleTwo
  from '~/src/components/products/modals/configuration/styles/ProductConfigurationModalTemplateServiceStyleTwo'
import ProductConfigurationModalTemplateServiceStyleOne
  from '~/src/components/products/modals/configuration/styles/ProductConfigurationModalTemplateServiceStyleOne'
import ProductConfigurationModalTemplateServiceInterface
  from '~/src/components/products/modals/configuration/styles/ProductConfigurationModalTemplateServiceInterface'
import { LayoutState } from '~/src/stores/layout'

const getProductConfigurationModalTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme, layout: LayoutState): ProductConfigurationModalTemplateServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new ProductConfigurationModalTemplateServiceStyleTwo(muiTheme, undefined, layout)
    default:
      return new ProductConfigurationModalTemplateServiceStyleOne(muiTheme, undefined, layout)
  }
}

export default getProductConfigurationModalTemplateService
