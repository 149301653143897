import { PriceList } from '@eo-storefronts/eo-core'
import useLogFacebookAnalyticEvent from '~/src/hooks/analytics/useLogFacebookPixelEvent'
import useLogGoogleAnalyticEvent from '~/src/hooks/analytics/useLogGoogleAnalyticEvent'
import { useEoValue } from '~/src/hooks/useEoState'
import { getProductPrice } from '~/src/services/ProductService'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import { LANGUAGE_STATE } from '~/src/stores/lang'
import { PRODUCTS_STATE } from '~/src/stores/product'
import { PRICE_LIST_STATE } from '~/src/stores/product/price-list'
import { CartProduct } from '~/src/types/CartProduct'
import FacebookPixelEventData from '~/src/types/facebook-pixel/FacebookPixelEventData'
import { FacebookPixelEventType } from '~/src/types/facebook-pixel/FacebookPixelEventType'
import GoogleAnalyticsEcommerceEventItem from '~/src/types/google-analytics/GoogleAnalyticsEcommerceEventItem'
import { GoogleAnalyticsEcommerceEventType } from '~/src/types/google-analytics/GoogleAnalyticsEcommerceEventType'

interface ReturnsType {
  logGoogleEvent(evenType: GoogleAnalyticsEcommerceEventType, params?: Record<string, unknown>): void,
  logFacebookEvent(eventType: FacebookPixelEventType | string, data?: FacebookPixelEventData, custom?: boolean): void,
}

const useLogAnalyticEcommerceEvent = (): ReturnsType => {
  const checkoutFormState = useEoValue(CHECKOUT_FORM_STATE)
  const { logEvent: logGoogleAnalyticEvent } = useLogGoogleAnalyticEvent()
  const { logEvent: logFacebookAnalyticEvent } = useLogFacebookAnalyticEvent()
  const products = useEoValue(PRODUCTS_STATE)
  const lang = useEoValue(LANGUAGE_STATE)
  const priceLists = useEoValue<PriceList[]>(PRICE_LIST_STATE)

  const _remapProduct = (cartProduct: CartProduct): GoogleAnalyticsEcommerceEventItem => {
    const prices = getProductPrice(products[cartProduct.id], priceLists)

    return {
      item_id: cartProduct.id?.toString(),
      item_name: products[cartProduct.id]?.name[lang] || 'Invalid Product',
      price: (checkoutFormState.orderingMethod.method && prices) ?
        prices[checkoutFormState.orderingMethod.method] :
        0,
      quantity: cartProduct.quantity
    }
  }

  const logGoogleEvent = (evenType: GoogleAnalyticsEcommerceEventType, params?: Record<string, unknown>): void => {
    if (params?.items) {
      params.items = (params.items as CartProduct[]).map((cartProduct: CartProduct) => _remapProduct(cartProduct))
    }

    if (params?.value) {
      params.value = Number(params.value)
    }

    logGoogleAnalyticEvent(evenType, params)
  }

  const logFacebookEvent = (eventType: FacebookPixelEventType | string, data?: FacebookPixelEventData, custom?: boolean): void => {
    logFacebookAnalyticEvent(eventType, custom, data)
  }

  return { logGoogleEvent, logFacebookEvent }
}

export default useLogAnalyticEcommerceEvent
