import {
  CheckoutOption,
  Currency,
  Firm,
  Id,
  OpeningHour,
  OrderingMethod,
  OrderingMethodNames,
  ServiceFee,
  Theme
} from '@eo-storefronts/eo-core'
import { createSelector, createSelectorFamily, createState } from '~/src/hooks/useEoState'
import { isClosed } from '~/src/services/OpeningHourService'
import { CHECKOUT_FORM_STATE, CheckoutFormState } from '~/src/stores/checkout'
import { BRAND_STATE } from '~/src/stores/brand'
import { LOCATOR_STATE } from '~/src/stores/locator'
import { CART_SUBTOTAL_WITH_DISCOUNTS_SELECTOR } from '~/src/stores/cart'
import NumberUtils from '~/src/utils/NumberUtils'

export const SELECTED_SHOP_STATE = createState<string>({
  key: 'selectedShop',
  default: ''
})

export const FIRM_SELECTOR = createSelector<Firm | null>({
  key: 'firmSelector',
  get: ({ get }): Firm | null => {
    const brand = get<Firm|null>(BRAND_STATE)
    const selectedShop = get<string>(SELECTED_SHOP_STATE)

    if (!brand?.isMultifirm) {
      return brand
    }

    if (selectedShop !== '') {
      return brand.locations?.find((location: Firm) => location.sitename === selectedShop) || null
    }

    return brand
  }
})

export const FIRM_BY_ID_SELECTOR = createSelectorFamily<Firm | null, Id>({
  key: 'firmByIdSelector',
  get: (id: Id) => ({ get }) => {
    const brand = get(BRAND_STATE)

    if (brand?.id === id) {
      return brand
    }

    const firm = brand?.locations?.find((f: Firm) => f.id === id)

    return firm || null
  }
})

export const FIRM_WITH_LOCATOR_STATE_SELECTOR = createSelector<Firm | null>({
  key: 'firmWithLocatorStateSelector',
  get: ({ get }): Firm | null => {
    const firm = get(FIRM_SELECTOR)
    const { firmSelected } = get(LOCATOR_STATE)

    return firmSelected ?? firm
  }
})

export const FIRM_THEME_SELECTOR = createSelector<Theme | undefined>({
  key: 'firmThemeSelector',
  get: ({ get }): Theme | undefined => {
    return get<Firm | null>(FIRM_SELECTOR)?.settings.theme
  }
})

export const FIRM_CURRENCY_SELECTOR = createSelector<Currency | undefined>({
  key: 'firmCurrencySelector',
  get: ({ get }): Currency | undefined => {
    return get<Firm | null>(FIRM_SELECTOR)?.settings.currency
  }
})

export const GET_ACTIVE_SERVICE_FEES_SELECTOR = createSelector<ServiceFee[]>({
  key: 'getActiveServiceFeesSelector',
  get: ({ get }) => {
    const firm: Firm | null = get<Firm | null>(FIRM_WITH_LOCATOR_STATE_SELECTOR)
    const checkout: CheckoutFormState = get(CHECKOUT_FORM_STATE)

    if (!firm?.settings.serviceFee) {
      return []
    }

    const activeFees: ServiceFee[] = firm.settings.serviceFee.filter((serviceFee: ServiceFee) => serviceFee.active)

    if (checkout.orderingMethod.pickupPoint) {
      return activeFees.filter((serviceFee: ServiceFee) =>
        serviceFee.firmPickupPointId === checkout.orderingMethod.pickupPoint?.id
        || !serviceFee.firmPickupPointId
      )
    }

    return activeFees
  }
})

export const GET_TOTAL_AMOUNT_OF_SERVICE_FEES_SELECTOR = createSelector<number>({
  key: 'getTotalAmountOfServiceFeesSelector',
  get: ({ get }) => {
    const activeServiceFee: ServiceFee[] = get(GET_ACTIVE_SERVICE_FEES_SELECTOR)
    const subTotal = get(CART_SUBTOTAL_WITH_DISCOUNTS_SELECTOR)

    if (!activeServiceFee) {
      return 0
    }

    return activeServiceFee.reduce((accumulator: number, current: ServiceFee) => {
      if (current.freeFrom && subTotal >= current.freeFromAmount) {
        return accumulator
      }

      if (current.feeChoice === 'percentage') {
        return accumulator + NumberUtils.getPercentageValue(subTotal, current.percentage)
      }

      return accumulator + current.fee
    }, 0)
  }
})

export const GET_FIRM_CHECKOUT_OPTIONS_SELECTOR = createSelector<CheckoutOption[]>({
  key: 'getFirmCheckoutOptionsSelector',
  get: ({ get }) => {
    const firm: Firm | null = get(FIRM_WITH_LOCATOR_STATE_SELECTOR)

    if (!firm?.settings.checkoutOptions) {
      return []
    }

    return firm.settings.checkoutOptions
  }
})

export const IS_FIRM_OPEN_FOR_DAY_ID_SELECTOR = createSelectorFamily({
  key: 'isFirmOpenForDayIdSelector',
  get: (dayId: number) => ({ get }) => {
    const firm = get<Firm | null>(FIRM_WITH_LOCATOR_STATE_SELECTOR)
    const openingHour = firm?.settings.periods?.openingHours?.find((openingHour: OpeningHour) => openingHour.dayId === dayId)

    return openingHour && !isClosed(openingHour)
  }
})

export const IS_DELIVERY_METHOD_ENABLED_FOR_FIRM = createSelector({
  key: 'isOrderingMethodEnabledForFirm',
  get: ({ get }) => {
    const firm = get<Firm | null>(FIRM_WITH_LOCATOR_STATE_SELECTOR)
    const checkoutForm = get<CheckoutFormState>(CHECKOUT_FORM_STATE)

    if (!firm) {
      return false
    }

    return Object.keys(firm.settings.orderingMethods).includes(checkoutForm.orderingMethod.method as OrderingMethodNames)
  }
})

export const IS_ALL_DELIVERY_METHODS_INACTIVE_FOR_FIRM = createSelector({
  key: 'isAllOrderingMethodsInactiveFormFirm',
  get: ({ get }) => {
    const firm = get<Firm | null>(FIRM_WITH_LOCATOR_STATE_SELECTOR)

    if (!firm) {
      return false
    }

    return Object.values(firm.settings.orderingMethods).every((orderingMethod: OrderingMethod) => orderingMethod?.temporaryInactive)
  }
})
