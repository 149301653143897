import { Modifier, Price as PriceType } from '@eo-storefronts/eo-core'
import { useEffect, useState } from 'react'
import Chip from '~/src/components/mui-wrappers/styled/chip'
import Title from '~/src/components/mui-wrappers/Title'
import Price from '~/src/components/Price'
import { useEoValue } from '~/src/hooks/useEoState'
import { getModifierPrice, modifierHasPromotion } from '~/src/services/ModifierService'
import { PRICE_LIST_STATE } from '~/src/stores/product/price-list'

interface Props {
  modifier: Modifier,
  asChip: boolean,
}

const ModifierPrice = ({ modifier, asChip }: Props) => {
  const priceLists = useEoValue(PRICE_LIST_STATE)
  const [ hasPromotion, setHasPromotion ] = useState(modifierHasPromotion(modifier, priceLists))
  const [ price, setPrice ] = useState<PriceType>(modifier.price)

  useEffect(() => {
    const hasPromotion = modifierHasPromotion(modifier, priceLists)

    setHasPromotion(hasPromotion)
    setPrice(hasPromotion ? modifier.price : getModifierPrice(modifier, priceLists))
  }, [ modifier, priceLists ])

  if (asChip) {
    return (
      <Chip
        color='primary'
        size='small'
        label={(
          <Price
            price={price}
            displayZeroAmount={false}
            sx={{
              textDecoration: hasPromotion ? 'line-through' : 'none'
            }}
          />
        )}
      />
    )
  }

  return (
    <Title
      variant='caption'
      sx={{
        textDecoration: hasPromotion ? 'line-through' : 'none'
      }}
    >
      <Price
        price={price}
        displayZeroAmount={true}
      />
    </Title>
  )
}

export default ModifierPrice
