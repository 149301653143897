import { Price, ServiceFee } from '@eo-storefronts/eo-core'
import { useEoValue } from '~/src/hooks/useEoState'
import { CART_SUBTOTAL_WITH_DISCOUNTS_SELECTOR } from '~/src/stores/cart'
import NumberUtils from '~/src/utils/NumberUtils'
import PriceHelper from '~/src/helpers/priceHelper'

const useGetFeePrice = (fee: ServiceFee): Price => {
  const subtotal = useEoValue(CART_SUBTOTAL_WITH_DISCOUNTS_SELECTOR)

  if (fee.freeFrom && subtotal >= fee.freeFromAmount) {
    return PriceHelper.transform(0)
  }

  if (fee.feeChoice === 'fee') {
    return PriceHelper.transform(fee.fee)
  }

  const feePrice = NumberUtils.getPercentageValue(subtotal, fee.percentage)

  return PriceHelper.transform(feePrice)
}

export default useGetFeePrice
