import { PlatformUtils } from '@eo-storefronts/eo-core'
import { Box, Theme as MuiTheme, useMediaQuery } from '@mui/material'
import { lazy, ReactNode, Suspense, useEffect } from 'react'
import ShoppingCartMessages from '~/src/components/cart/shopping-cart-messages'
import FirmClosed from '~/src/components/firms/FirmClosed'
import DevModeFloatingButton from '~/src/components/layout/dev-mode/DevModeFloatingButton'
import Footer from '~/src/components/layout/footer/Footer'
import Header from '~/src/components/layout/header'
import MobileNavigation from '~/src/components/layout/mobile-navigation'
import useGetLayoutTemplate from '~/src/components/layout/styles/useGetLayoutTemplate'
import SubHeader from '~/src/components/layout/sub-header'
import LayoutDrawerProvider from '~/src/components/providers/LayoutDrawerProvider'
import useGetFooterOffset from '~/src/hooks/layout/useGetFooterOffset'
import useGetLayoutOffset from '~/src/hooks/layout/useGetLayoutOffset'
import useWindowResize from '~/src/hooks/layout/useWindowResize'
import useIsLocatorPage from '~/src/hooks/router/useIsLocatorPage'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { useEoState } from '~/src/hooks/useEoState'
import { LAYOUT_STATE, LayoutState } from '~/src/stores/layout'

const FirmNotification = lazy(async () => import('~/src/components/firms/firm-notification'))
const MainSideBar = lazy(async () => import('~/src/components/layout/main-side-bar'))

interface Props {
  children?: ReactNode,
}

const Layout = ({ children }: Props) => {
  const styles = useGetLayoutTemplate()
  const { isLocatorPage } = useIsLocatorPage()
  const [ layout, setLayout ] = useEoState<LayoutState>(LAYOUT_STATE)
  const { offset: footerOffset } = useGetFooterOffset(true)
  const { windowSize } = useWindowResize()
  const { getOffset } = useGetLayoutOffset()
  const muiTheme = useCustomMuiTheme()
  const isSmallDevice = useMediaQuery((theme: MuiTheme) => theme.breakpoints.down('md'))

  useEffect(() => {
    setLayout((state: LayoutState) => ({
      ...state,
      isMobile: PlatformUtils(window).isMobile && isSmallDevice
    }))
  }, [ windowSize, isSmallDevice, setLayout ])

  return (
    <LayoutDrawerProvider>
      <FirmClosed
        id='layout_message__container'
        sx={{
          position: 'sticky',
          top: 0,
          zIndex: 4
        }}
        AlertProps={{
          sx: {
            paddingTop: `calc(${muiTheme.spacing(1)} + var(--eo-safe-area-top))`,
            paddingBottom: 1
          }
        }}/>

      <Box
        id='layout_container'
        sx={styles.getContainerSx(isLocatorPage, getOffset())}
      >

        {layout.header.display && <Header/>}

        {layout.subHeader.display && <SubHeader/>}

        <Box
          id='layout_main'
          component='main'
          gridArea='main'
          sx={styles.getMainSx(isLocatorPage)}
        >
          {layout.content.displayNotification && (
            <Suspense>
              <FirmNotification/>
            </Suspense>
          )}
          {children}
        </Box>

        {(!isSmallDevice && layout.mainSideBar.display) && (
          <Suspense>
            <MainSideBar/>
          </Suspense>
        )}

        {layout.footer.display && (
          <Box
            id='layout_footer'
            component='footer'
            gridArea='footer'
            sx={{
              ...styles.getFooterSx(),
              bottom: footerOffset
            }}
          >
            <Footer/>
          </Box>
        )}

        {(isSmallDevice && layout.mobileNavigation.display) && (
          <Box
            id='layout__mobile__navigation_bottom_navigation'
            gridArea='mobile-navigation'
            sx={{
              position: 'sticky',
              zIndex: 3,
              bottom: 0
            }}
          >
            <ShoppingCartMessages
              sx={{
                px: 1,
                my: .5
              }}
            />
            <MobileNavigation/>
          </Box>
        )}

        <DevModeFloatingButton/>
      </Box>
    </LayoutDrawerProvider>
  )
}

export default Layout
