import CloseIcon from '@mui/icons-material/Close'
import { IconButton } from '@mui/material'
import { useContext } from 'react'
import ResponsiveDialogContext from '~/src/components/mui-wrappers/responsive-dialog/ResponsiveDialogContext'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { PlatformUtils } from '@eo-storefronts/eo-core'

const ProductConfigurationCloseButton = () => {
  const responsiveDialogContext = useContext(ResponsiveDialogContext)
  const muiTheme = useCustomMuiTheme()

  const _handleOnClose = () => {
    responsiveDialogContext?.toggleClose && responsiveDialogContext.toggleClose()
  }

  return (
    <IconButton
      sx={{
        color: 'secondary.contrastText',
        backgroundColor: 'secondary.main',
        position: 'absolute',
        top: `calc(var(--eo-safe-area-top) - 1px + ${muiTheme.spacing(2)} + 2px)`,
        right: `calc(var(--eo-safe-area-right) + ${muiTheme.spacing(2)})`,
        zIndex: 11,
        p: 0.5,
        mr: 1,
        [muiTheme.breakpoints.down('md')]: {
          position: 'fixed',
          top: `calc(var(--eo-safe-area-top) - 1px + ${muiTheme.spacing(2)} + ${PlatformUtils().isCapacitorNative || PlatformUtils().isMobileWeb ? '24px' : '0px'})`,
          mr: 0
        }
      }}
      onClick={_handleOnClose}
    >
      <CloseIcon/>
    </IconButton>
  )
}

export default ProductConfigurationCloseButton
