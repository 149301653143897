import { PickupPoint } from '@eo-storefronts/eo-core'

interface Props {
  point: PickupPoint,
}

const DeliveryPickupPoint = ({ point }: Props) => {
  if (point.temporaryInactive) {
    return null
  }

  return (
    <option value={point.id}>{point.name}</option>
  )
}

export default DeliveryPickupPoint
