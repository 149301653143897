import { Id, OrderSummary } from '@eo-storefronts/eo-core'
import { createSelector, createSelectorFamily, createState } from '~/src/hooks/useEoState'
import { IS_LOGGED_IN_AS_SELECTOR_FAMILY } from '~/src/stores/authentication'
import { indexDbPersist } from '~/src/helpers/recoilPersist'

const keyLastGuestOrderIdState = 'lastGuestOrderIdState'
const keyOrderDetailsState = 'orderDetailsState'

export const LAST_GUEST_ORDER_ID_STATE = createState<Id | null>({
  key: keyLastGuestOrderIdState,
  default: null,
  effects: [ indexDbPersist(keyLastGuestOrderIdState) ]
})

export const ORDER_DETAILS_STATE = createState<OrderSummary | null>({
  key: keyOrderDetailsState,
  default: null
})

export const ORDER_HISTORY = createState<OrderSummary[]>({
  key: 'orderHistory',
  default: []
})

export const ORDERS_SELECTOR = createSelectorFamily<OrderSummary[], boolean>({
  key: 'ordersSelector',
  get: (includeNotPayedOrders: boolean) => ({ get }): OrderSummary[] => {
    return get(ORDER_HISTORY)
      .filter((order) => includeNotPayedOrders || order.paid)
  }
})

export const MOST_RECENT_ORDER_SELECTOR = createSelector<OrderSummary | null | undefined>({
  key: 'mostRecentOrderSelector',
  get: ({ get }): OrderSummary | null | undefined => {
    const orderDetail: OrderSummary | null = get(ORDER_DETAILS_STATE)
    const isLoggedInAsGuest = get(IS_LOGGED_IN_AS_SELECTOR_FAMILY('guest'))

    if (isLoggedInAsGuest) {
      return orderDetail
    }

    const mostRecentOrder: OrderSummary | undefined = get(ORDERS_SELECTOR(true))[0]

    return mostRecentOrder?.id === orderDetail?.id ? orderDetail : mostRecentOrder
  }
})
