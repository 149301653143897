import { MarketplaceCategory } from '@eo-storefronts/eo-core'
import { Box } from '@mui/material'
import { useEffect, useState } from 'react'
import MarketplaceSubHeaderLabel
  from '~/src/components/layout/sub-header/marketplace-sub-header/MarketplaceSubHeaderLabel'
import LinkTab from '~/src/components/mui-wrappers/styled/link-tab'
import Tabs from '~/src/components/mui-wrappers/styled/tabs'
import useGetMarketplaceSubHeaderTemplate
  from '~/src/components/layout/sub-header/marketplace-sub-header/styles/useGetMarketplaceSubHeaderTemplate'
import { useEoValue } from '~/src/hooks/useEoState'
import RoutesEnum from '~/src/router/enums/routes.enum'
import { LAYOUT_STATE, LayoutState } from '~/src/stores/layout'
import { MARKETPLACE_CATEGORIES_STATE } from '~/src/stores/marketplace/categories'
import { useParams } from 'react-router-dom'

const MarketplaceSubHeader = () => {
  const styles = useGetMarketplaceSubHeaderTemplate()
  const { subHeader } = useEoValue<LayoutState>(LAYOUT_STATE)
  const categories = useEoValue<MarketplaceCategory[]>(MARKETPLACE_CATEGORIES_STATE)
  const params = useParams()
  const [ selected, setSelected ] = useState<string | boolean>(false)

  const _computeClassName = (): string => {
    let className: string = subHeader.className ?? ''

    if (subHeader.variant === 'small') {
      className += ' small'
    }

    return className
  }

  useEffect(() => {
    setSelected(params.catId ?? false)
  }, [ params ])

  return (
    <Box
      id='layout_sub_header'
      component='nav'
      gridArea='sub-header'
      sx={styles.getHeaderMarketplaceSubHeaderSx()}
    >
      <Tabs
        variant='scrollable'
        scrollButtons='auto'
        className={_computeClassName()}
        value={selected}
        sx={{
          display: 'flex',
          overflowX: 'auto',
          listStyleType: 'none',
          margin: 0
        }}
      >
        {categories.map((category: MarketplaceCategory, index: number) => (
          <LinkTab
            key={index}
            href={`${RoutesEnum.CATEGORY}/${category.uid}${RoutesEnum.LOCATIONS}`}
            value={category.uid}
            label={<MarketplaceSubHeaderLabel category={category} />}
            sx={styles.getHeaderMarketplaceSubHeaderItemSx()}
          />
        ))}
      </Tabs>
    </Box>
  )
}

export default MarketplaceSubHeader
