import { Modifier, ModifierGroup } from '@eo-storefronts/eo-core'
import { useEoValue } from '~/src/hooks/useEoState'
import CartModifierGroupStateService from '~/src/services/CartModifierGroupStateService'
import { CartProductModifierGroupStateInterface } from '~/src/stores/cart/cart-product-modifier-groups'
import { CHECKOUT_TIME_STATE } from '~/src/stores/checkout'
import { MODIFIER_GROUPS_STATE } from '~/src/stores/modifier-groups'
import { MODIFIERS_STATE } from '~/src/stores/modifiers'
import { PRODUCTS_STATE } from '~/src/stores/product'

interface ReturnsType {
  isGroupMinSelectionReached(cartModifierGroups: CartProductModifierGroupStateInterface[], group: ModifierGroup): boolean,
  isGroupMaxSelectionReached(cartModifierGroups: CartProductModifierGroupStateInterface[], group: ModifierGroup): boolean,
  isGroupModifierMaxSelectionReached(cartModifierGroups: CartProductModifierGroupStateInterface[], group: ModifierGroup, modifierId: string): boolean,
  addModifierToGroup(cartModifierGroups: CartProductModifierGroupStateInterface[], group: ModifierGroup, modifier: Modifier, quantity: number): CartProductModifierGroupStateInterface[],
  setModifierToGroup(cartModifierGroups: CartProductModifierGroupStateInterface[], group: ModifierGroup, modifier: Modifier, selectedModifierGroups?: CartProductModifierGroupStateInterface[]): CartProductModifierGroupStateInterface[],
  getAllSubModifiersForGroup(groupId: string, selectedModifierGroups: CartProductModifierGroupStateInterface[], lang: string): string[],
  removeModifierFromGroup(cartModifierGroups: CartProductModifierGroupStateInterface[], group: ModifierGroup, modifierId: string): CartProductModifierGroupStateInterface[],
  validateGroups(cartModifierGroups: CartProductModifierGroupStateInterface[], modifierGroupIds: string[]): boolean,
}

const useCartModifierGroupsStateService = (): ReturnsType => {
  const modifierGroups = useEoValue(MODIFIER_GROUPS_STATE)
  const modifiers = useEoValue(MODIFIERS_STATE)
  const products = useEoValue(PRODUCTS_STATE)
  const checkoutTime = useEoValue(CHECKOUT_TIME_STATE)

  const isGroupMaxSelectionReached = (
    cartModifierGroups: CartProductModifierGroupStateInterface[],
    group: ModifierGroup
  ): boolean => {
    const service = new CartModifierGroupStateService(
      cartModifierGroups,
      modifierGroups,
      modifiers,
      products,
      checkoutTime
    )

    return service.isGroupMaxSelectionReached(group)
  }

  const isGroupMinSelectionReached = (
    cartModifierGroups: CartProductModifierGroupStateInterface[],
    group: ModifierGroup
  ): boolean => {
    const service = new CartModifierGroupStateService(
      cartModifierGroups,
      modifierGroups,
      modifiers,
      products,
      checkoutTime
    )

    return service.isGroupMinSelectionReached(group)
  }

  const isGroupModifierMaxSelectionReached = (
    cartModifierGroups: CartProductModifierGroupStateInterface[],
    group: ModifierGroup,
    modifierId: string
  ): boolean => {
    const service = new CartModifierGroupStateService(
      cartModifierGroups,
      modifierGroups,
      modifiers,
      products,
      checkoutTime
    )

    return service.isGroupModifierMaxSelectionReached(group, modifierId)
  }

  const addModifierToGroup = (
    cartModifierGroups: CartProductModifierGroupStateInterface[],
    group: ModifierGroup,
    modifier: Modifier,
    quantity: number
  ): CartProductModifierGroupStateInterface[] => {
    const service = new CartModifierGroupStateService(
      cartModifierGroups,
      modifierGroups,
      modifiers,
      products,
      checkoutTime
    )

    return service.addModifierToGroup(group, modifier, quantity)
  }

  const setModifierToGroup = (
    cartModifierGroups: CartProductModifierGroupStateInterface[],
    group: ModifierGroup,
    modifier: Modifier,
    selectedModifierGroups: CartProductModifierGroupStateInterface[] = []
  ): CartProductModifierGroupStateInterface[] => {
    const service = new CartModifierGroupStateService(
      cartModifierGroups,
      modifierGroups,
      modifiers,
      products,
      checkoutTime
    )

    return service.setModifierToGroup(group, modifier, selectedModifierGroups)
  }

  const getAllSubModifiersForGroup = (
    groupId: string,
    selectedModifierGroups: CartProductModifierGroupStateInterface[],
    lang: string
  ): string[] => {
    const service = new CartModifierGroupStateService(
      selectedModifierGroups,
      modifierGroups,
      modifiers,
      products,
      checkoutTime
    )

    return service.getAllSubModifiersForGroup(groupId, selectedModifierGroups, lang)
  }

  const removeModifierFromGroup = (
    cartModifierGroups: CartProductModifierGroupStateInterface[],
    group: ModifierGroup,
    modifierId: string
  ): CartProductModifierGroupStateInterface[] => {
    const service = new CartModifierGroupStateService(
      cartModifierGroups,
      modifierGroups,
      modifiers,
      products,
      checkoutTime
    )

    return service.removeModifierFromGroup(group, modifierId)
  }

  const validateGroups = (
    cartModifierGroups: CartProductModifierGroupStateInterface[],
    modifierGroupIds: string[]
  ): boolean => {
    const service = new CartModifierGroupStateService(
      cartModifierGroups,
      modifierGroups,
      modifiers,
      products,
      checkoutTime
    )

    return service.validateGroups(cartModifierGroups, modifierGroupIds)
  }

  return {
    isGroupMinSelectionReached,
    isGroupMaxSelectionReached,
    isGroupModifierMaxSelectionReached,
    addModifierToGroup,
    setModifierToGroup,
    getAllSubModifiersForGroup,
    removeModifierFromGroup,
    validateGroups
  }
}

export default useCartModifierGroupsStateService
