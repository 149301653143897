import { CustomerLoyaltyVoucher } from '@eo-storefronts/eo-core'
import { getLoyalty } from '~/src/services/CustomerService'
import { useEoValue, useSetEoState } from '~/src/hooks/useEoState'
import { LOYALTY_IS_ENABLED_SELECTOR } from '~/src/stores/loyalty'
import { CUSTOMER_LOYALTY_VOUCHERS } from '~/src/stores/customer'

interface ReturnType {
  fetchLoyalty(customerUid: string): Promise<CustomerLoyaltyVoucher[]>,
  fetchLoyaltyAndSetState(customerUid: string): Promise<CustomerLoyaltyVoucher[] | undefined>,
}

const useFetchCustomerLoyalty = (): ReturnType => {
  const loyaltyEnabled = useEoValue<boolean>(LOYALTY_IS_ENABLED_SELECTOR)
  const setCustomerLoyaltyVouchers = useSetEoState(CUSTOMER_LOYALTY_VOUCHERS)

  const fetchLoyalty = async (customerUid: string): Promise<CustomerLoyaltyVoucher[]> => {
    return getLoyalty(customerUid)
  }

  const fetchLoyaltyAndSetState = async (customerUid: string): Promise<CustomerLoyaltyVoucher[] | undefined> => {
    if (!loyaltyEnabled) {
      return
    }

    const loyaltyVouchers: CustomerLoyaltyVoucher[] = await fetchLoyalty(customerUid)
    setCustomerLoyaltyVouchers(loyaltyVouchers)
  }

  return {
    fetchLoyalty,
    fetchLoyaltyAndSetState
  }
}

export default useFetchCustomerLoyalty
