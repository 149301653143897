import { checkboxClasses, SxProps } from '@mui/material'
import MultiChoiceModifierTemplateServiceInterface from '~/src/components/products/modifier-groups/modifier-group/modifier-group-multi-choice/multi-choice-modifier/styles/MultiChoiceModifierTemplateServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class MultiChoiceModifierTemplateServiceStyleTwo extends TemplateServiceStyleBase implements MultiChoiceModifierTemplateServiceInterface {
  public getContainerSx(disabled: boolean): SxProps {
    return {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      filter: disabled ? 'opacity(.5) grayscale(1)' : undefined
    }
  }

  public getModifierSx(): SxProps {
    return {
      position: 'relative',
      height: '100%',
      width: '100%',
      minWidth: '100px',
      '> * ': {
        WebkitUserSelect: 'none', /* Safari */
        msUserSelect: 'none', /* IE 10 and IE 11 */
        userSelect: 'none' /* Standard syntax */
      }
    }
  }

  public getQuantitySelectorSx(quantity: number): SxProps {
    return {
      position: 'absolute',
      borderRadius: this.muiTheme.shape.borderRadius,
      p: .5,
      mx: 1,
      top: this.muiTheme.spacing(1),
      backgroundColor: 'container.main',
      color: 'container.contrastText',
      visibility: !quantity ? 'hidden' : 'visible',
      width: `calc(100% - ${this.muiTheme.spacing(1)} * 2)`,
      boxShadow: this.muiTheme.shadows[1],
      transition: 'all 250ms ease-in-out', // Smooth transition for the slide-down effect
      transform: !quantity ? 'translateY(-20px)' : 'translateY(0)', // Slide effect
      opacity: !quantity ? 0 : 1 // Fade effect for visibility change
    }
  }

  public getModifierToggleSx(selected: boolean): SxProps {
    return {
      display: 'flex',
      height: '100%',
      p: 1.5,
      cursor: 'pointer',
      borderRadius: this.muiTheme.shape.borderRadius,
      flexDirection: 'column',
      backgroundColor: 'content.main',
      color: 'content.contrastText',
      borderColor: !selected ? 'content.main' : 'primary.main',
      borderWidth: '3px',
      borderStyle: 'solid',
      boxShadow: selected ? this.muiTheme.shadows[1] : 'none',
      transition: 'all 250ms ease-in-out', // Smooth transition for the slide-down effect
      [`.${checkboxClasses.root}`]: {
        display: 'none'
      }
    }
  }

  public displayPriceAsChip(): boolean {
    return false
  }
}
