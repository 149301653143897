import { Id, Modifier, ModifierGroup, Product } from '@eo-storefronts/eo-core'
import { useContext } from 'react'
import ProductConfigurationModalContext from '~/src/components/products/modals/configuration/ProductConfigurationModalContext'
import { useProductAvailable } from '~/src/hooks/products/useProductAvailable'
import { useEoValue } from '~/src/hooks/useEoState'
import CartModifierGroupStateService from '~/src/services/CartModifierGroupStateService'
import { CHECKOUT_TIME_STATE } from '~/src/stores/checkout'
import { MODIFIER_GROUPS_STATE } from '~/src/stores/modifier-groups'
import { MODIFIERS_STATE } from '~/src/stores/modifiers'
import { PRODUCTS_STATE } from '~/src/stores/product'

interface ReturnsType {
  isAddToCartButtonEnabled(): boolean,
}

const useAddToCartButtonStatus = (product: Product): ReturnsType => {
  const modifierGroups = useEoValue<Record<string, ModifierGroup>>(MODIFIER_GROUPS_STATE)
  const modifiers = useEoValue<Record<string, Modifier>>(MODIFIERS_STATE)
  const products = useEoValue<Record<Id, Product>>(PRODUCTS_STATE)
  const checkoutTime = useEoValue(CHECKOUT_TIME_STATE)
  const { selectedModifierGroups } = useContext(ProductConfigurationModalContext)
  const { available: productAvailable } = useProductAvailable(product)
  const service = new CartModifierGroupStateService(
    selectedModifierGroups,
    modifierGroups,
    modifiers,
    products,
    checkoutTime
  )

  const isAddToCartButtonEnabled = (): boolean => {
    if (!productAvailable) {
      return false
    }

    return service.validateGroups(selectedModifierGroups, product.modifierGroups)
  }

  return {
    isAddToCartButtonEnabled
  }
}

export {
  useAddToCartButtonStatus
}
