import { Product } from '@eo-storefronts/eo-core'
import { Box } from '@mui/material'
import ProductDetailsComment from '~/src/components/products/product-details/product-details-comment'
import ProductDetailsModifierGroups from '~/src/components/products/product-details/product-details-modifier-groups'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

interface Props {
  product: Product,
}

const ProductDetailsInfo = ({ product }: Props) => {
  const muiTheme = useCustomMuiTheme()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        px: 2,
        [muiTheme.breakpoints.up('md')]: {
          maxHeight: '50vh',
          overflowY: 'auto'
        },
        [muiTheme.breakpoints.down('md')]: {
          mb: 3,
          width: '100%'
        }
      }}
    >
      <ProductDetailsModifierGroups product={product}/>
      <ProductDetailsComment/>
    </Box>
  )
}

export default ProductDetailsInfo
