import { PriceList, Product } from '@eo-storefronts/eo-core'
import { useSnackbar } from 'notistack'
import { getCartProductIndex } from '~/src/helpers/getCartProductIndex'
import useLogAddToCart from '~/src/hooks/analytics/ecommerce/useLogAddToCart'
import useDialog from '~/src/hooks/useDialog'
import { useEoState, useEoValue, useSetEoState } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { getProductPrice } from '~/src/services/ProductService'
import { CART_STATE, CartState } from '~/src/stores/cart'
import { CartProductModifierGroupStateInterface } from '~/src/stores/cart/cart-product-modifier-groups'
import { CHECKOUT_FORM_STATE, CheckoutFormState, COUPON_STATE } from '~/src/stores/checkout'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { PRODUCTS_STATE } from '~/src/stores/product'
import { PRODUCT_COMMENT_STATE } from '~/src/stores/product-comment'
import { PRICE_LIST_STATE } from '~/src/stores/product/price-list'
import { CartProduct } from '~/src/types/CartProduct'
import eventProvider from '~/src/utils/EventUtils'

interface ReturnsType {
  addToCart(
    product: Product, 
    quantity: number,
    modifierGroups: CartProductModifierGroupStateInterface[]
  ): void,
}

const useAddProductToCart = (): ReturnsType => {
  const [ coupon, setCoupon ] = useEoState(COUPON_STATE)
  const setCarts = useSetEoState(CART_STATE)
  const [ checkoutForm, setCheckoutForm ] = useEoState(CHECKOUT_FORM_STATE)
  const firm = useEoValue(FIRM_SELECTOR)
  const products = useEoValue(PRODUCTS_STATE)
  const productComment = useEoValue(PRODUCT_COMMENT_STATE)
  const priceLists = useEoValue<PriceList[]>(PRICE_LIST_STATE)
  const { dismiss } = useDialog()
  const { enqueueSnackbar } = useSnackbar()
  const { log: logAddToCart } = useLogAddToCart()
  const { t } = useTranslations()

  const addToCart = (product: Product, quantity: number, modifierGroups: CartProductModifierGroupStateInterface[]): void => {
    if (!firm || !checkoutForm.orderingMethod.method) {
      return
    }

    const isCoupon = coupon?.product?.id === product.id || coupon?.category?.id === product.categoryId

    setCarts((state: CartState) => {
      const partialCartProduct: Partial<CartProduct> = {
        id: product.id,
        comment: productComment,
        isCoupon: isCoupon,
        modifierGroups
      }

      const cartProducts: CartProduct[] = [
        ...state[firm.id] || []
      ]
      const sameItemFoundInCart: number = getCartProductIndex(cartProducts, partialCartProduct as CartProduct)

      if (sameItemFoundInCart > -1 && !isCoupon) {
        cartProducts[sameItemFoundInCart] = {
          ...cartProducts[sameItemFoundInCart],
          quantity: cartProducts[sameItemFoundInCart].quantity + quantity
        }
      } else {
        cartProducts.push({
          ...partialCartProduct,
          quantity
        } as CartProduct)
      }

      return {
        ...state,
        [firm.id]: cartProducts
      }
    })

    if (isCoupon) {
      dismiss('coupon-products-modal')
      setCheckoutForm((state: CheckoutFormState) => ({
        ...state,
        coupon: {
          code: coupon.code,
          chosen_product_id: product.id || null,
          value: product.id ?
            getProductPrice(products[product.id], priceLists)[checkoutForm.orderingMethod.method!] :
            (coupon.value || 0),
          type: coupon.type,
          product: coupon.product,
          category: coupon.category
        }
      }))
      setCoupon(null)
    }

    logAddToCart(
      {
        id: product.id,
        comment: productComment,
        modifierGroups,
        quantity,
        isCoupon: isCoupon
      }
    )

    enqueueSnackbar({
      message: t('product.addedToCart'),
      variant: 'success'
    })

    eventProvider.trigger('addToCart')
  }

  return { addToCart }
}

export {
  useAddProductToCart
}
