import { ReactNode } from 'react'
import LoginForm from '~/src/components/auth/login/LoginForm'
import ResponsiveDialog from '~/src/components/mui-wrappers/responsive-dialog'
import Title from '~/src/components/mui-wrappers/Title'
import { useTranslations } from '~/src/hooks/useTranslations'
import ResponsiveDialogProps from '~/src/types/ResponsiveDialogProps'

interface Props extends Omit<ResponsiveDialogProps, 'Title' | 'fullWidth' | 'maxWidth'> {
  activator?: ReactNode,
}

const LoginFormDialog = ({ activator, ...responsiveDialogProps }: Props) => {
  const { t } = useTranslations()

  return (
    <ResponsiveDialog
      Title={<Title
        variant='h5'
        component='p'
      >
        {t('auth.logIn')}
      </Title>}
      activator={activator}
      fullWidth
      maxWidth='xs'
      {...responsiveDialogProps}
    >
      <LoginForm
        redirectAfterLogin={false}
        fieldsColor='container'/>
    </ResponsiveDialog>
  )
}

export default LoginFormDialog
