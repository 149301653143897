import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'
import StylesOrderingMethodsSummaryPickupPointsServiceInterface
  from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-pickup-points/styles/StylesOrderingMethodsSummaryPickupPointsServiceInterface'
import { BoxProps, SxProps } from '@mui/material'
import { TypographyProps } from '@mui/material/Typography/Typography'

export default class StylesOrderingMethodsSummaryPickupPointsServiceStyleOne extends TemplateServiceStyleBase implements StylesOrderingMethodsSummaryPickupPointsServiceInterface {
  getContainerSx(): SxProps {
    return {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap'
    }
  }


  getContainerProps(): BoxProps {
    return {
      sx: this.getContainerSx()
    }
  }

  getTypographyProps(): TypographyProps {
    return {}
  }
}
