import { Box } from '@mui/material'
import AppleLoginBtn from '~/src/components/auth/social-logins/AppleLoginBtn'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import GoogleLoginBtn from '~/src/components/auth/social-logins/GoogleLoginBtn'

const SocialLogins = () => {
  const muiTheme = useCustomMuiTheme()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: 2,
        [muiTheme.breakpoints.down('sm')]: {
          gap: 1
        }
      }}
    >
      <GoogleLoginBtn />
      {/*<FacebookLoginBtn />*/}
      <AppleLoginBtn />
    </Box>
  )
}

export default SocialLogins
