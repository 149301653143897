import { Box } from '@mui/material'
import ShoppingCartActions from '~/src/components/cart/shopping-cart-actions'
import ShoppingCartCoupon from '~/src/components/cart/shopping-cart-coupon'
import ShoppingCartCouponDiscount from '~/src/components/cart/shopping-cart-coupon-discount'
import ShoppingCartTotal from '~/src/components/cart/shopping-cart-total'
import useGetShoppingCartTemplate from '~/src/components/cart/shopping-cart/styles/useGetShoppingCartTemplate'
import Fees from '~/src/components/fees/Fees'
import Divider from '~/src/components/mui-wrappers/Divider'
import OrderSubTotal from '~/src/components/orders/OrderSubTotal'
import useOrderingMethodService from '~/src/hooks/ordering-methods/useOrderingMethodService'
import { useEoValue } from '~/src/hooks/useEoState'
import { CART_TOTAL_ITEMS_SELECTOR } from '~/src/stores/cart'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import ShoppingCartLoyaltySummary
  from '~/src/components/cart/shopping-cart/shopping-cart-infos/ShoppingCartLoyaltySummary'
import ShoppingCartPhysicalVoucherSummary
  from '~/src/components/cart/shopping-cart/shopping-cart-infos/ShoppingCartPhysicalVoucherSummary'

interface Props {
  showSubTotal: boolean,
  showLoyaltySummary: boolean,
  showFees: boolean,
  showTotal: boolean,
  showCoupon: boolean,
  showActions: boolean,
}

const ShoppingCartInfos = (
  {
    showSubTotal,
    showLoyaltySummary,
    showFees,
    showTotal,
    showCoupon,
    showActions
  }: Props
) => {
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)
  const { isMethodInactive } = useOrderingMethodService()
  const totalItems = useEoValue(CART_TOTAL_ITEMS_SELECTOR)
  const styles = useGetShoppingCartTemplate()

  if (isMethodInactive(checkoutForm.orderingMethod.method) || !totalItems) {
    return null
  }

  return (
    <Box sx={styles.getShoppingCartInfoSxProps()}>
      {showSubTotal && <Divider sx={styles.getDividerSxProps()}/>}
      {showSubTotal && <OrderSubTotal/>}
      {showLoyaltySummary && (
        <>
          <ShoppingCartLoyaltySummary/>
          <ShoppingCartPhysicalVoucherSummary/>
        </>
      )}
      {showFees && <Fees/>}
      <ShoppingCartCouponDiscount/>
      {(showTotal || showCoupon || showActions) && <Divider sx={styles.getDividerSxProps()}/>}
      {showTotal && (
        <ShoppingCartTotal shoppingCartTotalPriceProps={{
          totalPriceCalculationArgs: {
            withLoyaltyVouchers: showLoyaltySummary
          }
        }}/>
      )}
      {showCoupon && <ShoppingCartCoupon displayLabel={false}/>}
      {showActions && <ShoppingCartActions/>}
    </Box>
  )
}

export default ShoppingCartInfos
