import { Alert, AlertProps, Box, BoxProps } from '@mui/material'
import { useEoValue } from '~/src/hooks/useEoState'
import { FIRM_WITH_LOCATOR_STATE_SELECTOR } from '~/src/stores/firm'
import { LANGUAGE_STATE } from '~/src/stores/lang'

export type FirmOfflineBannerProps = {
  AlertProps?: AlertProps,
} & BoxProps

const FirmOfflineBanner = ({ AlertProps = {}, ...boxProps }: FirmOfflineBannerProps) => {
  const firm = useEoValue(FIRM_WITH_LOCATOR_STATE_SELECTOR)
  const lang = useEoValue(LANGUAGE_STATE)
  const { sx: alertSx, ...otherAlertProps } = AlertProps

  return (
    <Box
      gridArea='firmOfflineAlert'
      {...boxProps}>
      <Alert
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          ...alertSx
        }}
        severity='error'
        {...otherAlertProps}
      >
        {firm?.labels?.offline_alert[lang]}
      </Alert>
    </Box>
  )
}

export default FirmOfflineBanner
