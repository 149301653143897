import { SxProps } from '@mui/material'
import ProductDetailsTemplateServiceInterface
  from '~/src/components/products/product-details/styles/ProductDetailsTemplateServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class ProductDetailsTemplateServiceStyleOne extends TemplateServiceStyleBase implements ProductDetailsTemplateServiceInterface {
  public getCardElevation(): number {
    return this.muiTheme.elevation
  }

  public getContainerSx(hasImage: boolean): SxProps {
    return {
      display: 'grid',
      position: 'relative',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gridTemplateRows: 'repeat(2, max-content) auto',
      gridTemplateAreas: this.getContainerGridTemplate(hasImage),
      gap: 2,
      [this.muiTheme.breakpoints.only('xs')]: {
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'repeat(4, auto)',
        gridTemplateAreas: this.getMobileContainerGridTemplate(hasImage)
      }
    }
  }

  public getTitleSx(): SxProps {
    return {
      gridArea: 'title'
    }
  }

  public getImageSx(): SxProps {
    return {
      gridArea: 'image',
      p: 2
    }
  }

  public getInfoSx(): SxProps {
    return {
      gridArea: 'info',
      p: 2
    }
  }

  public getFooterSx(): SxProps {
    return {
      gridArea: 'footer',
      pb: 'var(--eo-safe-area-bottom))'
    }
  }

  private getContainerGridTemplate(hasImage: boolean): string {
    if (hasImage) {
      return '"image info info" "title info info" "footer footer footer"'
    }

    return '"title title title" "info info info" "footer footer footer"'
  }

  private getMobileContainerGridTemplate(hasImage: boolean): string {
    if (hasImage) {
      return '"image" "title" "info" "footer"'
    }

    return '"title" "info" "info" "footer"'
  }
}
