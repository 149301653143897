import { SocialMedia } from '@eo-storefronts/eo-core'
import FacebookIcon from '@mui/icons-material/Facebook'
import GoogleIcon from '@mui/icons-material/Google'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import TwitterIcon from '@mui/icons-material/Twitter'
import { Link } from '@mui/material'
import { useEoValue } from '~/src/hooks/useEoState'
import { BRAND_STATE } from '~/src/stores/brand'

interface Props {
  socialType: keyof SocialMedia,
}

const BrandSocialLink = ({ socialType }: Props) => {
  const brand = useEoValue(BRAND_STATE)

  if (!brand?.settings.socialMedia[socialType]) {
    return null
  }

  return (
    <Link
      href={brand.settings.socialMedia[socialType]}
      target='_blank'
      color='inherit'
    >
      {socialType === 'facebook' && <FacebookIcon color='inherit' />}
      {socialType === 'google' && <GoogleIcon color='inherit' />}
      {socialType === 'twitter' && <TwitterIcon color='inherit' />}
      {socialType === 'linkedin' && <LinkedInIcon color='inherit' />}
      {socialType === 'instagram' && <InstagramIcon color='inherit' />}
    </Link>
  )
}

export default BrandSocialLink
