import { ApiConfiguration, Catalog, CatalogService, Id, OrderingMethodNames, PriceList } from '@eo-storefronts/eo-core'

const fetchCatalog = async (firmId: Id): Promise<Catalog> => {
  ApiConfiguration.addOrReplaceHeader('store_id', firmId.toString())

  return CatalogService.getCatalog()
}

const fetchPriceList = async (orderingMethod: OrderingMethodNames, time: Date): Promise<PriceList[]> => {
  return CatalogService.getPriceList(orderingMethod, time)
}

export {
  fetchCatalog,
  fetchPriceList
}
