import { Firm } from '@eo-storefronts/eo-core'
import { Typography } from '@mui/material'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { buildEatInSentence } from '~/src/services/EatInService'
import { FIRM_SELECTOR } from '~/src/stores/firm'

interface Props {
  method: number|null,
  number: string|null,
}

const EatInModalContent = ({ method, number }: Props) => {
  const { t } = useTranslations()
  const firm = useEoValue<Firm|null>(FIRM_SELECTOR)

  return (
    <Typography>
      {buildEatInSentence(firm, t('eatIn.message'), method, number)}
    </Typography>
  )
}

export default EatInModalContent
