import { useSetEoState } from '~/src/hooks/useEoState'
import { API_TOKEN_STATE, BaseAuthenticationState } from '~/src/stores/authentication'
import { ApiConfiguration } from '@eo-storefronts/eo-core'
import { AppInjector } from '~/src/utils/DependenciesInjectionUtils'
import AppUtils from '~/src/utils/AppUtils'

const useSetApiTokenState = () => {
  const appUtils = AppInjector.get(AppUtils)
  const setApiTokenState = useSetEoState(API_TOKEN_STATE)

  const setAppApiToken = (apiTokenState: BaseAuthenticationState | null) => {
    if (apiTokenState) {
      appUtils.setAuthResponse(apiTokenState)
      ApiConfiguration.addOrReplaceHeader('Authorization', `${apiTokenState.tokenType} ${apiTokenState.token}`)
    }

    setApiTokenState(apiTokenState)
  }

  return {
    setAppApiToken
  }
}

export default useSetApiTokenState
