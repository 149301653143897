import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import useGetComponentStyle from '~/src/hooks/layout/useGetComponentStyle'
import StylesOrderingMethodsSummaryAddressButtonServiceInterface
  from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-address-button/styles/StylesOrderingMethodsSummaryAddressButtonServiceInterface'
import getOrderingMethodsSummaryAddressButtonTemplateService
  from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-address-button/styles/GetOrderingMethodsSummaryAddressButtonTemplateService'

const useGetOrderingMethodsSummaryAddressButtonTemplate = (): StylesOrderingMethodsSummaryAddressButtonServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const componentStyle = useGetComponentStyle('orderingMethodsSummary')

  return getOrderingMethodsSummaryAddressButtonTemplateService(componentStyle, muiTheme)
}

export default useGetOrderingMethodsSummaryAddressButtonTemplate
