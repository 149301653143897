import { Modifier, Price } from '@eo-storefronts/eo-core'
import { useEoValue } from '~/src/hooks/useEoState'
import ModifierService from '~/src/services/ModifierService'
import { CartProductModifierStateInterface } from '~/src/stores/cart/cart-product-modifier-groups'
import { CHECKOUT_TIME_STATE } from '~/src/stores/checkout'
import { MODIFIER_GROUPS_STATE } from '~/src/stores/modifier-groups'
import { MODIFIERS_STATE } from '~/src/stores/modifiers'
import { PRODUCTS_STATE } from '~/src/stores/product'
import { PRICE_LIST_STATE } from '~/src/stores/product/price-list'

interface ReturnsType {
  isModifierInStock(): boolean,
  isModifierSnoozed(): boolean,
  isModifierAvailable(): boolean,
  isModifierLocked(): boolean,
  calculatePrice(cartModifier: CartProductModifierStateInterface): Price,
}

const useModifiersService = (modifier: Modifier): ReturnsType => {
  const priceLists = useEoValue(PRICE_LIST_STATE)
  const checkoutTime = useEoValue(CHECKOUT_TIME_STATE)
  const modifierGroups = useEoValue(MODIFIER_GROUPS_STATE)
  const modifiers = useEoValue(MODIFIERS_STATE)
  const products = useEoValue(PRODUCTS_STATE)
  const modifierService: ModifierService = new ModifierService(
    modifier,
    modifierGroups,
    modifiers,
    products
  )

  const isModifierInStock = (): boolean => {
    return modifierService.isModifierInStock()
  }

  const isModifierSnoozed = (): boolean => {
    return modifierService.isModifierSnoozed(checkoutTime)
  }

  const isModifierLocked = (): boolean => {
    return modifierService.isModifierLocked()
  }

  const isModifierAvailable = (): boolean => {
    return isModifierInStock() && !isModifierSnoozed()
  }

  const calculatePrice = (cartModifier: CartProductModifierStateInterface): Price => {
    return modifierService.calculatePrice(cartModifier, priceLists)
  }

  return {
    isModifierInStock,
    isModifierSnoozed,
    isModifierAvailable,
    isModifierLocked,
    calculatePrice
  }
}

export default useModifiersService
