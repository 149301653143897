import { OrderingMethodNames } from '@eo-storefronts/eo-core'
import SlideFromContainer from '~/src/components/mui-wrappers/animations/SlideFromContainer'
import OrderingMethodsSummaryMethodInformationsDelivery from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-method-informations/OrderingMethodsSummaryMethodInformationsDelivery'
import OrderingMethodsSummaryMethodInformationsPickup from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-method-informations/OrderingMethodsSummaryMethodInformationsPickup'
import { useEoValue } from '~/src/hooks/useEoState'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'

interface Props {
  showAnimation: boolean,
}

const OrderingMethodsSummaryMethodInformations = ({ showAnimation }: Props) => {
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)

  if (showAnimation) {
    if (checkoutForm.orderingMethod.method === OrderingMethodNames.DELIVERY) {
      return (
        <SlideFromContainer
          direction={'down'}
          in={showAnimation}>
          <OrderingMethodsSummaryMethodInformationsDelivery/>
        </SlideFromContainer>
      )
    }

    if (checkoutForm.orderingMethod.method === OrderingMethodNames.PICKUP) {
      return (
        <SlideFromContainer
          direction={'down'}
          in={showAnimation}>
          <OrderingMethodsSummaryMethodInformationsPickup/>
        </SlideFromContainer>
      )
    }
  }

  return null
}

export default OrderingMethodsSummaryMethodInformations
