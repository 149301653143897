import { SxProps, typographyClasses } from '@mui/material'
import ModifierGroupMultiChoiceTemplateServiceInterface
  from '~/src/components/products/modifier-groups/modifier-group/modifier-group-multi-choice/styles/ModifierGroupMultiChoiceTemplateServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class ModifierGroupMultiChoiceTemplateServiceStyleTwo extends TemplateServiceStyleBase implements ModifierGroupMultiChoiceTemplateServiceInterface {
  public getContainerSx(): SxProps {
    return {
      mb: 2
    }
  }

  public getModifiersBoxSx(hasImage: boolean): SxProps {
    return {
      '--item__width': '1fr',
      '--item__height': '150px',
      display: 'grid',
      gridTemplateColumns: `repeat(${hasImage ? 4 : 3}, minmax(0, var(--item__width)))`,
      gridAutoRows: 'var(--item__height)',
      justifyItems: 'stretch',
      gap: 1,
      maxWidth: '100%',
      width: '100%',
      mt: 1,
      [this.muiTheme.breakpoints.up('lg')]: {
        '--item__height': '180px'
      },
      [this.muiTheme.breakpoints.down('md')]: {
        gridTemplateColumns: 'repeat(2, minmax(0, var(--item__width)))'
      },
      [`.${typographyClasses.root}`]: {
        textOverflow: 'ellipsis',
        overflow: 'hidden'
      }
    }
  }
}
