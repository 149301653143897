import { Modifier } from '@eo-storefronts/eo-core'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { alpha, Box, Button, Typography } from '@mui/material'
import { useContext } from 'react'
import Price from '~/src/components/Price'
import ProductConfigurationModal, {
  ProductConfigurationModalProps
} from '~/src/components/products/modals/configuration'
import ProductConfigurationModalContext, {
  ProductConfigurationModalContextInterface
} from '~/src/components/products/modals/configuration/ProductConfigurationModalContext'
import ModifierMedia from '~/src/components/products/modifier-groups/modifier-group/modifier/modifier-media'
import useModifiersService from '~/src/hooks/products/modifier-groups/modifiers/useModifiersService'
import useCartModifierGroupsStateService from '~/src/hooks/products/modifier-groups/useCartModifierGroupsStateService'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import useDialog from '~/src/hooks/useDialog'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { modifierHasImage } from '~/src/services/ModifierService'
import { productHasImage } from '~/src/services/ProductService'
import { CartProductModifierGroupStateInterface } from '~/src/stores/cart/cart-product-modifier-groups'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import { LANGUAGE_STATE } from '~/src/stores/lang'
import { MODIFIER_GROUPS_STATE } from '~/src/stores/modifier-groups'
import { GROUP_MODIFIERS_SELECTOR } from '~/src/stores/modifiers'
import { PRODUCTS_STATE } from '~/src/stores/product'
import { DialogNameEnum } from '~/src/types/DialogNameEnum'

interface Props {
  groupId: string,
  modifierId: string | number,
}

const SingleChoiceModifierSelected = ({ groupId, modifierId }: Props) => {
  const lang = useEoValue(LANGUAGE_STATE)
  const products = useEoValue(PRODUCTS_STATE)
  const modifierGroup = useEoValue(MODIFIER_GROUPS_STATE)
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)
  const groupModifiers = useEoValue(GROUP_MODIFIERS_SELECTOR(groupId))
  const productConfigurationContext = useContext<ProductConfigurationModalContextInterface>(ProductConfigurationModalContext)
  const { getAllSubModifiersForGroup } = useCartModifierGroupsStateService()
  const { present } = useDialog()
  const theme = useCustomMuiTheme()
  const { t } = useTranslations()
  const modifier: Modifier | undefined = groupModifiers.find((modifier: Modifier) => modifier.id === modifierId)
  const { calculatePrice } = useModifiersService(modifier!)
  const hasModifiers = (modifier?.modifierGroups || []).length > 0
  const subModifierGroups: string[] = getAllSubModifiersForGroup(
    groupId,
    productConfigurationContext.selectedModifierGroups,
    lang
  )
  const modifierPrice = calculatePrice(
    productConfigurationContext
      .selectedModifierGroups
      .find((smg: CartProductModifierGroupStateInterface) => smg.id === groupId)!
      .modifiers[0]
  )

  const handleEditModifier = () => {
    present<ProductConfigurationModalProps>(
      ProductConfigurationModal,
      `${DialogNameEnum.PRODUCT_BY_ID}${modifierId}`,
      {
        product: products[modifierId],
        maxWidth: productHasImage(products[modifierId]) ? 'md' : 'xs',
        parentContext: productConfigurationContext,
        group: modifierGroup[groupId],
        asModifier: true
      }
    )
  }

  const resolveGridTemplate = (): Record<string, string> => {
    if (!modifier) {
      return {
        columns: '',
        rows: '',
        areas: ''
      }
    }

    const rowsLength = [
      true,
      hasModifiers,
      modifierPrice[checkoutForm.orderingMethod.method!] > 0,
      hasModifiers
    ].filter(c => c).length

    return {
      columns: 'max-content auto',
      rows: `repeat(${rowsLength}, auto)`,
      areas: resolveGridTemplateArea(rowsLength)
    }
  }

  const resolveGridTemplateArea = (rowsLength: number): string => {
    if (!modifier) {
      return ''
    }

    switch (rowsLength) {
      case 1:
        return `"${modifierHasImage(modifier) ? 'image' : 'name'} name"`
      case 2:
        return `
          "${modifierHasImage(modifier) ? 'image' : 'name'} name" 
          "${modifierHasImage(modifier) ? 'image' : 'price'} price"`
      default:
        return `
          "${modifierHasImage(modifier) ? 'image' : 'name'} name" 
          "${modifierHasImage(modifier) ? 'image' : 'info'} info"
          "${modifierHasImage(modifier) ? 'image' : 'price'} price"
          "footer footer"`
    }
  }

  const gridStyles = resolveGridTemplate()

  if (!modifier) {
    return null
  }

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: gridStyles.columns,
        gridTemplateRows: gridStyles.rows,
        gridTemplateAreas: gridStyles.areas,
        gap: 1,
        width: '100%',
        backgroundColor: 'tab.main',
        borderRadius: '8px',
        color: 'background.contrastText',
        borderColor: alpha((theme.palette.background as any).contrastText, .3),
        borderWidth: '3px',
        borderStyle: 'solid',
        p: 2,
        mt: 1
      }}
    >
      {modifierHasImage(modifier) && (
        <Box sx={{ gridArea: 'image' }}>
          <ModifierMedia modifier={modifier}/>
        </Box>
      )}
      <Box sx={{ gridArea: 'name' }}>
        <Typography variant='body2' sx={{ fontWeight: 'bold' }}>{modifier.name[lang]}</Typography>
      </Box>
      {hasModifiers && (
        <Box sx={{ gridArea: 'info' }}>
          {subModifierGroups.map((content: string, index: number) => (
            <Typography key={index} variant='body2' sx={{ fontStyle: 'italic', opacity: .7 }}>
              {content}
            </Typography>
          ))}
        </Box>
      )}
      {modifierPrice[checkoutForm.orderingMethod.method!] > 0 && (
        <Box sx={{ gridArea: 'price' }}>
          <Typography variant='body2' sx={{ fontStyle: 'italic', opacity: .7 }}>
            + <Price price={modifierPrice}/>
          </Typography>
        </Box>
      )}
      {hasModifiers && (
        <Box
          sx={{
            gridArea: 'footer',
            borderTopStyle: 'solid',
            borderTopWidth: '1px',
            borderTopColor: alpha((theme.palette.background as any).contrastText, .3),
            mt: .5,
            pt: .5
          }}
        >
          <Button
            fullWidth
            sx={{
              justifyContent: 'space-between',
              px: 0,
              textTransform: 'none',
              color: 'background.contrastText'
            }}
            endIcon={<ArrowForwardIosIcon fontSize='small'/>}
            onClick={handleEditModifier}
          >
            {t('product.modifiers.edit')}
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default SingleChoiceModifierSelected
