import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import useGetComponentStyle from '~/src/hooks/layout/useGetComponentStyle'
import StylesOrderingMethodsSummaryPickupPointsServiceInterface
  from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-pickup-points/styles/StylesOrderingMethodsSummaryPickupPointsServiceInterface'
import getOrderingMethodsSummaryPickupPointsTemplateService
  from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-pickup-points/styles/GetOrderingMethodsSummaryPickupPointsTemplateService'

const useGetOrderingMethodsSummaryPickupPointsTemplate = (): StylesOrderingMethodsSummaryPickupPointsServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const componentStyle = useGetComponentStyle('orderingMethodsSummary')

  return getOrderingMethodsSummaryPickupPointsTemplateService(componentStyle, muiTheme)
}

export default useGetOrderingMethodsSummaryPickupPointsTemplate
