import { useEoValue } from '~/src/hooks/useEoState'
import { CUSTOMER_STATE } from '~/src/stores/customer'
import { Customer, Firm, SignalREventEnum, SignalRInvokeEventEnum } from '@eo-storefronts/eo-core'
import useFetchCustomerLoyalty from '~/src/hooks/customer/useFetchCustomerLoyalty'
import { BRAND_STATE } from '~/src/stores/brand'
import { useRef, useState } from 'react'
import { useAsyncEffect } from '~/src/hooks/useAsyncEffect'
import { HttpTransportType, HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr'
import Environment from '~/src/config/environment'

const useSignalRCustomerEffect = () => {
  // State and Refs
  const brand = useEoValue<Firm | null>(BRAND_STATE)
  const customer = useEoValue<Customer | null>(CUSTOMER_STATE)
  const { fetchLoyalty } = useFetchCustomerLoyalty()
  const [ connectionState, setConnectionState ] = useState<HubConnectionState | undefined>()
  const previousValue = useRef<{ brand: Firm | null, customer: Customer | null }>({ brand: null, customer: null })
  const connection = useRef<HubConnection | undefined>()

  // Helper to clean up SignalR connection
  const _clearConnection = async () => {
    const { brand: prevBrand, customer: prevCustomer } = previousValue.current

    if (prevCustomer && prevBrand) {
      await _sendAction(SignalRInvokeEventEnum.UNSUBSCRIBE, prevCustomer, prevBrand)
    }

    _stopListeningEvent(SignalREventEnum.REFRESH_VOUCHERS)

    if (connection.current) {
      await connection.current.stop()
    }

    previousValue.current = { brand: null, customer: null }
    connection.current = undefined
    setConnectionState(undefined)
  }

  // Helper to invoke SignalR methods
  const _sendAction = async (action: SignalRInvokeEventEnum, customer: Customer, brand: Firm) => {
    return connection.current?.send(action, `${brand.uid}::${customer.uid}`)
  }

  // Manage subscription updates
  const _updateSubscription = async (newCustomer: Customer, newBrand: Firm) => {
    const { brand: prevBrand, customer: prevCustomer } = previousValue.current

    if (prevBrand && prevCustomer) {
      await _sendAction(SignalRInvokeEventEnum.UNSUBSCRIBE, prevCustomer, prevBrand)
    }

    await _sendAction(SignalRInvokeEventEnum.SUBSCRIBE, newCustomer, newBrand)
    previousValue.current = { customer: newCustomer, brand: newBrand }
  }

  // Manage SignalR event listeners
  const _startListeningEvent = (event: string, callback: (...args: any[]) => void) => {
    connection.current?.on(event, callback)
  }

  const _stopListeningEvent = (event: SignalREventEnum) => {
    connection.current?.off(event)
  }

  // Event handler for refresh vouchers
  const _handleRefreshVoucher = (customer: Customer) => {
    void fetchLoyalty(customer.uid)
  }

  const _initializeSignalR = async () => {
    const hubUrl = `${Environment.getSignalREndpoint()}/customer`

    const hubConnection = new HubConnectionBuilder()
      .withUrl(
        hubUrl,
        {
          skipNegotiation: false,
          withCredentials: false,
          transport: HttpTransportType.WebSockets
        })
      .withAutomaticReconnect([ 0, 10000, 30000, 60000, 90000 ])
      .build()

    connection.current = hubConnection
    setConnectionState(hubConnection.state)

    try {
      await hubConnection.start()
      setConnectionState(hubConnection.state)

      hubConnection.onclose((error) => {
        setConnectionState(error ? HubConnectionState.Disconnected : undefined)
      })
      hubConnection.onreconnecting(() => setConnectionState(HubConnectionState.Reconnecting))
      hubConnection.onreconnected(() => setConnectionState(HubConnectionState.Connected))
    } catch (error) {
      setConnectionState(hubConnection.state)
    }
  }

  // Initialize SignalR connection
  useAsyncEffect(async () => {
    if (!Environment.isSignalREnabled()) {
      return
    }

    if (!customer) {
      await _clearConnection()
      return
    }

    await _initializeSignalR()
  }, [ customer ])

  // Handle subscription updates
  useAsyncEffect(async () => {
    if (!customer || !brand || connectionState !== HubConnectionState.Connected) {
      return
    }
    await _updateSubscription(customer, brand)
    _startListeningEvent(SignalREventEnum.REFRESH_VOUCHERS, () => _handleRefreshVoucher(customer))

    return () => {
      _stopListeningEvent(SignalREventEnum.REFRESH_VOUCHERS)
      // eslint-disable-next-line no-console
      _sendAction(SignalRInvokeEventEnum.UNSUBSCRIBE, customer, brand).catch(console.error)
    }
  }, [ connectionState, customer, brand ])
}

export default useSignalRCustomerEffect
