import DiscountIcon from '@mui/icons-material/Discount'
import { Box, Typography } from '@mui/material'
import FirmLoyaltyRewardPointBalance from '~/src/components/profile/account/loyalty/reward-card/point-balance'
import FirmLoyaltyRewardProgress from '~/src/components/profile/account/loyalty/reward-card/progress'
import useLoyaltyService from '~/src/hooks/customer/useLoyaltyService'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { BRAND_STATE } from '~/src/stores/brand'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { shadify } from '~/src/hooks/layout/useTheme'

const FirmLoyaltyRewardDiscount = () => {
  const muiTheme = useCustomMuiTheme()
  const { t } = useTranslations()
  const loyaltyService = useLoyaltyService()
  const brand = useEoValue(BRAND_STATE)

  return (
    <Box
      sx={{
        display: 'grid',
        gap: 1,
        gridTemplateColumns: 'max-content auto',
        gridTemplateRows: 'repeat(3, auto)',
        gridTemplateAreas: '"icon title"' +
          '"icon progress"' +
          '"icon points"'
      }}
    >
      <Box
        sx={{
          '--icon-size': '3rem',
          width: `calc(var(--icon-size) + ${muiTheme.spacing(3)})`,
          height: `calc(var(--icon-size) + ${muiTheme.spacing(3)})`,
          gridArea: 'icon',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '12px',
          backgroundColor: shadify(muiTheme.palette.primary.contrastText, 25)
        }}
      >
        <DiscountIcon
          color='primary'
          sx={{ fontSize: 'var(--icon-size) !important' }}/>
      </Box>
      <Typography
        sx={{
          gridArea: 'title',
          fontWeight: 'bold'
        }}
      >
        {t('profile.loyalty.discountMessage', {
          discount: `${brand?.settings.currency.symbol}${loyaltyService.getRequiredPoints()}`
        })}
      </Typography>
      <Box sx={{ gridArea: 'progress' }}>
        <FirmLoyaltyRewardProgress/>
      </Box>
      <FirmLoyaltyRewardPointBalance sx={{ gridArea: 'points' }}/>
    </Box>
  )
}

export default FirmLoyaltyRewardDiscount
