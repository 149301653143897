import { PriceList } from '@eo-storefronts/eo-core'
import useLogAnalyticEcommerceEvent from '~/src/hooks/analytics/useLogAnalyticEcommerceEvent'
import { useEoValue } from '~/src/hooks/useEoState'
import { getProductPrice } from '~/src/services/ProductService'
import { CATEGORIES_STATE } from '~/src/stores/categories'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { LANGUAGE_STATE } from '~/src/stores/lang'
import { PRODUCTS_STATE } from '~/src/stores/product'
import { PRICE_LIST_STATE } from '~/src/stores/product/price-list'
import { CartProduct } from '~/src/types/CartProduct'

interface ReturnsType {
  log(cartProduct: CartProduct): void,
}

const useLogRemoveFromCart = (): ReturnsType => {
  const {
    logGoogleEvent: logGoogleAnalyticEvent,
    logFacebookEvent: logFacebookAnalyticEvent
  } = useLogAnalyticEcommerceEvent()
  const firm = useEoValue(FIRM_SELECTOR)
  const products = useEoValue(PRODUCTS_STATE)
  const categories = useEoValue(CATEGORIES_STATE)
  const lang = useEoValue(LANGUAGE_STATE)
  const priceLists = useEoValue<PriceList[]>(PRICE_LIST_STATE)

  const log = (cartProduct: CartProduct): void => {
    const currency: string | undefined = firm?.settings.currency.code
    const product = products[cartProduct.id]

    if (!product) {
      return
    }

    logGoogleAnalyticEvent('remove_from_cart', {
      value: getProductPrice(product, priceLists) || -1,
      items: [ product ],
      currency
    })
    logFacebookAnalyticEvent('RemoveFromCart', {
      content_ids: [ product.categoryId || 'No Category found' ],
      content_name: !product.categoryId ? 'No Category found' : categories[product.categoryId].name[lang] || 'No name defined',
      content_type: 'product',
      contents: [ product ],
      currency
    }, true)
  }

  return { log }
}

export default useLogRemoveFromCart
