import { paperClasses, SxProps } from '@mui/material'
import { HeaderUserActionsProps } from '~/src/components/layout/header/header-user-actions'
import StylesHeaderServiceInterface from '~/src/components/layout/header/styles/StylesHeaderServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'
import { PlatformUtils } from '@eo-storefronts/eo-core'
import { HeaderLanguageSelectorProps } from '~/src/components/layout/header/header-language-selector'

export default class StylesHeaderServiceStyleThree extends TemplateServiceStyleBase implements StylesHeaderServiceInterface {
  public getBaseHeaderSx(): SxProps {
    return {
      display: 'grid',
      gridTemplateAreas: '"logo nav language-selector menu-burger"' +
        '"location-search location-search location-search location-search"',
      gridTemplateColumns: 'max-content 1fr repeat(2, max-content)',
      alignItems: 'center',
      gap: this.muiTheme.spacing(1),
      p: '2rem 15rem',
      pb: 0,
      [this.muiTheme.breakpoints.down('lg')]: {
        p: '2rem 5rem',
        pb: 0
      },
      [this.muiTheme.breakpoints.down('md')]: {
        pt: 1,
        px: 1,
        gridTemplateAreas: '' +
          '"menu-burger . language-selector"' +
          '"logo logo logo"' +
          '"location-search location-search location-search"',
        gridTemplateColumns: 'max-content auto max-content',
        rowGap: this.muiTheme.spacing(1)
      }
    }
  }

  public getHeaderSx(): SxProps {
    return {
      ...this.getBaseHeaderSx(),
      gridArea: 'header',
      backgroundColor: 'transparent',
      color: 'background.contrastText',
      width: '100%',
      zIndex: 2
    }
  }

  public getSimpleHeaderSx(): SxProps {
    return {
      width: '100%',
      zIndex: 3,
      p: `${this.muiTheme.spacing(3)} calc(15rem + ${this.muiTheme.spacing(4)})`,
      [this.muiTheme.breakpoints.down('lg')]: {
        p: `${this.muiTheme.spacing(2)} calc(5rem + ${this.muiTheme.spacing(4)})`
      },
      [this.muiTheme.breakpoints.down('md')]: {
        p: this.muiTheme.spacing(2),
        pt: PlatformUtils().isCapacitorNative
          ? `calc(var(--eo-safe-area-top) + ${this.muiTheme.spacing(1)})`
          : 1
      }
    }
  }

  public getMobileHeaderContainerSx(): SxProps {
    return {
      ...this.getBaseHeaderSx(),
      backgroundColor: 'transparent',
      color: 'secondary.contrastText',
      width: '100%',
      zIndex: 2,
      pt: `calc(var(--eo-safe-area-top) + ${this.muiTheme.spacing(1)}) !important`,
      px: 12
    }
  }

  public getHeaderNavSx(): SxProps {
    return {
      gridArea: 'nav',
      display: 'flex',
      justifyContent: 'flex-start',
      maxWidth: '100vw',
      [this.muiTheme.breakpoints.down('md')]: {
        display: 'none'
      }
    }
  }

  public getHeaderLogoSx(): SxProps {
    return {
      gridArea: 'logo',
      mr: 2,
      [this.muiTheme.breakpoints.down('md')]: {
        m: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }
    }
  }

  public getMenuBurgerSx(): SxProps {
    return {
      gridArea: 'menu-burger',
      justifySelf: 'start',
      svg: {
        width: '2rem',
        height: '2rem'
      }
    }
  }

  public getHeaderCurrentFirmLogoSx(): SxProps {
    return {
      position: 'relative',
      a: {
        img: {
          height: '50px'
        }
      },
      [this.muiTheme.breakpoints.down('md')]: {
        a: {
          img: {
            height: '6rem'
          }
        }
      }
    }
  }

  public getHeaderCurrentFirmSx(): SxProps {
    return {
      gridArea: 'current-firm'
    }
  }

  public getHeaderDeliveryOptionsSx(): SxProps {
    return {
      gridArea: 'ordering-methods'
    }
  }

  public getHeaderLanguageSelectorSx(): SxProps {
    return {
      gridArea: 'language-selector',
      [this.muiTheme.breakpoints.down('md')]: {
        justifySelf: 'end'
      }
    }
  }

  public getHeaderLanguageSelectorProps(): HeaderLanguageSelectorProps {
    return {
      showAsCode: true,
      color: undefined,
      useFlag: true,
      variant: 'outlined',
      useAutomaticShading: false
    }
  }

  public getHeaderLocationSearchSx(): SxProps {
    return {
      gridArea: 'location-search',
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '250px',
      height: '25vh',
      backgroundColor: 'transparent',
      [`& > .${paperClasses.root}`]: {
        position: 'absolute',
        zIndex: 2,
        top: '45%'
      },
      '& > img': {
        objectFit: 'cover',
        objectPosition: 'center',
        objectViewBox: 'inset(0% 0% -5% 0%)',
        width: '100%',
        height: '100%'
      },
      [this.muiTheme.breakpoints.down('md')]: {
        position: 'unset',
        minHeight: 'unset',
        height: 'unset',
        py: 2,
        px: 1,
        color: 'background.contrastText',
        '& > img': {
          display: 'none'
        },
        [`& > .${paperClasses.root}`]: {
          position: 'unset',
          zIndex: 2,
          top: '45%'
        }
      },
      [this.muiTheme.breakpoints.up('xl')]: {
        minHeight: '350px',
        height: '40vh'
      }
    }
  }

  public getHeaderUserActionsSx(): SxProps {
    return {
      gridArea: 'user-actions'
    }
  }

  public getHeaderUserActionsProps(): HeaderUserActionsProps {
    return {}
  }

  public getHeaderCartSx(): SxProps {
    return {
      gridArea: 'cart'
    }
  }
}
