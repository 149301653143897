import { Box } from '@mui/material'
import CurrentFirmLogo from '~/src/components/firms/CurrentFirmLogo'
import HeaderCart from '~/src/components/layout/header/header-cart'
import HeaderCurrentFirm from '~/src/components/layout/header/header-current-firm'
import HeaderLanguageSelector from '~/src/components/layout/header/header-language-selector'
import HeaderMenuBurger from '~/src/components/layout/header/header-menu-burger'
import HeaderNav from '~/src/components/layout/header/header-nav'
import HeaderUserActions from '~/src/components/layout/header/header-user-actions'
import useGetHeaderTemplate from '~/src/components/layout/header/styles/useGetHeaderTemplate'
import LocatorBrandPicture from '~/src/components/locator/locator-brand-picture'
import LocationSearchAutocomplete from '~/src/components/marketplace/ui/search/LocationSearchAutocomplete'
import { useEoValue } from '~/src/hooks/useEoState'
import { IS_LOGGED_IN_SELECTOR } from '~/src/stores/authentication'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { LAYOUT_STATE } from '~/src/stores/layout'
import useIsLocatorPage from '~/src/hooks/router/useIsLocatorPage'
import useGetHeaderOffset from '~/src/hooks/layout/useGetHeaderOffset'

const DesktopHeader = () => {
  const styles = useGetHeaderTemplate()
  const { isLocatorPage } = useIsLocatorPage()
  const isLoggedIn = useEoValue(IS_LOGGED_IN_SELECTOR)
  const layout = useEoValue(LAYOUT_STATE)
  const firm = useEoValue(FIRM_SELECTOR)
  const { getOffset } = useGetHeaderOffset()

  return (
    <Box
      component='header'
      id='layout_header'
      gridArea='header'
      sx={styles.getHeaderSx(isLocatorPage, getOffset())}
    >
      <Box
        id='header__logo'
        sx={styles.getHeaderLogoSx()}
      >
        <CurrentFirmLogo
          id='header__current_firm_logo'
          redirect
          LinkProps={{
            disabled: layout.header.logo.disableLink
          }}
          sx={styles.getHeaderCurrentFirmLogoSx()}
        />
      </Box>

      {layout.header.displayMenuBurger && (
        <Box
          id='header__menu_burger'
          gridArea='menu-burger'
          sx={styles.getMenuBurgerSx()}
        >
          <HeaderMenuBurger/>
        </Box>
      )}

      {layout.header.displayCurrentFirm && (
        <Box
          id='header__current_firm'
          sx={styles.getHeaderCurrentFirmSx()}
        >
          <HeaderCurrentFirm showFirmLabel={firm?.settings.theme?.showLocationInfoHeader}/>
        </Box>
      )}

      {layout.header.displayLanguagesSelector && (
        <Box
          id='header__language_selector'
          sx={styles.getHeaderLanguageSelectorSx()}
        >
          <HeaderLanguageSelector {...styles.getHeaderLanguageSelectorProps()}/>
        </Box>
      )}

      {layout.header.displayLocationSearch && (
        <Box
          id='header__location_search'
          sx={styles.getHeaderLocationSearchSx()}
        >
          <LocationSearchAutocomplete/>

          <LocatorBrandPicture/>
        </Box>
      )}

      {layout.header.displayUserActions && (
        <Box
          id='header__user_actions'
          sx={styles.getHeaderUserActionsSx()}
        >
          <HeaderUserActions {...styles.getHeaderUserActionsProps(isLoggedIn)} />
        </Box>
      )}

      {layout.header.displayCart && (
        <Box
          id='header__cart'
          sx={styles.getHeaderCartSx()}
        >
          <HeaderCart/>
        </Box>
      )}

      {(layout.header.type === 'navigation') && (
        <Box
          id='header__navigation'
          sx={styles.getHeaderNavSx()}
        >
          <HeaderNav/>
        </Box>
      )}
    </Box>
  )
}

export default DesktopHeader
