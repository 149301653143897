import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'
import { useLayout } from '~/src/hooks/layout/useLayout'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import StylesInternalErrorServiceInterface
  from '~/src/pages/errors/internal-error/styles/StylesInternalErrorServiceInterface'
import getInternalErrorTemplateService from '~/src/pages/errors/internal-error/styles/GetInternalErrorTemplateService'

const useGetInternalErrorTemplate = (): StylesInternalErrorServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle()

  useLayout('notFound')

  return getInternalErrorTemplateService(pageStyle, muiTheme)
}

export default useGetInternalErrorTemplate
