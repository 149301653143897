import { Product } from '@eo-storefronts/eo-core'
import { Box } from '@mui/material'
import { useEffect } from 'react'
import Card from '~/src/components/mui-wrappers/Card'
import ProductAvailabilityAlerts from '~/src/components/products/ProductAvailabilityAlerts'
import ProductMaxQuantityAlert from '~/src/components/products/ProductMaxQuantityAlert'
import ProductConfigurationCloseButton
  from '~/src/components/products/modals/configuration/ProductConfigurationCloseButton'
import ProductDetailsFooter from '~/src/components/products/product-details/product-details-footer'
import ProductDetailsImg from '~/src/components/products/product-details/product-details-img'
import ProductDetailsInfoContainer from '~/src/components/products/product-details/product-details-info-container'
import useProductDetailsTemplateService
  from '~/src/components/products/product-details/styles/useProductDetailsTemplateService'
import useLogViewItem from '~/src/hooks/analytics/ecommerce/useLogViewItem'
import { productHasImage } from '~/src/services/ProductService'
import ProductDetailsTitle from './product-details-title'

interface Props {
  product: Product,
}

const ProductDetails = ({ product }: Props) => {
  const { log: logViewItem } = useLogViewItem()
  const styles = useProductDetailsTemplateService()

  useEffect(() => {
    logViewItem(product)
  }, [])

  return (
    <Card
      id='product_details'
      sx={{ p: 0 }}
      elevation={styles.getCardElevation()}>
      <ProductConfigurationCloseButton/>
      <ProductAvailabilityAlerts product={product}/>
      <ProductMaxQuantityAlert product={product}/>
      <Box sx={styles.getContainerSx(productHasImage(product))}>
        <ProductDetailsImg product={product}/>
        <Box sx={styles.getTitleSx()}>
          <ProductDetailsTitle product={product}/>
        </Box>
        <Box sx={styles.getInfoSx(productHasImage(product))}>
          <ProductDetailsInfoContainer product={product}/>
        </Box>
        <Box sx={styles.getFooterSx(productHasImage(product))}>
          <ProductDetailsFooter product={product}/>
        </Box>
      </Box>
    </Card>
  )
}

export default ProductDetails
