import { Address, Customer } from '@eo-storefronts/eo-core'
import { useSnackbar } from 'notistack'
import { useCallback } from 'react'
import { useFirmPathResolver } from '~/src/hooks/router/useFirmPathResolver'
import { useRouterPush } from '~/src/hooks/router/useRouterPush'
import { useEoState } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import RoutesEnum from '~/src/router/enums/routes.enum'
import { addCustomerAddress, updateCustomerAddress } from '~/src/services/CustomerService'
import { CUSTOMER_STATE } from '~/src/stores/customer'

interface ReturnsType {
  updateAddress(address: Address, redirect?: boolean, redirectTo?: string): Promise<void>,
  insertAddress(address: Address, redirect?: boolean, redirectTo?: string): Promise<void>,
}

const useCustomerAddress = (): ReturnsType => {
  const [ customer, setCustomer ] = useEoState(CUSTOMER_STATE)
  const { push } = useRouterPush()
  const { resolve } = useFirmPathResolver()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslations()

  const updateAddress = useCallback(async (address: Address, redirect = true, redirectTo: string = RoutesEnum.ACCOUNT): Promise<void> => {
    if (!customer) {
      return
    }

    updateCustomerAddress(customer.id, address)
      .then((updatedAddress: Address) => {
        const index = customer.addresses!.findIndex((address: Address) => address.id === updatedAddress.id)

        if (index > -1) {
          let addresses = [ ...customer.addresses! ]

          if (address.isDefault && !addresses[index].isDefault) {
            addresses = addresses.map((address: Address) => ({
              ...address,
              isDefault: false
            }))
          }

          addresses.splice(index, 1, updatedAddress)

          setCustomer((state: Customer | null) => {
            if (state === null) {
              return null
            }

            return {
              ...state,
              addresses
            }
          })

          enqueueSnackbar(t('profile.address.updated'), { variant: 'success' })
        } else {
          throw new Error('not found')
        }
      })
      .catch(() => {
        enqueueSnackbar(t('profile.address.failedUpdate'), { variant: 'error' })
      })
    redirect && push(resolve(redirectTo))
  }, [ customer ])

  const insertAddress = useCallback(async (address: Address, redirect = true, redirectTo: string = RoutesEnum.ACCOUNT): Promise<void> => {
    if (!customer) {
      return
    }

    addCustomerAddress(customer.id, address)
      .then((newAddress: Address) => {
        setCustomer((state: Customer | null) => {
          if (state === null) {
            return state
          }

          return {
            ...state,
            addresses: [
              ...(state.addresses || []),
              newAddress
            ]
          }
        })

        enqueueSnackbar(t('profile.address.added'), { variant: 'success' })
      })
      .catch(() => {
        enqueueSnackbar(t('profile.address.failedInsert'), { variant: 'error' })
      })

    redirect && push(resolve(redirectTo))
  }, [ customer ])

  return { updateAddress, insertAddress }
}

export {
  useCustomerAddress
}
