import deepEqual from '~/src/helpers/deepEqual'
import { CartProduct } from '~/src/types/CartProduct'

const getCartProductIndex = (cart: CartProduct[], cartProduct: CartProduct): number => (
  cart.findIndex((cp: CartProduct) => (
    cp.id === cartProduct.id
    && cp.isCoupon === cartProduct.isCoupon
    && deepEqual(cp.modifierGroups, cartProduct.modifierGroups)
  ))
)

export {
  getCartProductIndex
}
