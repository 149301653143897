import { Id } from '@eo-storefronts/eo-core'
import { useEffect, useState } from 'react'
import { useEoValue } from '~/src/hooks/useEoState'
import { checkCategoryAvailability } from '~/src/services/CategoryService'
import { CATEGORIES_STATE } from '~/src/stores/categories'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'

interface ReturnsType {
  available: boolean,
}

const useCategoryAvailable = (categoryId: Id | null): ReturnsType => {
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)
  const categories = useEoValue(CATEGORIES_STATE)
  const [ available, setAvailable ] = useState<boolean>(true)

  useEffect(() => {
    if (!categoryId) {
      return
    }

    const time: Date = checkoutForm.orderingMethod.time ? new Date(checkoutForm.orderingMethod.time) : new Date()

    setAvailable(checkCategoryAvailability(categoryId, categories, time))
  }, [ checkoutForm.orderingMethod.time, categories, categoryId ])

  return { available }
}

export {
  useCategoryAvailable
}
