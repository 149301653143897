import { Product, TemplateEnum } from '@eo-storefronts/eo-core'
import { Box } from '@mui/material'
import { memo } from 'react'
import CardMedia from '~/src/components/mui-wrappers/CardMedia'
import ProductItemPromotion from '~/src/components/products/product-item/ProductItemPromotion'
import useGetProductItemTemplate from '~/src/components/products/product-item/styles/useGetProductItemTemplate'
import { useEoValue } from '~/src/hooks/useEoState'
import { LANGUAGE_STATE } from '~/src/stores/lang'
import { SHOW_PRODUCT_IMG_SELECTOR_FAMILY } from '~/src/stores/theme'

interface Props {
  product: Product | null,
  containerTemplate?: TemplateEnum,
}

const ProductItemCardMedia = ({ product, containerTemplate }: Props) => {
  const style = useGetProductItemTemplate(containerTemplate)
  const lang = useEoValue(LANGUAGE_STATE)
  const showImage = useEoValue(SHOW_PRODUCT_IMG_SELECTOR_FAMILY(product))

  if (!product || !showImage) {
    return null
  }

  return (
    <Box sx={{ position: 'relative' }}>
      {containerTemplate === TemplateEnum.STYLE_TWO && <ProductItemPromotion product={product}/>}
      <CardMedia
        src={product.images[0]?.thumb}
        alt={product.name[lang] || ''}
        isDefault={product.images[0]?.isDefault}
        sx={style.getCardMediaStyle(showImage)}
      />
    </Box>
  )
}

export default memo(ProductItemCardMedia)
