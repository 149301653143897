import { DateUtils, OrderingMethod, OrderingMethodNames } from '@eo-storefronts/eo-core'
import { useEffect, useState } from 'react'
import { useCanOrderOnDay } from '~/src/hooks/ordering-methods/useCanOrderOnDay'
import { useEoValue } from '~/src/hooks/useEoState'
import { isOpened } from '~/src/services/OpeningHourService'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import { FIRM_WITH_LOCATOR_STATE_SELECTOR } from '~/src/stores/firm'

interface State {
  canToday: boolean,
  canTomorrow: boolean,
  canPick: boolean,
}

interface ReturnsType extends State {
  canOrderOnDay(date: Date): boolean,
}

const useOrderingMethodsDate = (): ReturnsType => {
  const firm = useEoValue(FIRM_WITH_LOCATOR_STATE_SELECTOR)
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)
  const { canOrderOnDay } = useCanOrderOnDay()
  const [ state, setState ] = useState<State>({
    canToday: false,
    canTomorrow: false,
    canPick: false
  })

  useEffect(() => {
    if (
      !firm
      || !checkoutForm.orderingMethod.method
      || checkoutForm.orderingMethod.method === OrderingMethodNames.ON_THE_SPOT
      || !checkoutForm.orderingMethod.minDate
    ) {
      return
    }

    const orderingMethod = firm.settings.orderingMethods[checkoutForm.orderingMethod.method] as OrderingMethod

    if (!orderingMethod) {
      setState({
        canToday: false,
        canTomorrow: false,
        canPick: false
      })

      return
    }

    if (
      orderingMethod.ordersOnlyDuringOpeningHours
      && (
        checkoutForm.orderingMethod.method !== OrderingMethodNames.PICKUP
        || !checkoutForm.orderingMethod.pickupPoint
        || checkoutForm.orderingMethod.pickupPoint.mainBranch
      )
    ) {
      setState({
        canToday: isOpened(firm.settings.periods.openingHours) && canOrderOnDay(new Date()),
        canTomorrow: false,
        canPick: false
      })

      return
    }

    setState({
      canToday: canOrderOnDay(new Date()),
      canTomorrow: canOrderOnDay(DateUtils.addDays(1)),
      canPick: true
    })
  }, [ firm, checkoutForm ])

  return {
    ...state,
    canOrderOnDay
  }
}

export {
  useOrderingMethodsDate
}
