import { useCallback } from 'react'
import { useEoState, useEoValue } from '~/src/hooks/useEoState'
import { CHECKOUT_FORM_STATE, CheckoutFormState } from '~/src/stores/checkout'
import { CART_TOTAL_SELECTOR } from '~/src/stores/cart'
import { OrderingMethodNames } from '@eo-storefronts/eo-core'

interface ReturnType {
  calculateDeliveryCost(): void,
}

const useCalculateDeliveryCosts = (): ReturnType => {
  const [ checkoutForm, setCheckoutFormState ] = useEoState(CHECKOUT_FORM_STATE)
  const cartTotal = useEoValue(CART_TOTAL_SELECTOR({
    withDeliveryCosts: false,
    withCheckoutOptions: false,
    withLoyaltyDiscount: false,
    withGlobalVoucherDiscount: false,
    withCategoryVoucherDiscount: false,
    withProductVoucherDiscount: false,
    withServiceFee: false
  }))

  const calculateDeliveryCost = useCallback(() => {
    let deliveryCost = 0

    if (
      checkoutForm.orderingMethod.method !== OrderingMethodNames.DELIVERY
      || checkoutForm.orderingMethod.deliveryFreeFromPrice === null
    ) {
      return
    }

    if (cartTotal < checkoutForm.orderingMethod.deliveryFreeFromPrice) {
      deliveryCost = checkoutForm.orderingMethod.initialDeliveryCost
    }

    setCheckoutFormState((state: CheckoutFormState) => ({
      ...state,
      orderingMethod: {
        ...state.orderingMethod,
        deliveryCost
      }
    }))
  }, [
    cartTotal,
    checkoutForm.orderingMethod.method,
    checkoutForm.orderingMethod.deliveryFreeFromPrice,
    checkoutForm.orderingMethod.initialDeliveryCost
  ])

  return {
    calculateDeliveryCost
  }
}

export default useCalculateDeliveryCosts
