import { useEoValue } from '~/src/hooks/useEoState'
import { CUSTOMER_STATE } from '~/src/stores/customer'
import ValidatorUtils from '~/src/utils/ValidatorUtils'
import { Customer } from '@eo-storefronts/eo-core'

interface ReturnType {
  needsExtraInfo(customer?: Customer|null): boolean,
}

const useCustomerExtraInfo = (): ReturnType => {
  const customerState = useEoValue(CUSTOMER_STATE)

  const needsExtraInfo = (customer: Customer|null = customerState) => {
    if (!customer) {
      return false
    }

    return !customer?.email
      || !customer?.lastName
      || !customer?.firstName
      || !customer?.phone
      || !ValidatorUtils.isPhoneNumberValid(customer.phone)
  }

  return {
    needsExtraInfo
  }
}

export default useCustomerExtraInfo
