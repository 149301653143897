import { Firm, Id } from '@eo-storefronts/eo-core'
import { useCallback } from 'react'
import { useSetEoState } from '~/src/hooks/useEoState'
import { fetchFirm } from '~/src/services/FirmService'
import { BRAND_STATE } from '~/src/stores/brand'

interface ReturnsType {
  fetch(id: Id, setStore?: boolean): Promise<Firm>,
}

const useFetchFirm = (): ReturnsType => {
  const setFirm = useSetEoState(BRAND_STATE)

  const fetch = useCallback(async (id: Id, setStore = true): Promise<Firm> => {
    const firm = await fetchFirm({ id: id.toString() })

    setStore && setFirm((state: Firm | null) => {
      if (firm.id === state?.id) {
        return firm
      }

      if (!state?.locations) {
        return firm
      }

      const locations = [ ...state.locations ]

      const index = locations.findIndex((location: Firm) => location.id === firm.id)

      if (index !== -1) {
        locations.splice(index, 1, firm)
      }

      return {
        ...state,
        locations
      }
    })

    return firm
  }, [])

  return { fetch }
}

export {
  useFetchFirm
}
