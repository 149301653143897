import { Box, Typography } from '@mui/material'
import { useTranslations } from '~/src/hooks/useTranslations'
import { useEoValue } from '~/src/hooks/useEoState'
import { Customer } from '@eo-storefronts/eo-core'
import { CUSTOMER_STATE } from '~/src/stores/customer'

const SideDrawerGreetingsOnline = () => {
  const { t } = useTranslations()
  const customer = useEoValue<Customer | null>(CUSTOMER_STATE)

  return (
    <Box>
      <Typography sx={{ fontSize: '1.3rem' }}>
        {t('sideDrawer.greetings.loggedIn')}
      </Typography>
      <Typography sx={{ fontSize: '1.3rem', fontWeight: 'bold' }}>
        {customer?.firstName}!
      </Typography>
    </Box>
  )
}

export default SideDrawerGreetingsOnline
