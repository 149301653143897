import { ApiConfiguration, Firm, Id } from '@eo-storefronts/eo-core'
import { useEoValue } from '~/src/hooks/useEoState'
import { checkLocation } from '~/src/services/CustomerService'
import {
  API_TOKEN_STATE,
  AUTHENTICATION_STATE,
  AuthenticationState,
  BaseAuthenticationState
} from '~/src/stores/authentication'
import { FIRM_SELECTOR } from '~/src/stores/firm'

interface ReturnsType {
  check(id?: Id, auth?: Partial<AuthenticationState>): Promise<unknown | undefined>,
}

const useCheckLocation = (): ReturnsType => {
  const firm: Firm | null = useEoValue(FIRM_SELECTOR)
  const authState = useEoValue<AuthenticationState>(AUTHENTICATION_STATE)
  const apiToken = useEoValue<BaseAuthenticationState | null>(API_TOKEN_STATE)

  const check = async (id?: Id, auth: Partial<AuthenticationState> = authState): Promise<unknown | undefined> => {
    const locationId: Id | undefined = id ?? firm?.id

    if (
      !locationId
            || !auth.customer?.id
            || !auth.customer?.uid
            || !auth.customer?.token
            || !apiToken?.token
            || !ApiConfiguration.getHeader('Authorization')
    ) {
      return
    }

    try {
      await checkLocation(auth.customer.id, auth.customer.uid, locationId)
      return
    } catch {
      return
    }
  }

  return { check }
}

export default useCheckLocation
