import { createState } from '~/src/hooks/useEoState'

export interface CartProductModifierStateInterface {
  id: string,
  quantity: number,
  type: string,
  action: 'add'|'rem'|'pre',
  modifierGroups: CartProductModifierGroupStateInterface[],
}

export interface CartProductModifierGroupStateInterface {
  id: string,
  modifiers: CartProductModifierStateInterface[],
}

export const CART_PRODUCT_MODIFIER_GROUP_STATE = createState<CartProductModifierGroupStateInterface[]>({
  key: 'cartProductModifierGroupsState',
  default: []
})
