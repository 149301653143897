import DeliveryFees from '~/src/components/fees/DeliveryFees'
import ServiceFees from '~/src/components/fees/ServiceFees'
import { useEoValue } from '~/src/hooks/useEoState'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'

const Fees = () => {
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)

  return (
    <>
      {checkoutForm.orderingMethod.method === 'delivery' && <DeliveryFees />}
      <ServiceFees />
    </>
  )
}

export default Fees
