enum AuthRoutesEnum {
  'LOGIN' = '/auth/login',
  'REGISTER' = '/auth/register',
  'RESET_PASSWORD' = '/auth/reset-password',
  'ACTIVATE_ACCOUNT' = '/auth/activate-account',
  'ACCOUNT_ACTIVATION' = '/auth/activation',
  'EXTRA_INFO_GUEST' = '/extra-info/guest',
  'EXTRA_INFO_CUSTOMER' = '/extra-info/customer',
}

export default AuthRoutesEnum
