import { PriceList, Product } from '@eo-storefronts/eo-core'
import Price from '~/src/components/Price'
import Title from '~/src/components/mui-wrappers/Title'
import { useEoValue } from '~/src/hooks/useEoState'
import { getProductBasePrice } from '~/src/services/ProductService'
import { PRICE_LIST_STATE } from '~/src/stores/product/price-list'

interface Props {
  product: Product,
}

const ProductPrice = ({ product }: Props) => {
  const priceLists = useEoValue<PriceList[]>(PRICE_LIST_STATE)
  
  return ( 
    <Title
      component='span'
    >
      <Price
        price={getProductBasePrice(product, priceLists)}
        displayZeroAmount={true}
      />
    </Title>
  )
}

export default ProductPrice
