import { Box, SxProps, Typography } from '@mui/material'
import { Variant } from '@mui/material/styles/createTypography'
import { TypographyPropsVariantOverrides } from '@mui/material/Typography/Typography'
import { OverridableStringUnion } from '@mui/types'
import Price from '~/src/components/Price'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { CART_SUBTOTAL_WITH_DISCOUNTS_SELECTOR } from '~/src/stores/cart'
import PriceHelper from '~/src/helpers/priceHelper'

interface Props {
  sx?: SxProps,
  textVariant?: OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides>,
  priceVariant?: OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides>,
}

const OrderSubTotal = ({ textVariant, priceVariant, sx }: Props) => {
  const { t } = useTranslations()
  const total = useEoValue(CART_SUBTOTAL_WITH_DISCOUNTS_SELECTOR)

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', ...sx }}>
      <Typography variant={textVariant}>
        {t('order.subTotal')}
      </Typography>
      <Typography variant={priceVariant}>
        <Price price={PriceHelper.transform(total)}/>
      </Typography>
    </Box>
  )
}

export default OrderSubTotal
