import { Language, TemplateEnum } from '@eo-storefronts/eo-core'
import PublicIcon from '@mui/icons-material/Public'
import FirmLanguagesFlag from '~/src/components/firms/FirmLanguagesFlag'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { LAYOUT_STATE } from '~/src/stores/layout'
import TranslateIcon from '@mui/icons-material/Translate'
import { Box } from '@mui/material'

interface Props {
  language: Language,
  showAsCode?: boolean,
}

const LanguageDropdownLabel = ({ showAsCode, language }: Props) => {
  const style = useGetPageStyle()
  const { t } = useTranslations()
  const { isMobile } = useEoValue(LAYOUT_STATE)

  if (showAsCode) {
    return (
      <Box
        component='div'
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 1
        }}>
        <TranslateIcon sx={{ ml: 1 }}/>
        <span>{language.code.toUpperCase()}</span>
      </Box>
    )
  }

  if (style === TemplateEnum.STYLE_ONE) {
    return (
      <>
        <PublicIcon/> {!isMobile && t(`global.language.${language.code}`)}
      </>
    )
  }

  return (
    <FirmLanguagesFlag
      code={language.code}
      sx={{
        ml: 1
      }}
    />
  )
}

export default LanguageDropdownLabel
