import OrderingMethodsDateTime from '~/src/components/ordering-methods/OrderingMethodsDateTime'
import Title from '~/src/components/mui-wrappers/Title'
import { useTranslations } from '~/src/hooks/useTranslations'

const OrderingMethodsDateTimeContainer = () => {
  const { t } = useTranslations()

  return (
    <>
      <Title sx={{ mb: 1 }}>{`${t('global.when')}:`}</Title>
      <OrderingMethodsDateTime />
    </>
  )
}

export default OrderingMethodsDateTimeContainer
