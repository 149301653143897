import { useEoState } from '~/src/hooks/useEoState'
import { LANGUAGE_STATE } from '~/src/stores/lang'
import { DateUtils, LanguageType } from '@eo-storefronts/eo-core'
import CookiesUtils from '~/src/utils/CookiesUtils'
import ApiConfigUtils from '~/src/utils/ApiConfigUtils'
import { useTranslations } from '~/src/hooks/useTranslations'
import { Constants } from '~/src/helpers/constants'
import { useEffect } from 'react'

const useSetLanguageState = () => {
  const { t } = useTranslations()
  const [ currentLanguage, setLanguageState ] = useEoState(LANGUAGE_STATE)

  const setAppLanguage = (lang: LanguageType | string) => {
    if (lang === '') {
      return
    }

    CookiesUtils.setCookie(CookiesUtils.LANG, lang, 365)
    ApiConfigUtils.setLanguage(lang)

    setLanguageState(lang as LanguageType)
  }

  useEffect(() => {
    if (!currentLanguage) {
      return
    }

    const dateFormatDefinitions = {
      calendar: {
        sameDay: `[${t('global.today')}] [${t('global.at')}] HH:mm`,
        nextDay: `[${t('global.tomorrow')}] [${t('global.at')}] HH:mm`,
        lastDay: `[${t('global.yesterday')}] [${t('global.at')}] HH:mm`,
        nextWeek: Constants.DEFAULT_MOMENT_CALENDAR_FORMAT,
        lastWeek: Constants.DEFAULT_MOMENT_CALENDAR_FORMAT,
        sameElse: Constants.DEFAULT_MOMENT_CALENDAR_FORMAT
      },
      relativeTime : {
        future : t('global.relativeTime.future'),
        past : t('global.relativeTime.past'),
        s : t('global.relativeTime.s'),
        m : t('global.relativeTime.m'),
        mm: t('global.relativeTime.mm'),
        h: t('global.relativeTime.h'),
        hh: t('global.relativeTime.hh'),
        d: t('global.relativeTime.d'),
        dd: t('global.relativeTime.dd'),
        M: t('global.relativeTime.M'),
        MM: t('global.relativeTime.MM'),
        y: t('global.relativeTime.y'),
        yy: t('global.relativeTime.yy')
      }
    }

    if (currentLanguage === 'no') {
      DateUtils.setLocale('nb', dateFormatDefinitions)
    } else {
      DateUtils.setLocale(currentLanguage, dateFormatDefinitions)
    }
  }, [ currentLanguage ])

  return {
    setAppLanguage
  }
}

export default useSetLanguageState
