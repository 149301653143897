import { Box } from '@mui/material'
import Button from '~/src/components/mui-wrappers/buttons/Button'
import Link from '~/src/components/mui-wrappers/Link'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { useTranslations } from '~/src/hooks/useTranslations'
import RoutesEnum from '~/src/router/enums/routes.enum'
import { FormikProps } from 'formik'
import { LoginFormInterface } from '~/src/types/forms/LoginFormInterface'
import useDialog from '~/src/hooks/useDialog'
import SocialLogins from '~/src/components/auth/social-logins/SocialLogins'

interface Props {
  loading: boolean,
  form: FormikProps<LoginFormInterface>,
}

const LoginFormActions = ({ loading, form }: Props) => {
  const { t } = useTranslations()
  const theme = useCustomMuiTheme()
  const { dismissAll } = useDialog()

  const _handleOnRegisterClick = () => {
    dismissAll()
  }

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateAreas: '"links button" "social-logins social-logins"',
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: 'max-content',
        gap: 4,
        [theme.breakpoints.down('sm')]: {
          gridTemplateColumns: '1fr',
          gridTemplateRows: 'repeat(2, max-content)',
          gridTemplateAreas: '"button"' +
            '"social-logins"' +
            '"links"',
          gap: 1
        }
      }}
    >
      <Box
        sx={{
          gridArea: 'links',
          display: 'flex',
          justifyContent: 'center',
          gap: 1,
          [theme.breakpoints.up('sm')]: {
            flexDirection: 'column',
            gap: 0
          }
        }}
      >
        <span>{t('auth.dontHaveAnAccount')}</span>
        <Link
          href={RoutesEnum.REGISTER}
          onClick={_handleOnRegisterClick}
          sx={{
            fontSize: '1em',
            fontWeight: 'bold',
            textAlign: 'left'
          }}
        >
          {t('auth.createAccount')}
        </Link>
      </Box>

      <Button
        loading={loading}
        disabled={!form.dirty || !form.isValid}
        variant='contained'
        type='submit'
        sx={{
          gridArea: 'button',
          width: '100%',
          fontWeight: 'bold',
          fontSize: '1em'
        }}
      >
        {t('auth.logIn')}
      </Button>

      <Box gridArea='social-logins'>
        <SocialLogins />
      </Box>
    </Box>
  )
}

export default LoginFormActions
