import LocationOnIcon from '@mui/icons-material/LocationOn'
import { Box } from '@mui/material'
import { useEoValue } from '~/src/hooks/useEoState'
import { BRAND_STATE } from '~/src/stores/brand'

interface Props {
  showIcon?: boolean,
}

const BrandAddress = ({ showIcon = true }: Props) => {
  const brand = useEoValue(BRAND_STATE)

  if (!brand?.address.street || !brand?.address.zipcode || !brand?.address.locality) {
    return null
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1
      }}
    >
      {showIcon && <LocationOnIcon />}

      <span>{[ brand.address.street, brand.address.zipcode, brand.address.locality ].join(' ')}</span>
    </Box>
  )
}

export default BrandAddress
