import { PriceList, Product } from '@eo-storefronts/eo-core'
import useLogAnalyticEcommerceEvent from '~/src/hooks/analytics/useLogAnalyticEcommerceEvent'
import { useEoValue } from '~/src/hooks/useEoState'
import { getProductPrice } from '~/src/services/ProductService'
import { CATEGORIES_STATE } from '~/src/stores/categories'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { LANGUAGE_STATE } from '~/src/stores/lang'
import { PRICE_LIST_STATE } from '~/src/stores/product/price-list'

interface ReturnsType {
  log(product: Product): void,
}

const useLogViewItem = (): ReturnsType => {
  const {
    logGoogleEvent: logGoogleAnalyticEvent,
    logFacebookEvent: logFacebookAnalyticEvent
  } = useLogAnalyticEcommerceEvent()
  const firm = useEoValue(FIRM_SELECTOR)
  const lang = useEoValue(LANGUAGE_STATE)
  const categories = useEoValue(CATEGORIES_STATE)
  const priceLists = useEoValue<PriceList[]>(PRICE_LIST_STATE)

  const log = (product: Product) => {
    logGoogleAnalyticEvent('view_item', {
      currency: firm?.settings.currency.code,
      value: getProductPrice(product, priceLists),
      items: [ product ]
    })
    logFacebookAnalyticEvent('ViewContent', {
      content_ids: [ product.id.toString() ],
      content_category: product.categoryId ? (categories[product.categoryId]?.name[lang] || 'No name defined') : 'No Category Found',
      content_name: product.name[lang] || 'No name defined',
      content_type: 'product',
      contents: [ product ]
    })
  }

  return { log }
}

export default useLogViewItem
