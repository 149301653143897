import { DateUtils, Product } from '@eo-storefronts/eo-core'
import { useEffect, useState } from 'react'
import { useProductAvailableState } from '~/src/hooks/products/useProductAvailableState'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { LANGUAGE_STATE } from '~/src/stores/lang'

interface ReturnsType {
  nextAvailability: string,
}

const useProductAvailabilityMessage = (product: Product): ReturnsType => {
  const lang = useEoValue(LANGUAGE_STATE)
  const { t } = useTranslations()
  const { state: productAvailableState } = useProductAvailableState(product)
  const [ nextAvailability, setNextAvailability ] = useState<string>('')

  useEffect(() => {
    if (productAvailableState.inStock && !productAvailableState.snoozed) {
      setNextAvailability('')
      return
    }

    if (!productAvailableState.inStock) {
      setNextAvailability(t('product.outOfStock'))
      return
    }

    const deliveryDate = new Date()

    setNextAvailability(`${t('product.unavailable')} ${DateUtils.calendarSentence(deliveryDate, new Date(product.snooze!.end!))}`)
  }, [ productAvailableState, lang ])

  return { nextAvailability }
}

export {
  useProductAvailabilityMessage
}
