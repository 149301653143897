import { GuestAuthResponse } from '@eo-storefronts/eo-core'
import { useState } from 'react'
import { useFirmPathResolver } from '~/src/hooks/router/useFirmPathResolver'
import useGetQueryParam from '~/src/hooks/router/useGetQueryParam'
import { useRouterPush } from '~/src/hooks/router/useRouterPush'
import Params from '~/src/router/enums/params.enum'
import RoutesEnum, { RoutesEnumType } from '~/src/router/enums/routes.enum'
import { loginAsGuest } from '~/src/services/AuthenticationService'
import { GuestFormInterface } from '~/src/types/forms/GuestFormInterface'
import useSetAuthState from '~/src/hooks/auth/useSetAuthState'

interface ReturnsType {
  login(guest: GuestFormInterface, redirect?: boolean): void,

  loading: boolean,
}

const useLoginAsGuest = (): ReturnsType => {
  const [ loading, setLoading ] = useState<boolean>(false)
  const { push } = useRouterPush()
  const { resolve } = useFirmPathResolver()
  const { params: redirectTo } = useGetQueryParam(Params.REDIRECT_TO)
  const { setAuthState } = useSetAuthState()

  const login = (guest: GuestFormInterface, redirect = true) => {
    setLoading(true)

    loginAsGuest().then((response: GuestAuthResponse) => {
      if (!response.customerToken || !response.uid) {
        return
      }

      setAuthState({
        customer: undefined,
        guest: {
          ...guest,
          uid: response.uid,
          id: response.id,
          token: response.customerToken,
          expiresIn: 3600
        },
        loggedInAs: 'guest'
      })

      if (redirectTo) {
        redirect && push(resolve(RoutesEnum[redirectTo as keyof RoutesEnumType]))
        return
      }

      redirect && push(resolve(RoutesEnum.CHECKOUT))
    }).finally(() => setLoading(false))
  }

  return { login, loading }
}

export default useLoginAsGuest
