import { Box } from '@mui/material'
import OrderingMethodsSwitch from '~/src/components/ordering-methods/OrderingMethodsSwitch'
import OrderingMethodSummaryContent
  from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-content'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

const OrderingMethodsSummaryContainerStyleOne = () => {
  const muiTheme = useCustomMuiTheme()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: 1.5,
        [muiTheme.breakpoints.down('md')]: {
          gap: 1
        }
      }}
    >
      <Box sx={{
        [muiTheme.breakpoints.down('md')]: {
          textAlign: 'center'
        }
      }}>
        <OrderingMethodsSwitch />
      </Box>
      <OrderingMethodSummaryContent />
    </Box>
  )
}

export default OrderingMethodsSummaryContainerStyleOne
