import { Id } from '@eo-storefronts/eo-core'
import useUpdateCustomerPushNotifications from '~/src/hooks/customer/useUpdateCustomerPushNotifications'
import { useEoValue } from '~/src/hooks/useEoState'
import { saveDeviceToken } from '~/src/services/CustomerService'
import { AUTHENTICATION_STATE } from '~/src/stores/authentication'

interface ReturnsType {
  saveDeviceToken(token: string, customerId?: Id): Promise<void | undefined>,
}

const useSaveDeviceToken = (): ReturnsType => {
  const authState = useEoValue(AUTHENTICATION_STATE)
  const { updatePushNotification } = useUpdateCustomerPushNotifications()

  const save = async (token: string, customerUid?: string): Promise<void | undefined> => {
    const uid = customerUid ?? authState?.customer?.uid

    if (!uid) {
      return
    }

    saveDeviceToken(uid, token)
      .then(() => {
        updatePushNotification(true)
      })
  }

  return { saveDeviceToken: save }
}

export default useSaveDeviceToken
