import { Product } from '@eo-storefronts/eo-core'
import AllergenList from '~/src/components/products/allergens/allergen-list'
import ProductDetailsDescription from '~/src/components/products/product-details/product-details-description'
import ProductDetailsInfo from '~/src/components/products/product-details/product-details-info'
import { useEoValue } from '~/src/hooks/useEoState'
import { LANGUAGE_STATE } from '~/src/stores/lang'

interface Props {
  product: Product,
}

const ProductDetailsInfoContainer = ({ product }: Props) => {
  const lang = useEoValue(LANGUAGE_STATE)

  return (
    <>
      <AllergenList allergens={product.allergens}/>
      <ProductDetailsDescription description={product.description[lang] || ''}/>
      <ProductDetailsInfo product={product}/>
    </>
  )
}

export default ProductDetailsInfoContainer
