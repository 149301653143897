import { App } from '@capacitor/app'
import {
  ApiConfiguration,
  AppService,
  Category,
  Firm,
  IndexDBService,
  LanguageType,
  Location,
  MarketplaceCategory,
  Modifier,
  ModifierGroup,
  NewsFeed,
  Product
} from '@eo-storefronts/eo-core'
import { LoaderFunctionArgs, redirect } from 'react-router-dom'
import RoutesEnum from '~/src/router/enums/routes.enum'
import FirmResolver from '~/src/router/utils/FirmResolver'
import LanguageResolver from '~/src/router/utils/LanguageResolver'
import { fetchCatalog } from '~/src/services/CatalogService'
import { fetchNewsFeed } from '~/src/services/FirmService'
import { fetchMarketplaceCategories, fetchSuggestedLocations } from '~/src/services/MarketplaceService'
import AppLoaderType from '~/src/types/AppLoaderType'
import ApiConfigUtils from '~/src/utils/ApiConfigUtils'
import AppUtils from '~/src/utils/AppUtils'
import { AppInjector } from '~/src/utils/DependenciesInjectionUtils'
import EnvUtils from '~/src/utils/EnvUtils'

const appLoader = async ({ request, params }: LoaderFunctionArgs): Promise<Response | AppLoaderType> => {
  const firmResolver = AppInjector.get(FirmResolver)
  const appUtils = AppInjector.get(AppUtils)
  const languageResolver = AppInjector.get(LanguageResolver)

  const url = new URL(request.url)
  const paths = url.pathname.split('/').filter(p => p !== '')

  try {
    await appUtils.init()

    if (params.lang) {
      ApiConfigUtils.setLanguage(params.lang)
    }

    await firmResolver.resolve(url.hostname)

    const firm = firmResolver.getFirm()

    let marketplaceCategories: MarketplaceCategory[] = []
    let marketplaceSuggestedLocations: Location[] = []
    let news: NewsFeed[] = []
    let categories: Record<string, Category> = {}
    let products: Record<string, Product> = {}
    let modifierGroups: Record<string, ModifierGroup> = {}
    let modifiers: Record<string, Modifier> = {}

    await languageResolver.resolve(firm, params.lang as LanguageType)

    // If we have one param and path is 1 and on MultiFirm
    if (Object.keys(params).length === 1 && paths.length === 1) {
      if (firm?.isMarketplace) {
        return redirect(`/${languageResolver.getLang()}${RoutesEnum.EXPLORER}`)
      }

      const childFirm = firm?.locations?.find((f: Firm) => f.sitename === paths[0])

      if (childFirm) {
        return redirect(`/${paths[0]}/${childFirm.defaultLanguage}${RoutesEnum.MENU}`)
      }

      if (firm?.locations?.length === 1) {
        return redirect(`/${firm.locations[0].sitename}/${firm.locations[0].defaultLanguage}${RoutesEnum.MENU}`)
      }

      return redirect(`/${languageResolver.getLang()}${RoutesEnum.LOCATOR}`)
    }

    // If we try to access the page /shop/lang
    if (Object.keys(params).length === 2 && paths.length === 2) {
      return redirect(`/${params.shopName}/${params.lang}${RoutesEnum.MENU}`)
    }

    if (paths.includes(RoutesEnum.LOCATOR.substring(1)) && firm?.locations?.length === 1) {
      return redirect(`/${firm?.locations[0].sitename}/${firm?.locations[0].defaultLanguage}${RoutesEnum.MENU}`)
    }

    // If we try to access the Eat-in modal without any language
    if (paths.includes(RoutesEnum.EAT_IN)) {
      if (!firm?.isMultifirm && paths[0] === RoutesEnum.EAT_IN) {
        return redirect(`/${languageResolver.getLang()}/${paths.join('/')}`)
      }

      if (firm?.isMultifirm && paths[1] === RoutesEnum.EAT_IN) {
        const siteName = paths.shift() || ''

        return redirect(`/${siteName}/${languageResolver.getLang()}/${paths.join('/')}`)
      }
    }

    // If the URL is empty
    if (!Object.keys(params).length) {
      if (firm?.isMarketplace) {
        return redirect(`/${languageResolver.getLang()}${RoutesEnum.EXPLORER}`)
      }

      if (firm?.locations?.length === 1) {
        return redirect(`/${firm.locations[0].sitename}/${firm.locations[0].defaultLanguage}${RoutesEnum.MENU}`)
      }

      return redirect(`/${languageResolver.getLang()}${RoutesEnum.LOCATOR}`)
    }

    // Fetching sub-firm if needed
    if (params.shopName && firm?.isMultifirm) {
      await firmResolver.resolveFirmByName(params.shopName)
      await languageResolver.resolve(firm, params.lang as LanguageType)
    }

    // Language is not valid! We redirect to the correct language
    if (params.lang !== languageResolver.getLang()) {
      return redirect(`/${paths.join('/')}`.replace(`/${params.lang}/`, `/${languageResolver.getLang()}/`))
    }

    // App needs update
    if (EnvUtils.isDeviceApp) {
      const appService = new AppService(
        AppInjector.get(IndexDBService),
        firm
      )
      const { id, version } = await App.getInfo()

      if (appService.isCurrentVersionLowerThanTheLatest(EnvUtils.appVersion) && !paths.includes('update')) {
        return redirect(`/${params.lang}/update`)
      }

      const { needsUpdate, latestVersion } = await appService.isLiveUpdateAvailable(id, version)

      if (needsUpdate && !paths.includes('in-app-update')) {
        return redirect(`/${params.lang}/in-app-update?version=${latestVersion}`)
      }
    }
    
    const brand: Firm | null = firmResolver.getFirm()

    ApiConfiguration.addOrReplaceHeader('storefront_id', brand?.id)

    // Finally, we fetch the products if we have a firm
    let currentFirm

    if (params.shopName) {
      currentFirm = firm?.locations?.find((location: Firm) => location.sitename === params.shopName)
    }

    if (currentFirm) {
      ({ categories, products, modifiers, modifierGroups } = await fetchCatalog(currentFirm.id))
    }

    if (firm?.isMarketplace) {
      ([ marketplaceCategories, marketplaceSuggestedLocations, news ] = await Promise.all([
        fetchMarketplaceCategories(firm.id),
        fetchSuggestedLocations(firm.id),
        fetchNewsFeed(firm.id)
      ]))
    }

    return {
      brand,
      lang: languageResolver.getLang(),
      auth: appUtils.getAuthResponse(),
      location: params.shopName || '',
      marketplaceCategories,
      marketplaceSuggestedLocations,
      categories,
      products,
      modifiers,
      modifierGroups,
      news
    }
  } catch (e) {
    throw new Response('Firm Not Found', { status: 400 })
  }
}

export default appLoader
