import { useEffect } from 'react'
import { useEoValue } from '~/src/hooks/useEoState'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { Firm, PlatformUtils } from '@eo-storefronts/eo-core'
import EnvUtils from '~/src/utils/EnvUtils'
import useDialog from '~/src/hooks/useDialog'
import DownloadAppBanner, { DownloadAppBannerProps } from '~/src/components/dialogs/download-app-banner'

const useInitDownloadAppBanner = (): void => {
  const firm: Firm | null = useEoValue(FIRM_SELECTOR)
  const { present } = useDialog()

  useEffect(() => {
    if (EnvUtils.isDeviceApp || !PlatformUtils().isMobileWeb) {
      return
    }

    let url: string | undefined

    if (PlatformUtils().isIOS ) {
      url = firm?.settings.iosAppUrl
    }

    if (PlatformUtils().isAndroid) {
      url = firm?.settings.androidAppUrl
    }

    if (!url) {
      return
    }

    present<DownloadAppBannerProps>(
      DownloadAppBanner,
      'download-app-banner',
      {
        name: 'download-app-banner',
        url
      }
    )
  }, [ firm ])
}

export default useInitDownloadAppBanner
