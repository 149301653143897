import { FirebaseMessaging, PermissionStatus } from '@capacitor-firebase/messaging'
import { useEffect, useState } from 'react'
import EnvUtils from '~/src/utils/EnvUtils'

interface ReturnsType {
  check(): Promise<boolean | undefined>,
  isAllowed: boolean,
}

const useIsNotificationPermissionsAllowed = (): ReturnsType => {
  const [ isAllowed, setIsAllowed ] = useState<boolean>(false)

  /**
   * Check if the notification permission is allowed
   */
  const check = async (): Promise<boolean | undefined> => {
    if (EnvUtils.isDeviceWeb) {
      return
    }

    const status: PermissionStatus = await FirebaseMessaging.checkPermissions()

    setIsAllowed(status.receive === 'granted')

    return status.receive === 'granted'
  }

  useEffect(() => {
    void check()
  }, [])

  return { check, isAllowed }
}

export default useIsNotificationPermissionsAllowed
