import { Firm, Modifier, ModifierGroup, Product } from '@eo-storefronts/eo-core'
import { useEffect } from 'react'
import { useEoValue, useSetEoState } from '~/src/hooks/useEoState'
import { CART_OF_FIRM_SELECTOR, CART_STATE, CartState } from '~/src/stores/cart'
import { CartProductModifierGroupStateInterface } from '~/src/stores/cart/cart-product-modifier-groups'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { MODIFIER_GROUPS_STATE } from '~/src/stores/modifier-groups'
import { MODIFIERS_STATE } from '~/src/stores/modifiers'
import { PRODUCTS_STATE } from '~/src/stores/product'
import { CartProduct } from '~/src/types/CartProduct'

let RunOnceForCartUpdate = false

const useCartCleanUpEffect = (): void => {
  const products = useEoValue<Record<string, Product>>(PRODUCTS_STATE)
  const modifierGroups = useEoValue<Record<string, ModifierGroup>>(MODIFIER_GROUPS_STATE)
  const modifiers = useEoValue<Record<string, Modifier>>(MODIFIERS_STATE)
  const firm = useEoValue<Firm | null>(FIRM_SELECTOR)
  const setCart = useSetEoState(CART_STATE)
  const cartOfFirm = useEoValue(CART_OF_FIRM_SELECTOR)

  useEffect(() => {
    cleanUpCart()
  }, [ products, firm ])

  useEffect(() => {
    if (cartOfFirm.length === 0 || RunOnceForCartUpdate) {
      return
    }

    RunOnceForCartUpdate = true
    cleanUpCart()
  }, [ cartOfFirm ])

  const validateModifierGroups = (cartModifierGroups: CartProductModifierGroupStateInterface[]): boolean => {
    for (const mg of cartModifierGroups) {
      if (!modifierGroups[mg.id]) {
        return false
      }

      for (const mod of mg.modifiers) {
        if (mod.type === 'product' && !products[mod.id]) {
          return false
        }

        if (mod.type !== 'product' && !modifiers[mod.id]) {
          return false
        }

        if (!validateModifierGroups(mod.modifierGroups)) {
          return false
        }
      }
    }

    return true
  }

  const cleanUpCart = () => {
    if (!firm || !Object.keys(products).length) {
      return
    }

    setCart((state: CartState) => {
      const currentFirmCart = [ ...state[firm.id] || [] ]

      if (!currentFirmCart.length) {
        return state
      }

      const cleanedUpCurrentFirmCartProducts: CartProduct[] = []

      currentFirmCart.forEach((cp: CartProduct) => {
        if (!products[cp.id] || cp.isCoupon) {
          return
        }

        if (!validateModifierGroups(cp.modifierGroups)) {
          return
        }

        cleanedUpCurrentFirmCartProducts.push(cp)
      })

      return {
        ...state,
        [firm.id]: cleanedUpCurrentFirmCartProducts
      }
    })
  }
}

export default useCartCleanUpEffect
