import React, { useEffect } from 'react'
import AuthProvider from '~/src/components/providers/AuthProvider'
import ConfirmDialogProvider from '~/src/components/providers/ConfirmDialogProvider'
import OrderOnTheSpotProvider from '~/src/components/providers/OrderOnTheSpotProvider'
import SnackbarProvider from '~/src/components/providers/SnackbarProvider'
import TemplateProvider from '~/src/components/providers/TemplateProvider'
import ThemeProvider from '~/src/components/providers/ThemeProvider'
import AnnouncementsDialog from '~/src/components/announcements/dialog'
import DialogsProvider from '~/src/components/providers/DialogsProvider'
import IndexDbHydrate from '~/src/components/providers/IndexDbHydrate'
import { useRouteLoaderData } from 'react-router-dom'
import useSetApiTokenState from '~/src/hooks/auth/useSetApiTokenState'
import useSetLanguageState from '~/src/hooks/language/useSetLanguageState'
import { useSetEoState } from '~/src/hooks/useEoState'
import { CATEGORIES_STATE } from '~/src/stores/categories'
import { PRODUCTS_STATE } from '~/src/stores/product'
import { MODIFIER_GROUPS_STATE } from '~/src/stores/modifier-groups'
import { MODIFIERS_STATE } from '~/src/stores/modifiers'
import AppLoaderType from '~/src/types/AppLoaderType'
import { APP_LOADER_STATE } from '~/src/stores/app-loader'
import { MARKETPLACE_CATEGORIES_STATE } from '~/src/stores/marketplace/categories'
import { MARKETPLACE_SUGGESTED_LOCATIONS_STATE } from '~/src/stores/marketplace/suggestions'
import { NEWS_STATE } from '~/src/stores/news'
import { SplashScreen } from '@capacitor/splash-screen'
import EnvUtils from '~/src/utils/EnvUtils'
import useResetStates from '~/src/hooks/useResetStates'
import { BRAND_STATE } from '~/src/stores/brand'
import { SELECTED_SHOP_STATE } from '~/src/stores/firm'
import GoogleMapsApiProvider from '~/src/components/providers/GoogleMapsApiProvider'
import GlobalEffects from '~/src/components/providers/GlobalEffects'
import { Constants } from '~/src/helpers/constants'

interface Props {
  children: React.ReactNode,
}

const Providers = ({ children }: Props) => {
  const data = useRouteLoaderData('root') as AppLoaderType
  const setAppLoader = useSetEoState(APP_LOADER_STATE)
  const { setAppApiToken } = useSetApiTokenState()
  const { setAppLanguage } = useSetLanguageState()
  const { resetStates } = useResetStates()
  const setBrand = useSetEoState(BRAND_STATE)
  const setSelectedShop = useSetEoState(SELECTED_SHOP_STATE)
  const setCategories = useSetEoState(CATEGORIES_STATE)
  const setProducts = useSetEoState(PRODUCTS_STATE)
  const setModifierGroups = useSetEoState(MODIFIER_GROUPS_STATE)
  const setModifiers = useSetEoState(MODIFIERS_STATE)
  const setMarketplaceCategories = useSetEoState(MARKETPLACE_CATEGORIES_STATE)
  const setMarketplaceSuggestions = useSetEoState(MARKETPLACE_SUGGESTED_LOCATIONS_STATE)
  const setNewsfeed = useSetEoState(NEWS_STATE)

  useEffect(() => {
    setAppApiToken(data.auth)
    setAppLanguage(data.lang)
    setBrand(data.brand)
    setSelectedShop(data.location)
    setCategories(data.categories)
    setProducts(data.products)
    setModifierGroups(data.modifierGroups)
    setModifiers(data.modifiers)
    setMarketplaceCategories(data.marketplaceCategories)
    setMarketplaceSuggestions(data.marketplaceSuggestedLocations)
    setNewsfeed(data.news)

    if (data.brand && !data.location) {
      resetStates()
    }

    setTimeout(() => {
      setAppLoader({
        state: false,
        type: 'standard'
      })
    }, Constants.DEBOUNCE_TIME)

    if (data.brand && EnvUtils.isDeviceApp) {
      void SplashScreen.hide()
    }
  }, [ data ])

  return (
    <IndexDbHydrate>
      <ThemeProvider>
        <AuthProvider>
          <GoogleMapsApiProvider>
            <SnackbarProvider>
              <DialogsProvider>
                <ConfirmDialogProvider>
                  <OrderOnTheSpotProvider>
                    {children}
                    <GlobalEffects/>
                    <AnnouncementsDialog/>
                  </OrderOnTheSpotProvider>
                </ConfirmDialogProvider>
              </DialogsProvider>
            </SnackbarProvider>
          </GoogleMapsApiProvider>
        </AuthProvider>
      </ThemeProvider>

      <TemplateProvider/>
    </IndexDbHydrate>
  )
}

export default Providers
