import { CustomerLoyaltyVoucher, DateUtils } from '@eo-storefronts/eo-core'
import { useTranslations } from '~/src/hooks/useTranslations'

type Props = {
  voucher: CustomerLoyaltyVoucher,
}

const NextExpiringVoucherDate = ({ voucher }: Props) => {
  const { t } = useTranslations()

  // Validate expiration date
  if (!voucher.validityDate ||
    DateUtils.isAfter(new Date(), new Date(voucher.validityDate))) {
    return null
  }

  return (
    <>
      {t(
        'profile.loyalty.expires',
        {
          value: DateUtils.fromNow(new Date(voucher.validityDate))
        }
      ).toLowerCase()}
    </>
  )
}

export default NextExpiringVoucherDate
