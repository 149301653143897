import { Product } from '@eo-storefronts/eo-core'
import { useContext, useEffect } from 'react'
import ProductConfigurationModalContext, { ProductConfigurationModalContextInterface } from '~/src/components/products/modals/configuration/ProductConfigurationModalContext'
import { useEoValue } from '~/src/hooks/useEoState'
import ModifierGroupService from '~/src/services/ModifierGroupService'
import { CHECKOUT_TIME_STATE } from '~/src/stores/checkout'
import { MODIFIER_GROUPS_STATE } from '~/src/stores/modifier-groups'
import { MODIFIERS_STATE } from '~/src/stores/modifiers'

const usePreselectModifiers = (product: Product): void => {
  const modifierGroups = useEoValue(MODIFIER_GROUPS_STATE)
  const modifiers = useEoValue(MODIFIERS_STATE)
  const context = useContext<ProductConfigurationModalContextInterface>(ProductConfigurationModalContext)
  const checkoutTime = useEoValue(CHECKOUT_TIME_STATE)
  const service = new ModifierGroupService(modifierGroups, modifiers)

  useEffect(() => {
    const cartModifierGroups = service.preselectModifiersForCartState(product.modifierGroups, checkoutTime)

    context.updateModifierGroups(cartModifierGroups)
  }, [])
}

export default usePreselectModifiers
