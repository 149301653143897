import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

interface ReturnsType {
  params: Record<string, string>,
}

const useRouterQuery = (): ReturnsType => {
  const { search } = useLocation()
  const [ params, setParams ] = useState<Record<string, string>>({})

  useEffect(() => {
    if (!search) {
      setParams({})
      return
    }

    const queryParams: Record<string, string> = {}

    search
      .split('&')
      .forEach((queryParam: string) => {
        const [ key, value ] = queryParam.split('=')
        const cleanedKey: string = key.replace('?', '')

        queryParams[cleanedKey] = decodeURI(value) || ''
      })

    setParams(queryParams)
  }, [ search ])

  return { params }
}

export {
  useRouterQuery
}
