import { Id, PriceList, Product, TemplateEnum } from '@eo-storefronts/eo-core'
import { Box } from '@mui/material'
import { useEffect, useState } from 'react'
import ProductPrice from '~/src/components/products/product-unit-price/ProductPrice'
import ProductUnit from '~/src/components/products/product-unit-price/ProductUnit'
import useProductUnitPriceTemplateService from '~/src/components/products/product-unit-price/styles/useProductUnitPriceTemplateService'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { getProductBasePrice } from '~/src/services/ProductService'
import { CHECKOUT_FORM_STATE, CheckoutFormState } from '~/src/stores/checkout'
import { PRODUCTS_STATE } from '~/src/stores/product'
import { PRICE_LIST_STATE } from '~/src/stores/product/price-list'

export interface ProductUnitPriceProps {
  product: Product,
  containerTemplate?: TemplateEnum,
}

const ProductUnitPrice = ({ product, containerTemplate }: ProductUnitPriceProps) => {
  const { t } = useTranslations()
  const products = useEoValue<Record<Id, Product>>(PRODUCTS_STATE)
  const priceLists = useEoValue<PriceList[]>(PRICE_LIST_STATE)
  const [ selectedProduct, setSelectedProduct ] = useState<Product>(product)
  const styles = useProductUnitPriceTemplateService(containerTemplate)
  const checkoutFormState = useEoValue<CheckoutFormState>(CHECKOUT_FORM_STATE)

  useEffect(() => {
    if (!product.subProductsId.length || !checkoutFormState.orderingMethod.method) {
      return
    }

    const prices: Record<Id, number> = {}

    let lowestPriceProductId = product.subProductsId[0]
    let lowestPrice = getProductBasePrice(products[lowestPriceProductId], priceLists)[checkoutFormState.orderingMethod.method]

    for (const productId of product.subProductsId) {
      const subProductPrice = getProductBasePrice(products[productId], priceLists)[checkoutFormState.orderingMethod.method]
      prices[productId] = subProductPrice

      if (subProductPrice < lowestPrice) {
        lowestPrice = subProductPrice
        lowestPriceProductId = productId
      }
    }

    setSelectedProduct(products[lowestPriceProductId])
  }, [ checkoutFormState.orderingMethod.method, product, products ])

  return (
    <Box sx={styles.getContainerSx()}>
      {!!product.subProductsId.length && (
        <Box
          component='span'
          sx={{ mr: .5 }}>
          {t('global.from')}
        </Box>
      )}
      <ProductPrice product={selectedProduct}/>
      <ProductUnit unit={selectedProduct.unit}/>
    </Box>
  )
}

export default ProductUnitPrice
