import { PlatformUtils } from '@eo-storefronts/eo-core'
import { drawerClasses, SwipeableDrawer as MUISwipeableDrawer, styled, SwipeableDrawerProps } from '@mui/material'
import { SyntheticEvent } from 'react'
import Puller from '~/src/components/mui-wrappers/styled/drawer/Puller'

interface Props extends SwipeableDrawerProps {
  showPuller?: boolean,
}

const SwipeableDrawerStyled = styled(({ children, showPuller = false, ...otherProps }: Props) => {
  const handleOnPullerClick = ($event: SyntheticEvent) => {
    !PlatformUtils().isMobile && otherProps.onClose($event)
  }

  return (
    <MUISwipeableDrawer
      disableSwipeToOpen
      disableBackdropTransition
      disableDiscovery
      {...otherProps}
    >
      {showPuller && (
        <Puller onClick={handleOnPullerClick}/>
      )}

      {children}
    </MUISwipeableDrawer>
  )
})(({ theme }) => ({
  [`.${drawerClasses.paper}`]: {
    padding: theme.spacing(3),
    backgroundColor: 'background.main',
    color: 'background.contrastText'
  },
  [`.${drawerClasses.paperAnchorLeft}`]: {
    paddingTop: 'var(--eo-safe-area-top)'
  },
  [`.${drawerClasses.paperAnchorBottom}`]: {
    borderTopLeftRadius: '25px',
    borderTopRightRadius: '25px',
    paddingTop: 0,
    paddingBottom: 'var(--eo-safe-area-bottom)',
    paddingLeft: 'var(--eo-safe-area-left)',
    paddingRight: 'var(--eo-safe-area-right)',
    maxHeight: CSS.supports('height: 100dvh') ? 
      'calc(100dvh - var(--eo-safe-area-top))' 
      : 'calc(100vh - var(--eo-safe-area-top))'
  }
}))

export default SwipeableDrawerStyled
