import Fee from '~/src/components/fees/Fee'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import PriceHelper from '~/src/helpers/priceHelper'

const DeliveryFees = () => {
  const { t } = useTranslations()
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)

  return (
    <Fee
      label={t('global.deliveryFee')}
      value={PriceHelper.transform(checkoutForm.orderingMethod.deliveryCost)}
    />
  )
}

export default DeliveryFees
