import { createState } from '~/src/hooks/useEoState'

const key = 'appLoaderState'

export default interface AppLoaderState {
  state: boolean,
  type: 'standard' | 'language' | 'connection' | 'account_activation',
}

export const APP_LOADER_STATE = createState<AppLoaderState>({
  key,
  default: {
    state: false,
    type: 'standard'
  }
})
