import StylesNotFoundServiceInterface from '~/src/pages/errors/not-found/styles/StylesNotFoundServiceInterface'
import StylesNotFoundServiceStyleOne from '~/src/pages/errors/not-found/styles/StylesNotFoundServiceStyleOne'
import StylesNotFoundServiceStyleThree from '~/src/pages/errors/not-found/styles/StylesNotFoundServiceStyleThree'
import StylesNotFoundServiceStyleTwo from '~/src/pages/errors/not-found/styles/StylesNotFoundServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getNotFoundTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): StylesNotFoundServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesNotFoundServiceStyleTwo(muiTheme)
    case TemplateEnum.STYLE_THREE:
      return new StylesNotFoundServiceStyleThree(muiTheme)
    default:
      return new StylesNotFoundServiceStyleOne(muiTheme)
  }
}

export default getNotFoundTemplateService
