import { Box } from '@mui/material'
import { useContext } from 'react'
import ResponsiveDialogContext, {
  ResponsiveDialogContextInterface
} from '~/src/components/mui-wrappers/responsive-dialog/ResponsiveDialogContext'
import OrderingMethodsSummaryMethod from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-method'
import OrderingMethodsSummaryMethodInformations from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-method-informations'
import { useEoValue } from '~/src/hooks/useEoState'
import { FROZEN_DELIVERY_METHOD_STATE } from '~/src/stores/checkout'

const OrderingMethodsSummaryContainerStyleTwo = () => {
  const isOrderingMethodFrozen = useEoValue(FROZEN_DELIVERY_METHOD_STATE)
  const responsiveDialogContext = useContext<ResponsiveDialogContextInterface>(ResponsiveDialogContext)

  const _handleOnClick = () => {
    if (isOrderingMethodFrozen) {
      return
    }

    responsiveDialogContext.toggleOpen && responsiveDialogContext.toggleOpen()
  }

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: .5,
        '>.ordering-methods-summary__button': {
          zIndex: 3
        },
        '>div': {
          zIndex: 1
        }
      }}
      onClick={_handleOnClick}>
      <OrderingMethodsSummaryMethod showAnimation={true}/>
      <OrderingMethodsSummaryMethodInformations showAnimation={true}/>
    </Box>
  )
}

export default OrderingMethodsSummaryContainerStyleTwo
