import { FC, ReactNode } from 'react'
import DialogsContext from '~/src/components/providers/DialogsContext'
import { useSetEoState } from '~/src/hooks/useEoState'
import { DIALOGS_OVERLAY } from '~/src/stores/dialogs_overlay'
import DialogsOverlay from '~/src/types/DialogsOverlay'

interface Props {
  children: ReactNode,
}

const DialogsProvider = ({ children }: Props) => {
  const setOverlays = useSetEoState(DIALOGS_OVERLAY)

  const _handleOnPresent = <T,>(component: FC<T>, name: string, options?: T) => {
    setOverlays((currentState: DialogsOverlay[]) => {
      const index = currentState.findIndex((overlay: DialogsOverlay) => overlay.name === name)

      if (index !== -1) {
        return currentState
      }

      return [
        ...currentState,
        {
          component,
          name,
          options: {
            ...options ?? {},
            name
          }
        }
      ]
    })
  }

  const _handleOnDismiss = (name: string) => {
    setOverlays((currentState: DialogsOverlay[]) => {
      const all = [ ...currentState ]
      const index = all.findIndex((overlay: DialogsOverlay) => overlay.name === name)

      if (index === -1) {
        return all
      }

      all.splice(index, 1)

      return all
    })
  }

  const _handleOnDismissAll = () => {
    setOverlays([])
  }

  return (
    <DialogsContext.Provider
      value={{
        present: _handleOnPresent,
        dismiss: _handleOnDismiss,
        dismissAll: _handleOnDismissAll
      }}>
      {children}
    </DialogsContext.Provider>
  )
}

export default DialogsProvider
