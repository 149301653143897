import { useEoValue } from '~/src/hooks/useEoState'
import { LANGUAGE_STATE } from '~/src/stores/lang'
import { FIRM_SELECTOR, SELECTED_SHOP_STATE } from '~/src/stores/firm'
import EnvUtils from '~/src/utils/EnvUtils'
import { BRAND_STATE } from '~/src/stores/brand'
import { PlatformUtils } from '@eo-storefronts/eo-core'

interface ReturnsType {
  generateReturnUrl(suffix: string): string,
}

const useGetEmailReturnUrl = (): ReturnsType => {
  const lang = useEoValue(LANGUAGE_STATE)
  const firm = useEoValue(FIRM_SELECTOR)
  const brand = useEoValue(BRAND_STATE)
  const selectedShop = useEoValue(SELECTED_SHOP_STATE)

  const _generateBaseReturnUrl = (): string => {
    if (EnvUtils.isDeviceApp && PlatformUtils().isCapacitorNative) {

      return `${brand?.settings?.appUrl}://localhost/`
    }

    return `${location.origin}/`
  }

  const generateReturnUrl = (suffix = '') => {
    let returnUrl = _generateBaseReturnUrl()

    if (!selectedShop) {
      returnUrl += lang
    } else {
      returnUrl += `${firm!.sitename}/${lang}`
    }

    return `${returnUrl}${suffix}`
  }

  return { generateReturnUrl }
}

export default useGetEmailReturnUrl
