import { Box } from '@mui/material'
import SlideFromContainer from '~/src/components/mui-wrappers/animations/SlideFromContainer'
import OrderingMethodsSummaryMethodSentenceDate from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-method/ordering-methods-summary-method-sentence/OrderingMethodsSummaryMethodSentenceDate'
import OrderingMethodsSummaryMethodSentenceMethod from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-method/ordering-methods-summary-method-sentence/OrderingMethodsSummaryMethodSentenceMethod'
import OrderingMethodsSummaryMethodSentenceOnTheSpot from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-method/ordering-methods-summary-method-sentence/OrderingMethodsSummaryMethodSentenceOnTheSpot'
import OrderingMethodsSummaryMethodSentenceTime from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-method/ordering-methods-summary-method-sentence/OrderingMethodsSummaryMethodSentenceTime'

interface Props {
  showAnimation: boolean,
}

const OrderingMethodsSummaryMethodSentence = ({ showAnimation }: Props) => {
  return (
    <Box
      component={'div'}
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        columnGap: .5,
        flexWrap: 'wrap',
        span: {
          display: 'inline-flex',
          alignItems: 'center',
          textAlign: 'left'
        }
      }}>
      <OrderingMethodsSummaryMethodSentenceMethod/>
      {showAnimation && (
        <SlideFromContainer in direction={'right'}>
          <OrderingMethodsSummaryMethodSentenceOnTheSpot/>
          <OrderingMethodsSummaryMethodSentenceDate/>
          <OrderingMethodsSummaryMethodSentenceTime/>
        </SlideFromContainer>
      )}
    </Box>
  )
}

export default OrderingMethodsSummaryMethodSentence
