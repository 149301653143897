import PushNotificationSwitch from '~/src/components/PushNotificationSwitch'
import { useTranslations } from '~/src/hooks/useTranslations'
import { Box, Typography } from '@mui/material'

const SideDrawerPushNotifications = () => {
  const { t } = useTranslations()

  return (
    <Box>
      <PushNotificationSwitch
        labelPlacement={'start'}
        label={
          <Typography variant='body1'>
            {t('global.usePushNotifications')}
          </Typography>
        }
        sx={{
          display: 'flex',
          ml: 0,
          justifyContent: 'space-between'
        }}
      />
    </Box>
  )
}

export default SideDrawerPushNotifications
