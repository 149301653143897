import { useEoValue } from '~/src/hooks/useEoState'
import { OrderingMethodNames, Firm } from '@eo-storefronts/eo-core'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import OrderingMethodService from '~/src/services/OrderingMethodService'

interface ReturnsType {
  isMethodEnabled(method: OrderingMethodNames | null): boolean,
  isMethodInactive(method: OrderingMethodNames | null): boolean,
}

const useOrderingMethodService = (): ReturnsType => {
  const firm = useEoValue<Firm | null>(FIRM_SELECTOR)
  const service = new OrderingMethodService(firm)

  const isMethodEnabled = (method: OrderingMethodNames | null): boolean => {
    if (!method) {
      return false
    }

    return service.isMethodEnabled(method)
  }

  const isMethodInactive = (method: OrderingMethodNames | null): boolean => {
    if (!method) {
      return true
    }

    return service.isMethodInactive(method)
  }

  return {
    isMethodEnabled,
    isMethodInactive
  }
}

export default useOrderingMethodService
