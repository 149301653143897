import { Id, IndexDBService } from '@eo-storefronts/eo-core'
import { ReactNode, useState } from 'react'
import useSetAuthState from '~/src/hooks/auth/useSetAuthState'
import { useAsyncEffect } from '~/src/hooks/useAsyncEffect'
import { useSetEoState } from '~/src/hooks/useEoState'
import { CART_STATE } from '~/src/stores/cart'
import {
  CHECKOUT_FORM_STATE,
  CHECKOUT_ORDER_ID,
  CheckoutFormState,
  OrderIdState
} from '~/src/stores/checkout'
import { MARKETPLACE_VIEWED_RECENTLY_STATE } from '~/src/stores/marketplace/viewed-recently'
import { LAST_GUEST_ORDER_ID_STATE } from '~/src/stores/orders'

interface Props {
  children: ReactNode,
}

const IndexDbHydrate = ({ children }: Props) => {
  const [ hydrated, setHydrated ] = useState<boolean>(false)
  const setCartState = useSetEoState(CART_STATE)
  const setMarketplaceViewedRecentlyState = useSetEoState(MARKETPLACE_VIEWED_RECENTLY_STATE)
  const setCheckoutFormState = useSetEoState(CHECKOUT_FORM_STATE)
  const setLastGuestOrderId = useSetEoState(LAST_GUEST_ORDER_ID_STATE)
  const setOrderId = useSetEoState<OrderIdState>(CHECKOUT_ORDER_ID)
  const { setAuthState } = useSetAuthState()
  const indexDbService = new IndexDBService()
  const keys = [
    {
      indexDbKey: 'checkoutState',
      time: -1,
      setState: (checkoutForm: CheckoutFormState) => {
        setCheckoutFormState((state: CheckoutFormState) => ({
          ...state,
          orderingMethod: {
            ...state.orderingMethod,
            address: checkoutForm.orderingMethod.address
          }
        }))
      }
    },
    {
      indexDbKey: 'marketplaceViewedRecentlyState',
      time: 172800000,
      setState: setMarketplaceViewedRecentlyState
    },
    {
      indexDbKey: 'cartState',
      time: 604800000,
      setState: setCartState
    },
    {
      indexDbKey: 'authenticationState',
      time: -1,
      setState: setAuthState
    },
    {
      indexDbKey: 'lastGuestOrderIdState',
      time: -1,
      setState: setLastGuestOrderId
    },
    {
      indexDbKey: 'checkoutOrderIdState',
      time: -1,
      setState: (checkoutOrderIdState: Id|OrderIdState) => {
        if (typeof checkoutOrderIdState !== 'object') {
          return
        }

        setOrderId(checkoutOrderIdState)
      }
    }
  ]

  useAsyncEffect(async () => {
    for (const key of keys) {
      const [ setTime, indexDbValue ] = await Promise.all([
        indexDbService.get<number>(`${key.indexDbKey}-time`),
        indexDbService.get<any>(key.indexDbKey)
      ])

      if (indexDbValue && ((setTime && setTime + key.time > Date.now()) || key.time === -1)) {
        key.setState(indexDbValue)
      } else {
        void indexDbService.delMany([ `${key.indexDbKey}-time`, key.indexDbKey ])
      }
    }

    setHydrated(true)
  }, [])

  if (!hydrated) {
    return null
  }

  return <>{children}</>
}

export default IndexDbHydrate
