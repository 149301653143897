import { HolidayPeriod } from '@eo-storefronts/eo-core'
import { Box } from '@mui/material'
import FirmHolidayPeriodSentence from '~/src/components/firms/FirmHolidayPeriodSentence'
import Title from '~/src/components/mui-wrappers/Title'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { FIRM_SELECTOR } from '~/src/stores/firm'

const FirmHolidayPeriods = () => {
  const firm = useEoValue(FIRM_SELECTOR)
  const { t } = useTranslations()

  if (!firm?.settings.periods.holidayPeriod?.length) {
    return null
  }

  return (
    <div>
      <Title variant='h5'>{t('firm.holidays')}</Title>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        {firm.settings.periods.holidayPeriod.map((period: HolidayPeriod, index: number) => <FirmHolidayPeriodSentence
          period={period}
          key={index}
        />)}
      </Box>
    </div>
  )
}

export default FirmHolidayPeriods
