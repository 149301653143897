import { Modifier } from '@eo-storefronts/eo-core'
import { createSelectorFamily, createState } from '~/src/hooks/useEoState'
import { convertProductToModifier } from '~/src/services/ProductService'
import { MODIFIER_GROUPS_STATE } from '~/src/stores/modifier-groups'
import { PRODUCTS_STATE } from '~/src/stores/product'
import { CartProductModifierStateInterface } from './cart/cart-product-modifier-groups'

export const MODIFIERS_STATE = createState<Record<string, Modifier>>({
  key: 'modifiersState',
  default: {}
})

export const GROUP_MODIFIERS_SELECTOR = createSelectorFamily<Modifier[], string>({
  key: 'groupModifiersSelectorFamily',
  get: (groupId: string) => ({ get }) => {
    const modifierGroups = get(MODIFIER_GROUPS_STATE)
    const modifiers = get(MODIFIERS_STATE)
    const products = get(PRODUCTS_STATE)

    if (!modifierGroups[groupId]) {
      return []
    }

    if (modifierGroups[groupId].type === 'rank') {
      return modifierGroups[groupId].modifiers
        .filter((productId: string) => products[productId])
        .map((productId: string) => convertProductToModifier(modifierGroups[groupId], products[productId]))
    }

    return modifierGroups[groupId].modifiers.map((modifierId: string) => modifiers[modifierId])
  }
})

interface ModifierFromCartSelectorArguments {
  cartModifier: CartProductModifierStateInterface,
  groupId: string,
}

export const MODIFIER_FROM_CART_SELECTOR = createSelectorFamily<Modifier, any>({
  key: 'modifierFromCartSelector',
  get: ({ cartModifier, groupId }: ModifierFromCartSelectorArguments) => ({ get }) => {
    const modifierGroups = get(MODIFIER_GROUPS_STATE)
    const modifiers = get(MODIFIERS_STATE)
    const products = get(PRODUCTS_STATE)

    if (cartModifier.type === 'product') {
      return convertProductToModifier(modifierGroups[groupId], products[cartModifier.id])
    }

    return modifiers[cartModifier.id]
  }
})
