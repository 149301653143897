import { Box } from '@mui/material'
import { MouseEvent, useEffect, useState } from 'react'
import OrderingMethodsTimeButtonGroup from '~/src/components/ordering-methods/OrderingMethodsTimeButtonGroup'
import OrderingMethodsTimePicker from '~/src/components/ordering-methods/OrderingMethodsTimePicker'
import { useOrderingMethodsTime } from '~/src/hooks/ordering-methods/useOrderingMethodsTime'
import { useEoState } from '~/src/hooks/useEoState'
import { CHECKOUT_FORM_STATE, CheckoutFormState } from '~/src/stores/checkout'

const OrderingMethodsTimeContainer = () => {
  const [ checkoutForm, setCheckoutForm ] = useEoState(CHECKOUT_FORM_STATE)
  const { canOrderAsap } = useOrderingMethodsTime()
  const [ selectedButtonValue, setSelectedButtonValue ] = useState<string>('')

  const _updateCheckoutFormAsap = (asap: boolean) => {
    setCheckoutForm((state: CheckoutFormState) => ({
      ...state,
      orderingMethod: {
        ...state.orderingMethod,
        asap
      }
    }))
  }

  const handleOnChange = (event: MouseEvent, value: string) => {
    if (!value) {
      return
    }

    setSelectedButtonValue(value)
    _updateCheckoutFormAsap(value === 'asap')
  }

  useEffect(() => {
    if (checkoutForm.orderingMethod.time !== checkoutForm.orderingMethod.minDate) {
      setSelectedButtonValue('pick')
      return
    }

    if (canOrderAsap) {
      _updateCheckoutFormAsap(true)
      setSelectedButtonValue('asap')
      return
    }

    _updateCheckoutFormAsap(false)
    setSelectedButtonValue('pick')
  }, [ canOrderAsap, checkoutForm.orderingMethod.minDate ])

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 1,
        flexShrink: 0,
        flexWrap: 'wrap'
      }}
    >
      <OrderingMethodsTimeButtonGroup onChange={handleOnChange} value={selectedButtonValue} />
      {selectedButtonValue === 'pick' && <OrderingMethodsTimePicker />}
    </Box>
  )
}

export default OrderingMethodsTimeContainer
