import { OrderingMethodNames } from '@eo-storefronts/eo-core'
import { Box } from '@mui/material'
import EatInLocationPicker from '~/src/components/ordering-methods/EatInLocationPicker'
import EatInMethodSelector from '~/src/components/ordering-methods/EatInMethodSelector'
import Title from '~/src/components/mui-wrappers/Title'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { useFirmAcceptsOrder } from '~/src/hooks/orders/useFirmAcceptsOrder'
import FirmClosed from '~/src/components/firms/FirmClosed'
import CanOnlyOrderDuringOperationalHoursResponsiveModal
  from '~/src/components/error/CanOnlyOrderDuringOperationalHoursResponsiveModal'

const EatInMethod = () => {
  const { t } = useTranslations()
  const firm = useEoValue(FIRM_SELECTOR)
  const { checkAll } = useFirmAcceptsOrder()

  if (!checkAll()) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Title>{`${t('global.where')}:`}</Title>
        <FirmClosed sx={{ mb: 2 }} />
        <CanOnlyOrderDuringOperationalHoursResponsiveModal />
      </Box>
    )
  }

  if (firm?.settings.orderingMethods[OrderingMethodNames.ON_THE_SPOT]?.customMethods?.length) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Title>{`${t('global.where')}:`}</Title>
        <EatInMethodSelector />
        <EatInLocationPicker />
      </Box>
    )
  }

  return <EatInLocationPicker />
}

export default EatInMethod
