import { Firm, OrderingMethodNames, OrderingMethods } from '@eo-storefronts/eo-core'

export default class OrderingMethodService {
  private readonly _firm: Firm | null

  public constructor(firm: Firm | null) {
    this._firm = firm
  }

  public isMethodEnabled(method: OrderingMethodNames): boolean {
    return Boolean(
      this._firm?.settings.orderingMethods 
      && Object.keys(this._firm.settings.orderingMethods).indexOf(method) !== -1
      && this._firm.settings.orderingMethods[method]
    )
  }

  public isMethodInactive(method: OrderingMethodNames): boolean {
    if (!this.isMethodEnabled(method)) {
      return true
    }

    return Boolean(this._firm?.settings.orderingMethods[method as keyof OrderingMethods]?.temporaryInactive)
  }
}
