import { useLayoutEffect, useState } from 'react'
import { useEoValue } from '~/src/hooks/useEoState'
import useGetDOMNodeProperties from '~/src/hooks/layout/useGetDOMNodeProperties'
import { FIRM_WITH_LOCATOR_STATE_SELECTOR } from '~/src/stores/firm'

interface ReturnsType {
  offset: number,

  getOffset(includeLayoutMessage?: boolean): number,
}

const useGetLayoutOffset = (): ReturnsType => {
  const firm = useEoValue(FIRM_WITH_LOCATOR_STATE_SELECTOR)
  const [ offset, setOffset ] = useState<number>(0)
  const { height: layoutMessageContainerHeight } = useGetDOMNodeProperties('layout_message__container')

  const getOffset = (includeLayoutMessage = true): number => {
    let calculatedOffset = 0

    if (includeLayoutMessage) {
      calculatedOffset += layoutMessageContainerHeight
    }

    return calculatedOffset
  }

  useLayoutEffect(() => {
    setOffset(getOffset())
  }, [ firm?.settings.isOffline, layoutMessageContainerHeight ])

  return {
    offset,
    getOffset
  }
}

export default useGetLayoutOffset
