import { OrderingMethodNames, OrderOnSpotCustomMethod } from '@eo-storefronts/eo-core'
import { useEffect, useState } from 'react'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { LANGUAGE_STATE } from '~/src/stores/lang'

interface ReturnsType {
  sentence: string,
}

const useEatInLocationInline = (): ReturnsType => {
  const { t } = useTranslations()
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)
  const firm = useEoValue(FIRM_SELECTOR)
  const lang = useEoValue(LANGUAGE_STATE)
  const [ sentence, setSentence ] = useState<string>('')

  useEffect(() => {
    if (
      !firm
      || (
        !checkoutForm.orderingMethod.tableNumber
        && firm?.settings.orderingMethods[OrderingMethodNames.ON_THE_SPOT]?.numberRequired
      )
    ) {
      setSentence(t('orderingMethods.chooseYourSpot'))
      return
    }

    if (!checkoutForm.orderingMethod.tableMethod && checkoutForm.orderingMethod.tableNumber) {
      setSentence(`${firm.labels?.at_table} (${firm.labels?.table_number} #${checkoutForm.orderingMethod.tableNumber})`)
      return
    }

    if (!checkoutForm.orderingMethod.tableMethod && !checkoutForm.orderingMethod.tableNumber) {
      setSentence(firm.labels?.at_table[lang] || '')
      return
    }

    const eatInLocation = firm.settings.orderingMethods[OrderingMethodNames.ON_THE_SPOT]?.customMethods?.find(
      (customMethod: OrderOnSpotCustomMethod) => customMethod.id === checkoutForm.orderingMethod.tableMethod
    )

    eatInLocation && setSentence(`${eatInLocation.name} (#${checkoutForm.orderingMethod.tableNumber})`)
  }, [
    firm,
    checkoutForm.orderingMethod.tableNumber,
    checkoutForm.orderingMethod.tableMethod
  ])

  return { sentence }
}

export {
  useEatInLocationInline
}
