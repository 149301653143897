import { TemplateEnum, Theme } from '@eo-storefronts/eo-core'
import { useCallback } from 'react'
import { Templates } from '~/src/types/Templates'
import { useEoValue } from '~/src/hooks/useEoState'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { Constants } from '~/src/helpers/constants'

interface ReturnsType {
  templates: Templates,
}

const useFirmTemplates = (): ReturnsType => {
  const firm = useEoValue(FIRM_SELECTOR)

  const getTemplateMapping = useCallback((theme: Pick<Theme, 'pages' | 'components'>) => ({
    pages: {
      // not used in a shop, only for a marketplace so by default using the marketplace_preset
      explorer: theme?.pages?.explorer || Constants.MARKETPLACE_TEMPLATE_PRESET,
      locations: theme?.pages?.locations || Constants.MARKETPLACE_TEMPLATE_PRESET,
      location: theme?.pages?.location || Constants.MARKETPLACE_TEMPLATE_PRESET,

      // firm theme pages template
      locator: theme?.pages?.locator || TemplateEnum.STYLE_ONE,
      cart: theme?.pages?.cart || TemplateEnum.STYLE_ONE,
      categories: theme?.pages?.categories || TemplateEnum.STYLE_ONE,
      category: theme?.pages?.category || TemplateEnum.STYLE_ONE,
      products: theme?.pages?.products || TemplateEnum.STYLE_ONE,
      product: theme?.pages?.product || TemplateEnum.STYLE_ONE,
      search: theme?.pages?.categories || TemplateEnum.STYLE_ONE,
      login: theme?.pages?.authentication || TemplateEnum.STYLE_ONE,
      register: theme?.pages?.authentication || TemplateEnum.STYLE_ONE,
      activateAccount: theme?.pages?.authentication || TemplateEnum.STYLE_ONE,
      activation: theme?.pages?.authentication || TemplateEnum.STYLE_ONE,
      resetPassword: theme?.pages?.authentication || TemplateEnum.STYLE_ONE,
      checkout: theme?.pages?.checkout || TemplateEnum.STYLE_ONE,
      extraInfoGuest: theme?.pages?.authentication || TemplateEnum.STYLE_ONE,
      extraInfoCustomer: theme?.pages?.authentication || TemplateEnum.STYLE_ONE,
      privacyPolicy: theme?.pages?.authentication || TemplateEnum.STYLE_ONE,
      termsAndConditions: theme?.pages?.authentication || TemplateEnum.STYLE_ONE,
      order: theme?.pages?.authentication || TemplateEnum.STYLE_ONE,
      profile: theme?.pages?.authentication || TemplateEnum.STYLE_ONE,
      firmInfos: theme?.pages?.location || TemplateEnum.STYLE_ONE,
      news: theme?.pages?.news || TemplateEnum.STYLE_ONE,
      newsDetails: theme?.pages?.news || TemplateEnum.STYLE_ONE,
      notFound: theme?.pages?.location || TemplateEnum.STYLE_ONE,
      update: theme?.pages?.location || TemplateEnum.STYLE_ONE
    },
    components: {
      modifiers: theme?.components?.modifiers || TemplateEnum.STYLE_ONE,
      header: theme?.components?.header?.style || TemplateEnum.STYLE_ONE,
      orderingMethodsSummary: theme?.components?.orderingMethodsSummary || TemplateEnum.STYLE_ONE
    }
  }), [ firm ])

  return {
    templates: getTemplateMapping({
      pages: firm?.settings?.theme?.pages,
      components: firm?.settings?.theme?.components
    })
  }
}

export default useFirmTemplates
