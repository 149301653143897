import { Box, BoxProps } from '@mui/material'
import Link from '~/src/components/mui-wrappers/Link'
import { useEoValue } from '~/src/hooks/useEoState'
import routes from '~/src/router/enums/routes.enum'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import EoLinkProps from '~/src/types/EoLinkProps'
import { LANGUAGE_STATE } from '~/src/stores/lang'

interface Props extends BoxProps {
  redirect?: boolean,
  LinkProps?: Partial<Omit<EoLinkProps, 'href' | 'shallow'>>,
}

const CurrentFirmLogo = ({ redirect = false, LinkProps,  ...otherProps }: Props) => {
  const firm = useEoValue(FIRM_SELECTOR)
  const language = useEoValue(LANGUAGE_STATE)

  if (!firm) {
    return null
  }

  if (!redirect) {
    return (
      <Box
        component='img'
        src={firm?.assets.logoFullsize || ''}
        alt={firm?.name[language] || ''}
        {...otherProps}
      />
    )
  }

  return (
    <Box {...otherProps}>
      <Link href={routes.FIRM_HOME} {...LinkProps}>
        <img
          height='50px'
          src={firm?.assets.logoFullsize || ''}
          alt={firm?.name[language] || ''}
        />
      </Link>
    </Box>
  )
}

export default CurrentFirmLogo
