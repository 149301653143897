import { OrderingMethodNames, OrderOnSpotCustomMethod } from '@eo-storefronts/eo-core'
import { Box, TextField, Typography } from '@mui/material'
import { ChangeEvent, useEffect } from 'react'
import { useEoState, useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { CHECKOUT_FORM_STATE, CheckoutFormState } from '~/src/stores/checkout'
import { FIRM_SELECTOR } from '~/src/stores/firm'

const EatInMethodSelector = () => {
  const firm = useEoValue(FIRM_SELECTOR)
  const [ checkoutForm, setCheckoutForm ] = useEoState(CHECKOUT_FORM_STATE)
  const { t } = useTranslations()

  useEffect(() => {
    if (
      checkoutForm.orderingMethod.tableMethod !== null
      || !firm?.settings.orderingMethods[OrderingMethodNames.ON_THE_SPOT]?.customMethods
      || !firm?.settings.orderingMethods[OrderingMethodNames.ON_THE_SPOT]?.customMethods?.length
    ) {
      return
    }

    setCheckoutForm((state: CheckoutFormState) => ({
      ...state,
      orderingMethod: {
        ...state.orderingMethod,
        tableMethod: firm.settings.orderingMethods[OrderingMethodNames.ON_THE_SPOT]!.customMethods![0].id,
        tableUid: null,
        tableNumber: null
      }
    }))
  }, [])

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCheckoutForm((state: CheckoutFormState) => ({
      ...state,
      orderingMethod: {
        ...state.orderingMethod,
        tableMethod: event.target.value !== '' ? Number(event.target.value) : null,
        tableUid: null,
        tableNumber: null
      }
    }))
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Typography>{t('orderingMethods.eatInSelect')}</Typography>
      <TextField
        select
        size='small'
        color='container'
        SelectProps={{
          native: true
        }}
        variant='outlined'
        onChange={handleOnChange}
        value={checkoutForm.orderingMethod.tableMethod || ''}>
        {
          firm?.settings.orderingMethods[OrderingMethodNames.ON_THE_SPOT]?.customMethods?.map((method: OrderOnSpotCustomMethod) => (
            <option
              key={method.id}
              value={method.id}
            >
              {method.name}
            </option>
          ))
        }
      </TextField>
    </Box>
  )
}

export default EatInMethodSelector
