import { Address, Customer, CustomerLoyaltyVoucher, DateUtils, Id, LoyaltyPointTypeEnum } from '@eo-storefronts/eo-core'
import { DefaultValue } from 'recoil'
import { createSelector, createSelectorFamily, createState } from '~/src/hooks/useEoState'
import { addCustomerAddress } from '~/src/services/CustomerService'
import { AUTHENTICATION_STATE, IS_LOGGED_IN_SELECTOR } from '~/src/stores/authentication'
import { BRAND_STATE } from '~/src/stores/brand'
import { CART_SUB_TOTAL_SELECTOR } from '~/src/stores/cart'
import { CHECKOUT_FORM_STATE, CheckoutFormState } from '~/src/stores/checkout'
import {
  LOYALTY_IS_ENABLED_AND_CUSTOMER_IS_LOGGED_IN_SELECTOR
} from '~/src/stores/loyalty'
import NumberUtils from '~/src/utils/NumberUtils'

const key = 'customerState'

export const CUSTOMER_STATE = createState<Customer | null>({
  key,
  default: null,
  effects: [
    ({ onSet, getPromise }) => {
      onSet(async (newValue: Customer | null, oldValue: DefaultValue | Customer | null) => {
        if (oldValue !== null || !newValue?.id || !newValue?.uid) {
          return
        }

        const checkoutForm: CheckoutFormState = await getPromise(CHECKOUT_FORM_STATE)
        const address = checkoutForm.orderingMethod.address

        if (address && !address.id) {
          try {
            await addCustomerAddress(newValue.id as string, {
              ...address,
              description: address.street
            })
          } catch (e) {
            // Ignore
          }
        }
      })
    }
  ]
})

export const CUSTOMER_LOYALTY_VOUCHERS = createState<CustomerLoyaltyVoucher[]>({
  key: 'customerLoyaltyVouchers',
  default: []
})

export const CUSTOMER_USABLE_LOYALTY_VOUCHERS_SORTED_BY_VALIDITY_DATE_SELECTOR = createSelector<CustomerLoyaltyVoucher[]>({
  key: 'customerUsableLoyaltyVouchersSortedByValidityDateSelector',
  get: ({ get }): CustomerLoyaltyVoucher[] => {
    return get<CustomerLoyaltyVoucher[]>(CUSTOMER_LOYALTY_VOUCHERS)
      .filter((voucher: CustomerLoyaltyVoucher) => voucher.validityDate !== null)
      .filter((voucher: CustomerLoyaltyVoucher) => DateUtils.isAfter(new Date(voucher.validityDate!), new Date()))
      .sort((voucherA: CustomerLoyaltyVoucher, voucherB: CustomerLoyaltyVoucher) => {
        const dateA = new Date(voucherA.validityDate!).getTime()
        const dateB = new Date(voucherB.validityDate!).getTime()

        if (dateA === dateB && (!!voucherA.id && !!voucherB.id)) {
          return voucherA.id > voucherB.id ? -1 : 1
        }

        return dateA - dateB
      })
  }
})

export const CUSTOMER_LOYALTY_VOUCHERS_BALANCE_SELECTOR = createSelector<number>({
  key: 'customerLoyaltyVouchersBalance',
  get: ({ get }) => {
    const loyaltyVouchers: CustomerLoyaltyVoucher[] = get(CUSTOMER_LOYALTY_VOUCHERS)

    return loyaltyVouchers
      .filter((voucher: CustomerLoyaltyVoucher) => !voucher.fidelityPointType)
      .map((loyaltyVoucher: CustomerLoyaltyVoucher) => loyaltyVoucher.amount || 0)
      .reduce((accumulator: number, current: number) => NumberUtils.round(accumulator + current, 2), 0)
  }
})

export const CUSTOMER_LOYALTY_POINT_BALANCE_SELECTOR = createSelector<number>({
  key: 'customerLoyaltyPointBalance',
  get: ({ get }): number => {
    const vouchers: CustomerLoyaltyVoucher[] = get<CustomerLoyaltyVoucher[]>(CUSTOMER_USABLE_LOYALTY_VOUCHERS_SORTED_BY_VALIDITY_DATE_SELECTOR)

    return vouchers
      .filter((voucher: CustomerLoyaltyVoucher) => voucher.fidelityPointType)
      .filter((voucher: CustomerLoyaltyVoucher) => {
        if (!voucher.validityDate) {
          return true
        }

        return DateUtils.isAfter(new Date(voucher.validityDate), new Date(), 'seconds')
      })
      .map((voucher: CustomerLoyaltyVoucher) => voucher.fidelityPoint || 0)
      .reduce((accumulator: number, current: number) => NumberUtils.round(accumulator + current, 2), 0)
  }
})

export const CUSTOMER_LOYALTY_NEXT_EXPIRING_VOUCHER_SELECTOR = createSelector<CustomerLoyaltyVoucher | undefined>({
  key: 'customerLoyaltyNextExpiringVoucher',
  get: ({ get }): CustomerLoyaltyVoucher | undefined => {
    return get<CustomerLoyaltyVoucher[]>(CUSTOMER_USABLE_LOYALTY_VOUCHERS_SORTED_BY_VALIDITY_DATE_SELECTOR)[0]
  }
})

export const CUSTOMER_LOYALTY_POINT_TRESHOLD_NEXT_EXPIRING_VOUCHER_SELECTOR_FAMILY = createSelectorFamily<CustomerLoyaltyVoucher | undefined, 'point' | 'value'>({
  key: 'customerLoyaltyPointTresholdNextExpiringVoucher',
  get: (type: 'point' | 'value') => ({ get }): CustomerLoyaltyVoucher | undefined => {
    const customerLoyaltyVouchers: CustomerLoyaltyVoucher[] = get<CustomerLoyaltyVoucher[]>(CUSTOMER_USABLE_LOYALTY_VOUCHERS_SORTED_BY_VALIDITY_DATE_SELECTOR)

    return customerLoyaltyVouchers.filter((voucher: CustomerLoyaltyVoucher) => {
      return type === 'point' ? voucher.fidelityPointType : !voucher.fidelityPointType
    })[0]
  }
})

export const CUSTOMER_LOYALTY_REACHED_SELECTOR = createSelector<boolean>({
  key: 'customerLoyaltyReachedSelector',
  get: ({ get }) => {
    const loyaltyIsEnabledAndCustomerIsLoggedIn = get(LOYALTY_IS_ENABLED_AND_CUSTOMER_IS_LOGGED_IN_SELECTOR)
    const brand = get(BRAND_STATE)
    const customerVouchersValue = get(CUSTOMER_LOYALTY_VOUCHERS_BALANCE_SELECTOR)

    if (!loyaltyIsEnabledAndCustomerIsLoggedIn) {
      return false
    }

    if (brand!.settings.loyalty!.pointType !== LoyaltyPointTypeEnum.POINT_THRESHOLD) {
      return true
    }

    return customerVouchersValue >= (brand!.settings.loyalty!.pointThreshold || 1)
  }
})

export const CUSTOMER_ADDRESS_BY_ID = createSelectorFamily({
  key: 'customerAddressById',
  get: (id: number) => ({ get }) => {
    const customer = get(CUSTOMER_STATE)

    return customer?.addresses?.find((address: Address) => address.id === id)
  }
})

export interface CustomerLoadingState {
  customer: boolean,
  address: boolean,
  addresses: boolean,
}

const loaderKey = 'customerLoadingState'

export const CUSTOMER_LOADING_STATE = createState<CustomerLoadingState>({
  key: loaderKey,
  default: {
    customer: false,
    address: false,
    addresses: false
  }
})

export const IS_FETCHING_CUSTOMER_SELECTOR = createSelectorFamily({
  key: 'isFetchingCustomerData',
  get: (key: keyof CustomerLoadingState) => ({ get }): boolean => {
    const loadingState = get<CustomerLoadingState>(CUSTOMER_LOADING_STATE)

    return loadingState[key]
  }
})

export const GET_CUSTOMER_ID_SELECTOR = createSelector<Id>({
  key: 'getCustomerIdSelector',
  get: ({ get }): Id => {
    const isLoggedInAsCustomer = get(IS_LOGGED_IN_SELECTOR)
    const customer: Customer | null = get(CUSTOMER_STATE)

    if (isLoggedInAsCustomer) {
      return customer?.id || 0
    }

    return 1
  }
})

export const GET_CUSTOMER_UUID_SELECTOR = createSelector<string>({
  key: 'getCustomerUuidSelector',
  get: ({ get }): string => {
    const authState = get(AUTHENTICATION_STATE)
    const isLoggedInAsCustomer = get(IS_LOGGED_IN_SELECTOR)
    const customer: Customer | null = get(CUSTOMER_STATE)

    if (isLoggedInAsCustomer) {
      return customer?.uid ?? ''
    }

    return authState.guest?.uid ?? ''
  }
})

export const GET_CUSTOMER_QUICK_AUTH_ID_SELECTOR = createSelector<string | null | undefined>({
  key: 'getCustomerQuickAuthIdSelector',
  get: ({ get }): string | null | undefined => {
    return get(CUSTOMER_STATE)?.quickAuthId
  }
})

export const IS_CUSTOMER_DEVELOPER = createSelector<boolean>({
  key: 'isCustomerDeveloper',
  get: ({ get }): boolean => {
    const customer = get(CUSTOMER_STATE)

    if (!customer) {
      return false
    }

    return customer.email.includes('@easyorderapp.com') || customer.email.includes('@restomax.com') || false
  }
})

export const GET_CUSTOMER_LOYALTY_VOUCHERS_TO_USE_FOR_ORDER_SELECTOR = createSelector<CustomerLoyaltyVoucher[]>({
  key: 'getCustomerLoyaltyVouchers',
  get: ({ get }): CustomerLoyaltyVoucher[] => {
    const cartSubTotalValue = get<number>(CART_SUB_TOTAL_SELECTOR)
    const checkoutFormState = get<CheckoutFormState>(CHECKOUT_FORM_STATE)
    const vouchers: CustomerLoyaltyVoucher[] = get<CustomerLoyaltyVoucher[]>(CUSTOMER_USABLE_LOYALTY_VOUCHERS_SORTED_BY_VALIDITY_DATE_SELECTOR)
    const usableVouchers: CustomerLoyaltyVoucher[] = [
      ...vouchers
    ]

    if (checkoutFormState.physicalVoucher) {
      usableVouchers.push(checkoutFormState.physicalVoucher)
    }

    const voucherToUse: CustomerLoyaltyVoucher[] = []
    let remainingToPay: number = cartSubTotalValue

    usableVouchers.forEach((voucher: CustomerLoyaltyVoucher) => {
      if (remainingToPay <= 0 || !voucher.amount) {
        return
      }

      // Calculate the amount to use from this voucher
      let voucherAmountToUse = 0

      if (voucher.amount > 0) {
        voucherAmountToUse = NumberUtils.round(Math.min(remainingToPay, voucher.amount), 2)
      } else {
        voucherAmountToUse = voucher.amount
      }

      voucherToUse.push({
        ...voucher,
        amount: voucherAmountToUse
      })

      // Decrement the remaining loyalty balance
      remainingToPay = NumberUtils.round(remainingToPay - voucherAmountToUse, 2)
    })

    return voucherToUse
  }
})


