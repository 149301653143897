import { Box } from '@mui/material'
import Card from '~/src/components/mui-wrappers/Card'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import {
  LOYALTY_IS_ENABLED_AND_CUSTOMER_IS_LOGGED_IN_SELECTOR,
  LOYALTY_IS_POINT_THRESHOLD_SELECTOR
} from '~/src/stores/loyalty'
import Title from '~/src/components/mui-wrappers/Title'
import CustomerLoyaltyCurrentPointsLabel
  from '~/src/components/profile/account/loyalty/current-points-balance/CustomerLoyaltyCurrentPointsLabel'
import CustomerLoyaltyPointTresholdCurrentPointsLabel
  from '~/src/components/profile/account/loyalty/current-points-balance/CustomerLoyaltyPointTresholdCurrentPointsLabel'

const CustomerLoyaltyCurrentPointsBalance = () => {
  const theme = useCustomMuiTheme()
  const { t } = useTranslations()
  const isLoyaltyEnabled = useEoValue(LOYALTY_IS_ENABLED_AND_CUSTOMER_IS_LOGGED_IN_SELECTOR)
  const isLoyaltyPointTreshold = useEoValue(LOYALTY_IS_POINT_THRESHOLD_SELECTOR)

  if (!isLoyaltyEnabled) {
    return null
  }

  return (
    <Box sx={{
      cursor: 'pointer'
    }}>
      <Title color='primary'>{t('profile.loyalty.pointsBalance')}</Title>
      <Card
        sx={{
          textAlign: 'center',
          fontSize: '1rem',
          mt: 1,
          p: 1,
          cursor: 'pointer',
          '&:hover': {
            boxShadow: `0 0 5px ${theme.palette.primary.main}`
          }
        }}
      >
        {!isLoyaltyPointTreshold && <CustomerLoyaltyCurrentPointsLabel />}
        {isLoyaltyPointTreshold && <CustomerLoyaltyPointTresholdCurrentPointsLabel />}
      </Card>
    </Box>
  )
}

export default CustomerLoyaltyCurrentPointsBalance
