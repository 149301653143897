import {
  DateUtils,
  Id,
  Modifier,
  ModifierGroup,
  OrderingMethodNames,
  Price,
  PriceList,
  Product
} from '@eo-storefronts/eo-core'
import PriceHelper from '~/src/helpers/priceHelper'

interface ProductAvailable {
  inStock: boolean,
  snoozed: boolean,
}

const getFirstAvailableProduct = (products: Record<Id, Product>): Product | null => {
  for (const id in products) {
    if (!products[id]) {
      continue
    }

    if (!products[id].categoryId) {
      continue
    }

    return products[id]
  }

  return null
}

const checkProductAvailability = (product: Product, time: Date): ProductAvailable => {
  if (!product.inStock) {
    return {
      inStock: false,
      snoozed: false
    }
  }

  if (product.snooze?.start && product.snooze?.end) {
    const snoozed = DateUtils.isAfter(time, new Date(product.snooze.start))
      && DateUtils.isBefore(time, new Date(product.snooze.end))

    return {
      inStock: true,
      snoozed
    }
  }

  return {
    inStock: true,
    snoozed: false
  }
}

const _getProductInterval = (product: Product): number => {
  return product.unit?.id === 4 ? parseFloat((product.unitInterval / 1000).toFixed(3)) : product.unitInterval
}

const _getProductMinQuantity = (product: Product): number | undefined => {
  if (!product.min) {
    return
  }

  return product.unit?.id === 4 ? parseFloat((product.min / 1000).toFixed(3)) : product.min
}

const _getProductMaxQuantity = (product: Product): number | undefined => {
  if (!product.max) {
    return
  }

  return product.unit?.id === 4 ? parseFloat((product.max / 1000).toFixed(3)) : product.max
}

const convertProductUnitKgInGr = (product: Product | null): Pick<Product, 'unitInterval' | 'min' | 'max'> => {
  return {
    unitInterval: product ? _getProductInterval(product) : 0,
    min: product ? _getProductMinQuantity(product) : 0,
    max: product ? _getProductMaxQuantity(product) : 0
  }
}

const getProductPriceList = (product: Product, priceLists: PriceList[]): PriceList | null => {
  const priceList = priceLists.find((pl: PriceList) => pl.item_uid === product.uid)

  return priceList ?? null
}

const productHasPromotion = (product: Product, priceLists: PriceList[]): boolean => {
  const priceList = getProductPriceList(product, priceLists)

  if (!priceList) {
    return false
  }

  return priceList.is_promotion
}

const getProductPrice = (product: Product, priceLists: PriceList[]): Price => {
  const priceList = getProductPriceList(product, priceLists)

  if (priceList) {
    return PriceHelper.transform(priceList.price)
  }

  return product.price
}

const getProductBasePrice = (product: Product, priceLists: PriceList[]): Price => {
  const priceList = getProductPriceList(product, priceLists)

  if (priceList) {
    return PriceHelper.transform(priceList.base_price)
  }

  return product.price
}

const searchProductsByName = (search: string, lang: string, products: Record<Id, Product>): string[] => {
  if (search === '') {
    return []
  }

  const searchResults: string[] = []

  for (const productId in products) {
    if (!products[productId]) {
      continue
    }

    if ((products[productId].name[lang] || '').toLowerCase().indexOf(search.toLowerCase()) === -1) {
      continue
    }

    searchResults.push(productId)
  }

  return searchResults
}

const productHasImage = (product: Product): boolean => {
  return product.images.some((image) => image.detail)
}

const convertProductToModifier = (group: ModifierGroup, product: Product): Modifier => ({
  id: product.id.toString(),
  uid: product.uid,
  isDefault: false,
  isLocked: false,
  inStock: product.inStock,
  name: product.name,
  description: product.description,
  price: {
    [OrderingMethodNames.DELIVERY]: group.rankModifierPrice[product.id.toString()],
    [OrderingMethodNames.PICKUP]: group.rankModifierPrice[product.id.toString()],
    [OrderingMethodNames.ON_THE_SPOT]: group.rankModifierPrice[product.id.toString()]
  },
  quantity: 0,
  snooze: {
    start: product.snooze?.start || undefined,
    end: product.snooze?.end || undefined
  },
  images: product.images,
  ingredientType: 'product',
  type: 'product',
  allergens: product.allergens,
  modifierGroups: product.modifierGroups
})

export {
  checkProductAvailability,
  convertProductToModifier,
  convertProductUnitKgInGr,
  getFirstAvailableProduct, getProductBasePrice, getProductPrice, getProductPriceList,
  productHasImage,
  productHasPromotion,
  searchProductsByName
}

