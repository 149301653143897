import { createContext } from 'react'
import { CartProductModifierGroupStateInterface } from '~/src/stores/cart/cart-product-modifier-groups'

interface ProductConfigurationModalContextInterface {
  asModifier: boolean,
  selectedModifierGroups: CartProductModifierGroupStateInterface[],
  updateModifierGroups(cartModifierGroups: CartProductModifierGroupStateInterface[]): void,
  save(quantity: number): void,
}

const ProductConfigurationModalContext = createContext<ProductConfigurationModalContextInterface>({
  asModifier: false,
  selectedModifierGroups: [],
  updateModifierGroups: () => {
    throw new Error('ProductConfigurationModalContext.updateModifierGroups not implemented')
  },
  save: () => {
    throw new Error('ProductConfigurationModalContext.save not implemented')
  }
})

export default ProductConfigurationModalContext
export type { ProductConfigurationModalContextInterface }
