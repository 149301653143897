import ResponsiveDialog from '~/src/components/mui-wrappers/responsive-dialog'
import { useState } from 'react'
import { useTranslations } from '~/src/hooks/useTranslations'
import Title from '~/src/components/mui-wrappers/Title'
import { Box, Typography } from '@mui/material'
import Button from '~/src/components/mui-wrappers/buttons/Button'
import useDialog from '~/src/hooks/useDialog'

export interface AlreadyInCartProps {
  onClose(addNewProduct: boolean): void,
}

const AlreadyInCart = ({ onClose }: AlreadyInCartProps) => {
  const { t } = useTranslations()
  const [ openByDefault, setOpenByDefault ] = useState<boolean>(true)
  const { dismiss } = useDialog()

  const _handleUseItemInCart = () => {
    onClose(false)
    setOpenByDefault(false)
    dismiss('already-in-cart')
  }

  const _handleAddOneMoreItem = () => {
    onClose(true)
    setOpenByDefault(false)
    dismiss('already-in-cart')
  }

  return (
    <ResponsiveDialog
      openByDefault={openByDefault}
      name={'already-in-cart'}
      persistent
      Title={<Title>{t('product.alreadyInCart.title')}</Title>}
      Actions={(
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button variant='contained' onClick={_handleUseItemInCart}>{t('product.alreadyInCart.useItemInCart')}</Button>
          <Button variant='contained' onClick={_handleAddOneMoreItem}>{t('product.alreadyInCart.addOneMore')}</Button>
        </Box>
      )}
    >
      <Typography>{t('product.alreadyInCart.text')}</Typography>
    </ResponsiveDialog>
  )
}

export default AlreadyInCart
