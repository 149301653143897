import { Typography } from '@mui/material'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { PRODUCT_COUPON } from '~/src/stores/product'
import { CartProduct } from '~/src/types/CartProduct'

interface Props {
  cartProduct: CartProduct,
}

const ShoppingCartItemDiscounts = ({ cartProduct }: Props) => {
  const { t } = useTranslations()
  const productCoupon = useEoValue(PRODUCT_COUPON(cartProduct.id))

  if (!productCoupon) {
    return null
  }

  return (
    <Typography>
      {cartProduct.isCoupon && t('shoppingCart.couponDiscount')}
    </Typography>
  )
}

export default ShoppingCartItemDiscounts
