import { useEffect } from 'react'
import useFirmTemplates from '~/src/hooks/firm/useFirmTemplates'
import { useEoValue, useSetEoState } from '~/src/hooks/useEoState'
import { STYLES_STATE } from '~/src/stores/styles'
import { IS_ON_MARKETPLACE_PAGE_SELECTOR } from '~/src/stores/layout'
import { Templates } from '~/src/types/Templates'
import { Constants } from '~/src/helpers/constants'

const TemplateProvider = () => {
  const { templates } = useFirmTemplates()
  const isOnMarketplacePage = useEoValue(IS_ON_MARKETPLACE_PAGE_SELECTOR)
  const setStyles = useSetEoState<Templates | null>(STYLES_STATE)

  const getStyles = (): Templates => {
    if (isOnMarketplacePage) {
      return {
        pages: {
          locator: Constants.MARKETPLACE_TEMPLATE_PRESET,
          cart: Constants.MARKETPLACE_TEMPLATE_PRESET,
          categories: Constants.MARKETPLACE_TEMPLATE_PRESET,
          category: Constants.MARKETPLACE_TEMPLATE_PRESET,
          login: Constants.MARKETPLACE_TEMPLATE_PRESET,
          register: Constants.MARKETPLACE_TEMPLATE_PRESET,
          activateAccount: Constants.MARKETPLACE_TEMPLATE_PRESET,
          activation: Constants.MARKETPLACE_TEMPLATE_PRESET,
          resetPassword: Constants.MARKETPLACE_TEMPLATE_PRESET,
          checkout: Constants.MARKETPLACE_TEMPLATE_PRESET,
          extraInfoGuest: Constants.MARKETPLACE_TEMPLATE_PRESET,
          extraInfoCustomer: Constants.MARKETPLACE_TEMPLATE_PRESET,
          privacyPolicy: Constants.MARKETPLACE_TEMPLATE_PRESET,
          termsAndConditions: Constants.MARKETPLACE_TEMPLATE_PRESET,
          order: Constants.MARKETPLACE_TEMPLATE_PRESET,
          product: Constants.MARKETPLACE_TEMPLATE_PRESET,
          products: Constants.MARKETPLACE_TEMPLATE_PRESET,
          profile: Constants.MARKETPLACE_TEMPLATE_PRESET,
          search: Constants.MARKETPLACE_TEMPLATE_PRESET,
          firmInfos: Constants.MARKETPLACE_TEMPLATE_PRESET,
          notFound: Constants.MARKETPLACE_TEMPLATE_PRESET,
          update: Constants.MARKETPLACE_TEMPLATE_PRESET,
          explorer: Constants.MARKETPLACE_TEMPLATE_PRESET,
          locations: Constants.MARKETPLACE_TEMPLATE_PRESET,
          location: Constants.MARKETPLACE_TEMPLATE_PRESET,
          news: Constants.MARKETPLACE_TEMPLATE_PRESET,
          newsDetails: Constants.MARKETPLACE_TEMPLATE_PRESET
        },
        components: {
          modifiers: Constants.MARKETPLACE_TEMPLATE_PRESET,
          header: Constants.MARKETPLACE_TEMPLATE_PRESET,
          orderingMethodsSummary: Constants.MARKETPLACE_TEMPLATE_PRESET
        }
      }
    }

    return {
      pages: {
        locator: templates.pages.locator,
        cart: templates.pages.cart,
        categories: templates.pages.categories,
        category: templates.pages.category,
        login: templates.pages.login,
        register: templates.pages.register,
        activateAccount: templates.pages.activateAccount,
        activation: templates.pages.activation,
        resetPassword: templates.pages.resetPassword,
        checkout: templates.pages.checkout,
        extraInfoGuest: templates.pages.extraInfoGuest,
        extraInfoCustomer: templates.pages.extraInfoCustomer,
        privacyPolicy: templates.pages.privacyPolicy,
        termsAndConditions: templates.pages.termsAndConditions,
        order: templates.pages.order,
        product: templates.pages.product,
        products: templates.pages.products,
        profile: templates.pages.profile,
        search: templates.pages.search,
        firmInfos: templates.pages.firmInfos,
        notFound: templates.pages.locator,
        update: templates.pages.locator,
        explorer: templates.pages.locator,
        locations: templates.pages.locator,
        location: templates.pages.locator,
        news: templates.pages.news,
        newsDetails: templates.pages.newsDetails
      },
      components: {
        modifiers: templates.components.modifiers,
        header: templates.components.header,
        orderingMethodsSummary: templates.components.orderingMethodsSummary
      }
    }
  }

  useEffect(() => {
    setStyles(getStyles())
  }, [ templates, isOnMarketplacePage ])

  return null
}

export default TemplateProvider
