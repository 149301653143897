import { SxProps, typographyClasses } from '@mui/material'
import { SystemCssProperties } from '@mui/system'
import { ProductItemCardActionsProps } from '~/src/components/products/product-item/ProductItemCardActions'
import { ProductItemCardContentProps } from '~/src/components/products/product-item/ProductItemCardContent'
import StylesProductItemServiceInterface
  from '~/src/components/products/product-item/styles/StylesProductItemServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'
import { OverridableStringUnion } from '@mui/types'
import { Variant } from '@mui/material/styles/createTypography'
import { TypographyPropsVariantOverrides } from '@mui/material/Typography/Typography'

export default class StylesProductItemServiceStyleFour extends TemplateServiceStyleBase implements StylesProductItemServiceInterface {
  public getCardLayout(showImage: boolean): SxProps {
    return {
      display: 'grid',
      width: '100%',
      ...this._getGridSxProps(showImage),
      gridTemplateRows: '1fr',
      backgroundColor: 'secondary.main',
      color: 'secondary.contrastText',
      gap: 1,
      px: 2,
      py: 1,
      minHeight: `calc(100px + (${this.muiTheme.spacing(1)} * 2))`,
      transition: 'var(--ease-in-out)',
      '&:hover': {
        boxShadow: 3
      }
    }
  }

  public getCardMediaStyle(showImage: boolean): SxProps {
    if (!showImage) {
      return {
        display: 'none'
      }
    }

    return {
      alignSelf: 'center',
      justifySelf: 'center',
      gridArea: 'product-image',
      width: '100%',
      backgroundColor: 'secondary.main',
      color: 'inherit',
      maxWidth: '100%',
      borderRadius: '12px'
    }
  }

  public getCardContentStyle(): SxProps {
    return {
      color: 'inherit',
      gridArea: 'product-content',
      width: '100%',
      padding: 0,
      [`p.${typographyClasses.root}`]: {
        display: '-webkit-box',
        WebkitLineClamp: '2',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden'
      }
    }
  }

  public getProductItemCardContentProps(): Pick<ProductItemCardContentProps, 'showDescription'> {
    return {
      showDescription: true
    }
  }

  public getCardContentTitleSx(): SxProps {
    return {
      display: '-webkit-box',
      WebkitLineClamp: '1',
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      color: 'inherit',
      p: 0
    }
  }

  public getCardContentTitleVariant(): OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides> {
    return 'h6'
  }

  public getCardActionsStyle(): SxProps {
    return {
      color: 'inherit',
      gridArea: 'product-actions',
      p: 0
    }
  }

  public getProductActionsProps(): Pick<ProductItemCardActionsProps, 'showAddToCartButton'> {
    return {
      showAddToCartButton: false
    }
  }

  public getProductPriceStyle(free: boolean): SxProps {
    return {
      color: free ? 'success.main' : 'inherit'
    }
  }

  public getAddProductButtonStyle(): SxProps {
    return {}
  }

  private _getGridSxProps(showImage: boolean): Pick<SystemCssProperties, 'gridTemplateAreas' | 'gridTemplateColumns'> {
    if (showImage) {
      return {
        gridTemplateAreas:
          '"product-content product-image"' +
          '"product-content product-image"' +
          '"product-actions product-image"',
        gridTemplateColumns: '1fr 100px'
      }
    }

    return {
      gridTemplateAreas:
        '"product-content"' +
        '"product-content"' +
        '"product-actions"',
      gridTemplateColumns: '1fr'
    }
  }
}
