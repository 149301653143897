import useDialog from '~/src/hooks/useDialog'
import TuneIcon from '@mui/icons-material/Tune'
import DevModeDialog from '~/src/components/layout/dev-mode/dialog'
import { DialogNameEnum } from '~/src/types/DialogNameEnum'
import { Fab } from '@mui/material'
import { useEoValue } from '~/src/hooks/useEoState'
import { IS_CUSTOMER_DEVELOPER } from '~/src/stores/customer'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

const DevModeFloatingButton = () => {
  const theme = useCustomMuiTheme()
  const showDevMode = useEoValue(IS_CUSTOMER_DEVELOPER)
  const { present } = useDialog()

  const _handleOnClick = () => {
    present(DevModeDialog, DialogNameEnum.DEV_MODE)
  }

  if (!showDevMode) {
    return null
  }

  return (
    <Fab
      color='primary'
      onClick={_handleOnClick}
      sx={{
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2)
      }}>
      <TuneIcon/>
    </Fab>
  )
}

export default DevModeFloatingButton
