import { Customer } from '@eo-storefronts/eo-core'
import { useEoState } from '~/src/hooks/useEoState'
import { updatePushNotificationsState } from '~/src/services/CustomerService'
import { CUSTOMER_STATE } from '~/src/stores/customer'

interface ReturnsType {
  updatePushNotification(isAllowed: boolean): void,
}

const useUpdateCustomerPushNotifications = (): ReturnsType => {
  const [ customer, setCustomer ] = useEoState<Customer | null>(CUSTOMER_STATE)

  const updatePushNotification = (isAllowed: boolean): void => {
    if (!customer?.uid) {
      return
    }

    updatePushNotificationsState(customer.uid, isAllowed).then(() => {
      setCustomer((state: Customer | null) => {
        if (!state) {
          return null
        }

        return {
          ...state,
          allowPushNotifications: isAllowed
        }
      })
    })
  }


  return { updatePushNotification }
}

export default useUpdateCustomerPushNotifications
