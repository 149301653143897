import { GetTimeslotsResponse, Timeslot } from '@eo-storefronts/eo-core'
import { useEffect, useState } from 'react'
import useFetchTimeslots from '~/src/hooks/ordering-methods/useFetchTimeslots'
import { useAsyncEffect } from '~/src/hooks/useAsyncEffect'
import { useEoState, useEoValue, useSetEoState } from '~/src/hooks/useEoState'
import { CATEGORIES_STATE } from '~/src/stores/categories'
import { CHECKOUT_FORM_STATE, CheckoutFormState } from '~/src/stores/checkout'
import LocatorState, { LOCATOR_STATE } from '~/src/stores/locator'
import { CURRENT_TIMESLOT_KEY_SELECTOR, TIMESLOTS_STATE, TIMESLOT_FOR_SELECTED_DATE_SELECTOR } from '~/src/stores/timeslots'

interface ReturnsType {
  loading: boolean,
  fetchTimeslots(): Promise<GetTimeslotsResponse | null>,
}

const useTimeslots = (): ReturnsType => {
  const [ checkoutForm, setCheckoutForm ] = useEoState(CHECKOUT_FORM_STATE)
  const currentTimeslots = useEoValue(TIMESLOT_FOR_SELECTED_DATE_SELECTOR)
  const currentTimeslotKey = useEoValue(CURRENT_TIMESLOT_KEY_SELECTOR)
  const categories = useEoValue(CATEGORIES_STATE)
  const setTimeslots = useSetEoState(TIMESLOTS_STATE)
  const setLocatorState = useSetEoState(LOCATOR_STATE)
  const [ loading, setLoading ] = useState<boolean>(false)
  const { fetchTimeslots } = useFetchTimeslots()

  useAsyncEffect(async () => {
    if (!Object.keys(categories).length) {
      return
    }

    setLoading(true)

    if (!currentTimeslots.length) {
      const timeslots = await fetchTimeslots()

      setTimeslots((state: Record<string, Timeslot[]>) => ({
        ...state,
        [currentTimeslotKey]: timeslots?.data || []
      }))
    }

    setLoading(false)
  }, [ currentTimeslotKey, categories ])

  useEffect(() => {
    setLocatorState((state: LocatorState) => ({
      ...state,
      isLoading: loading
    }))
  }, [ loading ])

  useEffect(() => {
    if (!currentTimeslots[0]) {
      return
    }

    const timeslot = currentTimeslots.find((t: Timeslot) => t.id === Number(checkoutForm.orderingMethod.timeslotId))

    if (timeslot) {
      return
    }

    setCheckoutForm((state: CheckoutFormState) => ({
      ...state,
      orderingMethod: {
        ...state.orderingMethod,
        timeslotId: currentTimeslots[0].id
      }
    }))
  }, [ currentTimeslots ])

  return {
    loading,
    fetchTimeslots
  }
}

export {
  useTimeslots
}
