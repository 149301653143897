import {
  Address,
  ApiConfiguration,
  BasicResponse,
  CheckActivationResponse,
  Customer,
  CustomerAuthBaseResponse,
  CustomerService,
  Id,
  LoyaltyHistory,
  RegisterResponse
} from '@eo-storefronts/eo-core'
import CustomerLoyaltyVoucher from '@eo-storefronts/eo-core/dist/models/CustomerLoyaltyVoucher'

export const fetchCustomerById = async (id: Id): Promise<Customer> => {
  ApiConfiguration.addOrReplaceHeader('customer_id', id.toString())

  return CustomerService.getOneById(id)
}

export const askResetPassword = async (email: string, returnUrl: string): Promise<BasicResponse> => {
  ApiConfiguration.addOrReplaceHeader('return_url', returnUrl)

  return CustomerService.askResetPassword(email)
}

export const activateAccount = async (token: string, validity: string): Promise<BasicResponse> => {
  ApiConfiguration.addOrReplaceHeader('customer_token', token)

  return CustomerService.activateAccount(validity)
}

export const resetPassword = async (password: string, confirmPassword: string, token: string, validity: string): Promise<BasicResponse> => {
  ApiConfiguration.addOrReplaceHeader('customer_token', token)

  return CustomerService.resetPassword(password, confirmPassword, validity)
}

export const create = async (entity: Customer, google?: boolean): Promise<RegisterResponse> => {
  return CustomerService.create(entity, google)
}

export const updateCustomer = async (customerId: Id, entity: Customer): Promise<BasicResponse> => {
  delete entity.addresses
  ApiConfiguration.addOrReplaceHeader('customer_id', entity.id.toString())

  return CustomerService.update(customerId, entity)
}

export const updatePushNotificationsState = async (customerUUID: string, allowed: boolean): Promise<unknown> => {
  return CustomerService.updatePushNotificationsState(customerUUID, allowed)
}

export const saveDeviceToken = async (customerUUID: string, token: string): Promise<unknown> => {
  return CustomerService.saveDeviceToken(customerUUID, token)
}

export const checkLocation = async (customerId: Id, customerUUID: string, locationId: Id): Promise<unknown> => {
  ApiConfiguration.addOrReplaceHeader('store_id', locationId.toString())
  ApiConfiguration.addOrReplaceHeader('customer_id', customerId.toString())

  return CustomerService.checkLocation(customerUUID)
}

export const linkFirm = async (customerId: Id, firmId: Id): Promise<unknown> => {
  ApiConfiguration.addOrReplaceHeader('store_id', firmId.toString())
  ApiConfiguration.addOrReplaceHeader('customer_id', customerId.toString())

  return CustomerService.linkFirm(customerId)
}

export const checkEmail = async (email: string, google?: boolean): Promise<CustomerAuthBaseResponse> => {
  return CustomerService.checkEmailV2(email, google)
}

export const checkActivation = async (customerId: Id): Promise<CheckActivationResponse> => {
  return CustomerService.checkActivation(customerId)
}

export const fetchCustomerAddresses = async (customerId: Id): Promise<Address[]> => {
  ApiConfiguration.addOrReplaceHeader('customer_id', customerId.toString())

  return CustomerService.getCustomerAddresses(customerId)
}

export const updateCustomerAddress = async (customerId: Id, address: Address): Promise<Address> => {
  ApiConfiguration.addOrReplaceHeader('customer_id', customerId.toString())

  return CustomerService.updateCustomerAddress(customerId, address)
}

export const addCustomerAddress = async (customerId: Id, address: Address): Promise<Address> => {
  ApiConfiguration.addOrReplaceHeader('customer_id', customerId.toString())

  return CustomerService.addCustomerAddress(customerId, address)
}

export const deleteCustomer = async (customerUUID: string, customerId: Id) => {
  ApiConfiguration.addOrReplaceHeader('customer_id', customerId.toString())

  return CustomerService.deleteCustomer(customerUUID)
}

export const getLoyaltyHistory = async (customerUid: string): Promise<LoyaltyHistory[]> => {
  return CustomerService.getLoyaltyHistory(customerUid)
}

export const getLoyalty = async (customerUid: string): Promise<CustomerLoyaltyVoucher[]> => {
  return CustomerService.getLoyalty(customerUid)
}
