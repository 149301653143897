import { Allergen } from '@eo-storefronts/eo-core'
import { Box } from '@mui/material'
import AllergenItem from '~/src/components/products/allergens/AllergenItem'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

interface Props {
  allergens: Allergen[],
}

const AllergenList = ({ allergens }: Props) => {
  const muiTheme = useCustomMuiTheme()

  if (!allergens?.length) {
    return null
  }

  return (
    <Box
      component='div'
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: 0.3,
        pt: 2,
        [muiTheme.breakpoints.down('md')]: {
          px: 2,
          pt: 0
        }
      }}
    >
      {allergens.map((allergen: Allergen) => (
        <AllergenItem
          allergen={allergen}
          key={allergen.id}
        />
      ))}
    </Box>
  )
}

export default AllergenList
