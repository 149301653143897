import { Firm, Id, Price, Product } from '@eo-storefronts/eo-core'
import deepEqual from '~/src/helpers/deepEqual'
import { indexDbPersist } from '~/src/helpers/recoilPersist'
import { createSelector, createSelectorFamily, createState } from '~/src/hooks/useEoState'
import CartService from '~/src/services/CartService'
import { getProductPrice } from '~/src/services/ProductService'
import {
  CART_PRODUCT_MODIFIER_GROUP_STATE,
  CartProductModifierGroupStateInterface
} from '~/src/stores/cart/cart-product-modifier-groups'
import { CATEGORIES_STATE } from '~/src/stores/categories'
import {
  CHECKOUT_FORM_STATE,
  CHECKOUT_PRODUCT_COUPON_AMOUNT,
  GET_DELIVERY_COST,
  GET_TOTAL_AMOUNT_OF_CHECKOUT_OPTIONS_SELECTOR
} from '~/src/stores/checkout'
import { FIRM_SELECTOR, GET_TOTAL_AMOUNT_OF_SERVICE_FEES_SELECTOR } from '~/src/stores/firm'
import { MODIFIER_GROUPS_STATE } from '~/src/stores/modifier-groups'
import { MODIFIERS_STATE } from '~/src/stores/modifiers'
import { PRODUCTS_STATE } from '~/src/stores/product'
import { PRODUCT_COMMENT_STATE } from '~/src/stores/product-comment'
import { CartProduct } from '~/src/types/CartProduct'
import NumberUtils from '~/src/utils/NumberUtils'
import { LOYALTY_IS_ENABLED_SELECTOR, LOYALTY_MAXIMUM_AMOUNT_USABLE_SELECTOR } from '~/src/stores/loyalty'
import { PRICE_LIST_STATE } from '~/src/stores/product/price-list'

const key = 'cartState'

export interface CartState {
  [key: string | number]: CartProduct[],
}

export interface WithCartTotal {
  withServiceFee?: boolean,
  withCheckoutOptions?: boolean,
  withDeliveryCosts?: boolean,
  withLoyaltyVouchers?: boolean,
  withLoyaltyDiscount?: boolean,
  withGlobalVoucherDiscount?: boolean,
  withProductVoucherDiscount?: boolean,
  withCategoryVoucherDiscount?: boolean,
}

export const CART_STATE = createState<CartState>({
  key,
  default: {},
  effects: [
    // persisted 1week
    indexDbPersist(key),
    // Backward compatibility
    ({ setSelf, onSet }) => {
      onSet((newCartValue) => {
        const nCart: CartState = {}
        const firmIds = Object.keys(newCartValue)
        let mustUpdate = false

        for (const firmId of firmIds) {
          if (newCartValue[firmId].length === 0) {
            continue
          }

          if ('ingredient_groups' in newCartValue[firmId][0]) {
            mustUpdate = true
            nCart[firmId] = []
          } else {
            nCart[firmId] = [ ...newCartValue[firmId] ]
          }
        }

        if (mustUpdate) {
          setSelf(nCart)
        }
      })
    }
  ]
})

export const CART_OF_FIRM_SELECTOR = createSelector<CartProduct[]>({
  key: 'cartOfFirmSelector',
  get: ({ get }) => {
    const firmSelected: Firm | null = get(FIRM_SELECTOR)
    const carts: CartState | null = get(CART_STATE)

    if (!firmSelected || !carts || !carts[firmSelected.id]) {
      return []
    }

    return carts[firmSelected.id]
  }
})

export const CART_PRODUCTS_WITH_QUANTITY_SELECTOR = createSelector({
  key: 'cartProductsWithQuantitySelector',
  get: ({ get }) => {
    const products: CartProduct[] = get(CART_OF_FIRM_SELECTOR)
    const cartProductsWithQuantity: Record<Id, number> = {}

    for (const cp of products) {
      cartProductsWithQuantity[cp.id] = cp.quantity
    }

    return cartProductsWithQuantity
  }
})

export const CART_COUPON_PRODUCTS_LIST_SELECTOR = createSelector<string[]>({
  key: 'cartCouponProductsListSelector',
  get: ({ get }) => {
    const checkoutForm = get(CHECKOUT_FORM_STATE)
    const categories = get(CATEGORIES_STATE)

    if (
      !checkoutForm.coupon?.category ||
      checkoutForm.coupon.type !== 'category' ||
      checkoutForm.coupon.chosen_product_id !== null
    ) {
      return []
    }

    return categories[Number(checkoutForm.coupon.category.id)].products
  }
})

export const CART_SUB_TOTAL_SELECTOR = createSelector<number>({
  key: 'cartSubTotalSelector',
  get: ({ get }): number => {
    const checkoutForm = get(CHECKOUT_FORM_STATE)
    const priceLists = get(PRICE_LIST_STATE)
    const cart = get(CART_OF_FIRM_SELECTOR)
    const products = get(PRODUCTS_STATE)
    const modifierGroups = get(MODIFIER_GROUPS_STATE)
    const modifiers = get(MODIFIERS_STATE)

    let total = 0

    cart.forEach((cp: CartProduct) => {
      const product: Product = products[cp.id]

      if (!product || !checkoutForm.orderingMethod.method) {
        return
      }

      const service = new CartService(modifierGroups, modifiers, products)
      const price: Price = getProductPrice(product, priceLists)
      const cartProductPrice: Price = service.calculateCartProductPrice(price, cp.quantity, cp.modifierGroups || [], priceLists)

      total += cartProductPrice[checkoutForm.orderingMethod.method]
    })

    return total
  }
})

export const CART_SUBTOTAL_WITH_DISCOUNTS_SELECTOR = createSelector({
  key: 'cartSubtotalWithDiscounts',
  get: ({ get }) => {
    const subtotal = get(CART_SUB_TOTAL_SELECTOR)
    const couponDiscountAmount = get(CHECKOUT_PRODUCT_COUPON_AMOUNT)

    return subtotal - couponDiscountAmount
  }
})

export const CART_TOTAL_ITEMS_SELECTOR = createSelector({
  key: 'cartTotalItems',
  get: ({ get }) => {
    const products = get(PRODUCTS_STATE)
    const cartProducts = get(CART_OF_FIRM_SELECTOR)
    let total = 0

    cartProducts.forEach((cartProduct: CartProduct) => {
      if (products[cartProduct.id] && products[cartProduct.id].unit?.id !== 1) {
        total += 1
        return
      }

      total += cartProduct.quantity
    })

    return total
  }
})

export const CART_TOTAL_SELECTOR = createSelectorFamily<number, Readonly<WithCartTotal>>({
  key: 'cartTotalSelector',
  get: (
    {
      withServiceFee = true,
      withCheckoutOptions = true,
      withLoyaltyVouchers = true,
      withDeliveryCosts = true,
      withGlobalVoucherDiscount = true
    }: Readonly<WithCartTotal>
  ) => ({ get }) => {
    const totalAmountOfCart = get(CART_SUBTOTAL_WITH_DISCOUNTS_SELECTOR)
    const totalAmountOfServiceFees = get(GET_TOTAL_AMOUNT_OF_SERVICE_FEES_SELECTOR)
    const totalAmountOfCheckoutOptions = get(GET_TOTAL_AMOUNT_OF_CHECKOUT_OPTIONS_SELECTOR)
    const deliveryCost = get(GET_DELIVERY_COST)
    const checkoutForm = get(CHECKOUT_FORM_STATE)
    const isLoyaltyEnabled = get(LOYALTY_IS_ENABLED_SELECTOR)
    const loyaltyMaximumAmountUsable = get(LOYALTY_MAXIMUM_AMOUNT_USABLE_SELECTOR)

    let price = totalAmountOfCart

    if (withServiceFee) {
      price += totalAmountOfServiceFees
    }

    if (withDeliveryCosts) {
      price += deliveryCost
    }

    if (checkoutForm.coupon && checkoutForm.coupon.value) {
      if (withGlobalVoucherDiscount && checkoutForm.coupon.type !== 'product' && checkoutForm.coupon.type !== 'category') {
        if (checkoutForm.coupon.type === 'percentage') {
          price -= NumberUtils.getPercentageValue(price, checkoutForm.coupon.value)
        } else {
          price -= checkoutForm.coupon.value
        }
      }
    }

    if (withLoyaltyVouchers) {
      if (checkoutForm.useLoyaltyPoints && isLoyaltyEnabled) {
        price -= loyaltyMaximumAmountUsable
      }

      if (checkoutForm.physicalVoucher && checkoutForm.physicalVoucher?.amount) {
        price -= checkoutForm.physicalVoucher.amount
      }
    }

    if (withCheckoutOptions) {
      price += totalAmountOfCheckoutOptions
    }

    if (price < 0) {
      price = 0
    }

    return price
  }
})

export const CART_TOTAL_IS_ZERO_SELECTOR = createSelector({
  key: 'cartTotalIsZero',
  get: ({ get }) => {
    const total = get(CART_TOTAL_SELECTOR({}))

    return total === 0
  }
})

export const CART_ITEM_WITH_PRODUCT_SELECTOR = createSelectorFamily<CartProduct | undefined, any>({
  key: 'cartItemWithProductSelector',
  get: (productId: string) => ({ get }): CartProduct | undefined => {
    const cart: CartProduct[] = get(CART_OF_FIRM_SELECTOR)
    const modifierGroups: CartProductModifierGroupStateInterface[] = get(CART_PRODUCT_MODIFIER_GROUP_STATE)
    const productComment = get(PRODUCT_COMMENT_STATE)

    return cart.find((cartProduct: CartProduct) => {
      return (cartProduct.id === productId) &&
        deepEqual(cartProduct.modifierGroups, modifierGroups) &&
        cartProduct.comment === productComment
    })
  }
})
