import { OrderingMethodNames, PriceList } from '@eo-storefronts/eo-core'
import { fetchPriceList } from '~/src/services/CatalogService'
import { useSetEoState } from '~/src/hooks/useEoState'
import { PRICE_LIST_STATE } from '~/src/stores/product/price-list'

interface ReturnsType {
  fetch(orderingMethod: OrderingMethodNames, time: Date): void,
}

const useFetchPriceList = (): ReturnsType => {
  const setPriceLists = useSetEoState(PRICE_LIST_STATE)

  const fetch = (orderingMethod: OrderingMethodNames, time: Date): void => {
    fetchPriceList(orderingMethod, time)
      .then((priceLists: PriceList[]) => {
        setPriceLists(priceLists)
      })
  }

  return {
    fetch
  }
}

export default useFetchPriceList
