import { OrderingMethodNames, OrderOnSpotCustomMethod, OrderOnSpotCustomMethodLocation } from '@eo-storefronts/eo-core'
import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import EatInModalActions from '~/src/components/eat-in/eat-in-modal/eat-in-modal-actions'
import EatInModalContent from '~/src/components/eat-in/eat-in-modal/eat-in-modal-content'
import ResponsiveDialog from '~/src/components/mui-wrappers/responsive-dialog'
import Title from '~/src/components/mui-wrappers/Title'
import { useFirmPathResolver } from '~/src/hooks/router/useFirmPathResolver'
import { useRouterPush } from '~/src/hooks/router/useRouterPush'
import useDialog from '~/src/hooks/useDialog'
import { useEoValue, useSetEoState } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import RoutesEnum from '~/src/router/enums/routes.enum'
import { isOnHoliday, isOpened } from '~/src/services/OpeningHourService'
import { CHECKOUT_FORM_STATE, CheckoutFormState, FROZEN_DELIVERY_METHOD_STATE } from '~/src/stores/checkout'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import EatInLocationError from '../../dialogs/eat-in-location'

interface TableState {
  method: number | null,
  number: string | null,
  uid: string | null,
}

const EatInModal = () => {
  const { t } = useTranslations()
  const { pathname } = useLocation()
  const firm = useEoValue(FIRM_SELECTOR)
  const params = useParams()
  const { push } = useRouterPush()
  const { resolve } = useFirmPathResolver()
  const { present } = useDialog()
  const setCheckout = useSetEoState<CheckoutFormState>(CHECKOUT_FORM_STATE)
  const setFrozenOrderingMethod = useSetEoState<boolean>(FROZEN_DELIVERY_METHOD_STATE)
  const [ opened, setOpened ] = useState<boolean>(pathname.includes(RoutesEnum.EAT_IN))
  const [ table, setTable ] = useState<TableState>({
    method: null,
    number: null,
    uid: null
  })

  const handleOnAccept = () => {
    setFrozenOrderingMethod(
      !!firm
            && isOpened(firm.settings.periods.openingHours)
            && !isOnHoliday(new Date(), firm.settings.periods.holidayPeriod)
    )
    setCheckout((checkout: CheckoutFormState) => ({
      ...checkout,
      orderingMethod: {
        ...checkout.orderingMethod,
        method: OrderingMethodNames.ON_THE_SPOT,
        tableMethod: table.method,
        tableNumber: table.number,
        tableUid: table.uid
      }
    }))
    setOpened(false)
    push(resolve(RoutesEnum.MENU), { modal: true })
  }

  useEffect(() => {
    if (!pathname.includes(RoutesEnum.EAT_IN)) {
      return
    }

    const customMethod: OrderOnSpotCustomMethod | undefined = firm?.settings.orderingMethods
      .table?.customMethods?.find((customMethod: OrderOnSpotCustomMethod) => customMethod.id === Number(params.tableMethod))

    if (!customMethod) {
      setOpened(false)
      present(EatInLocationError, 'eat-in-location-error')
      return
    }

    const location: OrderOnSpotCustomMethodLocation | undefined = customMethod.locations
      .find((l: OrderOnSpotCustomMethodLocation) => l.name === params.tableNumber)

    if (!location) {
      setOpened(false)
      present(EatInLocationError, 'eat-in-location-error')
      return
    }

    setTable({
      method: customMethod.id,
      number: location.name,
      uid: location.uid
    })
  }, [ pathname ])

  return (
    <ResponsiveDialog
      openByDefault={opened}
      persistent
      Title={<Title>{t('orderingMethods.table')}</Title>}
      Actions={<EatInModalActions onAccept={handleOnAccept}/>}
      maxWidth='xs'
    >
      <EatInModalContent method={table.method} number={table.number}/>
    </ResponsiveDialog>
  )
}

export default EatInModal
