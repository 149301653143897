import Price from '~/src/components/Price'
import { useEoValue } from '~/src/hooks/useEoState'
import { CART_SUB_TOTAL_SELECTOR } from '~/src/stores/cart'
import PriceHelper from '~/src/helpers/priceHelper'

const CartTotalPrice = () => {
  const total = useEoValue(CART_SUB_TOTAL_SELECTOR)

  return (
    <Price price={PriceHelper.transform(total)}/>
  )
}

export default CartTotalPrice
