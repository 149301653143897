import { useEffect } from 'react'
import useCalculateDeliveryCosts from '~/src/hooks/fees/useCalculateDeliveryCosts'

const useCheckDeliveryCostEffect = () => {
  const { calculateDeliveryCost } = useCalculateDeliveryCosts()

  useEffect(() => {
    calculateDeliveryCost()
  }, [ calculateDeliveryCost ])
}

export default useCheckDeliveryCostEffect
