import { OrderingMethodNames } from '@eo-storefronts/eo-core'
import { useCallback, useEffect } from 'react'
import { useEoState, useEoValue } from '~/src/hooks/useEoState'
import { firmSupportOrderingMethod, getFirstFirmSupportedOrderingMethod } from '~/src/services/FirmService'
import { CHECKOUT_FORM_STATE, CheckoutFormState } from '~/src/stores/checkout'
import { FIRM_SELECTOR } from '~/src/stores/firm'

const usePreselectOrderingMethod = (): void => {
  const [ checkout, setCheckout ] = useEoState(CHECKOUT_FORM_STATE)
  const firm = useEoValue(FIRM_SELECTOR)

  const _checkMethod = useCallback((method: OrderingMethodNames): boolean => {
    if (firm && !firmSupportOrderingMethod(firm, method)) {
      return false
    }

    setCheckout((state: CheckoutFormState) => ({
      ...state,
      orderingMethod: {
        ...state.orderingMethod,
        method
      }
    }))

    return true
  }, [ firm ])

  const _selectFirstMethod = useCallback(() => {
    const method: OrderingMethodNames | undefined = getFirstFirmSupportedOrderingMethod(firm)

    if (!method) {
      return
    }

    setCheckout((state: CheckoutFormState) => ({
      ...state,
      orderingMethod: {
        ...state.orderingMethod,
        method
      }
    }))
  }, [ firm ])

  useEffect(() => {
    if (!!checkout.orderingMethod.method || !firm) {
      return
    }

    _checkMethod(OrderingMethodNames.PICKUP)
    || _checkMethod(OrderingMethodNames.DELIVERY)
    || _selectFirstMethod()
  }, [ checkout, firm ])
}

export {
  usePreselectOrderingMethod
}
