import { OrderingMethodNames, OrderingMethods } from '@eo-storefronts/eo-core'
import { MouseEvent } from 'react'
import ToggleButtonGroup from '~/src/components/mui-wrappers/styled/ToggleButtonGroup'
import { useEoState, useEoValue } from '~/src/hooks/useEoState'
import { CHECKOUT_FORM_STATE, CheckoutFormState, FROZEN_DELIVERY_METHOD_STATE } from '~/src/stores/checkout'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import OrderingMethodsSwitchButton from './OrderingMethodsSwitchButton'

const OrderingMethodsSwitch = () => {
  const firm  = useEoValue(FIRM_SELECTOR)
  const frozenOrderingMethod = useEoValue(FROZEN_DELIVERY_METHOD_STATE)
  const [ checkoutState, setCheckoutForm ] = useEoState(CHECKOUT_FORM_STATE)

  const handleOnChange = (event: MouseEvent, value: keyof OrderingMethods) => {
    if (value === null || frozenOrderingMethod) {
      return
    }

    setCheckoutForm((state: CheckoutFormState) => ({
      ...state,
      orderingMethod: {
        ...state.orderingMethod,
        asap: false,
        timeslotId: null,
        method: value
      }
    }))
  }

  return (
    <ToggleButtonGroup
      value={checkoutState.orderingMethod.method}
      exclusive
      onChange={handleOnChange}
    >
      <OrderingMethodsSwitchButton value={OrderingMethodNames.PICKUP} />
      <OrderingMethodsSwitchButton value={OrderingMethodNames.DELIVERY} />
      {
        firm?.settings.orderingMethods && Object.keys(firm.settings.orderingMethods).map((method: string) => {
          if (
            method === OrderingMethodNames.PICKUP
            || method === OrderingMethodNames.DELIVERY
            || firm?.settings.orderingMethods[method as OrderingMethodNames] === null
          ) {
            return []
          }

          return <OrderingMethodsSwitchButton key={method} value={method} />
        })
      }
    </ToggleButtonGroup>
  )
}

export default OrderingMethodsSwitch
