import LayoutActivateAccountServiceStyleThree from '~/src/pages/auth/activate-account/layout/LayoutActivateAccountServiceStyleThree'
import LayoutActivateAccountServiceStyleTwo from '~/src/pages/auth/activate-account/layout/LayoutActivateAccountServiceStyleTwo'
import { LayoutState } from '~/src/stores/layout'
import { DeepPartial } from '~/src/types/DeepPartial'
import { TemplateEnum } from '@eo-storefronts/eo-core'
import { GetLayoutArgs } from '~/src/utils/layout/LayoutServiceInterface'

const getActivateAccountLayout = (style: TemplateEnum, args: GetLayoutArgs): DeepPartial<LayoutState> => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new LayoutActivateAccountServiceStyleTwo().getLayout(args)
    case TemplateEnum.STYLE_THREE:
      return new LayoutActivateAccountServiceStyleThree().getLayout()
    default:
      return new LayoutActivateAccountServiceStyleTwo().getLayout(args)
  }
}

export default getActivateAccountLayout
