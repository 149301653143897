import { DateUtils, Firm, OpeningHour, Period } from '@eo-storefronts/eo-core'
import { useEffect, useState } from 'react'
import { useTranslations } from '~/src/hooks/useTranslations'
import {
  getCurrentPeriodTime,
  getFirstAvailableHourForPeriod,
  isBeforeOpeningHours,
  isBetweenOpeningHours,
  isClosed,
  isOffline,
  isOnHoliday
} from '~/src/services/OpeningHourService'
import { LANGUAGE_STATE } from '~/src/stores/lang'
import { OpeningTimeState } from '~/src/types/OpeningTimesStates'
import { useEoValue } from '../useEoState'

interface ReturnsType {
  state: OpeningTimeState | undefined,
  sentence: string,
}

const useFirmOpeningTimeStateAndSentence = (location: Firm): ReturnsType => {
  const { t } = useTranslations()
  const lang = useEoValue(LANGUAGE_STATE)
  const [ openingState, setOpeningState ] = useState<OpeningTimeState | undefined>()
  const [ openingTimeSentence, setOpeningTimeSentence ] = useState<string>(t('firms.infoOpening'))

  useEffect(() => {
    const period: Period | {} = 'periods' in location.settings ? location.settings.periods : {}
    const openingHours = 'openingHours' in period ? period.openingHours : []
    const holidayPeriods = 'holidayPeriod' in period ? period.holidayPeriod : []
    const now = new Date()

    if (!openingHours.map((openingHour) => openingHour.times).flat().length) {
      setOpeningTimeSentence(t('firm.closed'))
      setOpeningState('closed')

      return
    }

    if (isOffline(location)) {
      setOpeningTimeSentence(t('firm.offline'))
      setOpeningState('offline')

      return
    }

    if (isOnHoliday(now, holidayPeriods)) {
      setOpeningTimeSentence(t('firm.onHoliday'))
      setOpeningState('onHoliday')

      return
    }

    const currentDayId = DateUtils.getDayIdLikeMomentJs(now)
    const currentDayPeriod = openingHours?.find(
      (openingHour: OpeningHour) => openingHour.dayId === currentDayId
    )

    if (!currentDayPeriod) {
      return
    }

    if (currentDayPeriod.times.length > 0) {
      if (isBetweenOpeningHours(now, currentDayPeriod)) {
        setOpeningTimeSentence(`${t('firm.openedUntil')} ${getCurrentPeriodTime(now, currentDayPeriod)?.toTime}`)
        setOpeningState('open')

        return
      }
      
      if (isBeforeOpeningHours(now, currentDayPeriod)) {
        setOpeningTimeSentence(`${t('firm.opensAt')} ${getFirstAvailableHourForPeriod(currentDayPeriod, true)}`)
        setOpeningState('closed')

        return
      }
    }

    let counter = 1
    let nextDayId = currentDayId

    do {
      nextDayId = nextDayId + 1 > 7 ? 1 : nextDayId + 1

      const nextDayPeriod = openingHours?.find(
        (openingHour: OpeningHour) => openingHour.dayId === nextDayId
      )

      if (!nextDayPeriod || isClosed(nextDayPeriod)) {
        counter++
        continue
      }

      setOpeningTimeSentence(`${t('firm.opensOn')} ${nextDayPeriod.day[lang]} ${getFirstAvailableHourForPeriod(nextDayPeriod)}`)
      setOpeningState('closed')

      break
    } while (counter < 7)
  }, [ t ])

  return {
    state: openingState,
    sentence: openingTimeSentence
  }
}

export {
  useFirmOpeningTimeStateAndSentence
}
