import { Product, TemplateEnum } from '@eo-storefronts/eo-core'
import { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import Link from '~/src/components/mui-wrappers/Link'
import ProductAvailabilityMessage from '~/src/components/products/ProductAvailabilityMessage'
import ProductConfigurationModal, {
  ProductConfigurationModalProps
} from '~/src/components/products/modals/configuration'
import ProductItem from '~/src/components/products/product-item'
import { useAddProductToCart } from '~/src/hooks/cart/useAddProductToCart'
import { useProductAvailable } from '~/src/hooks/products/useProductAvailable'
import useDialog from '~/src/hooks/useDialog'
import { useEoValue } from '~/src/hooks/useEoState'
import routes from '~/src/router/enums/routes.enum'
import { productHasImage } from '~/src/services/ProductService'
import { PRODUCT_ID_STATE } from '~/src/stores/product'
import { NAVIGATE_BY_PRODUCT_MODAL_SELECTOR } from '~/src/stores/theme'
import { DialogNameEnum } from '~/src/types/DialogNameEnum'

interface Props {
  product: Product,
  containerTemplate?: TemplateEnum,
}

const ProductListItem = ({ product, containerTemplate }: Props) => {
  const params = useParams()
  const { available } = useProductAvailable(product)
  const { addToCart } = useAddProductToCart()
  const { dismissAll, present } = useDialog()
  const navigateByModal = useEoValue<boolean>(NAVIGATE_BY_PRODUCT_MODAL_SELECTOR)
  const productId = useEoValue(PRODUCT_ID_STATE)
  const location = useLocation()

  const getHref = (): string | undefined => {
    if (
      (
        product.modifierGroups.length === 0
        && product.subProductsId.length === 0
      )
      || !available
    ) {
      return
    }

    return `${routes.CATEGORY}/${params.catId ? params.catId : product.categoryId}${routes.PRODUCT}/${product.id}`
  }

  const handleOnClick = () => {
    if (
      product.modifierGroups.length > 0
      || product.subProductsId.length > 0
      || !available
    ) {
      return
    }

    addToCart(product, 1, [])
    dismissAll()
  }

  const _openProductDialog = () => {
    if (!navigateByModal) {
      return
    }

    if (product.parentProductsId.length) {
      dismissAll()
    }

    present<ProductConfigurationModalProps>(
      ProductConfigurationModal,
      `${DialogNameEnum.PRODUCT_BY_ID}${product.id}`,
      {
        product,
        maxWidth: productHasImage(product) ? 'md' : 'xs'
      }
    )
  }

  useEffect(() => {
    if (product.id.toString() !== productId?.toString()) {
      return
    }

    _openProductDialog()
  }, [ productId ])

  return (
    <>
      <ProductAvailabilityMessage product={product}/>
      <Link
        href={getHref()}
        onClick={handleOnClick}
        removeBaseLinkStyle
        state={{
          modal: navigateByModal,
          location: location
        }}
        className='eo-h-full eo-w-full'
        sx={{
          filter: !available ? 'grayscale(1) opacity(.5)' : ''
        }}
      >
        <ProductItem
          product={product}
          containerTemplate={containerTemplate}
        />
      </Link>
    </>
  )
}

export default ProductListItem
