import { ToggleButtonProps } from '@mui/material'
import ToggleButton from '~/src/components/mui-wrappers/styled/ToggleButton'
import { useTranslations } from '~/src/hooks/useTranslations'
import useOrderingMethodService from '~/src/hooks/ordering-methods/useOrderingMethodService'
import { OrderingMethodNames } from '@eo-storefronts/eo-core'

const OrderingMethodsSwitchButton = (props: ToggleButtonProps) => {
  const { t } = useTranslations()
  const { isMethodEnabled, isMethodInactive } = useOrderingMethodService()
  const orderingMethod = props.value as OrderingMethodNames

  if (!isMethodEnabled(orderingMethod)) {
    return null
  }

  return (
    <ToggleButton
      {...props}
      disabled={isMethodInactive(orderingMethod)}
    >
      {t(`orderingMethods.${props.value}`)}
    </ToggleButton>
  )
}

export default OrderingMethodsSwitchButton
