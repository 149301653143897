import { Firm, HolidayPeriod, Period } from '@eo-storefronts/eo-core'
import FirmTimeslotSentenceContainer from '~/src/components/firms/FirmTimeslotSentenceContainer'
import FirmTimeslotStateChip from '~/src/components/firms/FirmTimeslotStateChip'
import { isOffline, isOnHoliday } from '~/src/services/OpeningHourService'

interface Props {
  firm: Firm,
  grouped?: boolean,
}

const FirmTimeslots = ({ firm, grouped = false }: Props) => {
  const period: Period | {} = firm.settings.periods
  const holidayPeriods: HolidayPeriod[] = 'holidayPeriod' in period ? period.holidayPeriod : []

  if (isOnHoliday(new Date(), holidayPeriods) || isOffline(firm)) {
    return (
      <FirmTimeslotStateChip firm={firm} />
    )
  }

  return (
    <>
      <FirmTimeslotStateChip firm={firm} />

      <FirmTimeslotSentenceContainer
        firm={firm}
        grouped={grouped}
      />
    </>
  )
}

export default FirmTimeslots
