import { Box, Typography } from '@mui/material'
import FirmLoyaltyRewardPointBalance from '~/src/components/profile/account/loyalty/reward-card/point-balance'
import FirmLoyaltyRewardProgress from '~/src/components/profile/account/loyalty/reward-card/progress'
import useLoyaltyService from '~/src/hooks/customer/useLoyaltyService'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { BRAND_STATE } from '~/src/stores/brand'
import NumberUtils from '~/src/utils/NumberUtils'

const FirmLoyaltyRewardDiscount = () => {
  const { t } = useTranslations()
  const loyaltyService = useLoyaltyService()
  const brand = useEoValue(BRAND_STATE)

  return (
    <Box
      sx={{
        display: 'grid',
        gap: 1,
        gridTemplateColumns: 'max-content auto',
        gridTemplateRows: 'repeat(3, auto)',
        gridTemplateAreas: '"icon title"' +
          '"icon progress"' +
          '"icon points"'
      }}
    >
      <Typography
        sx={{
          gridArea: 'title',
          fontWeight: 'bold'
        }}
      >
        {t('profile.loyalty.pointTreshold.discount.message', {
          value: loyaltyService.getRequiredPoints(),
          discountValue: `${NumberUtils.round(loyaltyService.getPointTresholdAsMoneyValue(), 2)}${brand?.settings.currency.symbol}`
        })}
      </Typography>
      <Box sx={{ gridArea: 'progress' }}>
        <FirmLoyaltyRewardProgress/>
      </Box>
      <FirmLoyaltyRewardPointBalance sx={{ gridArea: 'points' }}/>
    </Box>
  )
}

export default FirmLoyaltyRewardDiscount
