import { MouseEvent } from 'react'
import ToggleButtonGroup from '~/src/components/mui-wrappers/styled/ToggleButtonGroup'
import ToggleButton from '~/src/components/mui-wrappers/styled/ToggleButton'
import { useOrderingMethodsDate } from '~/src/hooks/ordering-methods/useOrderingMethodsDate'
import { useTranslations } from '~/src/hooks/useTranslations'
import { useFirmAcceptsOrder } from '~/src/hooks/orders/useFirmAcceptsOrder'

interface Props {
  onChange(event: MouseEvent, value: string): void,
  value: string,
}

const OrderingMethodsDateButtonGroup = ({ onChange, value }: Props) => {
  const { t } = useTranslations()
  const { canToday, canTomorrow, canPick } = useOrderingMethodsDate()
  const { checkOrderIsLimitedToSameDay } = useFirmAcceptsOrder()

  return (
    <ToggleButtonGroup
      color="primary"
      exclusive
      onChange={onChange}
      value={value}
    >
      <ToggleButton value='today' disabled={!canToday}>{t('global.today')}</ToggleButton>
      { !checkOrderIsLimitedToSameDay() && <ToggleButton value='tomorrow' disabled={!canTomorrow}>{t('global.tomorrow')}</ToggleButton> }
      { !checkOrderIsLimitedToSameDay() && <ToggleButton value='pick' disabled={!canPick}>{t('global.pickADate')}</ToggleButton> }
    </ToggleButtonGroup>
  )
}

export default OrderingMethodsDateButtonGroup
