import {
  DialogActions,
  dialogClasses,
  DialogContent,
  dialogTitleClasses,
  drawerClasses,
  paperClasses,
  typographyClasses,
  useTheme
} from '@mui/material'
import { Suspense, SyntheticEvent, useEffect, useState } from 'react'
import DialogTitle from '~/src/components/mui-wrappers/DialogTitle'
import ResponsiveDialogContext from '~/src/components/mui-wrappers/responsive-dialog/ResponsiveDialogContext'
import ResponsiveDialogWrapper from '~/src/components/mui-wrappers/responsive-dialog/wrappers/ResponsiveDialogWrapper'
import { Constants } from '~/src/helpers/constants'
import useDialog from '~/src/hooks/useDialog'
import { useEoValue } from '~/src/hooks/useEoState'
import usePopStateListener from '~/src/hooks/usePopStateListener'
import { IS_THE_LAST_OVERLAY_VISIBLE } from '~/src/stores/dialogs_overlay'
import { ResponsiveDialogEventType } from '~/src/types/ResponsiveDialogEventType'
import ResponsiveDialogProps from '~/src/types/ResponsiveDialogProps'

const ResponsiveDialog = (
  {
    Title,
    Actions,
    activator,
    children,
    onOpen,
    onClose,
    responsive = true,
    persistent = false,
    showCloseButton = true,
    openByDefault = false,
    contentSx = {},
    sx = {},
    name,
    ...otherProps
  }: ResponsiveDialogProps
) => {
  const theme = useTheme()
  const { listen, remove } = usePopStateListener()
  const { dismiss } = useDialog()
  const isTheLastOverlayVisible = useEoValue<boolean>(IS_THE_LAST_OVERLAY_VISIBLE(name))
  const [ state, setState ] = useState<boolean>(false)


  const _toggleOpen = ($event: ResponsiveDialogEventType = {} as ResponsiveDialogEventType) => {
    setState(true)

    onOpen && onOpen($event)
  }

  const _prepareClosing = () => {
    setState(false)
  }

  const _toggleClose = ($event: SyntheticEvent<{}, Event> = {} as ResponsiveDialogEventType) => {
    if (persistent) {
      return
    }

    _prepareClosing()

    /**
     * Delaying DOM destruction to handle the close animation
     */
    setTimeout(() => {
      name && dismiss(name)
      onClose && onClose($event)
    }, Constants.DEBOUNCE_TIME)
  }

  const _toggle = () => {
    if (state) {
      onClose && onClose({} as ResponsiveDialogEventType)
      name && dismiss(name)
    } else {
      onOpen && onOpen({} as ResponsiveDialogEventType)
    }

    setState(!state)
  }

  const _handleOnPopState = ($event: PopStateEvent) => {
    $event.preventDefault()
    $event.stopPropagation()

    _toggleClose({} as SyntheticEvent)
  }

  useEffect(() => {
    setState(openByDefault)
  }, [ openByDefault ])

  useEffect(() => {
    if (!state) {
      return
    }

    if (!isTheLastOverlayVisible) {
      return remove()
    }

    listen(_handleOnPopState)
  }, [ state, isTheLastOverlayVisible ])

  useEffect(() => {
    return () => {
      remove()
    }
  }, [])

  return (
    <ResponsiveDialogContext.Provider
      value={{
        state,
        toggle: _toggle,
        toggleOpen: _toggleOpen,
        toggleClose: _toggleClose
      }}
    >
      {activator}

      <ResponsiveDialogWrapper
        open={state}
        onClose={_toggleClose}
        onOpen={_toggleOpen}
        responsive={responsive}
        {...otherProps}
        sx={{
          [`&.${drawerClasses.root}`]: {
            [`.${dialogTitleClasses.root}`]: {
              width: '100%',
              [`>h1, >h2, >h3, >h4, >h5, >h6, >span, >p, >.${typographyClasses.root}`]: {
                ml: showCloseButton ? `calc(1.2857142857142856rem + ${theme.spacing(2)})` : 0,
                width: '100%',
                textAlign: 'center',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap'
              }
            }
          },
          [`&.${dialogClasses.root}`]: {
            [`.${dialogTitleClasses.root}`]: {
              px: 3,
              pr: 2
            }
          },
          [`.${dialogClasses.container} > .${paperClasses.root}`]: {
            pt: 'var(--eo-safe-area-top)',
            pb: 'var(--eo-safe-area-bottom)',
            backgroundColor: 'secondary.main',
            color: 'secondary.contrastText',
            backgroundImage: 'none'
          },
          [`& > .${paperClasses.root}`]: {
            backgroundColor: 'secondary.main',
            color: 'secondary.contrastText',
            backgroundImage: 'none'
          },
          ...sx
        }}
      >
        {(Title || showCloseButton) && (
          <DialogTitle
            CloseButtonProps={(!persistent || !showCloseButton) ? {
              onClick: _toggleClose
            } : undefined}
            showCloseButton={showCloseButton}
            sx={{
              p: 1,
              py: 1.5
            }}
          >
            {Title}
          </DialogTitle>
        )}

        <DialogContent
          sx={{
            px: 2,
            ...contentSx
          }}
        >
          <Suspense>
            {children}
          </Suspense>
        </DialogContent>

        {Actions && (
          <DialogActions
            sx={{
              pt: 2,
              px: 3,
              pb: 3
            }}
          >
            {Actions}
          </DialogActions>
        )}
      </ResponsiveDialogWrapper>
    </ResponsiveDialogContext.Provider>
  )
}

export default ResponsiveDialog
