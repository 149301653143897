import { OrderingMethodNames } from '@eo-storefronts/eo-core'
import { Box } from '@mui/material'
import { useEoValue } from '~/src/hooks/useEoState'
import { CHECKOUT_DELIVERY_METHOD_SELECTOR } from '~/src/stores/checkout'
import { IS_ALL_DELIVERY_METHODS_INACTIVE_FOR_FIRM } from '~/src/stores/firm'
import OrderingMethodNotAvailableMessage from '~/src/components/ordering-methods/OrderingMethodNotAvailableMessage'
import useOrderingMethodService from '~/src/hooks/ordering-methods/useOrderingMethodService'
import OrderingMethodsSummaryPickupPoints
  from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-pickup-points'
import OrderingMethodsSummary from '~/src/components/ordering-methods/ordering-methods-summary'
import useGetOrderingMethodsSummaryContentTemplate
  from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-content/styles/useGetOrderingMethodsSummaryContentTemplate'

const OrderingMethodSummaryContent = () => {
  const styles = useGetOrderingMethodsSummaryContentTemplate()
  const orderingMethodSelected = useEoValue(CHECKOUT_DELIVERY_METHOD_SELECTOR)
  const isAllFirmMethodsInactive = useEoValue(IS_ALL_DELIVERY_METHODS_INACTIVE_FOR_FIRM)
  const { isMethodInactive } = useOrderingMethodService()

  if (isAllFirmMethodsInactive || orderingMethodSelected && isMethodInactive(orderingMethodSelected)) {
    return <OrderingMethodNotAvailableMessage />
  }

  return (
    <>
      {orderingMethodSelected === OrderingMethodNames.PICKUP && (
        <OrderingMethodsSummaryPickupPoints />
      )}

      <Box {...styles.getOrderingMethodsSummaryContainerProps()}>
        <OrderingMethodsSummary />
      </Box>
    </>
  )
}

export default OrderingMethodSummaryContent
