import {
  ApiConfiguration,
  AppleSignInVerifyPayload,
  ThirdPartySignInVerifyResponse,
  AuthService,
  CheckSessionTokenResponse,
  RegisterResponse,
  Customer,
  CustomerAuthResponse,
  FindFirmArgType,
  GuestAuthResponse,
  InitSocialLoginResponse,
  SocialLoginType,
  GoogleSignInVerifyPayload
} from '@eo-storefronts/eo-core'
import EnvUtils from '~/src/utils/EnvUtils'
import Credentials from '~/src/types/Credentials'

export const loginAsClient = async (payload: FindFirmArgType) => {
  return AuthService.loginAsClient({
    clientId: EnvUtils.clientId,
    clientSecret: EnvUtils.clientSecret,
    applicationId: (payload as { applicationId: string }).applicationId,
    domain: (payload as { host: string }).host
  })
}

export const loginAsCustomer = async (credentials: Credentials): Promise<CustomerAuthResponse> => {
  return AuthService.loginAsCustomer(
    {
      ...credentials,
      clientId: 'app'
    }
  )
}

export const register = async (customer: Customer, returnUrl: string, google?: boolean): Promise<RegisterResponse> => {
  ApiConfiguration.addOrReplaceHeader('return_url', returnUrl)

  return AuthService.register(customer, google)
}

export const loginAsGuest = async (): Promise<GuestAuthResponse> => {
  return AuthService.loginAsGuest()
}

export const initSocialLogin = async (socialLoginType: SocialLoginType, returnUrl: string): Promise<InitSocialLoginResponse> => {
  return AuthService.initSocialLogin(socialLoginType, returnUrl)
}

export const checkSocialToken = async (socialToken: string): Promise<CheckSessionTokenResponse> => {
  return AuthService.checkSocialToken(socialToken)
}

export const verifyApplePayload = async (payload: AppleSignInVerifyPayload): Promise<ThirdPartySignInVerifyResponse> => {
  return AuthService.verifyApplePayload(payload)
}

export const verifyGooglePayload = async (payload: GoogleSignInVerifyPayload): Promise<ThirdPartySignInVerifyResponse> => {
  return AuthService.verifyGooglePayload(payload)
}
