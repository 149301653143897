import { Identifiable, UUID } from '@eo-storefronts/eo-core'
import { indexDbPersist } from '~/src/helpers/recoilPersist'
import { createSelector, createSelectorFamily, createState } from '~/src/hooks/useEoState'
import { GuestFormInterface } from '~/src/types/forms/GuestFormInterface'

export interface BaseAuthenticationState {
  token: string,
  expiresIn?: number,
  tokenType?: string,
}

type LoggedInAs = 'customer' | 'guest'

export interface AuthenticationState {
  customer: BaseAuthenticationState & Identifiable & UUID | undefined,
  guest: BaseAuthenticationState & GuestFormInterface & UUID & Identifiable | undefined,
  loggedInAs: LoggedInAs | undefined,
}

export const API_TOKEN_STATE = createState<BaseAuthenticationState | null>({
  key: 'apiTokenState',
  default: null
})

export const AUTHENTICATION_STATE = createState<AuthenticationState>({
  key: 'authenticationState',
  default: {
    loggedInAs: undefined,
    customer: undefined,
    guest: undefined
  },
  effects: [
    indexDbPersist('authenticationState')
  ]
})

export const IS_LOGGED_IN_AS_SELECTOR_FAMILY = createSelectorFamily<boolean, LoggedInAs>({
  key: 'isLoggerInAsSelectorFamily',
  get: (as: LoggedInAs) => ({ get }): boolean => {
    const authState: AuthenticationState = get(AUTHENTICATION_STATE)

    return authState.loggedInAs === as
  }
})

export const IS_LOGGED_IN_SELECTOR = createSelector<boolean>({
  key: 'isLoggerInSelector',
  get: ({ get }) => {
    return get(IS_LOGGED_IN_AS_SELECTOR_FAMILY('customer'))
  }
})
