import { useEoValue } from '~/src/hooks/useEoState'
import { CUSTOMER_LOYALTY_VOUCHERS_BALANCE_SELECTOR } from '~/src/stores/customer'
import { useTranslations } from '~/src/hooks/useTranslations'
import { Box } from '@mui/material'

const CustomerLoyaltyCurrentPointsLabel = () => {
  const { t } = useTranslations()
  const customerLoyaltyVouchersValue = useEoValue(CUSTOMER_LOYALTY_VOUCHERS_BALANCE_SELECTOR)

  return (
    <>
      <Box component='b'>
        {customerLoyaltyVouchersValue}
      </Box>
      &nbsp;
      {t('profile.loyalty.points').toLowerCase()}
    </>
  )
}

export default CustomerLoyaltyCurrentPointsLabel
