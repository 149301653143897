import { Currency, Product } from '@eo-storefronts/eo-core'
import StarsIcon from '@mui/icons-material/Stars'
import { Box, Tooltip, Typography } from '@mui/material'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { LOYALTY_IS_ENABLED_AND_CUSTOMER_IS_LOGGED_IN_SELECTOR } from '~/src/stores/loyalty'
import { useMemo } from 'react'
import useGetProductPrice from '~/src/hooks/products/useGetProductPrice'
import NumberUtils from '~/src/utils/NumberUtils'
import { FIRM_CURRENCY_SELECTOR } from '~/src/stores/firm'

interface Props {
  product: Product,
  quantity?: number,
  size?: 'small' | 'large',
}

const ProductLoyaltyPercentage = ({ product, quantity = 1, size = 'small' }: Props) => {
  const { t } = useTranslations()
  const theme = useCustomMuiTheme()
  const firmCurrency = useEoValue<Currency | undefined>(FIRM_CURRENCY_SELECTOR)
  const isLoyaltyEnabled = useEoValue(LOYALTY_IS_ENABLED_AND_CUSTOMER_IS_LOGGED_IN_SELECTOR)
  const { getPrice } = useGetProductPrice(product)
  const sx = {
    display: 'flex',
    justifyContent: 'center',
    verticalAlign: 'middle',
    gap: 1,
    py: `${theme.spacing(.5)} !important`,
    px: `${theme.spacing(1)} !important`,
    borderRadius: '25px',
    borderStyle: 'solid',
    borderColor: 'primary',
    borderWidth: '1px',
    whiteSpace: 'nowrap'
  }

  const loyaltyValue = useMemo<number | undefined>(() => {
    if (!product.loyalty.percentRatio) {
      return
    }

    return (product.loyalty.percentRatio * getPrice) * quantity
  }, [ getPrice, product.loyalty.percentRatio, quantity ])

  const point = useMemo((): string | undefined => {
    if (!firmCurrency || !loyaltyValue) {
      return undefined
    }

    return `${NumberUtils.toFixed(loyaltyValue, 2)}${firmCurrency.symbol}`
  }, [ loyaltyValue, firmCurrency ])

  if (!isLoyaltyEnabled || !point) {
    return null
  }

  if (size === 'large') {
    return (
      <Box sx={sx}>
        <StarsIcon/>
        <Typography sx={{ cursor: 'default' }}>{t('product.loyaltyPercent', { point })}</Typography>
      </Box>
    )
  }

  return (
    <Tooltip
      arrow
      followCursor
      title={t('product.loyaltyPercent', { point })}
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText
          }
        },
        arrow: {
          sx: {
            color: theme.palette.primary.main
          }
        }
      }}
    >
      <Box sx={sx}>
        <Typography
          component='span'
          sx={{ cursor: 'default' }}>
          {`+ ${point}`}
        </Typography>
        <StarsIcon/>
      </Box>
    </Tooltip>
  )
}

export default ProductLoyaltyPercentage
