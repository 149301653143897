import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'
import StylesOrderingMethodsSummaryMainButtonsServiceInterface
  from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-main-buttons/styles/StylesOrderingMethodsSummaryMainButtonsServiceInterface'
import { BoxProps, SxProps } from '@mui/material'

export default class StylesOrderingMethodsSummaryMainButtonsServiceStyleTwo extends TemplateServiceStyleBase implements StylesOrderingMethodsSummaryMainButtonsServiceInterface {
  getContainerProps(): BoxProps {
    return {
      sx: this.getContainerSx()
    }
  }

  getContainerSx(): SxProps {
    return {
      display: 'grid',
      gridTemplateColumns: 'max-content 1fr',
      maxWidth: '100%',
      overflow: 'hidden',
      columnGap: 1
    }
  }
}
