import { FormField, FormFieldOptionTypeEnum } from '@eo-storefronts/eo-core'
import FormFieldsService from '~/src/services/FormFieldsService'
import { FormValidationOptions } from '~/src/types/forms/FormValidationOptions'
import { BaseSchema, StringSchema } from 'yup'

interface ReturnsType {
  isRequired(fieldName: string): boolean,
  isHidden(fieldName: string): boolean,
  areAllHidden(): boolean,
  getPhoneFieldValidator(fieldName: string, options: FormValidationOptions<StringSchema>): StringSchema,
  getFieldRequiredValidator<T extends BaseSchema>(fieldName: string, options: FormValidationOptions<T>): T,
}

const useFormFields = (fields: FormField[]): ReturnsType => {
  const formFieldService = new FormFieldsService(fields)

  const isHidden = (fieldName: string): boolean => {
    return formFieldService.is(fieldName, FormFieldOptionTypeEnum.HIDDEN)
  }

  const isRequired = (fieldName: string): boolean => {
    return formFieldService.is(fieldName, FormFieldOptionTypeEnum.REQUIRED)
  }

  const getPhoneFieldValidator = (fieldName: string, options: FormValidationOptions<StringSchema>): StringSchema => {
    return formFieldService.getPhoneFieldValidator(fieldName, options)
  }

  const getFieldRequiredValidator = <T extends BaseSchema>(fieldName: string, options: FormValidationOptions<T>): T => {
    return formFieldService.getFieldRequiredValidator<T>(fieldName, options)
  }

  const areAllHidden = (): boolean => {
    return formFieldService.isAll(FormFieldOptionTypeEnum.HIDDEN)
  }

  return { isHidden, isRequired, areAllHidden, getPhoneFieldValidator, getFieldRequiredValidator }
}

export default useFormFields
