import { Firm, Tracking, TrackingField } from '@eo-storefronts/eo-core'
import { useEffect } from 'react'

// @ts-ignore
import TagManager from 'react-gtm-module'
import { useEoValue } from '~/src/hooks/useEoState'
import { BRAND_STATE } from '~/src/stores/brand'

const useInitGoogleTagManager = (): void => {
  const brand = useEoValue(BRAND_STATE)

  const _getGoogleTagManagerId = (firm: Firm | null): string | undefined => firm
    ?.settings
    ?.tracking
    ?.find((tracking: Tracking) => tracking.type === 'GOOGLE_TAG_MANAGER')
    ?.fields.find((field: TrackingField) => field.id === 'google_tag_manager_id')
    ?.value

  useEffect(() => {
    if (!brand) {
      return
    }

    const gtmId: string | undefined = _getGoogleTagManagerId(brand)

    if (!gtmId) {
      return
    }

    TagManager.initialize({ gtmId })
  }, [ brand ])
}

export default useInitGoogleTagManager
