import { CustomDay, DateUtils } from '@eo-storefronts/eo-core'
import { Box, TextField, Typography } from '@mui/material'
import { ChangeEvent, useEffect, useState } from 'react'
import { useEoState, useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { getCustomDays } from '~/src/services/DeliveryTimeService'
import { CHECKOUT_FORM_STATE, CheckoutFormState } from '~/src/stores/checkout'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { LANGUAGE_STATE } from '~/src/stores/lang'

const OrderingMethodsCustomDays = () => {
  const { t } = useTranslations()
  const firm = useEoValue(FIRM_SELECTOR)
  const [ checkoutForm, setCheckoutForm ] = useEoState(CHECKOUT_FORM_STATE)
  const lang = useEoValue(LANGUAGE_STATE)
  const [ customDays, setCustomDays ] = useState<string[]>([])

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCheckoutForm((state: CheckoutFormState) => ({
      ...state,
      orderingMethod: {
        ...state.orderingMethod,
        time: event.target.value !== '' ? event.target.value : null
      }
    }))
  }

  useEffect(() => {
    if (!firm || !checkoutForm.orderingMethod.method) {
      return
    }

    const apiCustomDays = getCustomDays(firm, checkoutForm.orderingMethod.method)
      .map((customDay: CustomDay) => customDay.date)

    setCustomDays(apiCustomDays)
  }, [ firm, checkoutForm.orderingMethod.method ])

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      <Typography>{t('orderingMethods.customDaySelector')}</Typography>
      {customDays.length === 0 && <Typography color='error'>{t('orderingMethods.no_more_custom_days_available')}</Typography>}
      {customDays.length !== 0 && (
        <TextField
          select
          size='small'
          SelectProps={{
            native: true
          }}
          variant='outlined'
          onChange={handleOnChange}
          value={checkoutForm.orderingMethod.time || ''}
        >
          <option value=''>{t('orderingMethods.chooseTime')}</option>
          {
            customDays.map((customDay: string, index: number) => (
              <option
                key={index}
                value={customDay}
              >
                {DateUtils.format(new Date(customDay), lang)}
              </option>
            ))
          }
        </TextField>
      )}
    </Box>
  )
}

export default OrderingMethodsCustomDays
