import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'
import StylesOrderingMethodsSummaryEditButtonServiceInterface
  from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-edit-button/styles/StylesOrderingMethodsSummaryEditButtonServiceInterface'
import StylesOrderingMethodsSummaryEditButtonServiceStyleTwo
  from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-edit-button/styles/StylesOrderingMethodsSummaryEditButtonServiceStyleTwo'
import StylesOrderingMethodsSummaryEditButtonServiceStyleOne
  from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-edit-button/styles/StylesOrderingMethodsSummaryEditButtonServiceStyleOne'

const getOrderingMethodsSummaryEditButtonTemplateService = (style: TemplateEnum | null | undefined, muiTheme: CustomMuiTheme): StylesOrderingMethodsSummaryEditButtonServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesOrderingMethodsSummaryEditButtonServiceStyleTwo(muiTheme)
    default:
      return new StylesOrderingMethodsSummaryEditButtonServiceStyleOne(muiTheme)
  }
}

export default getOrderingMethodsSummaryEditButtonTemplateService
