export enum DialogNameEnum {
  PRODUCT_BY_ID = 'product-dialog-',
  SHOPPING_CART = 'shopping-cart',
  ACCOUNT_ACTIVATION_SUCCESS = 'account-activation-success',
  PROFILE_QUICK_AUTH = 'profile-quick_auth',
  AUTH_MODAL = 'auth-modal',
  LANGUAGE_SELECTION = 'language-selection',
  ANNOUNCEMENTS = 'announcements',
  LOCATION_CONFIRMATION = 'location-confirmation',
  DELIVERY_METHOD_CONFIRMATION = 'ordering-methods-confirmation',
  THANK_YOU_FOR_YOUR_ORDER = 'thank-your-for-your-order-dialog',
  ORDER_VALIDATING_PAYMENT = 'order_validating_payment',
  DEV_MODE = 'dev-mode-dialog'
}
