import { FirebaseMessaging, NotificationActionPerformedEvent, NotificationReceivedEvent } from '@capacitor-firebase/messaging'
import { useEffect } from 'react'
import usePushNotificationsModal from '~/src/hooks/push-notifications/usePushNotificationsModal'
import EnvUtils from '~/src/utils/EnvUtils'

const usePushNotificationsActionEffect = () => {
  const { presentModal } = usePushNotificationsModal()

  useEffect(() => {
    if (EnvUtils.isDeviceWeb) {
      return
    }

    FirebaseMessaging.addListener('notificationReceived', (event: NotificationReceivedEvent) => {
      void presentModal(event.notification)
    })

    FirebaseMessaging.addListener('notificationActionPerformed', (action: NotificationActionPerformedEvent) => {
      void presentModal(action.notification)
    })
  }, [])
}

export default usePushNotificationsActionEffect
