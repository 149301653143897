import { BasicResponse, HttpError } from '@eo-storefronts/eo-core'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useTranslations } from '~/src/hooks/useTranslations'
import { askResetPassword } from '~/src/services/CustomerService'
import RoutesEnum from '~/src/router/enums/routes.enum'
import useGetEmailReturnUrl from '~/src/hooks/router/useGetEmailReturnUrl'

interface ReturnsType {
  reset(email: string): Promise<BasicResponse | undefined>,

  loading: boolean,
  error: HttpError | unknown | undefined,
}

const useAskResetPassword = (): ReturnsType => {
  const { t } = useTranslations()
  const { enqueueSnackbar } = useSnackbar()
  const { generateReturnUrl } = useGetEmailReturnUrl()
  const [ loading, setLoading ] = useState<boolean>(false)
  const [ error, setError ] = useState<HttpError | unknown | undefined>(undefined)

  const reset = async (email: string) => {
    setLoading(true)
    setError(undefined)

    try {
      const response: BasicResponse = await askResetPassword(
        email,
        generateReturnUrl(RoutesEnum.RESET_PASSWORD)
      )

      enqueueSnackbar(t('auth.checkMailbox'), { variant: 'success' })

      return Promise.resolve(response)
    } catch (error: HttpError | unknown) {
      setError(error)
      enqueueSnackbar(t('errors.defaultError'), { variant: 'error' })

      return Promise.reject(error)
    } finally {
      setLoading(false)
    }
  }

  return { reset, loading, error }
}

export default useAskResetPassword
