import { Location, OrderUrl } from '@eo-storefronts/eo-core'
import { Box, SxProps } from '@mui/material'
import LocationResponsiveDialogActionsSkeleton
  from '~/src/components/marketplace/locations/location-responsive-dialog/location-responsive-dialog-actions/LocationResponsiveDialogActionsSkeleton'
import OrderButton from '~/src/components/marketplace/order-buttons/OrderButton'
import { useEoValue } from '~/src/hooks/useEoState'
import { MARKETPLACE_LOCATION_SELECTED_STATE } from '~/src/stores/marketplace/locations'

interface Props {
  isLoading?: boolean,
  sx?: SxProps,
}

const LocationResponsiveDialogActions = ({ isLoading = false, sx = {} }: Props) => {
  const location: Location | undefined = useEoValue(MARKETPLACE_LOCATION_SELECTED_STATE)

  if (isLoading || !location) {
    return <LocationResponsiveDialogActionsSkeleton />
  }

  const { orderUrls } = location

  if (!orderUrls) {
    return null
  }

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: orderUrls.length > 1 ? 'repeat(2, 1fr)' : '1fr',
        width: '100%',
        gap: 2,
        ...sx
      }}
    >
      {orderUrls.map((orderUrl: OrderUrl, index: number) => (
        <OrderButton
          key={index}
          location={location}
          orderUrl={orderUrl}
        />
      ))}
    </Box>
  )
}

export default LocationResponsiveDialogActions
