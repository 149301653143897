import { Firm, LoyaltyHistory, LoyaltyPointTypeEnum } from '@eo-storefronts/eo-core'
import { createSelector, createState } from '~/src/hooks/useEoState'
import { CUSTOMER_LOYALTY_VOUCHERS_BALANCE_SELECTOR, CUSTOMER_STATE } from '~/src/stores/customer'
import { BRAND_STATE } from './brand'
import { CART_SUB_TOTAL_SELECTOR } from '~/src/stores/cart'
import NumberUtils from '~/src/utils/NumberUtils'
import { CHECKOUT_FORM_STATE, CheckoutFormState } from '~/src/stores/checkout'

export const CUSTOMER_LOYALTY_HISTORY = createState<LoyaltyHistory[]>({
  key: 'customerLoyaltyHistory',
  default: []
})

export const LOYALTY_IS_ENABLED_SELECTOR = createSelector({
  key: 'loyaltyIsEnabledSelector',
  get: ({ get }) => {
    const brand = get<Firm | null>(BRAND_STATE)

    if (!brand) {
      return false
    }

    return !!brand.settings.loyalty?.active
  }
})

export const LOYALTY_POINT_TYPE_SELECTOR = createSelector<LoyaltyPointTypeEnum | undefined>({
  key: 'loyaltyPointType',
  get: ({ get }): LoyaltyPointTypeEnum | undefined => {
    const brand = get<Firm | null>(BRAND_STATE)

    if (!brand?.settings.loyalty?.active) {
      return
    }

    return brand.settings.loyalty.pointType
  }
})

export const LOYALTY_IS_POINT_SELECTOR = createSelector({
  key: 'loyaltyIsPointSelector',
  get: ({ get }) => {
    const loyaltyPointType: LoyaltyPointTypeEnum | undefined = get(LOYALTY_POINT_TYPE_SELECTOR)

    if (!loyaltyPointType) {
      return false
    }

    return loyaltyPointType === LoyaltyPointTypeEnum.POINT
  }
})

export const LOYALTY_IS_POINT_THRESHOLD_SELECTOR = createSelector({
  key: 'loyaltyIsPointThreshold',
  get: ({ get }) => {
    const loyaltyPointType: LoyaltyPointTypeEnum | undefined = get(LOYALTY_POINT_TYPE_SELECTOR)

    if (!loyaltyPointType) {
      return false
    }

    return loyaltyPointType === LoyaltyPointTypeEnum.POINT_THRESHOLD
  }
})

export const LOYALTY_IS_ENABLED_AND_CUSTOMER_IS_LOGGED_IN_SELECTOR = createSelector<boolean>({
  key: 'loyaltyIsEnabledAndCustomerIsLoggedInAndCustomerIsLoggedInSelector',
  get: ({ get }) => {
    const customer = get(CUSTOMER_STATE)
    const loyaltyIsEnabled = get(LOYALTY_IS_ENABLED_SELECTOR)

    return !!customer
      && !customer.loyalty.fidelityExcluded
      && loyaltyIsEnabled
  }
})

export const LOYALTY_CAN_BE_APPLIED_SELECTOR = createSelector<boolean>({
  key: 'loyaltyDiscountCanBeAppliedSelector',
  get: ({ get }) => {
    const loyaltyIsEnabled = get(LOYALTY_IS_ENABLED_AND_CUSTOMER_IS_LOGGED_IN_SELECTOR)
    const brand = get<Firm | null>(BRAND_STATE)

    if (!loyaltyIsEnabled) {
      return false
    }

    return !!brand?.settings?.loyalty?.pointUseOnlineWallet
  }
})

export const LOYALTY_MAXIMUM_AMOUNT_USABLE_SELECTOR = createSelector<number>({
  key: 'loyaltyMaximumAmountUsableSelector',
  get: ({ get }): number => {
    const checkoutFormState = get<CheckoutFormState>(CHECKOUT_FORM_STATE)
    const brand = get<Firm | null>(BRAND_STATE)
    const cartSubTotalValue = get<number>(CART_SUB_TOTAL_SELECTOR)
    const loyaltyTotalVouchersValue = get<number>(CUSTOMER_LOYALTY_VOUCHERS_BALANCE_SELECTOR)
    const allLoyaltyAmount: number = loyaltyTotalVouchersValue + (checkoutFormState.physicalVoucher?.amount ?? 0)

    if (!brand) {
      return 0
    }

    if (cartSubTotalValue >= allLoyaltyAmount) {
      return NumberUtils.round(allLoyaltyAmount, 2)
    }

    return NumberUtils.round(cartSubTotalValue, 2)
  }
})

export const LOYALTY_PHYSICAL_VOUCHER_MAXIMUM_AMOUNT_USABLE_SELECTOR = createSelector<number>({
  key: 'loyaltyPhysicalVoucherMaximumAmountUsableSelector',
  get: ({ get }): number => {
    const brand = get<Firm | null>(BRAND_STATE)
    const cartSubTotalValue = get<number>(CART_SUB_TOTAL_SELECTOR)
    const checkoutFormState = get<CheckoutFormState>(CHECKOUT_FORM_STATE)
    const loyaltyMaximumAmountUsable = get<number>(LOYALTY_MAXIMUM_AMOUNT_USABLE_SELECTOR)
    let cartSubTotal: number = cartSubTotalValue

    if (!brand || !checkoutFormState.physicalVoucher?.amount) {
      return 0
    }

    if (checkoutFormState.useLoyaltyPoints) {
      cartSubTotal -= loyaltyMaximumAmountUsable
    }

    if (cartSubTotal >= checkoutFormState.physicalVoucher?.amount) {
      return NumberUtils.round(checkoutFormState.physicalVoucher.amount, 2)
    }

    return NumberUtils.round(cartSubTotal, 2)
  }
})
