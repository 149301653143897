import { App, URLOpenListenerEvent } from '@capacitor/app'
import { Browser } from '@capacitor/browser'
import { PlatformUtils } from '@eo-storefronts/eo-core'
import { useEffect } from 'react'
import { useRouterPush } from '~/src/hooks/router/useRouterPush'
import { useSetEoState } from '~/src/hooks/useEoState'
import RoutesEnum from '~/src/router/enums/routes.enum'
import AppLoaderState, { APP_LOADER_STATE } from '~/src/stores/app-loader'
import EnvUtils from '~/src/utils/EnvUtils'

const useAppUrlOpenListener = () => {
  const { push } = useRouterPush()
  const setAppLoader = useSetEoState<AppLoaderState>(APP_LOADER_STATE)

  useEffect(() => {
    if (!EnvUtils.isDeviceApp && !PlatformUtils().isCapacitorNative) {
      return
    }

    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      const url = new URL(event.url)

      if (url.pathname.indexOf(RoutesEnum.CHECKOUT) !== -1) {
        void Browser.close()
      }

      setAppLoader({ type: 'standard', state: false })
      push(url.pathname.replace('//localhost', '') + url.search)
    })
  }, [])
}

export default useAppUrlOpenListener
