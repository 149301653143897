import { Box } from '@mui/material'
import { useEatInLocationInline } from '~/src/hooks/ordering-methods/useEatInLocationInline'
import TableRestaurantIcon from '@mui/icons-material/TableRestaurant'

const EatInSummary = () => {
  const { sentence } = useEatInLocationInline()

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 1
      }}
    >
      <TableRestaurantIcon/> {sentence}
    </Box>
  )
}

export default EatInSummary
