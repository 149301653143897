import FirmOfflineBanner, { FirmOfflineBannerProps } from '~/src/components/firms/FirmOfflineBanner'
import { useEoValue } from '~/src/hooks/useEoState'
import { FIRM_WITH_LOCATOR_STATE_SELECTOR } from '~/src/stores/firm'

const FirmClosed = (props: FirmOfflineBannerProps) => {
  const firm = useEoValue(FIRM_WITH_LOCATOR_STATE_SELECTOR)

  if (!firm?.settings.isOffline) {
    return null
  }

  return <FirmOfflineBanner {...props}/>
}

export default FirmClosed
