import useGetShoppingCartActionsTemplate from '~/src/components/cart/shopping-cart-actions/styles/useGetShoppingCartActionsTemplate'
import Button from '~/src/components/mui-wrappers/buttons/Button'
import useShoppingCartActions from '~/src/hooks/cart/useShoppingCartActions'
import useValidateOrder, { OrderErrorType } from '~/src/hooks/orders/useValidateOrder'
import { useTranslations } from '~/src/hooks/useTranslations'

const ShoppingCartActionsLoggedIn = () => {
  const styles = useGetShoppingCartActionsTemplate()
  const { t } = useTranslations()
  const { errors } = useValidateOrder()
  const { finalize } = useShoppingCartActions()
  const disabled = !!errors.filter((error: OrderErrorType) => error.variant === 'error').length

  const _handleOnClick = () => {
    finalize()
  }

  return (
    <Button
      disabled={disabled}
      variant='contained'
      disableElevation
      sx={styles.getGoToCheckoutSxProps()}
      onClick={_handleOnClick}
    >
      {t('global.goToCheckout')}
    </Button>
  )
}

export default ShoppingCartActionsLoggedIn
