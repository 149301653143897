import { Customer, Id } from '@eo-storefronts/eo-core'
import { useEoValue } from '~/src/hooks/useEoState'
import { fetchCustomerById } from '~/src/services/CustomerService'
import { AUTHENTICATION_STATE } from '~/src/stores/authentication'

interface ReturnsType {
  fetch(customerIdOrUUID?: Id): Promise<Customer | undefined>,
}

const useFetchCustomer = (): ReturnsType => {
  const { customer } = useEoValue(AUTHENTICATION_STATE)

  const fetch = async (customerId?: Id): Promise<Customer | undefined> => {
    const id: Id | undefined = customerId ?? customer?.id

    if (!id) {
      return
    }

    return fetchCustomerById(id)
  }

  return { fetch }
}

export default useFetchCustomer
