import { Menu as MUIMenu, MenuProps, styled, Theme } from '@mui/material'
import useGetMenuTemplate from '~/src/components/mui-wrappers/styled/menu/styles/useGetMenuTemplate'

const Menu = styled((props: MenuProps) => (
  <MUIMenu
    id='basic-menu'
    MenuListProps={{
      'aria-labelledby': 'basic-button'
    }}
    {...props}
  />
// eslint-disable-next-line @typescript-eslint/no-unused-vars
))(({ theme: _, ...props }: MenuProps & { theme: Theme }) =>
  useGetMenuTemplate().getComponentSx(props)
)

export default Menu
