import { Id, Timeslot } from '@eo-storefronts/eo-core'
import { Box, CircularProgress, Typography } from '@mui/material'
import { ChangeEvent } from 'react'
import OrderingMethodsTimeslotOption from '~/src/components/ordering-methods/OrderingMethodsTimeslotOption'
import { useEoState, useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { TIMESLOT_FOR_SELECTED_DATE_SELECTOR } from '~/src/stores/timeslots'
import { CHECKOUT_FORM_STATE, CheckoutFormState } from '~/src/stores/checkout'
import TextField from '~/src/components/mui-wrappers/styled/text-field'

interface Props {
  loading: boolean,
}

const OrderingMethodsTimeslots = ({ loading }: Props) => {
  const { t } = useTranslations()
  const [ checkoutForm, setCheckoutForm ] = useEoState(CHECKOUT_FORM_STATE)
  const timeslots = useEoValue(TIMESLOT_FOR_SELECTED_DATE_SELECTOR)

  const _updateCheckoutForm = (timeslotId: Id) => {
    setCheckoutForm((state: CheckoutFormState) => ({
      ...state,
      orderingMethod: {
        ...state.orderingMethod,
        timeslotId
      }
    }))
  }

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    _updateCheckoutForm(event.target.value)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <Typography>{t('orderingMethods.selectYourSlot')}</Typography>
      {loading && <CircularProgress size={20} />}
      {!loading && (
        <TextField
          select
          size='small'
          color='container'
          SelectProps={{
            native: true,
            sx: {
              option: {
                backgroundColor: 'var(--container-background) !important',
                color: 'var(--container-constrast) !important'
              }
            }
          }}
          variant='outlined'
          onChange={handleOnChange}
          value={checkoutForm.orderingMethod.timeslotId || 0}
        >
          {timeslots.map((timeslot: Timeslot) => (
            <OrderingMethodsTimeslotOption
              key={timeslot.id}
              timeslot={timeslot}
            />
          ))}
        </TextField>
      )}
    </Box>
  )
}

export default OrderingMethodsTimeslots
