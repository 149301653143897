import { Firm, Tracking, TrackingField } from '@eo-storefronts/eo-core'
import { useEffect } from 'react'
import ReactGA from 'react-ga4'
import { useEoValue } from '~/src/hooks/useEoState'
import { BRAND_STATE } from '~/src/stores/brand'

const useInitGoogleAnalytics = (): void => {
  const brand = useEoValue(BRAND_STATE)

  const _getGoogleAnalyticsId = (firm: Firm | null): string | undefined => firm
    ?.settings
    ?.tracking
    ?.find((tracking: Tracking) => tracking.type === 'GOOGLE_ANALYTICS')
    ?.fields.find((field: TrackingField) => field.id === 'google_analytics_id')
    ?.value


  useEffect(() => {
    ReactGA.reset()

    if (!brand) {
      return
    }

    const googleAnalyticsId: string | undefined = _getGoogleAnalyticsId(brand)

    if (!googleAnalyticsId) {
      return
    }

    ReactGA.initialize(googleAnalyticsId)
  }, [ brand ])
}

export default useInitGoogleAnalytics
