import SocialLoginBtn from '~/src/components/auth/social-logins/SocialLoginBtn'
import GoogleColoredIcon from '~/src/components/mui-wrappers/GoogleColoredIcon'
import useSocialSignIn from '~/src/hooks/auth/useSocialSignIn'
import { useTranslations } from '~/src/hooks/useTranslations'
import { buttonClasses } from '@mui/material'

const GoogleLoginBtn = () => {
  const { login, loading } = useSocialSignIn('google')
  const { t } = useTranslations()

  const handleOnClick = async () => {
    await login()
  }

  return (
    <SocialLoginBtn
      socialType='google'
      startIcon={<GoogleColoredIcon/>}
      onClick={handleOnClick}
      loading={loading}
      sx={{
        color: 'text.black',
        [`.${buttonClasses.startIcon}`]: {
          marginLeft: 0,
          height: '1rem',
          width: '1rem',
          g: {
            height: '100%',
            svg: {
              position: 'absolute'
            }
          }
        }
      }}
    >
      {t('auth.signInWithGoogle')}
    </SocialLoginBtn>
  )
}

export default GoogleLoginBtn
