import { Box, Container, Typography } from '@mui/material'
import Title from '~/src/components/mui-wrappers/Title'
import GoBackToTheShopButton from '~/src/components/navigation/GoBackToTheShopButton'
import useGetNotFoundTemplate from '~/src/pages/errors/not-found/styles/useGetNotFoundTemplate'
import DocumentTitleInjector from '~/src/components/root/DocumentTitleInjector'
import { useTranslations } from '~/src/hooks/useTranslations'
import RoutesEnum from '~/src/router/enums/routes.enum'
import SlideFromRight from '~/src/components/mui-wrappers/animations/motion/SlideFromRight'
import { Player } from '@lottiefiles/react-lottie-player'
import { useEffect } from 'react'
import EnvUtils from '~/src/utils/EnvUtils'
import * as Sentry from '@sentry/react'

interface Props {
  showCta?: boolean,
}

const NotFound = ({ showCta = true }: Props) => {
  const pageName: keyof typeof RoutesEnum = 'NOT_FOUND'
  const { t } = useTranslations()
  const styles = useGetNotFoundTemplate()

  useEffect(() => {
    try {
      // Simulate a 404 error
      throw new Error('[PAGE] - 404 - Not found')
    } catch (error) {
      // Log the error to Sentry
      // Make sure you have Sentry initialized in your application
      if (EnvUtils.isSentryEnabled) {
        Sentry.captureException(error)
      }
    }
  }, [])

  return (
    <SlideFromRight
      pathname={pageName}
      transition={{
        duration: .5
      }}
    >
      <DocumentTitleInjector pageName={pageName}/>

      <Box
        sx={styles.getPageSx()}
      >
        {showCta && <GoBackToTheShopButton/>}

        <Container sx={styles.getContainerSx()}>
          <Player
            src='/images/lotties/404.json'
            autoplay
            loop
            style={{ width: '100%' }}
          />

          <Title
            variant='h4'
            sx={styles.getTitleSx()}
          >
            {t('404.title')}
          </Title>

          <Typography
            component='p'
            sx={{
              whiteSpace: 'break-spaces'
            }}>
            {t('404.description')}
          </Typography>

          {showCta && (
            <GoBackToTheShopButton
              color='primary'
              variant='contained'
              sx={styles.getContainerActionButtonSx()}
            />
          )}
        </Container>
      </Box>
    </SlideFromRight>
  )
}

export default NotFound
