import { SxProps } from '@mui/material'
import ModifierGroupSingleChoiceTemplateServiceInterface from '~/src/components/products/modifier-groups/modifier-group/modifier-group-single-choice/styles/ModifierGroupSingleChoiceTemplateServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class ModifierGroupSingleChoiceTemplateOneService extends TemplateServiceStyleBase implements ModifierGroupSingleChoiceTemplateServiceInterface {
  public getContainerSx(): SxProps {
    return {
      mb: 2
    }
  }

  public getModifiersContainerSx(): SxProps {
    return {
      px: 1,
      width: '100%'
    }
  }
}
