import { DateUtils } from '@eo-storefronts/eo-core'
import jwtDecode, { JwtPayload } from 'jwt-decode'
import { useEffect } from 'react'
import { useEoValue } from '~/src/hooks/useEoState'
import { API_TOKEN_STATE } from '~/src/stores/authentication'
import useLoginAsClient from '~/src/hooks/auth/useLoginAsClient'
import useFetchLastModification from '~/src/hooks/firm/useFetchLastModification'

let interval: NodeJS.Timeout

const useCheckTokenExpiration = (): void => {
  const apiToken = useEoValue(API_TOKEN_STATE)
  const { doLoginAsClient: loginAsClient } = useLoginAsClient()
  const { fetch: fetchLastModification } = useFetchLastModification()

  const checkTokenInterval = (expirationDate: Date) => {
    interval = setInterval(() => {
      if (DateUtils.isBefore(DateUtils.addMinutes(10), expirationDate)) {
        return
      }

      void loginAsClient()
      void fetchLastModification()
    }, 5000)
  }

  useEffect(() => {
    if (!apiToken?.token) {
      return
    }

    clearInterval(interval)

    const decoded: JwtPayload = jwtDecode(apiToken.token)

    if (decoded.exp && !(window as any).Cypress) {
      checkTokenInterval(new Date(new Date(0).setUTCSeconds(decoded.exp as number)))
    }

    return () => {
      clearInterval(interval)
    }
  }, [ apiToken?.token ])
}

export default useCheckTokenExpiration
