import { PlatformUtils } from '@eo-storefronts/eo-core'
import AppleIcon from '@mui/icons-material/Apple'
import SocialLoginBtn from '~/src/components/auth/social-logins/SocialLoginBtn'
import useSocialSignIn from '~/src/hooks/auth/useSocialSignIn'
import { useTranslations } from '~/src/hooks/useTranslations'
import EnvUtils from '~/src/utils/EnvUtils'

const AppleLoginBtn = () => {
  const { login, loading } = useSocialSignIn('apple')
  const { t } = useTranslations()

  const handleOnClick = async () => {
    await login()
  }

  if (!PlatformUtils(window).isIOS || EnvUtils.isDeviceWeb) {
    return null
  }

  return (
    <SocialLoginBtn
      socialType='apple'
      startIcon={<AppleIcon />}
      onClick={handleOnClick}
      loading={loading}
    >
      {t('auth.signInWithApple')}
    </SocialLoginBtn>
  )
}

export default AppleLoginBtn
