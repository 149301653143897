import { SxProps } from '@mui/material'
import StylesProductDetailsTitleServiceInterface from '~/src/components/products/product-details/product-details-title/styles/StylesProductDetailsTitleServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesProductDetailInfoContainerServiceStyleTwo extends TemplateServiceStyleBase implements StylesProductDetailsTitleServiceInterface {
  public getProductTitleContainerSxProps(): SxProps {
    return {
      gridArea: 'product-details-title-container',
      display: 'flex',
      gap: 3,
      verticalAlign: 'middle',
      width: '100%',
      [this.muiTheme.breakpoints.down('md')]: {
        backgroundColor: 'secondary.main',
        color: 'secondary.contrastText'
      }
    }
  }
}
