import { TemplateEnum } from '@eo-storefronts/eo-core'
import StylesProductDetailsTitleServiceStyleFour from '~/src/components/products/product-details/product-details-title/styles/StylesProductDetailsTitleServiceStyleFour'
import StylesProductDetailsTitleServiceStyleOne from '~/src/components/products/product-details/product-details-title/styles/StylesProductDetailsTitleServiceStyleOne'
import StylesProductDetailsTitleServiceStyleTwo from '~/src/components/products/product-details/product-details-title/styles/StylesProductDetailsTitleServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { LayoutState } from '~/src/stores/layout'

const getProductDetailsTitleTemplateService = (style: TemplateEnum | null | undefined, muiTheme: CustomMuiTheme, layout: LayoutState): StylesProductDetailsTitleServiceStyleTwo => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesProductDetailsTitleServiceStyleTwo(muiTheme, undefined, layout)
    case TemplateEnum.STYLE_FOUR:
      return new StylesProductDetailsTitleServiceStyleFour(muiTheme, undefined, layout)
    default:
      return new StylesProductDetailsTitleServiceStyleOne(muiTheme, undefined, layout)
  }
}

export default getProductDetailsTitleTemplateService
