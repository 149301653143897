import { TextField } from '@mui/material'
import { ChangeEvent } from 'react'
import { useTranslations } from '~/src/hooks/useTranslations'
import { FormProps } from '~/src/types/FormProps'
import { PasswordForgotFormInterface } from '~/src/types/forms/PasswordForgotFormInterface'
import { HttpError } from '@eo-storefronts/eo-core'

interface Props extends FormProps<PasswordForgotFormInterface> {
  error: HttpError,
}

const ForgotPasswordFields = ({ form, onChange, error }: Props) => {
  const { t } = useTranslations()

  const _getFieldError = (): string => {
    if (!error || !error?.body?.code) {
      return ''
    }

    return t(`auth.${error.body.code.toLowerCase()}`)
  }

  return (
    <TextField
      color='container'
      error={ !!error }
      helperText={_getFieldError()}
      id="email"
      label={ t('auth.emailAddress') }
      variant="outlined"
      value={ form.email }
      type="email"
      fullWidth
      required
      onChange={(event: ChangeEvent<HTMLInputElement>) => onChange(event, 'email')}
      sx={{
        my: 1
      }}
    />
  )
}

export default ForgotPasswordFields
