import { Box } from '@mui/material'
import { useOrderingMethodsInlineTime } from '~/src/hooks/ordering-methods/useOrderingMethodsInlineTime'
import AccessTimeIcon from '@mui/icons-material/AccessTime'

const OrderingMethodsTimeSummary = () => {
  const { sentence } = useOrderingMethodsInlineTime()

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <AccessTimeIcon /> {sentence}
    </Box>
  )
}

export default OrderingMethodsTimeSummary
