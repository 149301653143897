import { Id, OrderCalculateTime } from '@eo-storefronts/eo-core'
import { useSnackbar } from 'notistack'
import { useEoState, useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { confirmTime, releaseSlot } from '~/src/services/OrderService'
import { AUTHENTICATION_STATE } from '~/src/stores/authentication'
import { CART_PRODUCTS_WITH_QUANTITY_SELECTOR } from '~/src/stores/cart'
import { CHECKOUT_FORM_STATE, CHECKOUT_SLOT_ID } from '~/src/stores/checkout'
import { FIRM_WITH_LOCATOR_STATE_SELECTOR } from '~/src/stores/firm'

interface ReturnsType {
  confirmTimeRequest(time: string): Promise<OrderCalculateTime | null>,
  confirmCheckoutTime(time: string): Promise<boolean>,
}

const useConfirmTime = (): ReturnsType => {
  const firm = useEoValue(FIRM_WITH_LOCATOR_STATE_SELECTOR)
  const auth = useEoValue(AUTHENTICATION_STATE)
  const products = useEoValue(CART_PRODUCTS_WITH_QUANTITY_SELECTOR)
  const { t } = useTranslations()
  const { enqueueSnackbar } = useSnackbar()
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)
  const [ checkoutSlotId, setCheckoutSlotId ] = useEoState(CHECKOUT_SLOT_ID)

  const confirmTimeRequest = async (time: string): Promise<OrderCalculateTime | null> => {
    if (!firm || (!auth.guest?.id && !auth.customer?.id)) {
      return null
    }

    const confirmTimeResponse = await confirmTime(
      firm.id,
      (auth.guest?.id || auth.customer?.id) as Id,
      {
        slotId: checkoutSlotId,
        items: products,
        orderingMethod: checkoutForm.orderingMethod.method,
        timeslotId: checkoutForm.orderingMethod.timeslotId,
        timestamp: time
      }
    )

    if (checkoutSlotId && checkoutSlotId !== confirmTimeResponse.id) {
      void releaseSlot(checkoutSlotId)
    }

    if (confirmTimeResponse.id) {
      setCheckoutSlotId(Number(confirmTimeResponse.id))
    }

    return confirmTimeResponse
  }

  const confirmCheckoutTime = async (time: string): Promise<boolean> => {
    if (!firm || (!auth.guest?.id && !auth.customer?.id)) {
      return false
    }

    try {
      const confirmTimeResponse = await confirmTimeRequest(time)

      if (!confirmTimeResponse) {
        return false
      }

      if (!confirmTimeResponse.valid) {
        enqueueSnackbar(t('orderingMethods.timeNotValid'), { variant: 'error' })
        return false
      }

      return true
    } catch (e) {
      enqueueSnackbar(t('orderingMethods.couldNotValidateTime'), { variant: 'error' })
    }

    return false
  }

  return {
    confirmTimeRequest,
    confirmCheckoutTime
  }
}

export {
  useConfirmTime
}
