import { Alert } from '@mui/material'
import ResponsiveDialog from '~/src/components/mui-wrappers/responsive-dialog'
import { useFirmPathResolver } from '~/src/hooks/router/useFirmPathResolver'
import { useRouterPush } from '~/src/hooks/router/useRouterPush'
import { useTranslations } from '~/src/hooks/useTranslations'
import RoutesEnum from '~/src/router/enums/routes.enum'

const EatInLocationError = () => {
  const { push } = useRouterPush()
  const { resolve } = useFirmPathResolver()
  const { t } = useTranslations()

  const handleOnClose = () => {
    push(resolve(RoutesEnum.MENU), { modal: true })
  }

  return (
    <ResponsiveDialog
      openByDefault={true}
      name='eat-in-location-error'
      onClose={handleOnClose}
      Title={<>{t('errors.defaultError')}</>}
      contentSx={{
        minWidth: '30vw'
      }}
    >
      <Alert color='error'>
        {t('checkout.errors.orderingMethodNotValid')}<br />
        {t('orderingMethods.eatInSelect')}<br />
        {t('orderingMethods.chooseYourSpot')}
      </Alert>        
    </ResponsiveDialog>
  )
}

export default EatInLocationError
