import { Firm, OpeningHour } from '@eo-storefronts/eo-core'
import { useEffect, useState } from 'react'
import { buildOpeningHoursString } from '~/src/services/OpeningHourService'
import { useEoValue } from '~/src/hooks/useEoState'
import { LANGUAGE_STATE } from '~/src/stores/lang'

export interface GroupedOpeningHours {
  days: string,
  openingHours: string,
}

const useGroupOpeningHours = (location: Firm): GroupedOpeningHours[] => {
  const lang = useEoValue(LANGUAGE_STATE)
  const [ plainGrouped, setPlainGrouped ] = useState<GroupedOpeningHours[]>([])

  useEffect(() => {
    const { openingHours: openingHours } = location.settings.periods
    const groupedDays: Record<string, string[]> = {}

    openingHours?.forEach(
      (openingHour: OpeningHour) => {
        const openingHourString = buildOpeningHoursString(openingHour)

        groupedDays[openingHourString] ||= []
        groupedDays[openingHourString].push(openingHour.day[lang])
      }
    )

    const grouped: GroupedOpeningHours[] = []

    for (const openingHourString in groupedDays) {
      grouped.push({
        days: groupedDays[openingHourString].join(' '),
        openingHours: openingHourString
      })
    }

    setPlainGrouped(grouped)
  }, [])

  return plainGrouped
}

export {
  useGroupOpeningHours
}
