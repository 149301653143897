import { LanguageType, ModifierGroup } from '@eo-storefronts/eo-core'
import { Box } from '@mui/material'
import { useContext } from 'react'
import Button from '~/src/components/mui-wrappers/buttons/Button'
import Title from '~/src/components/mui-wrappers/Title'
import ProductConfigurationModalContext, {
  ProductConfigurationModalContextInterface
} from '~/src/components/products/modals/configuration/ProductConfigurationModalContext'
import ModifierGroupSubTitle
  from '~/src/components/products/modifier-groups/modifier-group/modifier-group-title/subtitle'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { LANGUAGE_STATE } from '~/src/stores/lang'

interface Props {
  group: ModifierGroup,
}

const ModifierGroupTitle = ({ group }: Props) => {
  const { t } = useTranslations()
  const lang = useEoValue<LanguageType | string>(LANGUAGE_STATE)
  const context = useContext<ProductConfigurationModalContextInterface>(ProductConfigurationModalContext)

  const handleOnChangeClick = () => {
    const newState = [ ...context.selectedModifierGroups ]
    const index = newState.findIndex((cpmg) => cpmg.id === group.id)

    if (index === -1) {
      return
    }

    newState.splice(index, 1)

    return context.updateModifierGroups(newState)
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Box>
        <Title variant='h6'>{group.name[lang]}</Title>
        <ModifierGroupSubTitle group={group}/>
      </Box>
      {
        group.type === 'rank' && context.selectedModifierGroups.find((cpmg) => cpmg.id === group.id) && (
          <Button
            variant='contained'
            onClick={handleOnChangeClick}
            sx={{
              my: .5
            }}>
            {t('actions.edit')}
          </Button>
        )
      }
    </Box>
  )
}

export default ModifierGroupTitle
