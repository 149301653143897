import { FirebaseMessaging, Notification } from '@capacitor-firebase/messaging'
import PushNotificationDialog, { PushNotificationDialogProps } from '~/src/components/dialogs/push-notification'
import useDialog from '~/src/hooks/useDialog'

interface ReturnsType {
  presentModal(notification: Notification): Promise<void>,
}

const usePushNotificationsModal = (): ReturnsType => {
  const { present } = useDialog()

  const presentModal = async (notification: Notification): Promise<void> => {
    const title = (notification.data as any).aps?.alert?.data?.title || (notification.data as any)?.title
    const body = (notification.data as any).aps?.alert?.data?.body || (notification.data as any)?.body
    const buttonText = (notification.data as any).aps?.alert?.data?.buttonText || (notification.data as any)?.buttonText
    const redirectTo =  (notification.data as any).aps?.alert?.data?.redirectTo || (notification.data as any)?.redirectTo

    if (title && body) {
      await FirebaseMessaging.removeAllDeliveredNotifications()

      present<PushNotificationDialogProps>(
        PushNotificationDialog,
        'push-notification',
        {
          title,
          body,
          buttonText,
          redirectTo
        }
      )
      return
    }
  }

  return  { presentModal }
}

export default usePushNotificationsModal
