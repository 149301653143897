import { Box } from '@mui/material'
import useGetShoppingCartActionsTemplate from '~/src/components/cart/shopping-cart-actions/styles/useGetShoppingCartActionsTemplate'
import Button from '~/src/components/mui-wrappers/buttons/Button'
import useShoppingCartActions from '~/src/hooks/cart/useShoppingCartActions'
import useValidateOrder, { OrderErrorType } from '~/src/hooks/orders/useValidateOrder'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { FIRM_SELECTOR } from '~/src/stores/firm'

const ShoppingCartActionsLoggedOut = () => {
  const { t } = useTranslations()
  const styles = useGetShoppingCartActionsTemplate()
  const { errors } = useValidateOrder()
  const firm = useEoValue(FIRM_SELECTOR)
  const { continueAsGuest, continueAsCustomer } = useShoppingCartActions()
  const disabled = !!errors.filter((error: OrderErrorType) => error.variant === 'error').length

  const _handleOnClick = (loginType: 'customer' | 'guest') => {
    if (loginType === 'customer') {
      continueAsCustomer()
      return
    }
    
    continueAsGuest()
  }

  if (!firm) {
    return null
  }

  return (
    <Box sx={styles.getContainerSxProps()}>
      <Button
        disabled={disabled}
        variant='contained'
        disableElevation
        sx={styles.getLoginAndGoToCheckoutSxProps()}
        onClick={() => _handleOnClick('customer')}
      >
        {t('shoppingCart.actions.loginAndGoToCheckout')}
      </Button>
      {firm.settings.theme.flow?.ordering.allowGuestCheckout && (
        <Button
          disabled={disabled}
          variant='contained'
          color='inherit'
          disableElevation
          sx={styles.getContinueAsGuestSxProps()}
          onClick={() => _handleOnClick('guest')}
        >
          {t('global.continueAsGuest')}
        </Button>
      )}
    </Box>
  )
}

export default ShoppingCartActionsLoggedOut
