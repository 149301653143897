import { OrderingMethodNames } from '@eo-storefronts/eo-core'
import { Box } from '@mui/material'
import OrderingMethodsDateContainer from '~/src/components/ordering-methods/OrderingMethodsDateContainer'
import OrderingMethodsCustomDays from '~/src/components/ordering-methods/OrderingMethodsCustomDays'
import OrderingMethodsTimeContainer from '~/src/components/ordering-methods/OrderingMethodsTimeContainer'
import OrderingMethodsTimeslotsContainer from '~/src/components/ordering-methods/OrderingMethodsTimeslotsContainer'
import { useEoValue } from '~/src/hooks/useEoState'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import { FIRM_WITH_LOCATOR_STATE_SELECTOR } from '~/src/stores/firm'
import { hasCustomDays, hasOrderTimeslots, mustShowAsapButtonOnly } from '~/src/services/DeliveryTimeService'
import { useFirmAcceptsOrder } from '~/src/hooks/orders/useFirmAcceptsOrder'
import FirmClosed from '~/src/components/firms/FirmClosed'
import CanOnlyOrderDuringOperationalHoursResponsiveModal
  from '~/src/components/error/CanOnlyOrderDuringOperationalHoursResponsiveModal'

const OrderingMethodsDateTime = () => {
  const firm = useEoValue(FIRM_WITH_LOCATOR_STATE_SELECTOR)
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)
  const { checkAll } = useFirmAcceptsOrder()

  if (!checkAll()) {
    return (
      <>
        <FirmClosed sx={{ mb: 2 }} />
        <CanOnlyOrderDuringOperationalHoursResponsiveModal />
      </>
    )
  }

  if (
    !firm
    || !checkoutForm.orderingMethod.method
    || checkoutForm.orderingMethod.method === OrderingMethodNames.ON_THE_SPOT
  ) {
    return null
  }

  if (hasCustomDays(firm, checkoutForm.orderingMethod.method)) {
    return <OrderingMethodsCustomDays />
  }

  return (
    <>
      <OrderingMethodsDateContainer />
      <Box sx={{ mt: 1 }}>
        {hasOrderTimeslots(firm, checkoutForm) && !mustShowAsapButtonOnly(firm, checkoutForm.orderingMethod.method) && <OrderingMethodsTimeslotsContainer />}
        {(!hasOrderTimeslots(firm, checkoutForm) || mustShowAsapButtonOnly(firm, checkoutForm.orderingMethod.method)) && <OrderingMethodsTimeContainer />}
      </Box>
    </>
  )
}

export default OrderingMethodsDateTime
