import {
  EoInject,
  EoInjectable,
  FindFirmArgType,
  Firm
} from '@eo-storefronts/eo-core'
import HostResolver from '~/src/router/utils/HostResolver'
import { fetchFirm } from '~/src/services/FirmService'

@EoInjectable()
export default class FirmResolver {
  private _firm: Firm | null = null

  constructor(
    @EoInject(HostResolver) private readonly _hostResolver: HostResolver
  ) {
  }

  public async resolve(domain: string): Promise<void> {
    const searchFirmParams: FindFirmArgType = await this._hostResolver.resolve(domain)
    await this.fetchFirm(searchFirmParams)
  }

  public async resolveFirmByName(sitename: string): Promise<void> {
    if (!this._firm) {
      return
    }

    const childFirm = this._firm.locations?.find((f: Firm) => f.sitename === sitename)

    if (!childFirm) {
      const firm = await fetchFirm({
        applicationId: sitename
      })

      this._firm.locations ||= []
      this._firm.locations.push(firm)
    }
  }

  public getFirm(): Firm | null {
    return this._firm
  }

  private async fetchFirm(args: FindFirmArgType): Promise<void> {
    try {
      this._firm = await fetchFirm(args)
    } catch (e) {
      // ignore
    }
  }
}
