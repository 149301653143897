import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { Button } from '@mui/material'
import useGetOrderingMethodsSummaryEditButtonTemplate
  from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-edit-button/styles/useGetOrderingMethodsSummaryEditButtonTemplate'

const OrderingMethodsSummaryEditButton = () => {
  const styles = useGetOrderingMethodsSummaryEditButtonTemplate()

  return (
    <Button {...styles.getButtonProps()}>
      <EditOutlinedIcon {...styles.getEditOutlinedIconProps()} />
    </Button>
  )
}

export default OrderingMethodsSummaryEditButton
