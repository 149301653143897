import { Box, Snackbar, Typography } from '@mui/material'
import { Firm } from '@eo-storefronts/eo-core'
import { SyntheticEvent, useState } from 'react'
import useDialog from '~/src/hooks/useDialog'
import Card from '~/src/components/mui-wrappers/Card'
import { useEoValue } from '~/src/hooks/useEoState'
import { BRAND_STATE } from '~/src/stores/brand'
import Title from '~/src/components/mui-wrappers/Title'
import { useTranslations } from '~/src/hooks/useTranslations'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

export interface DownloadAppBannerProps {
  name: string,
  url: string,
}

const DownloadAppBanner = ({ name, url }: DownloadAppBannerProps) => {
  const brand: Firm | null = useEoValue(BRAND_STATE)
  const { t } = useTranslations()
  const { dismiss } = useDialog()
  const [ open, setOpen ] = useState(true)

  const handleOnClose = (e: SyntheticEvent<any> | Event) => {
    e.stopPropagation()
    e.preventDefault()

    setOpen(false)
    dismiss(name)
  }

  const handleOnClick = () => {
    window.open(url)
  }

  if (!brand) {
    return null
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={10000}
      open={open}
      onClose={handleOnClose}
      onClick={handleOnClick}
    >
      <Box>
        <Card
          elevation={5}
          sx={{
            p: 1,
            width: 'calc(100vw - 40px)'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              justifyContent: 'space-between'
            }}
          >
            <Box
              component={'img'}
              src={brand.assets.logoSquare}
              alt={''}
              sx={{
                width: '12.5vw'
              }}
            />
            <Box>
              <Title variant={'h6'}>{t('downloadApp.title')}</Title>
              <Typography>{t('downloadApp.content')}</Typography>
            </Box>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleOnClose}
            >
              <CloseIcon fontSize="small"/>
            </IconButton>
          </Box>
        </Card>
      </Box>
    </Snackbar>
  )
}

export default DownloadAppBanner
