import { DateUtils, OrderingMethodNames, OpeningHour } from '@eo-storefronts/eo-core'
import { ClockPickerView } from '@mui/x-date-pickers'
import { useEoValue } from '~/src/hooks/useEoState'
import { isBetweenOpeningHours } from '~/src/services/OpeningHourService'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import { FIRM_WITH_LOCATOR_STATE_SELECTOR } from '~/src/stores/firm'

const useTimePickerAvailability = () => {
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)
  const firm = useEoValue(FIRM_WITH_LOCATOR_STATE_SELECTOR)

  const isTimeDisabled = (selectedTime: string, timeValue: number, clockType: ClockPickerView): boolean => {
    if (clockType === 'seconds') {
      return false
    }

    let periods: OpeningHour[] = []
    const clockTime: string = timeValue < 10 ? `0${timeValue}` : timeValue.toString()

    if (checkoutForm.orderingMethod.method === OrderingMethodNames.PICKUP) {
      periods = [ ...firm!.settings.periods.pickupHours ]

      if (
        checkoutForm.orderingMethod.pickupPoint
        && !checkoutForm.orderingMethod.pickupPoint.mainBranch
        && !checkoutForm.orderingMethod.pickupPoint.usePickupHours
      ) {
        periods = [ ...checkoutForm.orderingMethod.pickupPoint.periods.pickupHours ]
      }
    } else if (checkoutForm.orderingMethod.method === OrderingMethodNames.DELIVERY) {
      periods = [ ...firm!.settings.periods.deliveryHours ]
    }

    const selectedTimeDayId = DateUtils.getDayIdLikeMomentJs(new Date(selectedTime))
    const currentPeriod = periods.find((period: OpeningHour) => period.dayId === selectedTimeDayId)

    if (!currentPeriod) {
      return true
    }

    if (clockType === 'hours') {
      const dateHour = new Date()
      dateHour.setHours(Number(clockTime), 0, 0)

      return !isBetweenOpeningHours(dateHour, currentPeriod)
    }

    if (clockType === 'minutes') {
      const [ hour ] = DateUtils.formatTime(new Date(selectedTime)).split(':')

      const dateHour = new Date()
      dateHour.setHours(Number(hour), Number(clockTime), 0)

      return !isBetweenOpeningHours(dateHour, currentPeriod)
    }

    return true
  }

  return {
    isTimeDisabled
  }
}

export {
  useTimePickerAvailability
}
