import { useRef } from 'react'
import { useLocation } from 'react-router-dom'
import useFetchLastModification from '~/src/hooks/firm/useFetchLastModification'
import { useAsyncEffect } from '~/src/hooks/useAsyncEffect'
import { useEoState, useEoValue } from '~/src/hooks/useEoState'
import RoutesEnum from '~/src/router/enums/routes.enum'
import { releaseSlot } from '~/src/services/OrderService'
import { APP_STATE } from '~/src/stores/app'
import { CHECKOUT_SLOT_ID } from '~/src/stores/checkout'

const useBrowsingEffect = () => {
  const location = useLocation()
  const { isActive } = useEoValue(APP_STATE)
  const { fetch: fetchLastModifications } = useFetchLastModification()
  const [ checkoutSlotId, setCheckoutSlodId ] = useEoState(CHECKOUT_SLOT_ID)
  const navigationCount = useRef<number>(0)

  useAsyncEffect(async () => {
    if (!isActive) {
      return
    }

    await fetchLastModifications()

    navigationCount.current++

    if (location.pathname.includes(RoutesEnum.CHECKOUT)) {
      navigationCount.current = 0
    }

    if (
      !location.pathname.includes(RoutesEnum.CHECKOUT) 
      && checkoutSlotId 
      && navigationCount.current > 1
    ) {
      void releaseSlot(checkoutSlotId)
      setCheckoutSlodId(null)
    }
  }, [ location, isActive ])
}

export default useBrowsingEffect
