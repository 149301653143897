import { DateUtils, FirstAvailableFulfilmentTime, Id, OrderingMethodNames } from '@eo-storefronts/eo-core'
import { useCallback, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import deepEqual from '~/src/helpers/deepEqual'
import useGetFirstAvailableFulfilmentTime from '~/src/hooks/ordering-methods/useGetFirstAvailableFulfilmentTime'
import { useEoState, useEoValue } from '~/src/hooks/useEoState'
import RoutesEnum from '~/src/router/enums/routes.enum'
import { CART_PRODUCTS_WITH_QUANTITY_SELECTOR } from '~/src/stores/cart'
import { CHECKOUT_FORM_STATE, CHECKOUT_PICKUP_POINT_SELECTOR, CheckoutFormState } from '~/src/stores/checkout'
import { FIRM_WITH_LOCATOR_STATE_SELECTOR } from '~/src/stores/firm'

interface ReturnsType {
  setFirstAvailableTime(force?: boolean): Promise<void>,
}

interface PreviousRunRefInterface {
  firmId: Id,
  products: Record<Id, number>,
  pickupPointId: Id | null,
  orderingMethod: string,
}

const useSetFirstAvailableTime = (): ReturnsType => {
  const location = useLocation()
  const firm = useEoValue(FIRM_WITH_LOCATOR_STATE_SELECTOR)
  const [ checkoutForm, setCheckoutForm ] = useEoState(CHECKOUT_FORM_STATE)
  const { calculateFirstAvailableTime } = useGetFirstAvailableFulfilmentTime()
  const pickupPoint = useEoValue(CHECKOUT_PICKUP_POINT_SELECTOR)
  const products = useEoValue(CART_PRODUCTS_WITH_QUANTITY_SELECTOR)
  const previousRun = useRef<PreviousRunRefInterface>({
    firmId: 0,
    products: {},
    pickupPointId: null,
    orderingMethod: ''
  })

  const _updatePreviousRun = () => {
    previousRun.current = {
      firmId: firm?.id || 0,
      products,
      pickupPointId: checkoutForm.orderingMethod.pickupPoint?.id || null,
      orderingMethod: checkoutForm.orderingMethod.method || ''
    }
  }

  const setFirstAvailableTime = useCallback(async (force = true): Promise<void> => {
    try {
      /**
       * Preventing same call to be triggered several times, unless we want it to
       */
      if (!firm) {
        previousRun.current.firmId = 0
        return
      }

      if (
        location.pathname.includes(RoutesEnum.CHECKOUT)
        || (
          checkoutForm.orderingMethod.method === OrderingMethodNames.PICKUP
          && (firm.settings.orderingMethods[OrderingMethodNames.PICKUP]?.pickupPoints || []).length
          && !pickupPoint
        )
        || (
          deepEqual(previousRun.current, {
            firmId: firm.id,
            products,
            pickupPointId: checkoutForm.orderingMethod.pickupPoint?.id || null,
            orderingMethod: checkoutForm.orderingMethod.method || ''
          }) && !force
        )
      ) {
        return
      }

      const firmId = previousRun.current.firmId
      const orderingMethod = previousRun.current.orderingMethod

      _updatePreviousRun()

      if (
        checkoutForm.orderingMethod.method 
        && !firm.settings.orderingMethods[checkoutForm.orderingMethod.method]
      ) {
        return
      }

      const firstAvailableTime: FirstAvailableFulfilmentTime | undefined = await calculateFirstAvailableTime(
        firm.id,
        checkoutForm.orderingMethod.method,
        pickupPoint,
        products
      )

      if (!firstAvailableTime?.timestamp) {
        setCheckoutForm((state: CheckoutFormState) => ({
          ...state,
          orderingMethod: {
            ...state.orderingMethod,
            minDate: null,
            time: null
          }
        }))

        return
      }

      /**
       * If the time returned by the API is below the current selected time; we do not update it
       * Unless we force it.
       */
      if (
        !force
        && firmId === firm.id
        && checkoutForm.orderingMethod.time
        && checkoutForm.orderingMethod.method === orderingMethod
        && DateUtils.isAfter(
          new Date(checkoutForm.orderingMethod.time),
          new Date(firstAvailableTime.timestamp)
        )
      ) {
        if (checkoutForm.orderingMethod.minDate !== firstAvailableTime.timestamp) {
          setCheckoutForm((state: CheckoutFormState) => ({
            ...state,
            orderingMethod: {
              ...state.orderingMethod,
              minDate: DateUtils.toISOString(firstAvailableTime.timestamp)
            }
          }))
        }

        return
      }

      setCheckoutForm((state: CheckoutFormState) => {
        // Restoring the checkout form from the localState (payment failure or cancellation)
        if (previousRun.current.orderingMethod === null && state.orderingMethod.minDate) {
          return state
        }

        return {
          ...state,
          orderingMethod: {
            ...state.orderingMethod,
            minDate: DateUtils.toISOString(firstAvailableTime.timestamp),
            time: DateUtils.toISOString(firstAvailableTime.timestamp)
          }
        }
      })
    } catch (e) {
      _updatePreviousRun()
    }
  }, [
    firm,
    products,
    checkoutForm.orderingMethod.method,
    pickupPoint
  ])

  return { setFirstAvailableTime }
}

export default useSetFirstAvailableTime
