import { Box, BoxProps } from '@mui/material'

interface Props extends BoxProps {
  WrapperProps?: BoxProps,
}

const Puller = ({ WrapperProps = {}, ...props }: Props) => (
  <Box
    sx={{
      height: '24px',
      flex: '0 0 24px',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}
    {...WrapperProps}
  >
    <Box
      component='span'
      sx={{
        width: '30px',
        height: '6px',
        backgroundColor: 'primary.main',
        borderRadius: 3,
        left: 'calc(50% - 15px)',
        ':hover': {
          cursor: 'grab'
        }
      }}
      {...props}/>
  </Box>
)

export default Puller
