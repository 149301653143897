import { Product } from '@eo-storefronts/eo-core'
import StarsIcon from '@mui/icons-material/Stars'
import { Box, Tooltip, Typography } from '@mui/material'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { LOYALTY_IS_ENABLED_AND_CUSTOMER_IS_LOGGED_IN_SELECTOR } from '~/src/stores/loyalty'
import { useMemo } from 'react'

interface Props {
  product: Product,
  quantity?: number,
  size?: 'small' | 'large',
}

const ProductLoyaltyPoint = ({ product, quantity = 1, size = 'small' }: Props) => {
  const { t } = useTranslations()
  const theme = useCustomMuiTheme()
  const isLoyaltyEnabled = useEoValue(LOYALTY_IS_ENABLED_AND_CUSTOMER_IS_LOGGED_IN_SELECTOR)
  const sx = {
    display: 'flex',
    justifyContent: 'center',
    verticalAlign: 'middle',
    gap: 1,
    py: .5,
    px: 1,
    borderRadius: '25px',
    borderStyle: 'solid',
    borderColor: 'primary',
    borderWidth: '1px',
    whiteSpace: 'nowrap'
  }

  const point = useMemo<number>(() => {
    return product.loyalty.point * quantity
  }, [ product.loyalty.point, quantity ])

  if (!isLoyaltyEnabled || !product.loyalty.point) {
    return null
  }

  if (size === 'large') {
    return (
      <Box sx={sx}>
        <StarsIcon/>
        <Typography
          sx={{ cursor: 'default' }}>
          {t('product.loyalty', { point })}
        </Typography>
      </Box>
    )
  }

  return (
    <Tooltip
      arrow
      followCursor
      title={t('product.loyalty', { point })}
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText
          }
        },
        arrow: {
          sx: {
            color: theme.palette.primary.main
          }
        }
      }}
    >
      <Box sx={sx}>
        <Typography sx={{ cursor: 'default' }}>{`+\u00A0${point}`}</Typography>
        <StarsIcon/>
      </Box>
    </Tooltip>
  )
}

export default ProductLoyaltyPoint
