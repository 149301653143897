import { Modifier } from '@eo-storefronts/eo-core'
import Price from '~/src/components/Price'
import Title from '~/src/components/mui-wrappers/Title'
import { useEoValue } from '~/src/hooks/useEoState'
import { getModifierPrice, modifierHasPromotion } from '~/src/services/ModifierService'
import { PRICE_LIST_STATE } from '~/src/stores/product/price-list'

interface Props {
  modifier: Modifier,
}

const ModifierPromotionalPrice = ({ modifier }: Props) => {
  const priceLists = useEoValue(PRICE_LIST_STATE)

  if (!modifierHasPromotion(modifier, priceLists)) {
    return null
  }

  return (
    <Title
      variant='caption'
      sx={{
        top: '-12px',
        left: 0,
        marginLeft: '2px'
      }}
    >
      <Price
        price={getModifierPrice(modifier, priceLists)}
        displayZeroAmount={true}
      />
    </Title>
  )
}

export default ModifierPromotionalPrice
