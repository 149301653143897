import { useEoState, useEoValue, useSetEoState } from '~/src/hooks/useEoState'
import { LAST_MODIFICATION_STATE } from '~/src/stores/last-modification'
import { ApiConfiguration, DateUtils, Firm } from '@eo-storefronts/eo-core'
import { fetchLastModification } from '~/src/services/FirmService'
import { INTERVALS_STATE, TIMESLOTS_STATE } from '~/src/stores/timeslots'
import { useFetchFirm } from '~/src/hooks/firm/useFetchFirm'
import { useFetchCatalog } from '~/src/hooks/catalog/useFetchCatalog'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { BRAND_STATE } from '~/src/stores/brand'

interface ReturnsType {
  fetch(): Promise<void>,
}

const useFetchLastModification = (): ReturnsType => {
  const [ lastModifications, setLastModifications ] = useEoState(LAST_MODIFICATION_STATE)
  const brand = useEoValue<Firm | null>(BRAND_STATE)
  const firm = useEoValue<Firm | null>(FIRM_SELECTOR)
  const setTimeslots = useSetEoState(TIMESLOTS_STATE)
  const setIntervals = useSetEoState(INTERVALS_STATE)
  const { fetch: fetchFirm } = useFetchFirm()
  const { fetch: fetchCategories } = useFetchCatalog()

  const _fetch = async (): Promise<void> => {
    if (
      !brand
            || !firm
            || !ApiConfiguration.getHeader('Authorization')
    ) {
      return
    }

    try {
      const lastModificationResponse = await fetchLastModification()

      if (
        DateUtils.isBefore(
          new Date(lastModifications.lastFirmUpdate),
          new Date(lastModificationResponse.lastFirmUpdate)
        )
      ) {
        await fetchFirm(brand.id)

        setTimeslots({})
        setIntervals({})
      }

      if (
        DateUtils.isBefore(
          new Date(lastModifications.lastProductUpdate),
          new Date(lastModificationResponse.lastProductUpdate)
        )
      ) {
        await fetchCategories(firm.id)
      }

      setLastModifications(lastModificationResponse)
    } catch {
      // Ignore
    }
  }

  return {
    fetch: _fetch
  }
}

export default useFetchLastModification
