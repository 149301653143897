import { Address, Firm } from '@eo-storefronts/eo-core'
import AddressFormAction from '~/src/components/customers/addresses/AddressFormAction'
import AddressFormFields from '~/src/components/customers/addresses/AddressFormFields'
import { FormikProps, useFormik } from 'formik'
import { useEoValue } from '~/src/hooks/useEoState'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { AddressFormInterface } from '~/src/types/forms/AddressFormInterface'
import * as yup from 'yup'
import { useTranslations } from '~/src/hooks/useTranslations'
import useAddressForm from '~/src/hooks/customer/useAddressForm'

interface Props {
  address?: Address | null,
  showSetAsDefault?: boolean,
  redirectAfterSubmit?: boolean,
  showDescription?: boolean,
  closeAfterSubmit?: boolean,
  onSubmit?(address: Address): void,
}

const AddressForm = ({
  address,
  showSetAsDefault = true,
  showDescription = true,
  redirectAfterSubmit = true,
  closeAfterSubmit = false,
  onSubmit
}: Props) => {
  const { t } = useTranslations()
  const firm = useEoValue<Firm | null>(FIRM_SELECTOR)
  const { submit } = useAddressForm()
  const formik: FormikProps<AddressFormInterface> = useFormik<AddressFormInterface>({
    initialValues: {
      id: address?.id ?? 0,
      name: address?.description ?? '',
      isDefault: address?.isDefault ?? false,
      street: address?.street ?? '',
      houseNumber: address?.houseNumber ?? '',
      bus: address?.bus ?? '',
      locality: address?.locality ?? '',
      zipCode: address?.zipCode ?? '',
      country: btoa(encodeURIComponent(address?.country ? JSON.stringify(address.country) : JSON.stringify(firm?.address.country)))
    },
    validationSchema: yup.object({
      name: showDescription ? yup.string().required(t('errors.addressNameRequired')) : yup.string(),
      isDefault: showSetAsDefault ? yup.boolean().required() : yup.boolean(),
      street: yup.string().required(t('errors.streetNameRequired')),
      // houseNumber: yup.string().required(t('errors.houseNumberRequired')),
      bus: yup.string(),
      locality: yup.string().required(t('errors.localityRequired')),
      zipCode: yup.string().required(t('errors.zipcodeRequired')),
      country: yup.string().required(t('errors.countryRequired'))
    }),
    onSubmit: ({ id, name, isDefault, street, houseNumber, bus, locality, zipCode, country }: AddressFormInterface) => {
      void submit({
        id,
        description: name,
        isDefault: isDefault,
        street,
        houseNumber: houseNumber,
        bus,
        locality,
        zipCode: zipCode,
        country: JSON.parse(decodeURIComponent(atob(country)))
      } as Address,
      {
        redirectAfterSubmit
      },
      closeAfterSubmit,
      onSubmit
      )
    }
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <AddressFormFields
        form={formik}
        showSetAsDefault={showSetAsDefault}
        showDescription={showDescription}
      />
      <AddressFormAction form={formik} />
    </form>
  )
}

export default AddressForm
