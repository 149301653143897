import { Box, Typography } from '@mui/material'
import { useTranslations } from '~/src/hooks/useTranslations'
import Card from '~/src/components/mui-wrappers/Card'
import { useEoValue } from '~/src/hooks/useEoState'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { Firm } from '@eo-storefronts/eo-core'
import FirmTimeslotSentenceContainer from '~/src/components/firms/FirmTimeslotSentenceContainer'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FirmInfoDialog from '~/src/components/firms/FirmInfoDialog'
import ViewAllInfo from '~/src/components/utils/buttons/ViewAllInfo'
import SideDrawerFirmBoxTitle from '~/src/components/layout/side-drawer/side-drawer-firm-box/title'
import Title from '~/src/components/mui-wrappers/Title'

const SideDrawerFirmBox = () => {
  const { t } = useTranslations()
  const firm = useEoValue<Firm|null>(FIRM_SELECTOR)

  if (!firm) {
    return null
  }

  return (
    <Box>
      <Title color='primary'>{t('firm.shopInformations')}</Title>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          gap: 1,
          mt: 1
        }}
      >
        <SideDrawerFirmBoxTitle />
        <Typography variant='subtitle2'>
          {firm.address.street}, {firm.address.zipcode} {firm.address.locality}
        </Typography>
        <FirmTimeslotSentenceContainer
          firm={firm}
          grouped={false}
          sx={{
            fontWeight: 'normal',
            justifyContent: 'start',
            my: 0
          }}
          icon={<ExpandMoreIcon color='primary' />}
        />
        <FirmInfoDialog
          activator={<ViewAllInfo />}
        />
      </Card>
    </Box>
  )
}

export default SideDrawerFirmBox
