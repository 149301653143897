import Card from '~/src/components/mui-wrappers/Card'
import FirmLoyaltyRewardDiscount from '~/src/components/profile/account/loyalty/reward-card/discount'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { useEoValue } from '~/src/hooks/useEoState'
import { LOYALTY_IS_ENABLED_SELECTOR, LOYALTY_IS_POINT_THRESHOLD_SELECTOR } from '~/src/stores/loyalty'
import FirmLoyaltyPointTresholdRewardDiscount
  from '~/src/components/profile/account/loyalty/reward-card/discount/FirmLoyaltyPointTresholdRewardDiscount'

const FirmLoyaltyRewardCard = () => {
  const theme = useCustomMuiTheme()
  const isLoyaltyEnabled = useEoValue(LOYALTY_IS_ENABLED_SELECTOR)
  const isLoyaltyPointTreshold = useEoValue(LOYALTY_IS_POINT_THRESHOLD_SELECTOR)

  if (!isLoyaltyEnabled) {
    return null
  }

  return (
    <Card
      sx={{
        p: 2,
        '&:hover': {
          boxShadow: `0 0 5px ${theme.palette.primary.main}`
        }
      }}
    >
      {isLoyaltyPointTreshold ?
        <FirmLoyaltyPointTresholdRewardDiscount /> :
        <FirmLoyaltyRewardDiscount />}
    </Card>
  )
}

export default FirmLoyaltyRewardCard
