import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'
import StylesOrderingMethodsSummaryMethodButtonServiceInterface
  from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-method-button/styles/StylesOrderingMethodsSummaryMethodButtonServiceInterface'
import StylesOrderingMethodsSummaryMethodButtonServiceStyleTwo
  from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-method-button/styles/StylesOrderingMethodsSummaryMethodButtonServiceStyleTwo'
import StylesOrderingMethodsSummaryMethodButtonServiceStyleOne
  from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-method-button/styles/StylesOrderingMethodsSummaryMethodButtonServiceStyleOne'

const getOrderingMethodsSummaryMethodButtonTemplateService = (style: TemplateEnum | null | undefined, muiTheme: CustomMuiTheme): StylesOrderingMethodsSummaryMethodButtonServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesOrderingMethodsSummaryMethodButtonServiceStyleTwo(muiTheme)
    default:
      return new StylesOrderingMethodsSummaryMethodButtonServiceStyleOne(muiTheme)
  }
}

export default getOrderingMethodsSummaryMethodButtonTemplateService
