import { Product, Theme } from '@eo-storefronts/eo-core'
import { MouseEvent } from 'react'
import GridList from '~/src/components/mui-wrappers/grid-list/GridList'
import GridListItem from '~/src/components/mui-wrappers/grid-list/GridListItem'
import ProductItemCardVirtualized from '~/src/components/products/product-item/ProductItemCardVirtualized'
import useGetProductListTemplate from '~/src/components/products/product-list/styles/useGetProductListTemplate'
import { useEoValue } from '~/src/hooks/useEoState'
import { FIRM_THEME_SELECTOR } from '~/src/stores/firm'
import { PRODUCTS_BY_IDS_SELECTOR } from '~/src/stores/product'
import { GridListItemProps, GridListProps } from '~/src/types/GridList'

interface Props {
  productsId: string[],
  GridProps?: GridListProps,
  ItemProps?: GridListItemProps,
  onItemClick?(e: MouseEvent<HTMLElement>, productId: string): void,
}

const ProductList = ({ productsId, GridProps, ItemProps, onItemClick }: Props) => {
  const style = useGetProductListTemplate()
  const products = useEoValue(PRODUCTS_BY_IDS_SELECTOR(productsId))
  const firmTheme = useEoValue<Theme | undefined>(FIRM_THEME_SELECTOR)

  return (
    <GridList
      sx={style.getGridStyleSxProps()}
      {...style.getGridProps()}
      {...GridProps}
    >
      {products.map((product: Product, index: number) => (
        <GridListItem
          key={`${index}-${product.id}`}
          onClick={(e: MouseEvent<HTMLElement>) => onItemClick && onItemClick(e, product.id.toString())}
          {...style.getGridListItemProps()}
          {...ItemProps}
        >
          <ProductItemCardVirtualized
            product={product}
            containerTemplate={firmTheme?.components?.productsList}
          />
        </GridListItem>
      ))}
    </GridList>
  )
}

export default ProductList
