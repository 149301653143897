import { paperClasses } from '@mui/material'
import { ReactNode } from 'react'
import ResponsiveDialog from '~/src/components/mui-wrappers/responsive-dialog'
import OrderingMethods from '~/src/components/ordering-methods/OrderingMethods'
import OrderingMethodsDialogActions from '~/src/components/ordering-methods/OrderingMethodsDialogActions'
import OrderingMethodsTitle from '~/src/components/ordering-methods/OrderingMethodsTitle'

interface Props {
  activator: ReactNode,
}

const OrderingMethodsResponsiveDialog = ({ activator }: Props) => {
  return (
    <ResponsiveDialog
      Title={<OrderingMethodsTitle />}
      Actions={<OrderingMethodsDialogActions />}
      activator={activator}
      keepMounted={true}
      ModalProps={{
        keepMounted: true,
        id: 'ordering-methods-dialog'
      }}
      maxWidth='sm'
      fullWidth
      sx={{
        [`.${paperClasses.root}`]: {
          bgcolor: 'secondary.main',
          color: 'secondary.contrastText'
        }
      }}
    >
      <OrderingMethods />
    </ResponsiveDialog>
  )
}

export default OrderingMethodsResponsiveDialog
