import { OrderingMethodNames } from '@eo-storefronts/eo-core'
import { Box, Button } from '@mui/material'
import OrderingMethodsTimeSummary from '~/src/components/ordering-methods/OrderingMethodsTimeSummary'
import EatInSummary from '~/src/components/ordering-methods/EatInSummary'
import { useEoValue } from '~/src/hooks/useEoState'
import { CHECKOUT_FORM_STATE, FROZEN_DELIVERY_METHOD_STATE } from '~/src/stores/checkout'
import useGetOrderingMethodsSummaryMethodButtonTemplate
  from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-method-button/styles/useGetOrderingMethodsSummaryMethodButtonTemplate'

const OrderingMethodsSummaryMethodButton = () => {
  const styles = useGetOrderingMethodsSummaryMethodButtonTemplate()
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)
  const frozenOrderingMethod = useEoValue(FROZEN_DELIVERY_METHOD_STATE)

  return (
    <Button {...styles.getButtonProps(frozenOrderingMethod)}>
      <Box {...styles.getLabelContainerProps()}>
        {checkoutForm.orderingMethod.method !== OrderingMethodNames.ON_THE_SPOT && <OrderingMethodsTimeSummary/>}
        {checkoutForm.orderingMethod.method === OrderingMethodNames.ON_THE_SPOT && <EatInSummary/>}
      </Box>
    </Button>
  )
}

export default OrderingMethodsSummaryMethodButton
