import {
  ApiConfiguration,
  DateUtils,
  FirstAvailableFulfilmentTime,
  Id,
  OrderingMethodNames,
  PickupPoint
} from '@eo-storefronts/eo-core'
import { useEoValue } from '~/src/hooks/useEoState'
import { getFirstAvailableFulfilmentTime } from '~/src/services/OrderService'
import { AUTHENTICATION_STATE } from '~/src/stores/authentication'

interface ReturnsType {
  calculateFirstAvailableTime(
    firmId: Id,
    orderingMethod: OrderingMethodNames | null,
    pickupPoint: PickupPoint | null,
    products: Record<Id, number>
  ): Promise<FirstAvailableFulfilmentTime | undefined>,
}

const useGetFirstAvailableFulfilmentTime = (): ReturnsType => {
  const auth = useEoValue(AUTHENTICATION_STATE)

  const calculateFirstAvailableTime = async (
    firmId: Id,
    orderingMethod: OrderingMethodNames | null,
    pickupPoint: PickupPoint | null,
    items: Record<Id, number>
  ): Promise<FirstAvailableFulfilmentTime | undefined> => {
    if (!firmId || !orderingMethod) {
      return
    }

    if (
      (auth.customer?.id && auth.customer.token)
      && ApiConfiguration.getHeader('customer_token') !== auth.customer.token
    ) {
      ApiConfiguration.addOrReplaceHeader('customer_token', auth.customer.token)
    } else if (
      (auth.guest?.id && auth.guest.token)
      && ApiConfiguration.getHeader('customer_token') !== auth.guest.token
    ) {
      ApiConfiguration.addOrReplaceHeader('customer_token', auth.guest.token)
    }

    if (orderingMethod === OrderingMethodNames.ON_THE_SPOT) {
      return {
        timestamp: DateUtils.momentFormat(new Date())
      }
    }

    return getFirstAvailableFulfilmentTime(
      firmId,
      auth.guest?.id || auth.customer?.id || 0,
      {
        orderingMethod: orderingMethod,
        items,
        firmPickupPointId: pickupPoint?.id
      }
    )
  }

  return {
    calculateFirstAvailableTime
  }
}

export default useGetFirstAvailableFulfilmentTime
