import { SxProps } from '@mui/material'
import ProductDetailsFooterTemplateServiceInterface from '~/src/components/products/product-details/product-details-footer/styles/ProductDetailsFooterTemplateServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class ProductDetailsFooterTemplateServiceStyleOne extends TemplateServiceStyleBase implements ProductDetailsFooterTemplateServiceInterface {
  public getContainerSx(): SxProps {
    return {
      display: 'grid',
      alignItems: 'center',
      backgroundColor: 'background.main',
      color: 'background.contrastText',
      gridTemplateColumns: 'max-content auto',
      gridTemplateRows: 'auto',
      gridTemplateAreas: '"quantity button"',
      gap: 2,
      p: 2
    }
  }

  public getQuantitySx(): SxProps {
    return {
      gridArea: 'quantity'
    }
  }

  public getAddToCartSx(): SxProps {
    return {
      gridArea: 'button',
      width: '100%'
    }
  }
}
