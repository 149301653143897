import { ApiConfiguration, Customer } from '@eo-storefronts/eo-core'
import { useSetEoState } from '~/src/hooks/useEoState'
import { CUSTOMER_STATE } from '~/src/stores/customer'
import useSetAuthState from '~/src/hooks/auth/useSetAuthState'

interface ReturnsType {
  logout(): void,
}

const useLogout = (): ReturnsType => {
  const { setAuthState } = useSetAuthState()
  const setCustomerState = useSetEoState<Customer | null>(CUSTOMER_STATE)

  const logout = (): void => {
    ApiConfiguration.deleteHeader('customer_token')
    ApiConfiguration.deleteHeader('customer_id')

    setAuthState({
      customer: undefined,
      guest: undefined,
      loggedInAs: undefined
    })
    setCustomerState(null)
  }

  return { logout }
}

export default useLogout
