import { Box, dialogContentClasses } from '@mui/material'
import ShoppingCart from '~/src/components/cart/shopping-cart'
import ShoppingCartActions from '~/src/components/cart/shopping-cart-actions'
import ResponsiveDialog from '~/src/components/mui-wrappers/responsive-dialog'
import { useTranslations } from '~/src/hooks/useTranslations'
import { DialogNameEnum } from '~/src/types/DialogNameEnum'

const ShoppingCartResponsiveDialog = () => {
  const { t } = useTranslations()

  return (
    <ResponsiveDialog
      openByDefault
      name={DialogNameEnum.SHOPPING_CART}
      Title={<span>{t('shoppingCart.title')}</span>}
      keepMounted={true}
      Actions={
        <Box
          sx={{
            width: '100%'
          }}
        >
          <ShoppingCartActions />
        </Box>
      }
      ModalProps={{
        keepMounted: true
      }}
      sx={{
        [`.${dialogContentClasses.root}`]: {
          pb: 0,
          'div.MuiBox-root': {
            'div.MuiBox-root': {
              px: 0
            }
          }
        }
      }}
    >
      <ShoppingCart
        showOrderingMethodsResume
        showActions={false}
      />
    </ResponsiveDialog>
  )
}

export default ShoppingCartResponsiveDialog

