import ResponsiveDialog from '~/src/components/mui-wrappers/responsive-dialog'
import DevModeDialogActions from '~/src/components/layout/dev-mode/dialog/DevModeDialogActions'
import DevModeDialogContent from '~/src/components/layout/dev-mode/dialog/DevModeDialogContent'
import { useFormik } from 'formik'
import { Firm, Theme } from '@eo-storefronts/eo-core'
import { useEoState, useEoValue } from '~/src/hooks/useEoState'
import { FIRM_THEME_SELECTOR, SELECTED_SHOP_STATE } from '~/src/stores/firm'
import { Box } from '@mui/material'
import { BRAND_STATE } from '~/src/stores/brand'
import useDialog from '~/src/hooks/useDialog'
import { DialogNameEnum } from '~/src/types/DialogNameEnum'

export type ThemeColorsFormInterface = Pick<Theme, 'containerColor' | 'containerContrastColor' | 'mainColor' | 'mainColorContrastColor' | 'mainGradientColorFrom' | 'mainGradientColorTo' | 'mainGradientContrastColor' | 'successGradientColorFrom' | 'successGradientColorTo' | 'successGradientContrastColor' | 'tabColor' | 'tabContrastColor' | 'themeColor' | 'themeColorContrastColor' | 'wellColor' | 'wellContrastColor' | 'contentColor' | 'contentContrastColor'>


const DevModeDialog = () => {
  const { dismiss } = useDialog()
  const defaultColor = '#ffffff'
  const [ brand, setBrand ] = useEoState(BRAND_STATE)
  const selectedShopName = useEoValue(SELECTED_SHOP_STATE)
  const firmTheme = useEoValue(FIRM_THEME_SELECTOR)
  const form = useFormik<ThemeColorsFormInterface>({
    initialValues: {
      containerColor: firmTheme?.containerColor || defaultColor,
      containerContrastColor: firmTheme?.containerContrastColor || defaultColor,
      mainColor: firmTheme?.mainColor || defaultColor,
      mainColorContrastColor: firmTheme?.mainColorContrastColor || defaultColor,
      mainGradientColorFrom: firmTheme?.mainGradientColorFrom || defaultColor,
      mainGradientColorTo: firmTheme?.mainGradientColorTo || defaultColor,
      mainGradientContrastColor: firmTheme?.mainGradientContrastColor || defaultColor,
      successGradientColorFrom: firmTheme?.successGradientColorFrom || defaultColor,
      successGradientColorTo: firmTheme?.successGradientColorTo || defaultColor,
      successGradientContrastColor: firmTheme?.successGradientContrastColor || defaultColor,
      tabColor: firmTheme?.tabColor || defaultColor,
      tabContrastColor: firmTheme?.tabContrastColor || defaultColor,
      themeColor: firmTheme?.themeColor || defaultColor,
      themeColorContrastColor: firmTheme?.themeColorContrastColor || defaultColor,
      wellColor: firmTheme?.wellColor || defaultColor,
      wellContrastColor: firmTheme?.wellContrastColor || defaultColor,
      contentColor: firmTheme?.contentColor || defaultColor,
      contentContrastColor: firmTheme?.contentContrastColor || defaultColor
    },
    onSubmit: (values: ThemeColorsFormInterface) => {
      if (!brand) {
        closeDialog()
        return
      }

      let updatedBrand: Firm = brand

      if (selectedShopName) {
        const locationIndex = brand?.locations?.findIndex((shop: Firm) => shop.sitename === selectedShopName)

        if (!locationIndex || locationIndex === -1 || !brand.locations) {
          closeDialog()
          return
        }

        updatedBrand = {
          ...brand,
          locations: [
            ...brand.locations.slice(0, locationIndex),
            {
              ...brand.locations[locationIndex],
              settings: {
                ...brand.locations[locationIndex].settings ?? {},
                theme: {
                  ...brand.locations[locationIndex].settings.theme,
                  ...values
                }
              }
            },
            ...brand.locations.slice(locationIndex + 1)
          ]
        }
      } else {
        updatedBrand = {
          ...brand,
          settings: {
            ...brand.settings ?? {},
            theme: {
              ...brand.settings.theme,
              ...values
            }
          }
        }
      }

      setBrand(updatedBrand)
      closeDialog()
    }
  })

  const closeDialog = () => {
    dismiss(DialogNameEnum.DEV_MODE)
  }

  return (
    <ResponsiveDialog
      openByDefault
      name={DialogNameEnum.DEV_MODE}
      Title={<span>Developer options</span>}
      fullWidth
      maxWidth='lg'
      sx={{
        'input[type="color"]': {
          padding: '0 !important',
          '-webkit-appearance': 'none',
          border: 'none',
          height: '32px'
        },
        'input[type="color"]::-webkit-color-swatch-wrapper': {
          padding: 0,
          borderRadius: '25px !important'
        },
        'input[type="color"]::-webkit-color-swatch': {
          border: 'none',
          borderRadius: '.5em !important'
        }
      }}
    >
      <Box
        component='form'
        onSubmit={form.handleSubmit}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2
        }}>
        <DevModeDialogContent form={form}/>
        <DevModeDialogActions form={form}/>
      </Box>
    </ResponsiveDialog>
  )
}

export default DevModeDialog
