import { ApiConfiguration, Firm, Id } from '@eo-storefronts/eo-core'
import { useEoValue } from '~/src/hooks/useEoState'
import { linkFirm } from '~/src/services/CustomerService'
import {
  API_TOKEN_STATE,
  AUTHENTICATION_STATE,
  AuthenticationState,
  BaseAuthenticationState
} from '~/src/stores/authentication'
import { FIRM_SELECTOR } from '~/src/stores/firm'

interface ReturnsType {
  doLinkFirm(firmId?: Id, auth?: Partial<AuthenticationState>): Promise<unknown | undefined>,
}

const useLinkFirm = (): ReturnsType => {
  const firm = useEoValue<Firm | null>(FIRM_SELECTOR)
  const authState = useEoValue<AuthenticationState>(AUTHENTICATION_STATE)
  const apiToken = useEoValue<BaseAuthenticationState | null>(API_TOKEN_STATE)

  const doLinkFirm = async (firmId?: Id, auth: Partial<AuthenticationState> = authState) => {
    const id: Id | undefined = firmId ?? firm?.id

    if (
      !id
      || !auth.customer?.id
      || !auth.customer?.token
      || !apiToken?.token
      || !ApiConfiguration.getHeader('Authorization')
    ) {
      return
    }

    try {
      await linkFirm(auth.customer.id, id)
      return
    } catch {
      return
    }
  }

  return { doLinkFirm }
}

export default useLinkFirm
