import ResponsiveDialog from '~/src/components/mui-wrappers/responsive-dialog'
import ResponsiveDialogProps from '~/src/types/ResponsiveDialogProps'

const ProductModalContainer = ({ children, ...responsiveDialogProps }: ResponsiveDialogProps) => (
  <ResponsiveDialog
    contentSx={{
      position: 'relative',
      p: 0
    }}
    {...responsiveDialogProps}
  >
    {children}
  </ResponsiveDialog>
)

export default ProductModalContainer
