import { PriceList, Product } from '@eo-storefronts/eo-core'
import { Box } from '@mui/material'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { useEoValue } from '~/src/hooks/useEoState'
import { getProductPriceList } from '~/src/services/ProductService'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { PRICE_LIST_STATE } from '~/src/stores/product/price-list'

interface Props {
  product: Product,
}

const ProductItemPromotion = ({ product }: Props) => {
  const priceLists = useEoValue<PriceList[]>(PRICE_LIST_STATE)
  const priceList = getProductPriceList(product, priceLists)
  const firm = useEoValue(FIRM_SELECTOR)
  const theme = useCustomMuiTheme()

  if (!priceList?.is_promotion) {
    return null
  }

  return (
    <Box sx={{
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1),
      fontWeight: 'bold',
      backgroundColor: 'primary.main',
      color: 'primary.contrastText',
      px: 1,
      py: .5,
      borderRadius: theme.spacing(2)
    }}>
      { priceList.discount_rate > 0 && `-${Math.round(priceList.discount_rate*100)}%` }
      { priceList.discount_rate <= 0 && `- ${firm?.settings.currency.symbol} ${(priceList.base_price - priceList.price).toFixed(2)}` }
    </Box>
  )
}

export default ProductItemPromotion
