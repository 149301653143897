import AddIcon from '@mui/icons-material/Add'
import { Box, Button } from '@mui/material'
import { useEoValue } from '~/src/hooks/useEoState'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import useGetOrderingMethodsSummaryAddressButtonTemplate
  from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-address-button/styles/useGetOrderingMethodsSummaryAddressButtonTemplate'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import OrderingMethodSummaryAddressButtonLabel
  from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-address-button/OrderingMethodSummaryAddressButtonLabel'

const OrderingMethodsSummaryAddressButton = () => {
  const styles = useGetOrderingMethodsSummaryAddressButtonTemplate()
  const { orderingMethod: { address } } = useEoValue(CHECKOUT_FORM_STATE)

  return (
    <Button {...styles.getButtonProps(address)}>
      <Box sx={styles.getButtonIconContainerSx()}>
        {!address && <AddIcon {...styles.getAddIconProps()}/>}
        {address && <LocationOnOutlinedIcon {...styles.getLocationOnOutlinedIconProps()}/>}
      </Box>
      <OrderingMethodSummaryAddressButtonLabel {...styles.getLabelContainerProps()} />
    </Button>
  )
}

export default OrderingMethodsSummaryAddressButton
