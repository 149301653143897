import { TemplateEnum } from '@eo-storefronts/eo-core'
import OrderingMethodsSummaryContainerStyleOne
  from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-container/ordering-methods-summary-container-style-one'
import OrderingMethodsSummaryContainerStyleTwo
  from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-container/ordering-methods-summary-container-style-two'
import useGetComponentStyle from '~/src/hooks/layout/useGetComponentStyle'

const OrderingMethodsSummaryContainer = () => {
  const componentStyle = useGetComponentStyle('orderingMethodsSummary')

  switch (componentStyle) {
    case TemplateEnum.STYLE_TWO:
      return <OrderingMethodsSummaryContainerStyleTwo/>
    default:
      return <OrderingMethodsSummaryContainerStyleOne/>
  }
}

export default OrderingMethodsSummaryContainer
