import { DateUtils, Interval, OrderingMethodNames, Timeslot } from '@eo-storefronts/eo-core'
import { useEffect } from 'react'
import useFetchDeliveryAddressCost from '~/src/hooks/ordering-methods/useFetchDeliveryAddressCost'
import useFetchTimeslotIntervals from '~/src/hooks/ordering-methods/useFetchTimeslotIntervals'
import useFetchTimeslots from '~/src/hooks/ordering-methods/useFetchTimeslots'
import useSetFirstAvailableTime from '~/src/hooks/ordering-methods/useSetFirstAvailableTime'
import { useAsyncEffect } from '~/src/hooks/useAsyncEffect'
import { useEoValue, useSetEoState } from '~/src/hooks/useEoState'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import { FIRM_WITH_LOCATOR_STATE_SELECTOR } from '~/src/stores/firm'
import { CURRENT_TIMESLOT_KEY_SELECTOR, INTERVALS_STATE, TIMESLOTS_STATE } from '~/src/stores/timeslots'

const useCalculateTimeAndCostsEffect = (): void => {
  const firm = useEoValue(FIRM_WITH_LOCATOR_STATE_SELECTOR)
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)
  const currentTimeslotKey = useEoValue(CURRENT_TIMESLOT_KEY_SELECTOR)
  const { fetchDeliveryCost } = useFetchDeliveryAddressCost()
  const { setFirstAvailableTime } = useSetFirstAvailableTime()
  const setTimeslots = useSetEoState(TIMESLOTS_STATE)
  const setIntervals = useSetEoState(INTERVALS_STATE)
  const { fetchTimeslots } = useFetchTimeslots()
  const { fetchTimeslotsIntervals } = useFetchTimeslotIntervals()

  const _fetchTimeslots = async () => {
    const timeslots = await fetchTimeslots()

    setTimeslots((state: Record<string, Timeslot[]>) => ({
      ...state,
      [currentTimeslotKey]: timeslots?.data || []
    }))
  }

  const _fetchIntervals = async () => {
    if (!checkoutForm.orderingMethod.time) {
      return
    }

    const intervals = await fetchTimeslotsIntervals()
    const availableIntervals = intervals
      .filter((interval: Interval) => interval.available && !interval.blocked)

    let key: string = DateUtils.momentFormat(new Date(checkoutForm.orderingMethod.time), 'YYYY-MM-DD')
    key += `-${checkoutForm.orderingMethod.timeslotId}`

    setIntervals((state: Record<number, Interval[]>) => ({
      ...state,
      [key]: availableIntervals || []
    }))
  }

  /**
   * Locator page show delivery method first
   * get the first available time when a firm is selected
   */
  useAsyncEffect(async () => {
    await setFirstAvailableTime(false)
  }, [ setFirstAvailableTime ])

  useAsyncEffect(async () => {
    if (
      checkoutForm.orderingMethod.method
      && checkoutForm.orderingMethod.method !== OrderingMethodNames.ON_THE_SPOT
      && firm?.settings.orderingMethods[checkoutForm.orderingMethod.method]
      && firm?.settings.orderingMethods[checkoutForm.orderingMethod.method]?.orderTimeslots.active
    ) {
      await _fetchTimeslots()
      await _fetchIntervals()
    }
  }, [ 
    firm,
    checkoutForm.orderingMethod.method,
    checkoutForm.orderingMethod.minDate
  ])

  /**
   * Calculate delivery cost when delivery method or an address is selected
   */
  useEffect(() => {
    if (
      checkoutForm.orderingMethod.method !== OrderingMethodNames.DELIVERY
      || !checkoutForm.orderingMethod.address
      || checkoutForm.orderingMethod.minDate === null
    ) {
      return
    }

    void fetchDeliveryCost(checkoutForm.orderingMethod.address)
  }, [
    firm,
    checkoutForm.orderingMethod.minDate,
    checkoutForm.orderingMethod.method,
    checkoutForm.orderingMethod.address
  ])
}

export default useCalculateTimeAndCostsEffect
