import { SxProps } from '@mui/material'
import ProductDetailsTemplateServiceInterface
  from '~/src/components/products/product-details/styles/ProductDetailsTemplateServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class ProductDetailsTemplateServiceStyleTwo extends TemplateServiceStyleBase implements ProductDetailsTemplateServiceInterface {
  public getCardElevation(): number {
    return 0
  }

  public getContainerSx(hasImage: boolean): SxProps {
    if (hasImage) {
      return {
        display: 'grid',
        backgroundColor: 'background.main',
        color: 'background.contrastText',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gridTemplateRows: 'repeat(3, auto)',
        gridTemplateAreas: '' +
          '"title title title"' +
          '"image info info"' +
          '"footer info info"' +
        '',
        gap: 1,
        [this.muiTheme.breakpoints.down('md')]: {
          gridTemplateColumns: '1fr',
          minHeight: '100vh',
          gridTemplateRows: 'repeat(4, auto)',
          gridTemplateAreas: '"image" "title" "info" "footer"',
          gap: 0,
          p: 0
        }
      }
    }

    return {
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto 1fr auto',
      gridTemplateAreas: '"title" "info" "footer"',
      [this.muiTheme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr',
        minHeight: '100vh',
        gridTemplateRows: 'max-content auto max-content',
        gridTemplateAreas: '"title" "info" "footer"',
        gap: 0,
        p: 0
      }
    }
  }

  public getTitleSx(): SxProps {
    return {
      gridArea: 'title',
      position: 'sticky',
      zIndex: 10,
      backgroundColor: 'secondary.main',
      color: 'secondary.contrastText',
      top: 0,
      p: 2
    }
  }

  public getImageSx(): SxProps {
    return {
      gridArea: 'image',
      px: 1,
      [this.muiTheme.breakpoints.down('md')]: {
        px: 0,
        pt: 'var(--eo-safe-area-top)'
      }
    }
  }

  public getInfoSx(): SxProps {
    return {
      gridArea: 'info',
      py: 2,
      overflowY: 'auto',
      backgroundColor: 'background.main',
      color: 'background.contrastText',
      [this.muiTheme.breakpoints.down('md')]: {
        pb: `calc(${this.muiTheme.spacing(10)} + var(--eo-safe-area-bottom))`
      }
    }
  }

  public getFooterSx(): SxProps {
    return {
      gridArea: 'footer',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      p: 2,
      zIndex: 1,
      [this.muiTheme.breakpoints.down('md')]: {
        position: 'fixed',
        width: '100%',
        backgroundColor: 'secondary.main',
        overflow: 'hidden',
        bottom: 'var(--eo-safe-area-bottom)'
      }
    }
  }
}
