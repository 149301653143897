import { PriceList, Price as PriceModel, Product } from '@eo-storefronts/eo-core'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import { Box, SxProps, Typography } from '@mui/material'
import { useCallback, useContext, useState } from 'react'
import PrimaryButton from '~/src/components/mui-wrappers/buttons/PrimaryButton'
import ResponsiveDialogContext, {
  ResponsiveDialogContextInterface
} from '~/src/components/mui-wrappers/responsive-dialog/ResponsiveDialogContext'
import Price from '~/src/components/Price'
import { Constants } from '~/src/helpers/constants'
import { useCalculatePrice } from '~/src/hooks/products/useCalculatePrice'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { getProductPrice } from '~/src/services/ProductService'
import { CartProductModifierGroupStateInterface } from '~/src/stores/cart/cart-product-modifier-groups'
import { COUPON_STATE } from '~/src/stores/checkout'
import { LAYOUT_STATE } from '~/src/stores/layout'
import { PRICE_LIST_STATE } from '~/src/stores/product/price-list'
import ProductConfigurationModalContext, {
  ProductConfigurationModalContextInterface
} from './modals/configuration/ProductConfigurationModalContext'

export interface AddToCartPayload {
  addedToCart: boolean,
  product: Product,
  quantity: number,
  modifierGroups: CartProductModifierGroupStateInterface[],
}

interface Props {
  product: Product,
  quantity: number,
  disabled: boolean,
  sx?: SxProps,
}

const ProductDetailsAddToCart = ({ product, quantity, disabled, sx = {} }: Props) => {
  const muiTheme = useCustomMuiTheme()
  const { calculate: calculatePrice } = useCalculatePrice()
  const { t } = useTranslations()
  const priceLists: PriceList[] = useEoValue(PRICE_LIST_STATE)
  const coupon = useEoValue(COUPON_STATE)
  const { isMobile } = useEoValue(LAYOUT_STATE)
  const [ loading, setLoading ] = useState(false)
  const responsiveDialogContext = useContext<ResponsiveDialogContextInterface>(ResponsiveDialogContext)
  const productConfigurationContext = useContext<ProductConfigurationModalContextInterface>(ProductConfigurationModalContext)

  const isCoupon = coupon?.product?.id === product.id || coupon?.category?.id === product.categoryId

  const handleAddToCart = () => {
    setLoading(true)

    setTimeout(() => {
      productConfigurationContext.save(quantity)
      responsiveDialogContext.toggleClose && responsiveDialogContext.toggleClose({ addedToCart: true })
    }, Constants.DEBOUNCE_TIME)
  }

  const price = useCallback(() => {
    const price: PriceModel = getProductPrice(product, priceLists)
    const newPrice: PriceModel = calculatePrice(price, quantity)

    if (isCoupon) {
      Object.keys(newPrice).forEach((key) => {
        const objectKey: keyof PriceModel = key as keyof PriceModel

        newPrice[objectKey] -= price[objectKey]
      })
    }

    return newPrice
  }, [
    priceLists,
    calculatePrice,
    quantity
  ])

  return (
    <PrimaryButton
      id='product_details__add_to_cart_btn'
      disabled={disabled || loading}
      loading={loading}
      fullWidth
      onClick={handleAddToCart}
      sx={sx}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          p: 1,
          [muiTheme.breakpoints.only('xs')]: {
            p: 1 / 2
          }
        }}
      >
        {!productConfigurationContext.asModifier && isMobile && <ShoppingCartIcon/>}
        {!productConfigurationContext.asModifier && (
          <Typography variant='body2'>{t('cart.addToCart')}</Typography>
        )}
        {productConfigurationContext.asModifier && (
          <Typography
            variant='body2'
            sx={{
              textAlign: 'center',
              width: '100%'
            }}
          >
            {t('actions.confirm')}
          </Typography>
        )}
        {!productConfigurationContext.asModifier && (
          <Typography
            className='price'
            variant='body2'
          >
            <Price price={price()}/>
          </Typography>
        )}
      </Box>
    </PrimaryButton>
  )
}

export default ProductDetailsAddToCart
