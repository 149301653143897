import Divider from '~/src/components/mui-wrappers/Divider'
import CheckoutOptions from '~/src/components/checkout/CheckoutOptions'
import useGetShoppingCartTemplate from '~/src/components/cart/shopping-cart/styles/useGetShoppingCartTemplate'
import { useEoValue } from '~/src/hooks/useEoState'
import { GET_FIRM_CHECKOUT_OPTIONS_SELECTOR } from '~/src/stores/firm'

interface Props {
  enableEditCheckoutOptions: boolean,
}

const ShoppingCartOptions = ({ enableEditCheckoutOptions }: Props) => {
  const styles = useGetShoppingCartTemplate()
  const checkoutOptions = useEoValue(GET_FIRM_CHECKOUT_OPTIONS_SELECTOR)

  if (!checkoutOptions.length) {
    return null
  }

  return (
    <>
      <Divider sx={{
        ...styles.getDividerSxProps(),
        pt: 1
      }} />
      <CheckoutOptions enableEdit={enableEditCheckoutOptions} />
    </>
  )
}

export default ShoppingCartOptions
