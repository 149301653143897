import { Modifier, ModifierGroup, Price, PriceList, Product } from '@eo-storefronts/eo-core'
import PriceHelper from '~/src/helpers/priceHelper'
import ModifierService from '~/src/services/ModifierService'
import { CartProductModifierGroupStateInterface } from '~/src/stores/cart/cart-product-modifier-groups'
import { convertProductToModifier } from './ProductService'
import NumberUtils from '~/src/utils/NumberUtils'

export default class CartService {
  private readonly _modifierGroups: Record<string, ModifierGroup>
  private readonly _modifiers: Record<string, Modifier>
  private readonly _products: Record<string, Product>

  public constructor(
    modifierGroups: Record<string, ModifierGroup>,
    modifiers: Record<string, Modifier>,
    products: Record<string, Product>
  ) {
    this._modifierGroups = modifierGroups
    this._modifiers = modifiers
    this._products = products
  }

  public calculateCartProductPrice(
    productPrice: Price,
    quantity: number,
    cartModifierGroups: CartProductModifierGroupStateInterface[],
    priceList: PriceList[]
  ): Price {
    let calculatedPrice: Price = productPrice

    Object.keys(calculatedPrice).map((key) => {
      const objectKey: keyof Price = key as keyof Price

      calculatedPrice = {
        ...calculatedPrice,
        [objectKey]: (this._modifierGroupsPrice(cartModifierGroups, priceList)[objectKey] + NumberUtils.round(calculatedPrice[objectKey], 2)) * quantity
      }
    })

    return calculatedPrice
  }

  private _modifierGroupsPrice(
    cartModifierGroups: CartProductModifierGroupStateInterface[],
    priceList: PriceList[]
  ): Price {
    let calculatedPrice: Price = PriceHelper.transform(0)

    for (const modifierGroup of cartModifierGroups) {
      if (!this._modifierGroups[modifierGroup.id]) {
        continue
      }

      for (const modifier of modifierGroup.modifiers) {
        const modifierService = new ModifierService(
          modifier.type === 'product' ? convertProductToModifier(this._modifierGroups[modifierGroup.id], this._products[modifier.id]) : this._modifiers[modifier.id],
          this._modifierGroups,
          this._modifiers,
          this._products
        )

        Object.keys(calculatedPrice).map((key) => {
          const objectKey: keyof Price = key as keyof Price

          calculatedPrice = {
            ...calculatedPrice,
            [objectKey]: calculatedPrice[objectKey] + modifierService.calculatePrice(modifier, priceList)[objectKey]
          }
        })
      }
    }

    return calculatedPrice
  }
}
