import { Modifier } from '@eo-storefronts/eo-core'
import { Box, Typography } from '@mui/material'
import { useCallback } from 'react'
import ModifierMedia from '~/src/components/products/modifier-groups/modifier-group/modifier/modifier-media'
import ModifierPrice from '~/src/components/products/modifier-groups/modifier-group/modifier/modifier-price'
import ModifierPromotionalPrice
  from '~/src/components/products/modifier-groups/modifier-group/modifier/modifier-promotional-price'
import useModifierToggleTemplateService
  from '~/src/components/products/modifier-groups/modifier-group/modifier/modifier-toggle/styles/useModifierToggleTemplateService'
import { useEoValue } from '~/src/hooks/useEoState'
import { getModifierPrice } from '~/src/services/ModifierService'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import { LANGUAGE_STATE } from '~/src/stores/lang'
import { PRICE_LIST_STATE } from '~/src/stores/product/price-list'

interface Props {
  modifier: Modifier,
}

const ModifierToggle = ({ modifier }: Props) => {
  const checkoutFormState = useEoValue(CHECKOUT_FORM_STATE)
  const priceLists = useEoValue(PRICE_LIST_STATE)
  const styles = useModifierToggleTemplateService()
  const lang = useEoValue(LANGUAGE_STATE)

  const currentModifierPrice = useCallback(() => {
    if (!checkoutFormState.orderingMethod.method) {
      return 0
    }

    return getModifierPrice(modifier, priceLists)[checkoutFormState.orderingMethod.method]
  }, [
    checkoutFormState.orderingMethod.method,
    priceLists,
    modifier.price
  ])

  return (
    <Box
      className='modifier-toggle'
      sx={styles.getContainerSx()}>
      <ModifierMedia modifier={modifier}/>
      <Typography
        variant='body2'
        sx={{
          textAlign: 'center',
          display: '-webkit-box',
          WebkitLineClamp: '2',
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          lineHeight: '1rem' /* Adjust line-height as needed */
        }}>
        {modifier.name[lang]}
      </Typography>
      {currentModifierPrice() > 0 && (
        <Box sx={styles.getPriceContainerSx()}>
          <ModifierPrice
            modifier={modifier}
            asChip={styles.displayPriceAsChip()}
          />
          <ModifierPromotionalPrice modifier={modifier}/>
        </Box>
      )}
    </Box>
  )
}

export default ModifierToggle
