import { Box } from '@mui/material'
import OrderingMethodsSummary from '~/src/components/ordering-methods/OrderingMethodsSummary'
import Divider from '~/src/components/mui-wrappers/Divider'
import { useEoValue } from '~/src/hooks/useEoState'
import { LAYOUT_STATE } from '~/src/stores/layout'
import useOrderingMethodService from '~/src/hooks/ordering-methods/useOrderingMethodService'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import useGetShoppingCartTemplate from '~/src/components/cart/shopping-cart/styles/useGetShoppingCartTemplate'

const ShoppingCartOrderingMethodsSummary = () => {
  const layout = useEoValue(LAYOUT_STATE)
  const { isMethodInactive } = useOrderingMethodService()
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)
  const styles = useGetShoppingCartTemplate()

  if (layout.mainSideBar.displayDeliveryOptions) {
    return null
  }

  return (
    <Box sx={styles.getOrderingMethodsContainerSxProps()}>
      <OrderingMethodsSummary />
      {!isMethodInactive(checkoutForm.orderingMethod.method) && <Divider sx={styles.getDividerSxProps()} />}
    </Box>
  )
}

export default ShoppingCartOrderingMethodsSummary
