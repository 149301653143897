import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import getProductConfigurationModalTemplateService
  from '~/src/components/products/modals/configuration/styles/getProductConfigurationModalTemplateService'
import { useEoValue } from '~/src/hooks/useEoState'
import { LAYOUT_STATE } from '~/src/stores/layout'

const useProductConfigurationModalTemplateService = () => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle('product')
  const layout = useEoValue(LAYOUT_STATE)

  return getProductConfigurationModalTemplateService(pageStyle, muiTheme, layout)
}

export default useProductConfigurationModalTemplateService
