import { Box, CircularProgress } from '@mui/material'
import useGetShoppingCartTemplate from '~/src/components/cart/shopping-cart/styles/useGetShoppingCartTemplate'
import useLogViewCart from '~/src/hooks/analytics/ecommerce/useLogViewCart'
import { useEoValue } from '~/src/hooks/useEoState'
import ShoppingCartProps from '~/src/types/ShoppingCartProps'
import { CHECKOUT_BUTTON_LOADING_STATE } from '~/src/stores/checkout'
import ShoppingCartOrderingMethodsSummary from '~/src/components/cart/shopping-cart/ShoppingCartOrderingMethodsSummary'
import ShoppingCartContent from '~/src/components/cart/shopping-cart/ShoppingCartContent'
import ShoppingCartInfos from '~/src/components/cart/shopping-cart/shopping-cart-infos'
import useCheckDeliveryCostEffect from '~/src/hooks/checkout/useCheckDeliveryCostEffect'

const ShoppingCart = ({
  showOrderingMethodsResume = false,
  showIngredientsAndOptions = true,
  showQuantityAsText = false,
  showQuantitySelector = true,
  showPrice = true,
  showDeleteButton = true,
  showSubTotal = true,
  showFees = true,
  showLoyaltySummary = true,
  enableEditCheckoutOptions = true,
  showCheckoutOptions = true,
  showTotal = true,
  showCoupon = true,
  showActions = true,
  enableCollapse = false
}: ShoppingCartProps) => {
  const styles = useGetShoppingCartTemplate()
  const loading = useEoValue(CHECKOUT_BUTTON_LOADING_STATE)

  useLogViewCart()
  useCheckDeliveryCostEffect()

  return (
    <Box sx={styles.getContainerSxProps()}>
      {showOrderingMethodsResume && <ShoppingCartOrderingMethodsSummary />}
      <ShoppingCartContent
        enableCollapse={enableCollapse}
        showPrice={showPrice}
        showQuantityAsText={showQuantityAsText}
        showQuantitySelector={showQuantitySelector}
        showDeleteButton={showDeleteButton}
        showIngredientsAndOptions={showIngredientsAndOptions}
        showCheckoutOptions={showCheckoutOptions}
        enableEditCheckoutOptions={enableEditCheckoutOptions}
      />
      <ShoppingCartInfos
        showSubTotal={showSubTotal}
        showLoyaltySummary={showLoyaltySummary}
        showFees={showFees}
        showTotal={showTotal}
        showCoupon={showCoupon}
        showActions={showActions}
      />
      {loading && (
        <Box sx={{
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%'
        }}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  )
}

export default ShoppingCart
