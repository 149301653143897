export default abstract class EnvironmentAbstract {
  public static isSentryEnabled(): boolean {
    return false
  }

  public static getApiEndpoint(): string {
    return ''
  }

  public static isSignalREnabled(): boolean {
    return false
  }

  public static getSignalREndpointSuffix(): string {
    return 'clickandcollect'
  }

  public static getSignalREndpoint(): string {
    return `${this.getApiEndpoint()}/${this.getSignalREndpointSuffix()}`
  }

  public static getApiVersion(): string {
    return '5.7.1'
  }

  public static getAppVersion(): string {
    return '12.4.2'
  }

  public static getClientId(): string {
    return 'app'
  }

  public static getClientSecret(): string {
    return 'VhtDuF5GQjXyMu3Md8XTKuys3t4vy8astxOAGCeE'
  }

  public static getDevice(): string {
    return 'web'
  }

  public static getGoogleMapKey(): string {
    return 'AIzaSyAEkYlfggbE1E_fHgTWADVc8QIIQgwJEOY'
  }

  public static getGoogleSignInClientIdWeb(): string | undefined {
    return '488721083554-cu88jjhff4n6tskb0kqbjj05o32o49f3.apps.googleusercontent.com'
  }

  public static getLocalhost(): string {
    return ''
  }
}
