import { Modifier, ModifierGroup } from '@eo-storefronts/eo-core'
import { Box } from '@mui/material'
import MultiChoiceModifier
  from '~/src/components/products/modifier-groups/modifier-group/modifier-group-multi-choice/multi-choice-modifier'
import useModifierGroupMultiChoiceTemplateService
  from '~/src/components/products/modifier-groups/modifier-group/modifier-group-multi-choice/styles/useModifierGroupMultiChoiceTemplateService'
import ModifierGroupTitle from '~/src/components/products/modifier-groups/modifier-group/modifier-group-title'
import useCartModifierGroupsStateService from '~/src/hooks/products/modifier-groups/useCartModifierGroupsStateService'
import { useEoValue } from '~/src/hooks/useEoState'
import { productHasImage } from '~/src/services/ProductService'
import { CartProductModifierGroupStateInterface } from '~/src/stores/cart/cart-product-modifier-groups'
import { PRODUCT_ID_SELECTOR } from '~/src/stores/product'

interface Props {
  group: ModifierGroup,
  cartModifierGroups: CartProductModifierGroupStateInterface[],
  onChange(cartModifierGroups: CartProductModifierGroupStateInterface[]): void,
}

const ModifierGroupMultiChoice = ({ group, cartModifierGroups, onChange }: Props) => {
  const product = useEoValue(PRODUCT_ID_SELECTOR)
  const styles = useModifierGroupMultiChoiceTemplateService()
  const {
    isGroupMaxSelectionReached,
    isGroupModifierMaxSelectionReached,
    addModifierToGroup,
    removeModifierFromGroup
  } = useCartModifierGroupsStateService()
  const disabled = isGroupMaxSelectionReached(cartModifierGroups, group)

  const handleOnModifierUpdate = (
    modifier: Modifier,
    amount: number,
    selected: boolean
  ): void => {
    if (!selected) {
      onChange(removeModifierFromGroup(cartModifierGroups, group, modifier.id))
      return
    }

    onChange(addModifierToGroup(cartModifierGroups, group, modifier, amount))
  }

  if (!product) {
    return null
  }

  return (
    <Box sx={styles.getContainerSx()}>
      <ModifierGroupTitle group={group} />
      <Box sx={styles.getModifiersBoxSx(productHasImage(product))}>
        {group.modifiers.map((modifierId: string) => (
          <MultiChoiceModifier
            key={modifierId}
            modifierId={modifierId}
            canBeSelectedMultiTimes={group.multipleChoiceMultiselectMax === null || group.multipleChoiceMultiselectMax > 1}
            disabled={disabled || isGroupModifierMaxSelectionReached(cartModifierGroups, group, modifierId)}
            onUpdate={handleOnModifierUpdate}
          />
        ))}
      </Box>
    </Box>
  )
}

export default ModifierGroupMultiChoice
