import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'
import StylesOrderingMethodsSummaryAddressButtonServiceInterface
  from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-address-button/styles/StylesOrderingMethodsSummaryAddressButtonServiceInterface'
import StylesOrderingMethodsSummaryAddressButtonServiceStyleTwo
  from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-address-button/styles/StylesOrderingMethodsSummaryAddressButtonServiceStyleTwo'
import StylesOrderingMethodsSummaryAddressButtonServiceStyleOne
  from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-address-button/styles/StylesOrderingMethodsSummaryAddressButtonServiceStyleOne'

const getOrderingMethodsSummaryAddressButtonTemplateService = (style: TemplateEnum | null | undefined, muiTheme: CustomMuiTheme): StylesOrderingMethodsSummaryAddressButtonServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesOrderingMethodsSummaryAddressButtonServiceStyleTwo(muiTheme)
    default:
      return new StylesOrderingMethodsSummaryAddressButtonServiceStyleOne(muiTheme)
  }
}

export default getOrderingMethodsSummaryAddressButtonTemplateService
