import { LayoutState } from '~/src/stores/layout'
import { DeepPartial } from '~/src/types/DeepPartial'
import { TemplateEnum } from '@eo-storefronts/eo-core'
import { GetLayoutArgs } from '~/src/utils/layout/LayoutServiceInterface'
import LayoutResetPasswordServiceStyleTwo
  from '~/src/pages/auth/reset-password/layout/LayoutResetPasswordServiceStyleTwo'
import LayoutResetPasswordServiceStyleThree
  from '~/src/pages/auth/reset-password/layout/LayoutResetPasswordServiceStyleThree'

const getResetPasswordLayout = (style: TemplateEnum, args: GetLayoutArgs): DeepPartial<LayoutState> => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new LayoutResetPasswordServiceStyleTwo().getLayout(args)
    case TemplateEnum.STYLE_THREE:
      return new LayoutResetPasswordServiceStyleThree().getLayout()
    default:
      return new LayoutResetPasswordServiceStyleTwo().getLayout(args)
  }
}

export default getResetPasswordLayout
