import { DateUtils, Interval } from '@eo-storefronts/eo-core'
import { Box, CircularProgress, Typography } from '@mui/material'
import { ChangeEvent, useEffect } from 'react'
import { useUpdateCheckoutTime } from '~/src/hooks/checkout/useUpdateCheckoutTime'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import { INTERVALS_FOR_TIMESLOT_ID_SELECTOR } from '~/src/stores/timeslots'
import TextField from '~/src/components/mui-wrappers/styled/text-field'

interface Props {
  loading: boolean,
}

const OrderingMethodsTimeslotsIntervals = ({ loading }: Props) => {
  const { t } = useTranslations()
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)
  const intervals = useEoValue(INTERVALS_FOR_TIMESLOT_ID_SELECTOR)
  const { updateCheckoutTimeWithInterval } = useUpdateCheckoutTime()

  let selectedTime = ''

  if (checkoutForm.orderingMethod.time) {
    selectedTime = DateUtils.formatTime(new Date(checkoutForm.orderingMethod.time))
  }

  const _updateCheckoutForm = (time: string) => {
    const interval = intervals.find((i: Interval) => i.time === time)
    interval && updateCheckoutTimeWithInterval(interval)
  }

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    _updateCheckoutForm(event.target.value)
  }

  useEffect(() => {
    const interval = intervals.find((i: Interval) => i.time === selectedTime)

    if (!interval && intervals[0]) {
      _updateCheckoutForm(intervals[0].time)
    }
  }, [ selectedTime ])

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <Typography>{t('orderingMethods.pickATime')}</Typography>
      {loading && <CircularProgress size={20} />}
      {!loading && (
        <TextField
          select
          color='container'
          size='small'
          SelectProps={{
            native: true,
            sx: {
              option: {
                backgroundColor: 'var(--container-background) !important',
                color: 'var(--container-constrast) !important'
              }
            }
          }}
          variant='outlined'
          onChange={handleOnChange}
          value={selectedTime}
        >
          {intervals.map((interval: Interval, index: number) => {
            return <option key={index} value={interval.time}>{interval.time}</option>
          })}
        </TextField>
      )}
    </Box>
  )
}

export default OrderingMethodsTimeslotsIntervals
