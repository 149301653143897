import DoDisturbIcon from '@mui/icons-material/DoDisturb'
import { Box, Typography } from '@mui/material'
import CartModifierInlineQuantity from '~/src/components/cart/cart-modifier-in-line/CartModifierInlineQuantity'
import { useEoValue } from '~/src/hooks/useEoState'
import { CartProductModifierStateInterface } from '~/src/stores/cart/cart-product-modifier-groups'
import { LANGUAGE_STATE } from '~/src/stores/lang'
import { MODIFIER_FROM_CART_SELECTOR } from '~/src/stores/modifiers'

interface Props {
  cartModifier: CartProductModifierStateInterface,
  groupId: string,
}

const CartModifierInlineName = ({ groupId, cartModifier }: Props) => {
  const modifier = useEoValue(MODIFIER_FROM_CART_SELECTOR({ cartModifier, groupId }))
  const lang = useEoValue(LANGUAGE_STATE)

  return (
    <Box sx={{
      display: 'flex',
      gap: 2
    }}>
      {cartModifier.action === 'rem' && <DoDisturbIcon fontSize='small' />}
      {cartModifier.action !== 'rem' && <CartModifierInlineQuantity cartModifier={cartModifier} groupId={groupId}/>}
      <Typography variant="body2">
        {modifier.name[lang] || ''}
      </Typography>
    </Box>
  )
}

export default CartModifierInlineName
