import { createSelectorFamily, createState } from '~/src/hooks/useEoState'
import DialogsOverlay from '~/src/types/DialogsOverlay'

export const DIALOGS_OVERLAY = createState<DialogsOverlay[]>({
  key: 'overlays',
  default: []
})

export const IS_THE_LAST_OVERLAY_VISIBLE = createSelectorFamily({
  key: 'isTheLastOverlayVisible',
  get: (name) => ({ get }) => {
    const overlays = get(DIALOGS_OVERLAY)

    if (!overlays.length) {
      return false
    }

    return overlays.findIndex((overlay: DialogsOverlay) => overlay.name === name) === overlays.length -1
  }
})
