import { Box } from '@mui/material'
import OrderingMethodsSummaryMethodButton
  from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-method-button'
import { useEoValue } from '~/src/hooks/useEoState'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import { OrderingMethodNames } from '@eo-storefronts/eo-core'
import OrderingMethodsSummaryAddressButton
  from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-address-button'
import useGetOrderingMethodsSummaryMainButtonsTemplate
  from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-main-buttons/styles/useGetOrderingMethodsSummaryMainButtonsTemplate'

const OrderingMethodsSummaryMainButtons = () => {
  const styles = useGetOrderingMethodsSummaryMainButtonsTemplate()
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)
  const { method } = checkoutForm.orderingMethod

  return (
    <Box {...styles.getContainerProps()}>
      <OrderingMethodsSummaryMethodButton />
      {method === OrderingMethodNames.DELIVERY && <OrderingMethodsSummaryAddressButton />}
    </Box>
  )
}

export default OrderingMethodsSummaryMainButtons
