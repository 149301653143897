import { Alert, Box } from '@mui/material'
import OrderingMethodsTimeslots from '~/src/components/ordering-methods/OrderingMethodsTimeslots'
import OrderingMethodsTimeslotsIntervals from '~/src/components/ordering-methods/OrderingMethodsTimeslotsIntervals'
import { useTimeslotIntervals } from '~/src/hooks/ordering-methods/useTimeslotIntervals'
import { useTimeslots } from '~/src/hooks/ordering-methods/useTimeslots'
import { useFirmAcceptsOrder } from '~/src/hooks/orders/useFirmAcceptsOrder'
import { useTranslations } from '~/src/hooks/useTranslations'

const OrderingMethodsTimeslotsContainer = () => {
  const { t } = useTranslations()
  const { loading: loadingTimeslots } = useTimeslots()
  const { loading: loadingIntervals } = useTimeslotIntervals()
  const { hasTimeslots } = useFirmAcceptsOrder()

  if (!hasTimeslots()) {
    return (
      <Alert severity='error'>
        {t('checkout.ordersOnlyDuringOpeningHours')}
      </Alert>
    )
  }
  
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <OrderingMethodsTimeslots loading={loadingTimeslots}/>
      <OrderingMethodsTimeslotsIntervals loading={loadingIntervals}/>
    </Box>
  )
}

export default OrderingMethodsTimeslotsContainer
