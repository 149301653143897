import { useEffect } from 'react'
import * as Sentry from '@sentry/react'
import { Box, Button, Container, Link, Typography } from '@mui/material'
import Title from '~/src/components/mui-wrappers/Title'
import RefreshIcon from '@mui/icons-material/Refresh'
import DocumentTitleInjector from '~/src/components/root/DocumentTitleInjector'
import { useTranslations } from '~/src/hooks/useTranslations'
import RoutesEnum from '~/src/router/enums/routes.enum'
import SlideFromRight from '~/src/components/mui-wrappers/animations/motion/SlideFromRight'
import { Player } from '@lottiefiles/react-lottie-player'
import useGetInternalErrorTemplate from '~/src/pages/errors/internal-error/styles/useGetInternalErrorTemplate'
import { IndexDBService } from '@eo-storefronts/eo-core'
import EnvUtils from '~/src/utils/EnvUtils'

const InternalError = () => {
  const pageName: keyof typeof RoutesEnum = 'ERROR'
  const { t } = useTranslations()
  const styles = useGetInternalErrorTemplate()
  const indexDbService = new IndexDBService()

  useEffect(() => {
    try {
      // Simulate a 500 error
      throw new Error('[PAGE] - 500 - Internal error')
    } catch (error) {
      // Log the error to Sentry
      // Make sure you have Sentry initialized in your application
      if (EnvUtils.isSentryEnabled) {
        Sentry.captureException(error)
      }
    }
  }, [])

  const _handleRefresh = async () => {
    await indexDbService.deleteDatabase()
  }

  return (
    <SlideFromRight
      pathname={pageName}
      transition={{
        duration: 0.5
      }}>
      <DocumentTitleInjector pageName={pageName}/>

      <Box sx={styles.getPageSx()}>
        <Container sx={styles.getContainerSx()}>
          <Player
            src='/images/lotties/500.json'
            autoplay
            loop
            style={{ width: '100%' }}/>

          <Title
            variant='h4'
            sx={styles.getTitleSx()}>
            {t('500.title')}
          </Title>

          <Typography
            component='p'
            sx={{
              whiteSpace: 'break-spaces'
            }}>
            {t('500.description')}
          </Typography>

          <Link
            href='/'
            onClick={_handleRefresh}>
            <Button
              color='primary'
              variant='contained'
              fullWidth
              startIcon={<RefreshIcon/>}
              sx={styles.getContainerActionButtonSx()}>
              {t('500.callToAction')}
            </Button>
          </Link>
        </Container>
      </Box>
    </SlideFromRight>
  )
}

export default InternalError
