import getProductListTemplateService from '~/src/components/products/product-list/styles/GetProductListTemplateService'
import StylesProductListServiceInterface
  from '~/src/components/products/product-list/styles/StylesProductListServiceInterface'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { useEoValue } from '~/src/hooks/useEoState'
import { FIRM_THEME_SELECTOR } from '~/src/stores/firm'

const useGetProductListTemplate = (): StylesProductListServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const firmTheme = useEoValue(FIRM_THEME_SELECTOR)

  return getProductListTemplateService(firmTheme?.components?.productsList, muiTheme)
}

export default useGetProductListTemplate
