import { isRouteErrorResponse, useRouteError } from 'react-router-dom'
import NotFoundPage from '~/src/pages/errors/not-found'
import InternalErrorPage from '~/src/pages/errors/internal-error'

const ErrorBoundary = () => {
  const error = useRouteError()

  if (isRouteErrorResponse(error) && (error.status === 404 || error.status === 400)) {
    return <NotFoundPage showCta={error.status === 404} />
  }

  return <InternalErrorPage />
}

export default ErrorBoundary
