import { Box } from '@mui/material'
import Title from '~/src/components/mui-wrappers/Title'
import FirmLoyaltyRewadCard from '~/src/components/profile/account/loyalty/reward-card'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { LOYALTY_IS_ENABLED_SELECTOR, LOYALTY_IS_POINT_THRESHOLD_SELECTOR } from '~/src/stores/loyalty'

const FirmLoyaltyRewards = () => {
  const { t } = useTranslations()
  const isLoyaltyEnabled = useEoValue(LOYALTY_IS_ENABLED_SELECTOR)
  const isLoyaltyPointThreshold = useEoValue(LOYALTY_IS_POINT_THRESHOLD_SELECTOR)

  if (!isLoyaltyEnabled || !isLoyaltyPointThreshold) {
    return null
  }

  return (
    <Box>
      <Title color='primary'>
        {t('profile.loyalty.rewards')}
      </Title>
      <Box sx={{ mt: 1 }}>
        <FirmLoyaltyRewadCard/>
      </Box>
    </Box>
  )
}

export default FirmLoyaltyRewards
