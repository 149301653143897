import { useEoValue } from '~/src/hooks/useEoState'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { Firm } from '@eo-storefronts/eo-core'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import TextField from '~/src/components/mui-wrappers/styled/text-field'
import { inputBaseClasses, Typography } from '@mui/material'
import { useRouterPush } from '~/src/hooks/router/useRouterPush'
import { useContext } from 'react'
import SideDrawerContext, { SideDrawerProps } from '~/src/components/layout/side-drawer/SideDrawerContext'
import { LANGUAGE_STATE } from '~/src/stores/lang'
import { BRAND_PAGE_SELECTOR } from '~/src/stores/router'
import { BRAND_STATE } from '~/src/stores/brand'

const SideDrawerFirmBoxTitle = () => {
  const firm = useEoValue<Firm | null>(FIRM_SELECTOR)
  const brand = useEoValue<Firm | null>(BRAND_STATE)
  const { push } = useRouterPush()
  const sideDrawerContext = useContext<SideDrawerProps>(SideDrawerContext)
  const lang = useEoValue(LANGUAGE_STATE)
  const brandPage = useEoValue(BRAND_PAGE_SELECTOR)

  const _handleOnClick = () => {
    sideDrawerContext.open && sideDrawerContext.onToggle()
    push(`/${lang}${brandPage}`)
  }

  if (!brand?.isMultifirm) {
    return (
      <Typography variant='body1'>{brand?.name[lang]}</Typography>
    )
  }

  if (brand.locations?.length === 1) {
    return (
      <Typography variant='body1'>{firm?.name[lang]}</Typography>
    )
  }

  return (
    <TextField
      size='small'
      onClick={_handleOnClick}
      value={firm?.name[lang]}
      fullWidth
      sx={{
        cursor: 'pointer',
        [`> .${inputBaseClasses.root}`]: {
          cursor: 'pointer',
          borderRadius: 2,
          pr: 1,
          input: {
            cursor: 'pointer',
            pl: 1
          }
        }
      }}
      InputProps={{
        readOnly: true,
        endAdornment: <ExpandMoreIcon/>
      }}
    />
  )
}

export default SideDrawerFirmBoxTitle
