import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '@mui/icons-material/Close'
import { IconButton, InputBase, InputBaseProps, styled } from '@mui/material'
import { FocusEvent, useRef, useState } from 'react'
import useGetExpandableSearchButtonTemplate
  from '~/src/components/mui-wrappers/styled/expandable-search-button/styles/useGetExpandableSearchButtonTemplate'

interface Props extends Omit<InputBaseProps, 'ref' | 'type' | 'inputMode' | 'startAdornment' | 'endAdornment'> {
  collapseOnBlur?: boolean,
  onClose?(): void,
}

const ExpandableSearchButton = styled(({
  collapseOnBlur,
  name,
  placeholder,
  className,
  onBlur,
  onClose,
  ...otherProps
}: Props) => {
  const inputRef = useRef<HTMLDivElement>()
  const [ expanded, setExpanded ] = useState<boolean>(false)
  const [ hideStartAdornment, setHideStartAdornment ] = useState<boolean>(false)
  const [ hideEndAdornment, setHideEndAdornment ] = useState<boolean>(false)

  const _delayedShowAdornment = (expand: boolean) => {
    if (!expand) {
      setTimeout(() => {
        setHideStartAdornment(false)
        setHideEndAdornment(true)
      }, 150)

      return
    }

    setTimeout(() => {
      setHideStartAdornment(true)
    }, 50)
    setTimeout(() => {
      setHideEndAdornment(false)
    }, 150)
  }

  const _handleOnCollapse = () => {
    setExpanded(false)
    _delayedShowAdornment(false)
    onClose && onClose()
  }

  const _handleOnExpand = () => {
    setExpanded(true)
    _delayedShowAdornment(true)

    if (!inputRef.current) {
      return
    }

    const input: Element | null = inputRef.current.children.item(1)

    if (!input) {
      return
    }

    (input as HTMLInputElement).focus({ preventScroll: true })
  }

  const _handleOnBlur = ($event: FocusEvent<HTMLInputElement>) => {
    if (collapseOnBlur) {
      _handleOnCollapse()
    }

    onBlur && onBlur($event)
  }

  return (
    <InputBase
      ref={inputRef}
      className={`${className ?? ''} ${expanded ? 'expanded' : 'collapsed'}`}
      placeholder={placeholder}
      name={name}
      type='search'
      inputMode='search'
      onBlur={_handleOnBlur}
      startAdornment={
        <IconButton
          disableRipple
          onClick={_handleOnExpand}
          color='inherit'
          sx={{
            display: hideStartAdornment ? 'none' : 'flex',
            borderRadius: 0
          }}>
          <SearchIcon />
        </IconButton>
      }
      endAdornment={
        <IconButton
          disableRipple
          onClick={_handleOnCollapse}
          color='inherit'
          sx={{
            display: hideEndAdornment ? 'none' : 'flex'
          }}>
          <CloseIcon />
        </IconButton>
      }
      {...otherProps}
    />
  )
})(() => useGetExpandableSearchButtonTemplate().getComponentSx())

export default ExpandableSearchButton
