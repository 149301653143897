import { Typography } from '@mui/material'
import { useEoValue } from '~/src/hooks/useEoState'
import { ModifierGroup } from '@eo-storefronts/eo-core'
import { MODIFIER_GROUPS_STATE } from '~/src/stores/modifier-groups'
import { CartProductModifierStateInterface } from '~/src/stores/cart/cart-product-modifier-groups'

interface Props {
  cartModifier: CartProductModifierStateInterface,
  groupId: string,
}

const CartModifierInlineQuantity = ({ groupId, cartModifier }: Props) => {
  const modifierGroups = useEoValue<Record<string, ModifierGroup>>(MODIFIER_GROUPS_STATE)

  if (!modifierGroups[groupId].isMultipleChoice) {
    return null
  }

  return (
    <Typography variant='body2'>
      {cartModifier.quantity}<small>x</small>
    </Typography>
  )
}

export default CartModifierInlineQuantity
