import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import useGetComponentStyle from '~/src/hooks/layout/useGetComponentStyle'
import StylesOrderingMethodsSummaryMainButtonsServiceInterface
  from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-main-buttons/styles/StylesOrderingMethodsSummaryMainButtonsServiceInterface'
import getOrderingMethodsSummaryMainButtonsTemplateService
  from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-main-buttons/styles/GetOrderingMethodsSummaryMainButtonsTemplateService'

const useGetOrderingMethodsSummaryMainButtonsTemplate = (): StylesOrderingMethodsSummaryMainButtonsServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const componentStyle = useGetComponentStyle('orderingMethodsSummary')

  return getOrderingMethodsSummaryMainButtonsTemplateService(componentStyle, muiTheme)
}

export default useGetOrderingMethodsSummaryMainButtonsTemplate
