import { useEoValue } from '~/src/hooks/useEoState'
import useFormFields from '~/src/hooks/forms/useFormFields'
import { GUEST_FORM_FIELDS_SELECTOR } from '~/src/stores/guest-form'

const useGuestFormFields = () => {
  const formFields = useEoValue(GUEST_FORM_FIELDS_SELECTOR)

  return useFormFields(formFields)
}

export default useGuestFormFields
