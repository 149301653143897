import { OrderingMethodNames } from '@eo-storefronts/eo-core'
import OrderingMethodsSummaryMethodSentenceSplitter from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-method/ordering-methods-summary-method-sentence/OrderingMethodsSummaryMethodSentenceSplitter'
import { useEatInLocationInline } from '~/src/hooks/ordering-methods/useEatInLocationInline'
import { useEoValue } from '~/src/hooks/useEoState'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'

interface Props {
  showSplitter?: boolean,
}

const OrderingMethodsSummaryMethodSentenceOnTheSpot = ({ showSplitter = true }: Props) => {
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)
  const { sentence } = useEatInLocationInline()

  if (checkoutForm.orderingMethod.method !== OrderingMethodNames.ON_THE_SPOT) {
    return null
  }

  return (
    <span>
      {showSplitter && <OrderingMethodsSummaryMethodSentenceSplitter/>}
      {sentence}
    </span>
  )
}

export default OrderingMethodsSummaryMethodSentenceOnTheSpot
