import { useCallback } from 'react'
import { useEoValue } from '~/src/hooks/useEoState'
import routesEnum from '~/src/router/enums/routes.enum'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { IS_MARKETPLACE_SELECTOR } from '~/src/stores/brand'
import { useParams } from 'react-router-dom'

interface ReturnsType {
  resolve(path: string): string,
}

const useFirmPathResolver = (): ReturnsType => {
  const firm = useEoValue(FIRM_SELECTOR)
  const params = useParams()
  const isMarketplace = useEoValue(IS_MARKETPLACE_SELECTOR)

  const resolve = useCallback((path: string): string => {
    if (!params.shopName && !params.lang) { // GLOBAL FALLBACK
      return '/'
    }

    if (path === routesEnum.FIRM_HOME) {
      if (params.shopName) { // from an inner location going to firm-home => /shopName/lang/menu
        return `/${params.shopName}/${params.lang}${routesEnum.MENU}`
      }

      if (isMarketplace) { // from a marketplace going to firm-home => /lang/explorer
        return `/${params.lang}${routesEnum.EXPLORER}`
      }

      // from anywhere else, the default will go back to /lang
      return `/${params.lang}`
    }

    if (params.shopName) {
      return `/${params.shopName}/${params.lang}${path}`
    }

    return `/${params.lang}${path}`
  }, [ params, firm ])

  return { resolve }
}

export {
  useFirmPathResolver
}
