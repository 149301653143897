import { Product } from '@eo-storefronts/eo-core'
import { useEffect, useState } from 'react'
import { useCategoryAvailable } from '~/src/hooks/categories/useCategoryAvailable'
import { useProductAvailableState } from '~/src/hooks/products/useProductAvailableState'
import { FIRM_WITH_LOCATOR_STATE_SELECTOR } from '~/src/stores/firm'
import { useEoValue } from '~/src/hooks/useEoState'

interface ReturnsType {
  available: boolean,
}

const useProductAvailable = (product: Product): ReturnsType => {
  const firm = useEoValue(FIRM_WITH_LOCATOR_STATE_SELECTOR)
  const { available: categoryAvailable } = useCategoryAvailable(product.categoryId)
  const { state: productAvailableState } = useProductAvailableState(product)
  const [ available, setAvailable ] = useState<boolean>(true)

  useEffect(() => {
    setAvailable(categoryAvailable && productAvailableState.inStock && !productAvailableState.snoozed && !firm?.settings.isOffline)
  }, [ productAvailableState, categoryAvailable, firm ])

  return { available }
}

export {
  useProductAvailable
}
