import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'
import StylesOrderingMethodsSummaryContentServiceStyleTwo
  from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-content/styles/StylesOrderingMethodsSummaryContentServiceStyleTwo'
import StylesOrderingMethodsSummaryContentServiceStyleOne
  from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-content/styles/StylesOrderingMethodsSummaryContentServiceStyleOne'
import StylesOrderingMethodsSummaryContentServiceInterface
  from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-content/styles/StylesOrderingMethodsSummaryContentServiceInterface'

const getOrderingMethodsSummaryContentTemplateService = (style: TemplateEnum | null | undefined, muiTheme: CustomMuiTheme): StylesOrderingMethodsSummaryContentServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesOrderingMethodsSummaryContentServiceStyleTwo(muiTheme)
    default:
      return new StylesOrderingMethodsSummaryContentServiceStyleOne(muiTheme)
  }
}

export default getOrderingMethodsSummaryContentTemplateService
