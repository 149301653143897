import CustomerLoyaltyCurrentVoucherBalance from '~/src/components/profile/account/loyalty/current-voucher-balance'
import { useEoValue } from '~/src/hooks/useEoState'
import { LOYALTY_IS_ENABLED_AND_CUSTOMER_IS_LOGGED_IN_SELECTOR, LOYALTY_IS_POINT_THRESHOLD_SELECTOR } from '~/src/stores/loyalty'
import CustomerLoyaltyPointsTresholdCurrentBalance
  from '~/src/components/profile/account/loyalty/current-balance/CustomerLoyaltyPointsTresholdCurrentBalance'

const CustomerLoyaltyCurrentBalance = () => {
  const canShow = useEoValue(LOYALTY_IS_ENABLED_AND_CUSTOMER_IS_LOGGED_IN_SELECTOR)
  const isLoyaltyPointThreshold = useEoValue(LOYALTY_IS_POINT_THRESHOLD_SELECTOR)

  if (!canShow) {
    return null
  }

  if (!isLoyaltyPointThreshold) {
    return <CustomerLoyaltyCurrentVoucherBalance />
  }

  return <CustomerLoyaltyPointsTresholdCurrentBalance/>
}

export default CustomerLoyaltyCurrentBalance
