import { DateUtils, OrderingMethodNames, Firm, Timeslot } from '@eo-storefronts/eo-core'
import { useEoValue } from '~/src/hooks/useEoState'
import { FIRM_WITH_LOCATOR_STATE_SELECTOR } from '~/src/stores/firm'
import { CHECKOUT_FORM_STATE, CheckoutFormState } from '~/src/stores/checkout'

interface Props {
  timeslot: Timeslot,
}

const OrderingMethodsTimeslotOption = ({ timeslot }: Props) => {
  const firm = useEoValue<Firm|null>(FIRM_WITH_LOCATOR_STATE_SELECTOR)
  const checkoutForm = useEoValue<CheckoutFormState>(CHECKOUT_FORM_STATE)

  if (!firm || !checkoutForm.orderingMethod.method) {
    return null
  }

  if (
    checkoutForm.orderingMethod.method !== OrderingMethodNames.ON_THE_SPOT
    && firm.settings.orderingMethods[checkoutForm.orderingMethod.method]?.ordersOnlyDuringOperationalHours
    && !DateUtils.timeIsBetween(
      DateUtils.formatTime(new Date()),
      {
        start: timeslot.fromTime,
        end: timeslot.toTime
      }
    )
  ) {
    return null
  }

  return (
    <option value={timeslot.id}>{`${timeslot.fromTime} - ${timeslot.toTime}`}</option>
  )
}

export default OrderingMethodsTimeslotOption
