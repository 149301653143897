import { Box } from '@mui/material'
import OrderingMethodsSummaryContainer
  from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-container'
import OrderingMethodsResponsiveDialog from '~/src/components/ordering-methods/OrderingMethodsResponsiveDialog'

const OrderingMethodsSummary = () => (
  <OrderingMethodsResponsiveDialog
    activator={
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          alignItems: 'center',
          justifyContent: 'center',
          py: 2
        }}
      >
        <OrderingMethodsSummaryContainer />
      </Box>
    }
  />
)

export default OrderingMethodsSummary
