import { DateUtils, OrderingMethod, OrderingMethodNames, Interval, OpeningHour } from '@eo-storefronts/eo-core'
import { useCanOrderOnDay } from '~/src/hooks/ordering-methods/useCanOrderOnDay'
import { useEoState, useEoValue } from '~/src/hooks/useEoState'
import { getFirstAvailableHourForPeriod } from '~/src/services/OpeningHourService'
import { CHECKOUT_FORM_STATE, CheckoutFormState } from '~/src/stores/checkout'
import { FIRM_WITH_LOCATOR_STATE_SELECTOR } from '~/src/stores/firm'

interface ReturnsType {
  updateCheckoutTime(date: Date): void,
  updateCheckoutTimeWithInterval(interval: Interval): void,
}

const useUpdateCheckoutTime = (): ReturnsType => {
  const firm = useEoValue(FIRM_WITH_LOCATOR_STATE_SELECTOR)
  const [ checkoutForm, setCheckoutForm ] = useEoState(CHECKOUT_FORM_STATE)
  const { canOrderOnDay } = useCanOrderOnDay()

  const updateCheckoutTime = (date: Date) => {
    const dayId = DateUtils.getDayIdLikeMomentJs(date)
    let time = checkoutForm.orderingMethod.minDate

    const orderingMethod = firm!.settings.orderingMethods[checkoutForm.orderingMethod.method!] as OrderingMethod

    if (!canOrderOnDay(date)) {
      return
    }

    if (orderingMethod.orderTimeslots.active) {
      time = DateUtils.momentFormat(date)
    } else if (
      time
      && DateUtils.momentFormat(date, 'DM') !== DateUtils.momentFormat(new Date(time), 'DM')
    ) {
      const hoursKey = checkoutForm.orderingMethod.method === OrderingMethodNames.DELIVERY ? 'deliveryHours' : 'pickupHours'
      const period = firm!.settings.periods[hoursKey].find((p: OpeningHour) => p.dayId === dayId)

      if (!period) {
        return
      }

      const fromTime = getFirstAvailableHourForPeriod(period)

      const [ hours, minutes ] = fromTime.split(':')
      date.setHours(Number(hours))
      date.setMinutes(Number(minutes))

      time = DateUtils.momentFormat(date)
    }

    setCheckoutForm((state: CheckoutFormState) => ({
      ...state,
      orderingMethod: {
        ...state.orderingMethod,
        time
      }
    }))
  }

  const updateCheckoutTimeWithInterval = (interval: Interval) => {
    if (!checkoutForm.orderingMethod.time) {
      return
    }

    const [ hours, minutes ] = interval.time.split(':')
    const date = new Date(checkoutForm.orderingMethod.time)
    date.setHours(Number(hours))
    date.setMinutes(Number(minutes))

    setCheckoutForm((state: CheckoutFormState) => ({
      ...state,
      orderingMethod: {
        ...state.orderingMethod,
        time: DateUtils.momentFormat(date)
      }
    }))
  }

  return {
    updateCheckoutTime,
    updateCheckoutTimeWithInterval
  }
}

export {
  useUpdateCheckoutTime
}
