import { useEoValue, useSetEoState } from '~/src/hooks/useEoState'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { useAsyncEffect } from '~/src/hooks/useAsyncEffect'
import { fetchAnnouncements } from '~/src/services/FirmService'
import { AnnouncementsService, GetAnnouncementsResponse } from '@eo-storefronts/eo-core'
import { ANNOUNCEMENTS } from '~/src/stores/announcements'
import { useLocation } from 'react-router-dom'
import RoutesEnum from '~/src/router/enums/routes.enum'
import { useRef } from 'react'

const useFetchAnnouncementsEffect = () => {
  const currentFirm = useEoValue(FIRM_SELECTOR)
  const setAnnouncements = useSetEoState(ANNOUNCEMENTS)
  const { pathname } = useLocation()
  const triggeredOnce = useRef<boolean>(false)

  useAsyncEffect(async () => {
    if (
      !currentFirm
      || triggeredOnce.current
      || pathname.includes(RoutesEnum.IN_APP_UPDATE)
      || pathname.includes(RoutesEnum.FORCE_UPDATE)
    ) {
      return
    }

    triggeredOnce.current = true

    const announcements: GetAnnouncementsResponse|undefined = await fetchAnnouncements(currentFirm.id)

    if (!announcements || !announcements.data?.length) {
      return
    }

    const announcementsService = new AnnouncementsService(announcements.data)
    const finalAnnouncements = await announcementsService.getUnseenAnnouncements()

    setAnnouncements(finalAnnouncements)
  }, [ currentFirm, pathname ])
}

export default useFetchAnnouncementsEffect
