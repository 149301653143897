import { createSelector } from '~/src/hooks/useEoState'
import { Firm, FormField, OrderingMethodNames } from '@eo-storefronts/eo-core'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { CHECKOUT_DELIVERY_METHOD_SELECTOR } from '~/src/stores/checkout'

const GUEST_FORM_FIELDS_SELECTOR = createSelector<FormField[]>({
  key: 'guestFormFieldsSelector',
  get: ({ get }) => {
    const firm = get<Firm | null>(FIRM_SELECTOR)
    const orderingMethodSelected = get<OrderingMethodNames | null>(CHECKOUT_DELIVERY_METHOD_SELECTOR)

    if (!firm ||
      !orderingMethodSelected ||
      !firm.settings.orderingMethods[orderingMethodSelected]?.guestFields?.length) {
      return []
    }

    return firm.settings.orderingMethods[orderingMethodSelected]!.guestFields
  }
})


export {
  GUEST_FORM_FIELDS_SELECTOR
}
