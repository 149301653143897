import { App } from '@capacitor/app'
import { EoInjectable, FindFirmArgType, PlatformUtils } from '@eo-storefronts/eo-core'
import Environment from '~/src/config/environment'
import EnvUtils from '~/src/utils/EnvUtils'

@EoInjectable()
export default class HostResolver {
  public async resolve(host: string = location.host): Promise<FindFirmArgType> {
    if (process.env.NODE_ENV === 'screenshots') {
      return { applicationId: import.meta.env.APP_ID }
    }

    if (EnvUtils.isDeviceWeb && process.env.NODE_ENV !== 'development') {
      return { host }
    }

    if (PlatformUtils().isCapacitorNative) {
      const { id } = await App.getInfo()
      return { applicationId: id }
    }

    if (window?.Cypress) {
      return { host: 'localhost' }
    }

    return { host: Environment.getLocalhost() }
  }
}
