import { Box } from '@mui/material'
import NextExpiringVoucher from '~/src/components/profile/account/loyalty/next-expiring-voucher/NextExpiringVoucher'
import { useTranslations } from '~/src/hooks/useTranslations'
import { useEoValue } from '~/src/hooks/useEoState'
import { Currency } from '@eo-storefronts/eo-core'
import { FIRM_CURRENCY_SELECTOR } from '~/src/stores/firm'
import {
  CUSTOMER_LOYALTY_POINT_TRESHOLD_NEXT_EXPIRING_VOUCHER_SELECTOR_FAMILY,
  CUSTOMER_LOYALTY_VOUCHERS_BALANCE_SELECTOR
} from '~/src/stores/customer'

const CustomerLoyaltyCurrentVoucherBalancePointsTresholdLabel = () => {
  const { t } = useTranslations()
  const firmCurrency = useEoValue<Currency | undefined>(FIRM_CURRENCY_SELECTOR)
  const customerLoyaltyVouchersValue = useEoValue(CUSTOMER_LOYALTY_VOUCHERS_BALANCE_SELECTOR)
  const nextValueVoucherExpiring = useEoValue(CUSTOMER_LOYALTY_POINT_TRESHOLD_NEXT_EXPIRING_VOUCHER_SELECTOR_FAMILY('value'))

  return (
    <>
      <Box
        component='span'
        sx={{
          fontSize: '1rem'
        }}>
        <Box component='b'>
          {firmCurrency?.symbol}{customerLoyaltyVouchersValue}
        </Box>
        &nbsp;
        {t('profile.loyalty.voucherBalance').toLowerCase()}
      </Box>

      <NextExpiringVoucher voucher={nextValueVoucherExpiring} />
    </>
  )
}

export default CustomerLoyaltyCurrentVoucherBalancePointsTresholdLabel
