import { LanguageType, PriceList, Price as PriceModel, Product } from '@eo-storefronts/eo-core'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { Box, IconButton, Typography } from '@mui/material'
import CartItemModifierGroups from '~/src/components/cart/CartItemModifierGroups'
import ShoppingCartItemDiscounts from '~/src/components/cart/shopping-cart-item/ShoppingCartItemDiscounts'
import useGetShoppingCartItemTemplate from '~/src/components/cart/shopping-cart-item/styles/useGetShoppingCartItemTemplate'
import Price from '~/src/components/Price'
import ProductLoyaltyValue from '~/src/components/products/loyalty-value'
import ProductQuantity from '~/src/components/products/ProductQuantity'
import QuantitySelector from '~/src/components/products/QuantitySelector'
import PriceHelper from '~/src/helpers/priceHelper'
import { useRemoveProductOfCart } from '~/src/hooks/cart/useRemoveProductOfCart'
import { useUpdateCartProducts } from '~/src/hooks/cart/useUpdateCartProducts'
import { useEoValue } from '~/src/hooks/useEoState'
import { convertProductUnitKgInGr, getProductBasePrice, getProductPrice } from '~/src/services/ProductService'
import { LANGUAGE_STATE } from '~/src/stores/lang'
import { PRODUCT_BY_ID_SELECTOR, PRODUCT_COUPON } from '~/src/stores/product'
import { PRICE_LIST_STATE } from '~/src/stores/product/price-list'
import { CartProduct } from '~/src/types/CartProduct'
import NumberUtils from '~/src/utils/NumberUtils'

interface Props {
  cartProduct: CartProduct,
  showIngredientsAndOptions?: boolean,
  showQuantityAsText?: boolean,
  showQuantitySelector?: boolean,
  showPrice?: boolean,
  showDeleteButton?: boolean,
}

const ShoppingCartItem = (
  {
    cartProduct,
    showIngredientsAndOptions = false,
    showQuantityAsText = false,
    showDeleteButton = true,
    showPrice = true,
    showQuantitySelector = true
  }: Props
) => {
  const styles = useGetShoppingCartItemTemplate()
  const { updateQuantity } = useUpdateCartProducts()
  const { remove } = useRemoveProductOfCart()
  const product = useEoValue<Product | null>(PRODUCT_BY_ID_SELECTOR(cartProduct.id))
  const lang = useEoValue<LanguageType | string>(LANGUAGE_STATE)
  const { max, min, unitInterval } = convertProductUnitKgInGr(product)
  const productCoupon = useEoValue(PRODUCT_COUPON(cartProduct.id))
  const priceLists: PriceList[] = useEoValue(PRICE_LIST_STATE)

  const handleUpdateQuantity = (value: number) => {
    updateQuantity(cartProduct, value)
  }

  const handleRemoveProductOfCart = () => {
    remove(cartProduct)
  }

  const calculateTotalPrice = (): PriceModel => {
    if (!product) {
      return PriceHelper.transform(0)
    }

    let productPrice: PriceModel = getProductPrice(product, priceLists)

    Object.keys(productPrice).forEach((key) => {
      const objectKey: keyof PriceModel = key as keyof PriceModel
      let value: number = cartProduct.quantity * NumberUtils.round(productPrice[objectKey], 2)

      if (productCoupon && productCoupon.value && cartProduct.isCoupon) {
        value -= productCoupon.value
      }

      productPrice = {
        ...productPrice,
        [objectKey]: value
      }
    })

    return productPrice
  }

  const calculateTotalBasePrice = (): PriceModel => {
    if (!product) {
      return PriceHelper.transform(0)
    }

    let productPrice: PriceModel = getProductBasePrice(product, priceLists)

    Object.keys(productPrice).forEach((key) => {
      const objectKey: keyof PriceModel = key as keyof PriceModel
      let value: number = cartProduct.quantity * NumberUtils.round(productPrice[objectKey], 2)

      if (productCoupon && productCoupon.value && cartProduct.isCoupon) {
        value -= productCoupon.value
      }

      productPrice = {
        ...productPrice,
        [objectKey]: value
      }
    })

    return productPrice
  }

  const numberOfElementDisplayed = (): number => {
    let amount = 1

    if (showPrice) {
      amount++
    }
    if (showQuantityAsText) {
      amount++
    }
    if (showDeleteButton) {
      amount++
    }
    if (showQuantitySelector) {
      amount++
    }

    return amount
  }

  return (
    <Box sx={styles.getContainerSxProps()}>
      <Box>
        <Box sx={styles.getProductLabelSxProps()}>
          {showQuantityAsText && (
            <ProductQuantity
              quantity={cartProduct.quantity}
              unit={product?.unit}
            />
          )}
          <Typography
            component='p'
            variant='h6'
            sx={styles.getProductNameSxProps()}
          >
            {product?.name[lang]}
          </Typography>
          <ProductLoyaltyValue
            product={product}
            quantity={cartProduct.quantity}/>
        </Box>
        <ShoppingCartItemDiscounts cartProduct={cartProduct}/>
      </Box>

      {showQuantitySelector && (
        <QuantitySelector
          sx={styles.getQuantitySelectorSxProps(cartProduct.isCoupon)}
          quantity={cartProduct.quantity}
          onUpdate={handleUpdateQuantity}
          unitInterval={unitInterval}
          unit={product?.unit}
          min={min}
          max={max}
        />
      )}

      {showPrice && (
        <Box
          component='span'
          sx={styles.getPriceContainerSxProps()}
        >
          <Price
            price={calculateTotalPrice()}
            basePrice={calculateTotalBasePrice()}
            displayZeroAsFree
          />
        </Box>
      )}

      {showDeleteButton && (
        <IconButton
          onClick={handleRemoveProductOfCart}
          sx={styles.getDeleteButtonSxProps()}
        >
          <DeleteOutlinedIcon/>
        </IconButton>
      )}

      {showIngredientsAndOptions && (
        <Box
          sx={styles.getModifiersSxProps(numberOfElementDisplayed())}
        >
          <CartItemModifierGroups
            cartProduct={cartProduct}
            displayTitles={false}
          />
          {cartProduct.comment !== '' && (
            <Typography
              variant='body2'
              sx={{
                ml: 1,
                fontStyle: 'italic'
              }}
            >
              {cartProduct.comment}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  )
}

export default ShoppingCartItem
