import { Product } from '@eo-storefronts/eo-core'
import { Box } from '@mui/material'
import useGetProductDetailsTitleTemplate
  from '~/src/components/products/product-details/product-details-title/styles/useGetProductDetailsTitleTemplate'
import ProductTitle from '~/src/components/products/ProductTitle'
import ProductLoyaltyValue from '~/src/components/products/loyalty-value'

interface Props {
  product: Product,
}

const ProductDetailsTitle = ({ product }: Props) => {
  const styles = useGetProductDetailsTitleTemplate()
  
  return (
    <Box
      className='product-detail__title_container'
      sx={styles.getProductTitleContainerSxProps()}
    >
      <ProductTitle product={product}/>
      <ProductLoyaltyValue product={product} size='large'  />
    </Box>
  )
}

export default ProductDetailsTitle
