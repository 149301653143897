import { OrderingMethodNames, Id, PickupPoint } from '@eo-storefronts/eo-core'
import { MenuItem, TextFieldProps } from '@mui/material'
import { ChangeEvent, useEffect } from 'react'
import DeliveryPickupPoint from '~/src/components/ordering-methods/DeliveryPickupPoint'
import DeliveryPickupPointWithDetails from '~/src/components/ordering-methods/DeliveryPickupPointWithDetails'
import TextField from '~/src/components/mui-wrappers/styled/text-field'
import { useEoState, useEoValue } from '~/src/hooks/useEoState'
import { CHECKOUT_FORM_STATE, CheckoutFormState } from '~/src/stores/checkout'
import { FIRM_WITH_LOCATOR_STATE_SELECTOR } from '~/src/stores/firm'
import TextFieldSize from '~/src/types/TextiFieldSize'

interface Props extends Pick<TextFieldProps, 'SelectProps' | 'sx' | 'color' | 'variant' | 'fullWidth' | 'onClick'> {
  isLoading?: boolean,
  size?: TextFieldSize,
}

const DeliveryPickupPoints = (
  {
    SelectProps = {
      native: true
    },
    sx = {},
    color = 'container',
    size = 'small',
    variant = 'outlined',
    fullWidth = false,
    isLoading = false,
    onClick
  }: Props) => {
  const firm = useEoValue(FIRM_WITH_LOCATOR_STATE_SELECTOR)
  const [ checkoutForm, setCheckoutForm ] = useEoState(CHECKOUT_FORM_STATE)

  const _updatePickupPoint = (pickupPointId: Id) => {
    const pickupPoint = firm!.settings.orderingMethods[OrderingMethodNames.PICKUP]?.pickupPoints.find((pp: PickupPoint) => Number(pp.id) === Number(pickupPointId))

    setCheckoutForm((state: CheckoutFormState) => ({
      ...state,
      orderingMethod: {
        ...state.orderingMethod,
        timeslotId: null,
        pickupPoint: pickupPoint || null
      }
    }))
  }

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    _updatePickupPoint(event.target.value)
  }

  useEffect(() => {
    if (
      !firm
      || !firm.settings.orderingMethods[OrderingMethodNames.PICKUP]?.pickupPoints?.length
      || checkoutForm.orderingMethod.pickupPoint !== null
    ) {
      return
    }

    const mainPickupPoint = firm.settings.orderingMethods[OrderingMethodNames.PICKUP]?.pickupPoints.find((pp: PickupPoint) => pp.mainBranch)

    _updatePickupPoint(mainPickupPoint ? mainPickupPoint.id : firm.settings.orderingMethods[OrderingMethodNames.PICKUP]!.pickupPoints[0].id)
  }, [ firm ])

  if (!firm || !firm?.settings.orderingMethods[OrderingMethodNames.PICKUP]?.pickupPoints?.length) {
    return null
  }

  return (
    <TextField
      select
      size={size}
      disabled={isLoading}
      SelectProps={SelectProps}
      fullWidth={fullWidth}
      color={color}
      variant={variant}
      onClick={onClick}
      onChange={handleOnChange}
      value={checkoutForm.orderingMethod.pickupPoint?.id || 0}
      sx={sx}
    >
      {firm.settings.orderingMethods[OrderingMethodNames.PICKUP]?.pickupPoints.map((point: PickupPoint) => {
        if (SelectProps.native) {
          return (
            <DeliveryPickupPoint key={point.id} point={point} />
          )
        }

        return (
          <MenuItem
            key={point.id}
            value={point.id}
          >
            <DeliveryPickupPointWithDetails point={point} />
          </MenuItem>
        )
      })}
    </TextField>
  )
}

export default DeliveryPickupPoints
