import { Box } from '@mui/material'
import NextExpiringVoucher from '~/src/components/profile/account/loyalty/next-expiring-voucher/NextExpiringVoucher'
import CustomerLoyaltyCurrentPointsLabel
  from '~/src/components/profile/account/loyalty/current-points-balance/CustomerLoyaltyCurrentPointsLabel'
import { useEoValue } from '~/src/hooks/useEoState'
import { CustomerLoyaltyVoucher } from '@eo-storefronts/eo-core'
import { CUSTOMER_LOYALTY_NEXT_EXPIRING_VOUCHER_SELECTOR } from '~/src/stores/customer'

const CustomerLoyaltyCurrentVoucherBalancePointsLabel = () => {
  const customerLoyaltyNextExpiringVoucher = useEoValue<CustomerLoyaltyVoucher | undefined>(CUSTOMER_LOYALTY_NEXT_EXPIRING_VOUCHER_SELECTOR)

  return (
    <>
      <Box
        component='span'
        sx={{
          fontSize: '1rem'
        }}>
        <CustomerLoyaltyCurrentPointsLabel/>
      </Box>

      <NextExpiringVoucher voucher={customerLoyaltyNextExpiringVoucher}/>
    </>
  )
}

export default CustomerLoyaltyCurrentVoucherBalancePointsLabel
