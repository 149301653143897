import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import StylesOrderingMethodsSummaryServiceInterface
  from '~/src/components/ordering-methods/ordering-methods-summary/styles/StylesOrderingMethodsSummaryServiceInterface'
import getOrderingMethodsSummaryTemplateService
  from '~/src/components/ordering-methods/ordering-methods-summary/styles/GetOrderingMethodsSummaryTemplateService'
import useGetComponentStyle from '~/src/hooks/layout/useGetComponentStyle'

const useGetOrderingMethodsSummaryTemplate = (): StylesOrderingMethodsSummaryServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const componentStyle = useGetComponentStyle('orderingMethodsSummary')

  return getOrderingMethodsSummaryTemplateService(componentStyle, muiTheme)
}

export default useGetOrderingMethodsSummaryTemplate
