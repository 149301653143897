import { Location, LocationToFirm } from '@eo-storefronts/eo-core'
import { Box, chipClasses, Skeleton } from '@mui/material'
import FirmTimeslots from '~/src/components/firms/FirmTimeslots'
import { AppInjector } from '~/src/utils/DependenciesInjectionUtils'

interface Props {
  location?: Location,
  isLoading?: boolean,
}

const LocationTimeslots = ({ isLoading = false, location }: Props) => {
  if (isLoading || !location) {
    return (
      <Skeleton
        variant='rounded'
        height={60}
      />
    )
  }

  const { openingHours } = location

  if (!openingHours?.length) {
    return null
  }

  return (
    <Box
      sx={{
        [`.${chipClasses.root}`]: {
          width: 'auto'
        }
      }}
    >
      <FirmTimeslots firm={AppInjector.get<LocationToFirm>(LocationToFirm).transform(location)} />
    </Box>
  )
}

export default LocationTimeslots
