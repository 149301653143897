import { SxProps } from '@mui/material'
import StylesHeaderCurrentFirmTitleServiceInterface from '~/src/components/layout/header/header-current-firm-title/styles/StylesHeaderCurrentFirmTitleServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesHeaderCurrentFirmTitleServiceStyleTwo extends TemplateServiceStyleBase implements StylesHeaderCurrentFirmTitleServiceInterface {
  public getTitleSx(): SxProps {
    return {
      display: 'flex',
      alignItems: 'center',
      color: 'header.contrastText',
      '&:hover': {
        cursor: 'pointer'
      }
    }
  }
}
