import { initializeApp } from 'firebase/app'
import { useEffect } from 'react'
import { PlatformUtils } from '@eo-storefronts/eo-core'
import EnvUtils from '~/src/utils/EnvUtils'

const useInitFirebaseApp = () => {
  const init = () => {
    initializeApp({
      apiKey: 'AIzaSyCRdt9EQj_p_NTKoSPoW5dmcjBPNT5gFs8',
      authDomain: 'click-and-collect-ed845.firebaseapp.com',
      projectId: 'click-and-collect-ed845',
      storageBucket: 'click-and-collect-ed845.appspot.com',
      messagingSenderId: '488721083554',
      appId: '1:488721083554:web:52965666d1ee56617afbfb'
    })
  }


  useEffect(() => {
    if (!EnvUtils.isDeviceWeb || PlatformUtils().isCapacitorNative) {
      return
    }

    init()
  }, [])
}

export default useInitFirebaseApp
