import { Box, Typography } from '@mui/material'
import DeliveryPickupPoints from '~/src/components/ordering-methods/DeliveryPickupPoints'
import { useTranslations } from '~/src/hooks/useTranslations'
import { useEoValue } from '~/src/hooks/useEoState'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import useGetOrderingMethodsSummaryPickupPointsTemplate
  from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-pickup-points/styles/useGetOrderingMethodsSummaryPickupPointsTemplate'
import { OrderingMethodNames } from '@eo-storefronts/eo-core'

const OrderingMethodsSummaryPickupPoints = () => {
  const styles = useGetOrderingMethodsSummaryPickupPointsTemplate()
  const firm = useEoValue(FIRM_SELECTOR)
  const { t } = useTranslations()

  if (!firm || !firm?.settings?.orderingMethods[OrderingMethodNames.PICKUP]?.pickupPoints?.length) {
    return null
  }

  return (
    <Box {...styles.getContainerProps()}>
      <Typography {...styles.getTypographyProps()}>
        {t('orderingMethods.selectPickupPoint')}
      </Typography>
      <DeliveryPickupPoints/>
    </Box>
  )
}

export default OrderingMethodsSummaryPickupPoints
