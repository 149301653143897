import {
  Address,
  ApiConfiguration,
  CustomLink,
  FindFirmArgType,
  Firm,
  FirmService,
  FirmStoreLocator,
  GetAnnouncementsResponse,
  GetLastModificationResponse,
  GetTimeslotsResponse,
  Id,
  Interval,
  NewsFeed,
  OrderingMethodNames
} from '@eo-storefronts/eo-core'

export const fetchFirm = async (arg: FindFirmArgType): Promise<Firm> => {
  return FirmService.findFirm(arg)
}

export const findFirmByAddress = async (address: Address): Promise<FirmStoreLocator> => {
  return FirmService.findByAddress(address)
}

export const fetchLastModification = async (): Promise<GetLastModificationResponse> => {
  return FirmService.getLastModification()
}

export const fetchFirmTimeslots = async (firmId: Id, date: Date, orderingMethod: string, pickupPointId?: number | string): Promise<GetTimeslotsResponse> => {
  ApiConfiguration.addOrReplaceHeader('store_id', firmId.toString())

  return FirmService.getTimeslots(date, orderingMethod, pickupPointId)
}

export const fetchTimeIntervals = async (firmId: Id, timeslotId: Id, orderingMethod: string, items: Record<Id, number>, date: Date): Promise<Interval[]> => {
  ApiConfiguration.addOrReplaceHeader('store_id', firmId.toString())

  return FirmService.getTimeIntervals(timeslotId, orderingMethod, items, date)
}

export const fetchCustomLinks = async (firmId: Id): Promise<CustomLink[]> => {
  ApiConfiguration.addOrReplaceHeader('store_id', firmId.toString())

  return FirmService.getCustomLinks()
}

export const fetchAnnouncements = async (id: Id): Promise<GetAnnouncementsResponse> => {
  ApiConfiguration.addOrReplaceHeader('store_id', id)

  return FirmService.getAnnouncements()
}

export const fetchNewsFeed = async (id: Id, limit = 5): Promise<Array<NewsFeed>> => {
  ApiConfiguration.addOrReplaceHeader('store_id', id)

  return FirmService.getNewsFeed(limit)
}

/**
 * This method will check if the method exist in the firm and if the method is inactive or not
 * @param {Firm} firm
 * @param {OrderingMethodNames} method
 * @returns {boolean}
 */
export const firmSupportOrderingMethod = (firm: Firm, method: OrderingMethodNames): boolean => {
  return !!firm.settings.orderingMethods[method] && !firm.settings.orderingMethods[method]?.temporaryInactive
}

/**
 * This method will map through the possible orderingMethods in the firm
 * and check if there's one existing and active
 * @param {Firm | null} firm
 * @returns {OrderingMethodNames | undefined}
 */
export const getFirstFirmSupportedOrderingMethod = (firm: Firm | null): OrderingMethodNames | undefined => {
  if (!firm) {
    return
  }

  const methods: OrderingMethodNames[] = Object.keys(firm.settings.orderingMethods) as OrderingMethodNames[]

  if (!methods?.length) {
    return
  }

  for (const method of methods) {
    if (firmSupportOrderingMethod(firm, method as OrderingMethodNames)) {
      return method as OrderingMethodNames
    }
  }
}
