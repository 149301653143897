import { SignInResult } from '@capacitor-firebase/authentication'
import { ThirdPartySignInVerifyResponse } from '@eo-storefronts/eo-core'
import { verifyGooglePayload } from '~/src/services/AuthenticationService'

interface ReturnsType {
  verify(payload: SignInResult): Promise<ThirdPartySignInVerifyResponse | undefined>,
}

const useSignInWithGoogleVerify = (): ReturnsType => {
  const verify = async (payload: SignInResult) => {
    return verifyGooglePayload({
      id: payload.user?.uid || '',
      email: payload.user?.email || '',
      givenName: payload.user?.displayName || '',
      familyName: payload.user?.displayName || '',
      name: payload.user?.displayName || '',
      imageUrl: payload.user?.photoUrl || '',
      serverAuthCode: payload.credential?.serverAuthCode || '',
      authentication: {
        accessToken: payload.credential?.accessToken || '',
        idToken: payload.credential?.idToken || ''
      }
    })
  }

  return { verify }
}

export default useSignInWithGoogleVerify
