import { Currency, CustomerLoyaltyVoucher } from '@eo-storefronts/eo-core'
import { useEoValue } from '~/src/hooks/useEoState'
import { FIRM_CURRENCY_SELECTOR } from '~/src/stores/firm'
import { useTranslations } from '~/src/hooks/useTranslations'

type Props = {
  voucher: CustomerLoyaltyVoucher,
}

const CustomerLoyaltyCurrentVoucherValue = ({ voucher }: Props) => {
  const { t } = useTranslations()
  const firmCurrency = useEoValue<Currency | undefined>(FIRM_CURRENCY_SELECTOR)

  if (voucher.fidelityPointType && !!voucher.fidelityPoint) {
    return (
      <>
        {voucher.fidelityPoint} {t('profile.loyalty.points')}
      </>
    )
  }

  return (
    <>
      {firmCurrency?.symbol} {voucher.amount}
    </>
  )
}

export default CustomerLoyaltyCurrentVoucherValue
