import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'
import StylesOrderingMethodsSummaryContentServiceInterface
  from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-content/styles/StylesOrderingMethodsSummaryContentServiceInterface'
import { BoxProps } from '@mui/material'

export default class StylesOrderingMethodsSummaryContentServiceStyleOne extends TemplateServiceStyleBase implements StylesOrderingMethodsSummaryContentServiceInterface {
  getOrderingMethodsSummaryContainerProps(): BoxProps {
    return {}
  }
}
