import {
  Address,
  ApiConfiguration,
  BasicResponse,
  Coupon,
  CreateOrderPayload,
  FirstAvailableFulfilmentTime,
  GetFirstAvailableFulfilmentTimePayload,
  GetOrderDetailsResponse,
  Id,
  OrderCalculateTime,
  OrderDeliveryCost,
  OrderService,
  OrderStatusType,
  OrderSummary,
  Product
} from '@eo-storefronts/eo-core'
import CustomerLoyaltyVoucher from '@eo-storefronts/eo-core/dist/models/CustomerLoyaltyVoucher'

export const createOrder = async (order: CreateOrderPayload, firmId: Id): Promise<BasicResponse> => {
  ApiConfiguration.addOrReplaceHeader('store_id', firmId.toString())

  return OrderService.create(order)
}

export const fetchOrder = async (orderId: Id, firmId: Id, customerId: Id): Promise<GetOrderDetailsResponse> => {
  ApiConfiguration.addOrReplaceHeader('store_id', firmId.toString())
  ApiConfiguration.addOrReplaceHeader('customer_id', customerId.toString())

  return OrderService.getDetails(orderId)
}

export const fetchStorefrontsOrders = async (customerId: Id, firmId: Id | null): Promise<OrderSummary[]> => {
  firmId && ApiConfiguration.addOrReplaceHeader('store_id', firmId.toString())
  ApiConfiguration.addOrReplaceHeader('customer_id', customerId.toString())

  return OrderService.getStorefrontOrders()
}

export const getFirstAvailableFulfilmentTime = async (firmId: Id, customerId: Id, order: GetFirstAvailableFulfilmentTimePayload): Promise<FirstAvailableFulfilmentTime> => {
  ApiConfiguration.addOrReplaceHeader('store_id', firmId.toString())
  ApiConfiguration.addOrReplaceHeader('customer_id', customerId.toString())

  return OrderService.getFirstAvailableFulfilmentTime(order)
}

export const calculateTime = async (firmId: Id, customerId: Id, order: unknown): Promise<OrderCalculateTime> => {
  ApiConfiguration.addOrReplaceHeader('store_id', firmId.toString())
  ApiConfiguration.addOrReplaceHeader('customer_id', customerId.toString())

  return OrderService.calculateTime(order)
}

export const calculateDelivery = async (firmId: Id, customerId: Id, products: Product[], totalPrice: number, address: Address): Promise<OrderDeliveryCost> => {
  ApiConfiguration.addOrReplaceHeader('store_id', firmId.toString())
  ApiConfiguration.addOrReplaceHeader('customer_id', customerId.toString())

  return OrderService.calculateOrderDelivery({
    products,
    totalPrice: totalPrice,
    orderingMethod: 'delivery',
    deliveryStreet: address.street,
    //deliveryStreetNr: address.houseNumber,
    deliveryZipcode: address.zipCode,
    deliveryLocality: address.locality,
    deliveryCountryId: address.country.id
  })
}

export const fetchDeliveryAddressCosts = async (firmId: Id, address: Address): Promise<any> => {
  ApiConfiguration.addOrReplaceHeader('store_id', firmId.toString())

  return OrderService.fetchDeliveryAddressCosts(address)
}

export const confirmTime = async (firmId: Id, customerId: Id, order: unknown): Promise<OrderCalculateTime> => {
  ApiConfiguration.addOrReplaceHeader('store_id', firmId.toString())
  ApiConfiguration.addOrReplaceHeader('customer_id', customerId.toString())

  return OrderService.confirmTime(order)
}

export const releaseSlot = async (slotId: number): Promise<void> => {
  return OrderService.releaseSlot(slotId)
}

export const checkCoupon = async (firmId: Id, customerId: Id, code: string, total: number): Promise<Coupon> => {
  ApiConfiguration.addOrReplaceHeader('store_id', firmId.toString())

  if (Number(customerId) !== 0) {
    ApiConfiguration.addOrReplaceHeader('customer_id', customerId.toString())
  }

  return OrderService.checkCoupon(code, total)
}

export const useVoucher = async (voucher: string): Promise<CustomerLoyaltyVoucher> => {
  return OrderService.useVoucher(voucher)
}

export const orderIsPickedUp = (status: OrderStatusType): boolean => {
  return OrderService.orderIsPickedUp(status)
}
