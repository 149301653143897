import AlternateEmailIcon from '@mui/icons-material/AlternateEmail'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined'
import { Box, Link, Typography } from '@mui/material'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { LANGUAGE_STATE } from '~/src/stores/lang'

const FirmDetail = () => {
  const { t } = useTranslations()
  const lang = useEoValue(LANGUAGE_STATE)
  const firm = useEoValue(FIRM_SELECTOR)

  if (!firm) {
    return null
  }

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: 2,
      mb: 2
    }}>
      <Typography variant='h5'>
        {t('firm.shopDetails')}
      </Typography>
      <Link
        underline='none'
        sx={{
          display: 'flex',
          px: 1 / 2,
          py: 0,
          gap: 1
        }}
      >
        <LocationOnOutlinedIcon /><span>{`${firm.address.street}, ${firm.address.zipcode} ${firm.address.locality} ${firm.address.country.name[lang]}`}</span>
      </Link>

      {(!firm.settings.theme.hideEmail && firm.email) &&
        <Link
          underline='none'
          href={`mailto:${firm.email}`}
          sx={{
            display: 'flex',
            px: 1 / 2,
            py: 0,
            gap: 1
          }}
        >
          <AlternateEmailIcon /><span>{firm.email}</span>
        </Link>
      }

      {firm.phone &&
        <Link
          underline='none'
          href={`tel:${firm.phone}`}
          sx={{
            display: 'flex',
            px: 1 / 2,
            py: 0,
            gap: 1
          }}
        >
          <PhoneOutlinedIcon /><span>{firm.phone}</span>
        </Link>
      }
    </Box>
  )
}

export default FirmDetail
