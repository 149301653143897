import { Interval } from '@eo-storefronts/eo-core'
import { useEoValue } from '~/src/hooks/useEoState'
import { fetchTimeIntervals } from '~/src/services/FirmService'
import { CART_PRODUCTS_WITH_QUANTITY_SELECTOR } from '~/src/stores/cart'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import { FIRM_WITH_LOCATOR_STATE_SELECTOR } from '~/src/stores/firm'

interface ReturnsType {
  fetchTimeslotsIntervals(): Promise<Interval[]>,
}

const useFetchTimeslotIntervals = (): ReturnsType => {
  const firm = useEoValue(FIRM_WITH_LOCATOR_STATE_SELECTOR)
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)
  const products = useEoValue(CART_PRODUCTS_WITH_QUANTITY_SELECTOR)

  const fetchTimeslotsIntervals = async (): Promise<Interval[]> => {
    if (
      !firm
      || !checkoutForm.orderingMethod.method
      || !checkoutForm.orderingMethod.timeslotId
      || !checkoutForm.orderingMethod.time
    ) {
      return []
    }

    return fetchTimeIntervals(
      firm.id,
      checkoutForm.orderingMethod.timeslotId,
      checkoutForm.orderingMethod.method,
      products,
      new Date(checkoutForm.orderingMethod.time)
    )
  }

  return {
    fetchTimeslotsIntervals
  }
}

export default useFetchTimeslotIntervals
