import ResponsiveDialog from '~/src/components/mui-wrappers/responsive-dialog'
import { dialogContentClasses, Divider, Theme, Typography, useMediaQuery, useTheme } from '@mui/material'
import Title from '~/src/components/mui-wrappers/Title'
import ProfileQuickAuthIdAsText from '~/src/components/profile/quick-auth/ProfileQuickAuthIdAsText'
import CssUtils from '~/src/utils/CssUtils'
import { useTranslations } from '~/src/hooks/useTranslations'
import { DialogNameEnum } from '~/src/types/DialogNameEnum'
import ProfileQuickAuthIdAsQrCode from '~/src/components/profile/quick-auth/ProfileQuickAuthIdAsQrCode'
import ProfileLargeDropdownButton
  from '~/src/components/user-actions/profile-dropdown-button/ProfileLargeDropdownButton'
import useDialog from '~/src/hooks/useDialog'
import { shadify } from '~/src/hooks/layout/useTheme'

const ProfileQuickAuthDialog = () => {
  const { t } = useTranslations()
  const theme = useTheme()
  const isDownMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const { dismissAll } = useDialog()

  const _handleOnProfileItemClicked = () => {
    dismissAll()
  }

  return (
    <ResponsiveDialog
      openByDefault
      showCloseButton
      name={DialogNameEnum.PROFILE_QUICK_AUTH}
      Title={<Title variant='h4'>{t('profile.quick_auth.title')}</Title>}
      sx={{
        [`.${dialogContentClasses.root}`]: {
          pb: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2.5,
          textAlign: 'center',
          [theme.breakpoints.down('md')]: {
            pb: '10vh'
          },
          [theme.breakpoints.down('sm')]: {
            pb: '5vh'
          }
        }
      }}>
      <ProfileLargeDropdownButton
        onClick={_handleOnProfileItemClicked}
        ComponentProps={{
          Label: {
            ComponentProps: {
              Avatar: {
                color: 'container',
                useAutomaticShading: true
              }
            }
          },
          Menu: {
            color: 'container',
            useAutomaticShading: true
          }
        }}
        sx={{
          width: 'unset',
          maxWidth: '100%',
          'svg:last-of-type': {
            backgroundColor: shadify((theme.palette as any).container.main),
            margin: 1,
            borderRadius: 'var(--btn-border-radius)'
          }
        }}/>

      <Typography variant='subtitle1'>{t('profile.quick_auth.subTitle')}</Typography>

      <ProfileQuickAuthIdAsQrCode sx={{ my: 2 }}/>

      <ProfileQuickAuthIdAsText variant='h6'/>

      {isDownMd && (
        <Divider
          sx={{
            width: '50%',
            borderWidth: '2px',
            borderColor: CssUtils.addTransparencyToHexColor(theme.palette.primary.main, 20)
          }}/>
      )}
    </ResponsiveDialog>
  )
}

export default ProfileQuickAuthDialog
