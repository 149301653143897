import { Firm, Modifier } from '@eo-storefronts/eo-core'
import Image from '~/src/components/mui-wrappers/Image'
import useModifierMediaTemplateService from '~/src/components/products/modifier-groups/modifier-group/modifier/modifier-media/styles/useModifierMediaTemplateService'
import { useEoValue } from '~/src/hooks/useEoState'
import { modifierHasImage } from '~/src/services/ModifierService'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { LANGUAGE_STATE } from '~/src/stores/lang'

interface Props {
  modifier: Modifier,
  rounded?: boolean,
}

const ModifierMedia = ({ modifier, rounded = true }: Props) => {
  const firm: Firm | null = useEoValue(FIRM_SELECTOR)
  const lang = useEoValue(LANGUAGE_STATE)
  const styles = useModifierMediaTemplateService()

  if (
    !firm?.settings.theme?.showModifierImages
    || !modifierHasImage(modifier)
  ) {
    return null
  }

  return (
    <Image
      src={modifier.images![0].thumb}
      alt={`${modifier.name[lang]}-image`}
      sx={styles.getContainerSx(rounded)}
    />
  )
}

export default ModifierMedia
