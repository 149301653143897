import FooterGoToButton from '~/src/components/layout/footer/FooterGoToButton'
import { useTranslations } from '~/src/hooks/useTranslations'
import routes from '~/src/router/enums/routes.enum'

const FooterGoToCart = () => {
  const { t } = useTranslations()

  return <FooterGoToButton
    link={routes.CART}
    text={`${t('footer.goTo')} ${t('footer.cart')}`}
  />
}

export default FooterGoToCart
