import { Modifier, ModifierGroup } from '@eo-storefronts/eo-core'
import { Grow, RadioGroup } from '@mui/material'
import { ChangeEvent, useContext, useEffect, useState } from 'react'
import ProductConfigurationModal, {
  ProductConfigurationModalProps
} from '~/src/components/products/modals/configuration'
import ProductConfigurationModalContext, {
  ProductConfigurationModalContextInterface
} from '~/src/components/products/modals/configuration/ProductConfigurationModalContext'
import SingleChoiceModifier
  from '~/src/components/products/modifier-groups/modifier-group/modifier-group-single-choice/single-choice-modifier'
import SingleChoiceModifierSelected
  from '~/src/components/products/modifier-groups/modifier-group/modifier-group-single-choice/single-choice-modifier-selected'
import useSingleChoiceModifiersContainerTemplateService
  from '~/src/components/products/modifier-groups/modifier-group/modifier-group-single-choice/single-choice-modifiers-container/styles/useSingleChoiceModifiersContainerTemplateService'
import useCartModifierGroupsStateService from '~/src/hooks/products/modifier-groups/useCartModifierGroupsStateService'
import useDialog from '~/src/hooks/useDialog'
import { useEoValue } from '~/src/hooks/useEoState'
import { convertProductToModifier, productHasImage } from '~/src/services/ProductService'
import { CartProductModifierGroupStateInterface } from '~/src/stores/cart/cart-product-modifier-groups'
import { GROUP_MODIFIERS_SELECTOR, MODIFIERS_STATE } from '~/src/stores/modifiers'
import { PRODUCT_ID_SELECTOR, PRODUCTS_STATE } from '~/src/stores/product'
import { DialogNameEnum } from '~/src/types/DialogNameEnum'

interface Props {
  group: ModifierGroup,
  cartModifierGroups: CartProductModifierGroupStateInterface[],
  onChange(cartModifierGroups: CartProductModifierGroupStateInterface[]): void,
}

const SingleChoiceModifiersContainer = ({ group, cartModifierGroups, onChange }: Props) => {
  const styles = useSingleChoiceModifiersContainerTemplateService()
  const currentProduct = useEoValue(PRODUCT_ID_SELECTOR)
  const modifiers = useEoValue(MODIFIERS_STATE)
  const products = useEoValue(PRODUCTS_STATE)
  const productConfigurationContext = useContext<ProductConfigurationModalContextInterface>(ProductConfigurationModalContext)
  const { present } = useDialog()
  const groupModifiers = useEoValue(GROUP_MODIFIERS_SELECTOR(group.id))
  const { setModifierToGroup } = useCartModifierGroupsStateService()
  const [ radioGroupValue, setRadioGroupValue ] = useState<string>('')
  const currentGroup = cartModifierGroups.find((cartModifierGroup: CartProductModifierGroupStateInterface) => cartModifierGroup.id === group.id)

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>, value: string): void => {
    if (!modifiers[value] && !products[value]) {
      return
    }

    if (products[value]) {
      if (products[value].modifierGroups.length > 0) {
        setRadioGroupValue('')
        present<ProductConfigurationModalProps>(
          ProductConfigurationModal, 
          `${DialogNameEnum.PRODUCT_BY_ID}${products[value].id}`, 
          { 
            product: products[value],
            maxWidth: productHasImage(products[value]) ? 'md' : 'xs',
            parentContext: productConfigurationContext,
            group,
            asModifier: true
          }
        )
        return
      }

      onChange(setModifierToGroup(cartModifierGroups, group, convertProductToModifier(group, products[value])))
      setRadioGroupValue(value)
      return
    }

    onChange(setModifierToGroup(cartModifierGroups, group, modifiers[value]))
    setRadioGroupValue(value)
  }

  useEffect(() => {
    setRadioGroupValue(currentGroup?.modifiers[0].id || '')
  }, [ cartModifierGroups ])

  if (currentGroup?.modifiers[0] && group.type === 'rank') {
    return (
      <SingleChoiceModifierSelected
        groupId={group.id}
        modifierId={currentGroup?.modifiers[0].id}
      />
    )
  }

  if (!currentProduct) {
    return null
  }

  return (
    <Grow in>
      <RadioGroup
        name={`option-group-${group.id}`}
        onChange={handleOnChange}
        value={radioGroupValue}
        sx={styles.getContainerSx(productHasImage(currentProduct))}
      >
        {groupModifiers.map((modifier: Modifier) => (
          <SingleChoiceModifier
            key={modifier.id}
            modifier={modifier}
            selected={modifier.id === radioGroupValue}
          />
        ))}
      </RadioGroup>
    </Grow>
  )
}

export default SingleChoiceModifiersContainer
