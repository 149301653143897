import getProductDetailsTitleTemplateService from '~/src/components/products/product-details/product-details-title/styles/GetProductDetailsTitleTemplateService'
import StylesProductDetailsTitleServiceInterface from '~/src/components/products/product-details/product-details-title/styles/StylesProductDetailsTitleServiceInterface'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { useEoValue } from '~/src/hooks/useEoState'
import { FIRM_THEME_SELECTOR } from '~/src/stores/firm'
import { LAYOUT_STATE } from '~/src/stores/layout'

const useGetProductDetailsTitleTemplate = (): StylesProductDetailsTitleServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const firmTheme = useEoValue(FIRM_THEME_SELECTOR)
  const layout = useEoValue(LAYOUT_STATE)

  return getProductDetailsTitleTemplateService(firmTheme?.components?.productsList, muiTheme, layout)
}

export default useGetProductDetailsTitleTemplate
