import { createSelector } from '~/src/hooks/useEoState'
import RoutesEnum from '~/src/router/enums/routes.enum'
import { SELECTED_SHOP_STATE } from '~/src/stores/firm'
import { BRAND_STATE } from '~/src/stores/brand'

export const FALLBACK_PAGE_SELECTOR = createSelector<string>({
  key: 'fallbackPageSelector',
  get: ({ get }): string => {
    const brand = get(BRAND_STATE)
    const selectedShop = get(SELECTED_SHOP_STATE)

    if ((brand?.isMultifirm || brand?.isMarketplace) && !selectedShop) {
      return get(BRAND_PAGE_SELECTOR)
    }

    return RoutesEnum.MENU
  }
})

export const BRAND_PAGE_SELECTOR = createSelector({
  key: 'brandPageSelector',
  get: ({ get }) => {
    const brand = get(BRAND_STATE)

    if (brand?.isMarketplace) {
      return RoutesEnum.EXPLORER
    }

    return RoutesEnum.LOCATOR
  }
})
