import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'
import StylesOrderingMethodsSummaryServiceInterface
  from '~/src/components/ordering-methods/ordering-methods-summary/styles/StylesOrderingMethodsSummaryServiceInterface'
import { SxProps } from '@mui/material'
import {
  ResponsiveDialogContextInterface
} from '~/src/components/mui-wrappers/responsive-dialog/ResponsiveDialogContext'

export default class StylesOrderingMethodsSummaryServiceStyleOne extends TemplateServiceStyleBase implements StylesOrderingMethodsSummaryServiceInterface {
  public getContainerSx(frozenOrderingMethod: boolean): SxProps {
    return {
      display: 'flex',
      flexWrap: 'wrap-reverse',
      justifyContent: 'space-between',
      gap: 1,
      cursor: !frozenOrderingMethod ? 'pointer' : undefined,
      maxWidth: '100%'
    }
  }

  public getContainerOnClick(frozenOrderingMethod: boolean, responsiveDialogContext: ResponsiveDialogContextInterface): void {
    if (frozenOrderingMethod) {
      return
    }

    responsiveDialogContext.toggle && responsiveDialogContext.toggle()
  }

  public getFirmClosedSx(): SxProps {
    return {
      mb: 2
    }
  }
}
