import { ModifierGroup as ModifierGroupType } from '@eo-storefronts/eo-core'
import ModifierGroupMultiChoice from '~/src/components/products/modifier-groups/modifier-group/modifier-group-multi-choice'
import ModifierGroupSingleChoice from '~/src/components/products/modifier-groups/modifier-group/modifier-group-single-choice'
import { CartProductModifierGroupStateInterface } from '~/src/stores/cart/cart-product-modifier-groups'

interface Props {
  group: ModifierGroupType,
  cartModifierGroups: CartProductModifierGroupStateInterface[],
  onChange(cartModifierGroups: CartProductModifierGroupStateInterface[]): void,
}

const ModifierGroup = (props: Props) => {
  if (props.group.isMultipleChoice) {
    return <ModifierGroupMultiChoice {...props} />
  }

  return <ModifierGroupSingleChoice {...props} />
}

export default ModifierGroup
