import { Modifier } from '@eo-storefronts/eo-core'
import { Box, Checkbox, checkboxClasses } from '@mui/material'
import { useEffect, useState } from 'react'
import useMultiChoiceModifierTemplateService from '~/src/components/products/modifier-groups/modifier-group/modifier-group-multi-choice/multi-choice-modifier/styles/useMultiChoiceModifierTemplateService'
import ModifierToggle from '~/src/components/products/modifier-groups/modifier-group/modifier/modifier-toggle'
import QuantitySelector from '~/src/components/products/QuantitySelector'
import useModifiersService from '~/src/hooks/products/modifier-groups/modifiers/useModifiersService'
import { useEoValue } from '~/src/hooks/useEoState'
import { MODIFIERS_STATE } from '~/src/stores/modifiers'

interface Props {
  modifierId: string,
  disabled: boolean,
  canBeSelectedMultiTimes: boolean,
  onUpdate(modifier: Modifier, quantity: number, selected: boolean): void,
}

const MultiChoiceModifier = (
  {
    modifierId,
    disabled,
    canBeSelectedMultiTimes,
    onUpdate
  }: Props
) => {
  const [ quantity, setQuantity ] = useState<number>(0)
  const modifiers = useEoValue(MODIFIERS_STATE)
  const { isModifierAvailable, isModifierLocked } = useModifiersService(modifiers[modifierId])
  const styles = useMultiChoiceModifierTemplateService()

  const handleOnQuantityUpdate = (quantity: number): void => {
    setQuantity(quantity)
    onUpdate(modifiers[modifierId], quantity, quantity > 0)
  }

  const handleOnLabelClick = (): void => {
    if (modifiers[modifierId].ingredientType === 'main' || isModifierLocked()) {
      return
    }

    if (!canBeSelectedMultiTimes) {
      if ((disabled || !isModifierAvailable()) && quantity === 0) {
        return
      }

      handleOnQuantityUpdate(quantity >= 1 ? 0 : 1)
      return
    }

    if (disabled || !isModifierAvailable()) {
      return
    }

    handleOnQuantityUpdate(quantity + 1)
  }

  useEffect(() => {
    if (modifiers[modifierId].isDefault && isModifierAvailable()) {
      handleOnQuantityUpdate(modifiers[modifierId].quantity ?? 1)
    }
  }, [])

  return (
    <Box sx={styles.getContainerSx((disabled && quantity === 0) || !isModifierAvailable() || modifiers[modifierId].ingredientType === 'main')}>
      <Box sx={styles.getModifierSx()}>
        {canBeSelectedMultiTimes && <QuantitySelector
          textVariant='body2'
          disabled={disabled || !isModifierAvailable() || modifiers[modifierId].ingredientType === 'main'}
          disableMinus={quantity === 0 || modifiers[modifierId].ingredientType === 'main'}
          min={0}
          quantity={quantity}
          onUpdate={handleOnQuantityUpdate}
          size='small'
          rounded
          quantityBtnSx={{
            height: '1.85em',
            width: '1.85em'
          }}
          sx={styles.getQuantitySelectorSx(quantity)}
        />}

        <Box sx={styles.getModifierToggleSx(quantity > 0)} onClick={handleOnLabelClick}>
          {!canBeSelectedMultiTimes && <Checkbox
            value={modifierId}
            disabled={!isModifierAvailable()}
            checked={quantity >= 1}
            sx={{
              color: 'inherit',
              [`&.${checkboxClasses.checked}`]: {
                color: 'inherit'
              }
            }}
          />}

          <ModifierToggle modifier={modifiers[modifierId]} />
        </Box>
      </Box>
    </Box>
  )
}

export default MultiChoiceModifier
