import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'
import StylesOrderingMethodsSummaryMainButtonsServiceInterface
  from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-main-buttons/styles/StylesOrderingMethodsSummaryMainButtonsServiceInterface'
import StylesOrderingMethodsSummaryMainButtonsServiceStyleTwo
  from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-main-buttons/styles/StylesOrderingMethodsSummaryMainButtonsServiceStyleTwo'
import StylesOrderingMethodsSummaryMainButtonsServiceStyleOne
  from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-main-buttons/styles/StylesOrderingMethodsSummaryMainButtonsServiceStyleOne'

const getOrderingMethodsSummaryMainButtonsTemplateService = (style: TemplateEnum | null | undefined, muiTheme: CustomMuiTheme): StylesOrderingMethodsSummaryMainButtonsServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesOrderingMethodsSummaryMainButtonsServiceStyleTwo(muiTheme)
    default:
      return new StylesOrderingMethodsSummaryMainButtonsServiceStyleOne(muiTheme)
  }
}

export default getOrderingMethodsSummaryMainButtonsTemplateService
