import { FirebaseAuthentication, SignInResult } from '@capacitor-firebase/authentication'
import { Customer, Id, SocialLoginType, ThirdPartySignInVerifyResponse } from '@eo-storefronts/eo-core'
import { useSnackbar } from 'notistack'
import { useContext, useRef, useState } from 'react'
import ResponsiveDialogContext from '~/src/components/mui-wrappers/responsive-dialog/ResponsiveDialogContext'
import useSetAuthState from '~/src/hooks/auth/useSetAuthState'
import useSignInWithAppleVerify from '~/src/hooks/auth/useSignInWithAppleVerify'
import useSignInWithGoogleVerify from '~/src/hooks/auth/useSignInWithGoogleVerify'
import useSocialSignInWithBackend from '~/src/hooks/auth/useSocialSignInWithBackend'
import useCustomerExtraInfo from '~/src/hooks/customer/useCustomerExtraInfo'
import useLinkFirm from '~/src/hooks/customer/useLinkFirm'
import useSetCustomerState from '~/src/hooks/customer/useSetCustomerState'
import usePushNotifications from '~/src/hooks/push-notifications/usePushNotifications'
import { useFirmPathResolver } from '~/src/hooks/router/useFirmPathResolver'
import useGetQueryParam from '~/src/hooks/router/useGetQueryParam'
import { useRouterPush } from '~/src/hooks/router/useRouterPush'
import useDialog from '~/src/hooks/useDialog'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import Params from '~/src/router/enums/params.enum'
import RoutesEnum, { RoutesEnumType } from '~/src/router/enums/routes.enum'
import { AuthenticationState } from '~/src/stores/authentication'
import { FALLBACK_PAGE_SELECTOR } from '~/src/stores/router'

interface ReturnsType {
  loading: boolean,
  login(): Promise<void>,
}

const useSocialSignIn = (socialLoginType: SocialLoginType): ReturnsType => {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslations()
  const [ loading, setLoading ] = useState<boolean>(false)
  const responsiveDialogContext = useContext(ResponsiveDialogContext)
  const fallbackPage = useEoValue(FALLBACK_PAGE_SELECTOR)
  const { setAuthState } = useSetAuthState()
  const { setCustomer } = useSetCustomerState()
  const { dismiss } = useDialog()
  const { needsExtraInfo } = useCustomerExtraInfo()
  const { push } = useRouterPush()
  const { resolve } = useFirmPathResolver()
  const { params: redirectTo } = useGetQueryParam(Params.REDIRECT_TO)
  const { verify: verifyAppleSignInPayload } = useSignInWithAppleVerify()
  const { initPushNotifications } = usePushNotifications()
  const { signInWithSocial } = useSocialSignInWithBackend()
  const { doLinkFirm } = useLinkFirm()
  const { verify: verifyGoogleSignInPayload } = useSignInWithGoogleVerify()
  const authStateRef = useRef<Partial<AuthenticationState>>({})

  const _displayError = (message = 'errors.defaultError'): undefined => {
    enqueueSnackbar(t(message), { variant: 'error' })

    return
  }

  const _setCustomerInAuthState = (id: Id, uid: string, token: string) => {
    authStateRef.current = {
      guest: undefined,
      customer: {
        id,
        uid,
        token
      },
      loggedInAs: 'customer'
    }

    void setCustomer(authStateRef.current)
    return setAuthState(authStateRef.current)
  }

  const loginWithApple = async (): Promise<Partial<Customer> | undefined> => {
    const signInWithApplePayload: SignInResult = await FirebaseAuthentication.signInWithApple()
    const verify: ThirdPartySignInVerifyResponse | undefined = await verifyAppleSignInPayload(signInWithApplePayload)

    if (!verify) {
      return _displayError()
    }

    _setCustomerInAuthState(verify.customer.id, verify.customer.uid, verify.customerToken)

    return {
      id: verify.customer.id,
      uid: verify.customer.uid
    }
  }

  const loginWithGoogle = async (): Promise<Partial<Customer> | undefined> => {
    const signInWithGooglePayload: SignInResult = await FirebaseAuthentication.signInWithGoogle()
    const verify: ThirdPartySignInVerifyResponse | undefined = await verifyGoogleSignInPayload(signInWithGooglePayload)

    if (!verify) {
      return _displayError()
    }

    _setCustomerInAuthState(verify.customer.id, verify.customer.uid, verify.customerToken)

    return {
      id: verify.customer.id,
      uid: verify.customer.uid
    }
  }

  const redirectAfterLogin = (customer: Customer) => {
    if (needsExtraInfo(customer)) {
      push(resolve(RoutesEnum.EXTRA_INFO_CUSTOMER))
      return
    }

    if (!redirectTo) {
      push(resolve(fallbackPage))
    } else if (redirectTo === 'ACCOUNT') {
      push(resolve(`${RoutesEnum.PROFILE}/${RoutesEnum.ACCOUNT}`))
    } else {
      push(resolve(RoutesEnum[redirectTo as keyof RoutesEnumType]))
    }
  }

  const login = async () => {
    setLoading(true)

    try {
      let partialCustomer: Partial<Customer> | undefined

      if (socialLoginType === 'apple') {
        partialCustomer = await loginWithApple()
      } else if (socialLoginType === 'google') {
        partialCustomer = await loginWithGoogle()
      } else {
        partialCustomer = await signInWithSocial(socialLoginType)
      }

      // cancel by the user
      if (!partialCustomer) {
        return
      }


      if (partialCustomer.uid || partialCustomer.id) {
        await doLinkFirm(undefined, authStateRef.current)
        const customer: Customer | null = await setCustomer(authStateRef.current)

        if (!customer) {
          return _displayError()
        }

        await initPushNotifications(customer.uid)
        enqueueSnackbar({ message: `${t('auth.welcome')} ${customer.firstName ?? ''}`, variant: 'success' })

        responsiveDialogContext?.toggleClose && responsiveDialogContext.toggleClose({
          successfullyLoggedIn: true,
          customer
        })
        dismiss && dismiss('login-form-dialog')
        redirectAfterLogin(customer)
      }
    } catch {
      // Ignore
    } finally {
      setLoading(false)
    }
  }

  return { login, loading }
}

export default useSocialSignIn
