import { useCallback } from 'react'
import { useEoValue } from '~/src/hooks/useEoState'
import locales from '~/src/i18n'
import { LANGUAGE_STATE } from '~/src/stores/lang'

interface ReturnsType {
  t(key: string, replaces?: Record<string, string|number>): string,
}

const useTranslations = (): ReturnsType => {
  const lang = useEoValue(LANGUAGE_STATE)

  const t = useCallback((key: string, replaces?: Record<string, string|number>): string => {
    const keys = key.split('.')
    let locale = locales[lang]

    while (keys.length) {
      if (!keys) {
        return key
      }

      // @ts-ignore
      locale = locale ? locale[keys.shift()] : {}
    }

    let translated = (locale || key).toString()

    Object
      .entries(replaces || {})
      .map(([ key, value ]) => {
        translated = translated
          .replace(`{{ ${key} }}`, value)
          .replace(`{{${key}}}`, value)
      })

    return translated
  }, [ lang ])

  return { t }
}

export { useTranslations }

