import { createSelector, createState } from '~/src/hooks/useEoState'
import { Firm } from '@eo-storefronts/eo-core'

export const BRAND_STATE = createState<Firm | null>({
  key: 'brandState',
  default: null
})

export const IS_MARKETPLACE_SELECTOR = createSelector<boolean>({
  key: 'isMarketplaceSelector',
  get: ({ get }): boolean => {
    const brand: Firm | null = get(BRAND_STATE)

    return brand?.isMarketplace ?? false
  }
})
