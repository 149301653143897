import { ClientAuthResponse, FindFirmArgType } from '@eo-storefronts/eo-core'
import { loginAsClient } from '~/src/services/AuthenticationService'
import useSetApiTokenState from '~/src/hooks/auth/useSetApiTokenState'
import { AppInjector } from '~/src/utils/DependenciesInjectionUtils'
import HostResolver from '~/src/router/utils/HostResolver'

interface ReturnsType {
  doLoginAsClient(): Promise<void>,
}

const useLoginAsClient = (): ReturnsType => {
  const { setAppApiToken } = useSetApiTokenState()
  const hostResolver = AppInjector.get(HostResolver)

  const doLoginAsClient = async () => {
    const resolved: FindFirmArgType = await hostResolver.resolve()
    const clientResponse: ClientAuthResponse = await loginAsClient(resolved)

    setAppApiToken({
      expiresIn: clientResponse.expiresIn,
      token: clientResponse.accessToken,
      tokenType: clientResponse.tokenType
    })
  }

  return { doLoginAsClient }
}

export default useLoginAsClient
