import useValidateOrder, { OrderErrorType } from '~/src/hooks/orders/useValidateOrder'
import ShoppingCartMessage from '~/src/components/cart/shopping-cart-message'
import { Box, SxProps } from '@mui/material'
import { useEoValue } from '~/src/hooks/useEoState'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import useOrderingMethodService from '~/src/hooks/ordering-methods/useOrderingMethodService'
import { IS_ON_MARKETPLACE_PAGE_SELECTOR } from '~/src/stores/layout'

interface Props {
  sx?: SxProps,
}

const ShoppingCartMessages = ({ sx = {} }: Props) => {
  const { errors } = useValidateOrder()
  const marketplacePage = useEoValue(IS_ON_MARKETPLACE_PAGE_SELECTOR)
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)
  const { isMethodInactive } = useOrderingMethodService()

  if (
    isMethodInactive(checkoutForm.orderingMethod.method)
    || marketplacePage
  ) {
    return null
  }

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: .5,
      my: 1,
      ...sx
    }}>
      {errors.map((err: OrderErrorType) => (
        <ShoppingCartMessage key={err.text} error={err} />
      ))}
    </Box>)
}

export default ShoppingCartMessages
