import {
  Address,
  CheckoutOption,
  Coupon,
  Firm,
  Id,
  OrderingMethodNames,
  PaymentMethod,
  PaymentStateEnum,
  PickupPoint
} from '@eo-storefronts/eo-core'
import CustomerLoyaltyVoucher from '@eo-storefronts/eo-core/dist/models/CustomerLoyaltyVoucher'
import { indexDbPersist } from '~/src/helpers/recoilPersist'
import { createSelector, createState } from '~/src/hooks/useEoState'
import { CART_SUBTOTAL_WITH_DISCOUNTS_SELECTOR } from '~/src/stores/cart'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import CartCoupon from '~/src/types/CartCoupon'

const checkoutFormStateKey = 'checkoutFormState'

export interface CheckoutFormState {
  indicator: string,
  phone: string,
  receiveInvoice: boolean,
  invoiceDetails: {
    address: Address | null,
  },
  orderingMethod: {
    initialDeliveryCost: number,
    deliveryCost: number,
    pickupPoint: PickupPoint | null,
    method: OrderingMethodNames | null,
    address: Address | null,
    asap: boolean,
    date: Date,
    minDate: string | null,
    time: string | null,
    timeslotId: Id | null,
    tableNumber: string | null,
    tableUid: string | null,
    tableMethod: number | null,
    deliveryFreeFromPrice: number | null,
    minAmount: number,
    deliveryItemUid: string | null,
  },
  useLoyaltyPoints: boolean,
  loyaltyDiscount: number,
  loyaltyProductId: Id | null,
  paymentMethod: PaymentMethod | null,
  coupon: CartCoupon | null,
  physicalVoucher: CustomerLoyaltyVoucher | null,
  moreInformation: string | null,
  checkoutOptions: CheckoutOption[],
}

export interface OrderUidState {
  [key: string | number]: string|null,
}

export interface OrderIdState {
  [key: string | number]: Id|null,
}

export const checkoutFormInitialState = (): CheckoutFormState => (
  {
    indicator: '+32',
    phone: '',
    receiveInvoice: false,
    invoiceDetails: {
      address: null
    },
    orderingMethod: {
      initialDeliveryCost: 0,
      deliveryCost: 0,
      method: null,
      pickupPoint: null,
      address: null,
      asap: false,
      date: new Date(),
      minDate: null,
      time: null,
      timeslotId: null,
      tableNumber: null,
      tableMethod: null,
      tableUid: null,
      deliveryFreeFromPrice: null,
      minAmount: 0,
      deliveryItemUid: null
    },
    useLoyaltyPoints: false,
    loyaltyDiscount: 0,
    loyaltyProductId: null,
    paymentMethod: null,
    coupon: null,
    moreInformation: null,
    physicalVoucher: null,
    checkoutOptions: []
  }
)

export const CHECKOUT_FORM_STATE = createState<CheckoutFormState>({
  key: checkoutFormStateKey,
  default: checkoutFormInitialState(),
  effects: [
    indexDbPersist('checkoutState')
  ]
})

export const CHECKOUT_BUTTON_LOADING_STATE = createState<boolean>({
  key: 'checkoutButtonLoading',
  default: false
})

export const CHECKOUT_SLOT_ID = createState<number|null>({
  key: 'checkoutSlotId',
  default: null
})

export const CHECKOUT_TIME_STATE = createSelector<Date>({
  key: 'checkoutTimeState',
  get: ({ get }) => {
    const checkoutForm = get(CHECKOUT_FORM_STATE)

    if (checkoutForm.orderingMethod.time) {
      return new Date(checkoutForm.orderingMethod.time)
    }

    return new Date()
  }
})

export const FROZEN_DELIVERY_METHOD_STATE = createState<boolean>({
  key: 'frozenOrderingMethodState',
  default: false
})

export const GET_TOTAL_AMOUNT_OF_CHECKOUT_OPTIONS_SELECTOR = createSelector<number>({
  key: 'getTotalAmountOfCheckoutOptionsSelector',
  get: ({ get }) => {
    const checkoutForm = get(CHECKOUT_FORM_STATE)

    return checkoutForm.checkoutOptions
      .reduce((accumulator: number, option: CheckoutOption) => accumulator + option.price, 0)
  }
})

export const GET_DELIVERY_COST = createSelector<number>({
  key: 'getDeliveryCost',
  get: ({ get }) => {
    const checkoutForm = get(CHECKOUT_FORM_STATE)
    const totalAmountOfCart = get(CART_SUBTOTAL_WITH_DISCOUNTS_SELECTOR)
    const totalAmountOfCheckoutOptions = get(GET_TOTAL_AMOUNT_OF_CHECKOUT_OPTIONS_SELECTOR)

    if (checkoutForm.orderingMethod.method !== OrderingMethodNames.DELIVERY) {
      return 0
    }

    if (
      checkoutForm.orderingMethod.deliveryFreeFromPrice
      && checkoutForm.orderingMethod.deliveryFreeFromPrice <= totalAmountOfCart + totalAmountOfCheckoutOptions
    ) {
      return 0
    }

    return checkoutForm.orderingMethod.deliveryCost
  }
})

export const CHECKOUT_PICKUP_POINT_SELECTOR = createSelector<PickupPoint | null>({
  key: 'checkoutPickupPointSelector',
  get: ({ get }) => {
    const checkoutForm = get(CHECKOUT_FORM_STATE)

    if (checkoutForm.orderingMethod.method === OrderingMethodNames.PICKUP) {
      return checkoutForm.orderingMethod.pickupPoint
    }

    return null
  }
})

export const CHECKOUT_DELIVERY_METHOD_SELECTOR = createSelector<OrderingMethodNames | null>({
  key: 'checkoutOrderingMethodSelector',
  get: ({ get }) => {
    const checkoutForm = get(CHECKOUT_FORM_STATE)

    return checkoutForm.orderingMethod.method
  }
})

export const CHECKOUT_COUPON_DISCOUNT_PRICE_SELECTOR = createSelector<string>({
  key: 'checkoutCouponDiscountPriceSelector',
  get: ({ get }) => {
    const checkoutForm = get(CHECKOUT_FORM_STATE)
    const firm = get(FIRM_SELECTOR)

    if (!checkoutForm.coupon) {
      return ''
    }

    if (checkoutForm.coupon.type === 'percentage') {
      return `- ${checkoutForm.coupon.value}%`
    }

    return `- ${firm?.settings.currency.symbol} ${checkoutForm.coupon.value?.toFixed(2)}`
  }
})

export const CHECKOUT_PRODUCT_COUPON = createSelector<CartCoupon | undefined>({
  key: 'checkoutProductCoupon',
  get: ({ get }) => {
    const checkoutForm = get(CHECKOUT_FORM_STATE)

    if (!checkoutForm.coupon || !checkoutForm.coupon.chosen_product_id) {
      return undefined
    }

    return checkoutForm.coupon
  }
})

export const CHECKOUT_PRODUCT_COUPON_AMOUNT = createSelector<number>({
  key: 'checkoutProductCouponAmount',
  get: ({ get }) => {
    const coupon = get(CHECKOUT_PRODUCT_COUPON)

    if (!coupon || !coupon.value) {
      return 0
    }

    return coupon.value
  }
})

export const COUPON_STATE = createState<Coupon & { code: string } | null>({
  key: 'couponState',
  default: null
})

export const CHECKOUT_OPTIONS_ID_SELECTOR = createSelector<Id[]>({
  key: 'checkoutOptionsIdSelector',
  get: ({ get }) => {
    const checkoutForm = get(CHECKOUT_FORM_STATE)

    return checkoutForm.checkoutOptions.map((option: CheckoutOption) => option.id)
  }
})

export const CHECKOUT_PAYMENT_STATE = createState<PaymentStateEnum|null>({
  key: 'checkoutPaymentState',
  default: null
})

export const CHECKOUT_ORDER_ID = createState<OrderIdState>({
  key: 'checkoutCurrentOrderId',
  default: {},
  effects: [
    indexDbPersist('checkoutOrderIdState')
  ]
})

export const CHECKOUT_ORDER_ID_OF_FIRM_SELECTOR = createSelector<Id|null>({
  key: 'checkoutCurrentOrderIdOfFirmSelector',
  get: ({ get }) => {
    const firmSelected: Firm | null = get(FIRM_SELECTOR)
    const orderIds: OrderIdState | null = get(CHECKOUT_ORDER_ID)

    if (!firmSelected || !orderIds || !orderIds[firmSelected.id]) {
      return null
    }

    return orderIds[firmSelected.id]
  }
})
