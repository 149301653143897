import { Availability, Category, DateUtils, Id } from '@eo-storefronts/eo-core'
import { useEffect, useState } from 'react'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { CATEGORIES_STATE } from '~/src/stores/categories'
import { LANGUAGE_STATE } from '~/src/stores/lang'

interface ReturnsType {
  message: string | null,
}

const _checkAvailability = (availabilities: Availability[], dayId: number, time: Date): string | null => {
  const dayAvailabilities = availabilities.filter((availability: Availability) => availability.dayId === dayId)
  const lookUpDayId = DateUtils.getDayIdLikeMomentJs(time)

  if (!dayAvailabilities.length) {
    return null
  }

  for (const availability of dayAvailabilities) {
    const inlineAvailabilityTime = Number(availability.fromTime.replace(':', ''))
    const inlineLookUpTime = Number(DateUtils.formatTime(time).replace(':', ''))

    if (
      (availability.dayId === dayId && inlineLookUpTime < inlineAvailabilityTime)
      || availability.dayId !== lookUpDayId
    ) {
      // Number of days from day if the next available day is during the same week
      let nbrOfDays = availability.dayId - lookUpDayId
      const [ hours, minutes ] = availability.fromTime.split(':')

      // Number of days from day if the next available day is next week
      if (lookUpDayId > availability.dayId) {
        nbrOfDays = 7 - lookUpDayId + availability.dayId
      }

      const availableDate = DateUtils.addDays(nbrOfDays, time)
      availableDate.setHours(Number(hours))
      availableDate.setMinutes(Number(minutes))

      return DateUtils.calendarSentence(time, availableDate)
    }
  }

  return null
}

const _getNextAvailability = (availabilities: Availability[], time: Date): string => {
  const isoDay = DateUtils.getDayIdLikeMomentJs(time)

  for (let dayId = isoDay; dayId <= 7; dayId++) {
    const check = _checkAvailability(availabilities, dayId, time)

    if (!check) {
      continue
    }

    return check
  }

  for (let dayId = 0; dayId < isoDay; dayId++) {
    const check = _checkAvailability(availabilities, dayId, time)

    if (!check) {
      continue
    }

    return check
  }

  return ''
}

const _checkParentAvailability = (parentId: Id, time: Date, categories: Record<Id, Category>): string => {
  const parentCategory = categories[parentId]

  if (!parentCategory) {
    return ''
  }

  if (parentCategory.availabilities?.length) {
    return _getNextAvailability(parentCategory.availabilities, time)
  }

  if (parentCategory?.parentId !== null) {
    return _checkParentAvailability(parentCategory.parentId, time, categories)
  }

  return ''
}

const useCategoryAvailabilityMessage = (categoryId: string | null): ReturnsType => {
  const lang = useEoValue(LANGUAGE_STATE)
  const categories = useEoValue(CATEGORIES_STATE)
  const { t } = useTranslations()
  const [ message, setMessage ] = useState<string>('')

  useEffect(() => {
    if (!categoryId || !categories[categoryId]) {
      return
    }

    const category = categories[categoryId]
    const forDate = new Date()

    if (category.availabilities?.length) {
      const availability = _getNextAvailability(category.availabilities, forDate)
      availability !== '' && setMessage(`${t('category.availableOn')} ${availability}`)

      return
    }

    if (category.parentId !== null) {
      const parentAvailability = _checkParentAvailability(category.parentId, forDate, categories)
      parentAvailability !== '' && setMessage(`${t('category.availableOn')} ${parentAvailability}`)
    }
  }, [ lang ])

  return { message }
}

export {
  useCategoryAvailabilityMessage
}
