import { Box } from '@mui/material'
import { useEoValue } from '~/src/hooks/useEoState'
import { CUSTOMER_STATE } from '~/src/stores/customer'

interface Props {
  displayFirstname?: boolean,
  displayLastname?: boolean,
}

const CustomerLabel = ({ displayFirstname = true, displayLastname = true }: Props) => {
  const customer = useEoValue(CUSTOMER_STATE)

  if (!customer) {
    return null
  }

  return (
    <Box
      component='span'
      id='text'
    >
      {displayFirstname && customer.firstName} {displayLastname && customer.lastName}
    </Box>
  )
}

export default CustomerLabel
