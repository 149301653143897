import { useContext } from 'react'
import { Link } from '@mui/material'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { useTranslations } from '~/src/hooks/useTranslations'
import ResponsiveDialogContext, {
  ResponsiveDialogContextInterface
} from '~/src/components/mui-wrappers/responsive-dialog/ResponsiveDialogContext'

const ShowMore = () => {
  const { t } = useTranslations()
  const context = useContext<ResponsiveDialogContextInterface>(ResponsiveDialogContext)
  const muiTheme = useCustomMuiTheme()

  const handleOnClick = () => {
    if (!context) {
      return
    }

    context?.toggle && context.toggle()
  }

  return (
    <Link
      sx={{
        fontStyle: 'italic',
        cursor: 'pointer',
        [muiTheme.breakpoints.down('md')]: {
          px: 2
        }
      }}
      onClick={handleOnClick}
    >
      {t('global.showMore')}
    </Link>

  )
}

export default ShowMore
