import { Id, Price, Product } from '@eo-storefronts/eo-core'
import PriceHelper from '~/src/helpers/priceHelper'
import { createSelector, createSelectorFamily, createState } from '~/src/hooks/useEoState'
import { getProductPrice, searchProductsByName } from '~/src/services/ProductService'
import { CATEGORY_BY_ID_SELECTOR } from '~/src/stores/categories'
import { CHECKOUT_PRODUCT_COUPON } from '~/src/stores/checkout'
import { LANGUAGE_STATE } from '~/src/stores/lang'
import CartCoupon from '~/src/types/CartCoupon'
import { PRICE_LIST_STATE } from './product/price-list'

export const PRODUCT_ID_STATE = createState<Id | null>({
  key: 'productIdState',
  default: null
})

export const PRODUCTS_STATE = createState<Record<Id, Product>>({
  key: 'productsState',
  default: {}
})

export const PRODUCT_ID_SELECTOR = createSelector<Product | null>({
  key: 'productIdSelector',
  get: ({ get }) => {
    const productId = get(PRODUCT_ID_STATE)
    const products = get(PRODUCTS_STATE)

    if (!productId) {
      return null
    }

    return products[productId]
  }
})

export const PRODUCT_BY_ID_SELECTOR = createSelectorFamily<Product | null, Id>({
  key: 'productByIdSelector',
  get: (id: Id) => ({ get }) => {
    const products = get(PRODUCTS_STATE)

    return products[id] || null
  }
})

export const PRODUCT_PRICE_BY_ID_SELECTOR = createSelectorFamily<Price, Id>({
  key: 'productPriceByIdSelector',
  get: (id: Id) => ({ get }): Price => {
    const products = get(PRODUCTS_STATE)
    const priceLists = get(PRICE_LIST_STATE)
    const productPrice: Price = getProductPrice(products[id], priceLists)

    if (!productPrice) {
      return PriceHelper.transform(0)
    }

    return productPrice
  }
})

export const PRODUCTS_BY_IDS_SELECTOR = createSelectorFamily<Product[], string[]>({
  key: 'productsByIdsSelector',
  get: (ids: string[]) => ({ get }): Product[] => {
    const products = get(PRODUCTS_STATE)

    return ids
      .filter((key) => products[key])
      .map((key) => products[key])
  }
})

export const PRODUCTS_FROM_CATEGORY_SELECTOR = createSelector<Product[]>({
  key: 'productsFromCategorySelector',
  get: ({ get }) => {
    const category = get(CATEGORY_BY_ID_SELECTOR)
    const products = get(PRODUCTS_STATE)

    if (!category) {
      return []
    }

    return (category.products || []).map(
      (productId: string) => products[productId]
    )
  }
})

export const PRODUCT_SEARCH_SELECTOR = createSelectorFamily<string[], string>({
  key: 'productSearchSelector',
  get: (search: string) => ({ get }): string[] => {
    const products = get(PRODUCTS_STATE)
    const lang = get(LANGUAGE_STATE)

    return searchProductsByName(search, lang, products)
  }
})

export const PRODUCTS_SPOTLIGHTED_SELECTOR = createSelector<Product[]>({
  key: 'productSpotlightedSelector',
  get: ({ get }) => {
    const products: Record<Id, Product> = get(PRODUCTS_STATE)

    return Object.values(products).filter(product => product.featured)
  }
})

export const PRODUCT_COUPON = createSelectorFamily<CartCoupon | undefined, Id>({
  key: 'productCoupon',
  get: (productId: Id) => ({ get }) => {
    const coupon = get(CHECKOUT_PRODUCT_COUPON)

    if (coupon?.chosen_product_id === productId) {
      return coupon
    }

    return undefined
  }
})
