import { Firm, OrderingMethodNames } from '@eo-storefronts/eo-core'
import { AlertColor } from '@mui/material'
import { useEffect, useState } from 'react'
import { useFirmAcceptsOrder } from '~/src/hooks/orders/useFirmAcceptsOrder'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { checkCategoryAvailability } from '~/src/services/CategoryService'
import { checkProductAvailability } from '~/src/services/ProductService'
import {
  CART_OF_FIRM_SELECTOR,
  CART_SUBTOTAL_WITH_DISCOUNTS_SELECTOR,
  CART_TOTAL_ITEMS_SELECTOR
} from '~/src/stores/cart'
import { CATEGORIES_STATE } from '~/src/stores/categories'
import {
  CHECKOUT_FORM_STATE,
  CheckoutFormState,
  GET_TOTAL_AMOUNT_OF_CHECKOUT_OPTIONS_SELECTOR
} from '~/src/stores/checkout'
import { FIRM_SELECTOR, IS_DELIVERY_METHOD_ENABLED_FOR_FIRM } from '~/src/stores/firm'
import { LANGUAGE_STATE } from '~/src/stores/lang'
import { PRODUCTS_STATE } from '~/src/stores/product'
import { CartProduct } from '~/src/types/CartProduct'

export interface OrderErrorType {
  variant: AlertColor,
  text: string,
  type?: string,
  closable: boolean,
}

interface ReturnsType {
  errors: OrderErrorType[],
  getErrors(): OrderErrorType[],
}

const useValidateOrder = (): ReturnsType => {
  const categories = useEoValue(CATEGORIES_STATE)
  const products = useEoValue(PRODUCTS_STATE)
  const cart = useEoValue(CART_OF_FIRM_SELECTOR)
  const lang = useEoValue(LANGUAGE_STATE)
  const firm = useEoValue<Firm | null>(FIRM_SELECTOR)
  const cartTotalItems = useEoValue(CART_TOTAL_ITEMS_SELECTOR)
  const totalAmountOfCart = useEoValue(CART_SUBTOTAL_WITH_DISCOUNTS_SELECTOR)
  const totalAmountOfCheckoutOptions = useEoValue(GET_TOTAL_AMOUNT_OF_CHECKOUT_OPTIONS_SELECTOR)
  const checkoutForm = useEoValue<CheckoutFormState>(CHECKOUT_FORM_STATE)
  const deliverMethodEnabledForFirm = useEoValue(IS_DELIVERY_METHOD_ENABLED_FOR_FIRM)
  const [ errors, setErrors ] = useState<OrderErrorType[]>([])
  const { checkAll } = useFirmAcceptsOrder()
  const { t } = useTranslations()

  const getErrors = (): OrderErrorType[] => {
    if (!firm) {
      return []
    }

    const time: Date = checkoutForm.orderingMethod.time ? new Date(checkoutForm.orderingMethod.time) : new Date()
    const orderErrors: OrderErrorType[] = []

    if (!cart.length) {
      orderErrors.push({
        variant: 'error',
        text: t('cart.empty.oops'),
        closable: false
      })
    }

    cart.forEach((cartProduct: CartProduct) => {
      const product = products[cartProduct.id]

      if (!product) {
        return
      }

      const productCategoryAvailable = checkCategoryAvailability(product.categoryId, categories, time)
      const productAvailable = checkProductAvailability(product, time)

      if (!productCategoryAvailable || !productAvailable.inStock || productAvailable.snoozed) {
        orderErrors.push({
          variant: 'error',
          text: `${t('cart.errors.productCannotBeOrdered')}: ${product.name[lang]}`,
          closable: false
        })
      }
    })

    if (!checkAll()) {
      orderErrors.push({
        variant: 'error',
        text: t('checkout.ordersOnlyDuringOpeningHours'),
        closable: false
      })
    }

    if (checkoutForm.orderingMethod.method === null) {
      orderErrors.push({
        variant: 'error',
        text: t('checkout.errors.noOrderingMethodSelected'),
        closable: false
      })
    }

    if (
      checkoutForm.orderingMethod.method
      && !deliverMethodEnabledForFirm
    ) {
      orderErrors.push({
        variant: 'error',
        text: t('checkout.errors.orderingMethodNotValid'),
        closable: false
      })
    }

    if (
      checkoutForm.orderingMethod.time === null
      && !checkoutForm.orderingMethod.asap
      && checkoutForm.orderingMethod.method !== OrderingMethodNames.ON_THE_SPOT
    ) {
      orderErrors.push({
        variant: 'error',
        text: t('checkout.errors.noAvailableTime'),
        closable: false
      })
    }

    if (checkoutForm.orderingMethod.method === 'table' &&
      (!checkoutForm.orderingMethod.tableNumber ||
        !checkoutForm.orderingMethod.tableUid ||
        !checkoutForm.orderingMethod.tableMethod)
    ) {
      orderErrors.push({
        variant: 'error',
        type: 'address',
        text: t('checkout.errors.selectATable'),
        closable: false
      })
    }

    if (checkoutForm.orderingMethod.method === OrderingMethodNames.DELIVERY) {
      if (!checkoutForm.orderingMethod.address) {
        orderErrors.push({
          variant: 'error',
          type: 'address',
          text: t('checkout.errors.selectAnAddress'),
          closable: false
        })
      }

      if (
        checkoutForm.orderingMethod.minAmount
        && checkoutForm.orderingMethod.minAmount > totalAmountOfCart + totalAmountOfCheckoutOptions
      ) {
        orderErrors.push({
          variant: 'error',
          text: t(
            'checkout.errors.minAmountForDeliveryNotReached',
            {
              minimal_order_amount: `${firm.settings.currency.symbol} ${checkoutForm.orderingMethod.minAmount.toFixed(2)}`
            }
          ),
          closable: false
        })
      } else if (
        checkoutForm.orderingMethod.deliveryFreeFromPrice
        && checkoutForm.orderingMethod.deliveryFreeFromPrice > totalAmountOfCart + totalAmountOfCheckoutOptions
      ) {
        orderErrors.push({
          variant: 'info',
          text: t(
            'checkout.info.freeDeliveryFromPrice',
            {
              free_from_price: `${firm.settings.currency.symbol} ${checkoutForm.orderingMethod.deliveryFreeFromPrice.toFixed(2)}`
            }
          ),
          closable: true
        })
      }

      if (firm.settings.maxCartAmount && firm.settings.maxCartAmount < cartTotalItems) {
        orderErrors.push({
          variant: 'error',
          text: t('checkout.errors.maxCartAmount', { maxCartAmount: firm.settings.maxCartAmount }),
          closable: false
        })
      }
    }

    return orderErrors
  }

  useEffect(() => {
    setErrors(getErrors())
  }, [
    cartTotalItems,
    checkoutForm,
    categories,
    deliverMethodEnabledForFirm,
    firm,
    totalAmountOfCart,
    totalAmountOfCheckoutOptions
  ])

  return {
    errors,
    getErrors
  }
}

export default useValidateOrder
