import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import useGetComponentStyle from '~/src/hooks/layout/useGetComponentStyle'
import StylesOrderingMethodsSummaryEditButtonServiceInterface
  from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-edit-button/styles/StylesOrderingMethodsSummaryEditButtonServiceInterface'
import getOrderingMethodsSummaryEditButtonTemplateService
  from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-edit-button/styles/GetOrderingMethodsSummaryEditButtonTemplateService'

const useGetOrderingMethodsSummaryEditButtonTemplate = (): StylesOrderingMethodsSummaryEditButtonServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const componentStyle = useGetComponentStyle('orderingMethodsSummary')

  return getOrderingMethodsSummaryEditButtonTemplateService(componentStyle, muiTheme)
}

export default useGetOrderingMethodsSummaryEditButtonTemplate
