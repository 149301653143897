import { ModifierGroup, Product } from '@eo-storefronts/eo-core'
import { useContext } from 'react'
import ModifierGroups from '~/src/components/products/modifier-groups'
import usePreselectModifiers from '~/src/hooks/products/usePreselectModifiers'
import { useEoValue } from '~/src/hooks/useEoState'
import {
  CartProductModifierGroupStateInterface
} from '~/src/stores/cart/cart-product-modifier-groups'
import { PRODUCT_MODIFIER_GROUP_SELECTOR } from '~/src/stores/modifier-groups'
import ProductConfigurationModalContext from '../../modals/configuration/ProductConfigurationModalContext'

interface Props {
  product: Product,
}

const ProductDetailsModifierGroups = ({ product }: Props) => {
  const context = useContext(ProductConfigurationModalContext)
  const productModifierGroup = useEoValue<ModifierGroup[]>(PRODUCT_MODIFIER_GROUP_SELECTOR(product.id))

  usePreselectModifiers(product)

  const handleOnModifierGroupsChange = (cartModifierGroups: CartProductModifierGroupStateInterface[]) => {
    context.updateModifierGroups(cartModifierGroups)
  }

  return (
    <ModifierGroups
      groups={productModifierGroup}
      cartModifierGroups={context.selectedModifierGroups}
      onChange={handleOnModifierGroupsChange}
    />
  )
}

export default ProductDetailsModifierGroups
