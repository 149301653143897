import { Address, FirmStoreLocator } from '@eo-storefronts/eo-core'
import { findFirmByAddress } from '~/src/services/FirmService'

interface ReturnsType {
  find(address: Address): Promise<FirmStoreLocator | null>,
}

const useFindFirmByAddress = (): ReturnsType => {
  const find = async (address: Address): Promise<FirmStoreLocator | null> => {
    return findFirmByAddress(
      address
    )
  }

  return { find }
}

export {
  useFindFirmByAddress
}
