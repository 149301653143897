import OrderingMethodsSummaryEditButton
  from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-edit-button'
import { Box } from '@mui/material'
import OrderingMethodsSummaryMainButtons
  from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-main-buttons'
import { useEoValue } from '~/src/hooks/useEoState'
import { FROZEN_DELIVERY_METHOD_STATE } from '~/src/stores/checkout'
import { useFirmAcceptsOrder } from '~/src/hooks/orders/useFirmAcceptsOrder'
import FirmClosed from '~/src/components/firms/FirmClosed'
import { useContext } from 'react'
import ResponsiveDialogContext, {
  ResponsiveDialogContextInterface
} from '~/src/components/mui-wrappers/responsive-dialog/ResponsiveDialogContext'
import CanOnlyOrderDuringOperationalHoursResponsiveModal
  from '~/src/components/error/CanOnlyOrderDuringOperationalHoursResponsiveModal'
import useGetOrderingMethodsSummaryTemplate
  from '~/src/components/ordering-methods/ordering-methods-summary/styles/useGetOrderingMethodsSummaryTemplate'

const OrderingMethodsSummary = () => {
  const styles = useGetOrderingMethodsSummaryTemplate()
  const responsiveDialogContext = useContext<ResponsiveDialogContextInterface>(ResponsiveDialogContext)
  const frozenOrderingMethod = useEoValue(FROZEN_DELIVERY_METHOD_STATE)
  const { checkAll } = useFirmAcceptsOrder()

  const handleOnClick = () => {
    if (frozenOrderingMethod) {
      return
    }

    styles.getContainerOnClick(frozenOrderingMethod, responsiveDialogContext)
  }

  if (!checkAll()) {
    return (
      <>
        <FirmClosed sx={styles.getFirmClosedSx()}/>
        <CanOnlyOrderDuringOperationalHoursResponsiveModal/>
      </>
    )
  }

  return (
    <Box
      sx={styles.getContainerSx(frozenOrderingMethod)}
      onClick={handleOnClick}
    >
      <OrderingMethodsSummaryMainButtons/>
      {!frozenOrderingMethod && <OrderingMethodsSummaryEditButton/>}
    </Box>
  )
}

export default OrderingMethodsSummary
