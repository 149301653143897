import { Displayable } from '@eo-storefronts/eo-core'
import { createSelector, createState } from '~/src/hooks/useEoState'
import { IS_MARKETPLACE_SELECTOR } from '~/src/stores/brand'
import { FIRM_SELECTOR, SELECTED_SHOP_STATE } from '~/src/stores/firm'
import { LANGUAGE_STATE } from '~/src/stores/lang'

const key = 'layoutState'

export type LogoOptions = {
  disableLink: boolean,
}

export type HeaderState = Displayable & {
  type: 'navigation' | 'simple' | 'default',
  title: string,
  logo: LogoOptions,
  displayMenuBurger: boolean,
  displayCart: boolean,
  displayCurrentFirm: boolean,
  displayLanguagesSelector: boolean,
  displayUserActions: boolean,
  displayProductSearch: boolean,
  displayLocationSearch: boolean,
  displayDeliveryOptions: boolean,
  className?: string,
}

export type SubHeaderState = Displayable & {
  variant: 'default' | 'small',
  className?: string,
}

export type FooterState = Displayable & {
  displayLinks: boolean,
  displayInformations: boolean,
  displayGoToCheckoutBtn: boolean,
  displayGoToCartBtn: boolean,
}

export type MobileNavigationState = Displayable

export interface ContentState {
  displayNotification: boolean,
}

export type MainSideBarState = Displayable & {
  displayCart: boolean,
  displayDeliveryOptions: boolean,
  displayOrderSummary: boolean,
}

export type LayoutState = {
  header: HeaderState,
  subHeader: SubHeaderState,
  mainSideBar: MainSideBarState,
  content: ContentState,
  footer: FooterState,
  mobileNavigation: MobileNavigationState,
  isMobile: boolean,
}

const initialHeaderState = (): HeaderState => (
  {
    display: false,
    type: 'default',
    title: '',
    logo: {
      disableLink: false
    },
    displayMenuBurger: false,
    displayCart: false,
    displayCurrentFirm: false,
    displayDeliveryOptions: false,
    displayUserActions: false,
    displayProductSearch: false,
    displayLocationSearch: false,
    displayLanguagesSelector: false
  }
)

const initialSubheaderState = (): SubHeaderState => (
  {
    display: false,
    variant: 'default'
  }
)

const initialContentSideBarState = (): MainSideBarState => (
  {
    display: false,
    displayCart: false,
    displayDeliveryOptions: false,
    displayOrderSummary: false
  }
)

const initialFooterState = (): FooterState => (
  {
    display: false,
    displayInformations: false,
    displayLinks: false,
    displayGoToCartBtn: false,
    displayGoToCheckoutBtn: false
  }
)

const initialContentState = (): ContentState => (
  {
    displayNotification: false
  }
)

const initMobileNavigationState = (): MobileNavigationState => (
  {
    display: false
  }
)

export const initialState = (): LayoutState => ({
  header: initialHeaderState(),
  subHeader: initialSubheaderState(),
  mainSideBar: initialContentSideBarState(),
  content: initialContentState(),
  footer: initialFooterState(),
  mobileNavigation: initMobileNavigationState(),
  isMobile: false
})

export const LAYOUT_STATE = createState<LayoutState>({
  key,
  default: initialState()
})

export const IS_ON_MARKETPLACE_PAGE_SELECTOR = createSelector<boolean>({
  key: 'isOnMarketplacePageSelector',
  get: ({ get }) => {
    const isMarketplace = get(IS_MARKETPLACE_SELECTOR)
    const selectedShop = get(SELECTED_SHOP_STATE)

    if (!isMarketplace) {
      return false
    }

    return selectedShop === ''
  }
})

export const DOCUMENT_TITLE_SELECTOR = createSelector<string>({
  key: 'documentTitleSelector',
  get: ({ get }) => {
    const firm = get(FIRM_SELECTOR)
    const language = get(LANGUAGE_STATE)

    return firm?.name[language] || ''
  }
})

export const FAVICON_SELECTOR = createSelector<string>({
  key: 'faviconSelector',
  get: ({ get }) => {
    const firm = get(FIRM_SELECTOR)

    return firm?.assets.logoSquare || ''
  }
})

export const META_TITLE_SELECTOR = createSelector<string>({
  key: 'metaTitleSelector',
  get: ({ get }) => {
    const firm = get(FIRM_SELECTOR)
    const language = get(LANGUAGE_STATE)

    return firm?.name[language] || ''
  }
})
