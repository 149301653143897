import { Customer, Firm, LoyaltyService } from '@eo-storefronts/eo-core'
import { useEoValue } from '~/src/hooks/useEoState'
import { BRAND_STATE } from '~/src/stores/brand'
import {
  CUSTOMER_LOYALTY_POINT_BALANCE_SELECTOR,
  CUSTOMER_LOYALTY_VOUCHERS_BALANCE_SELECTOR,
  CUSTOMER_STATE
} from '~/src/stores/customer'

const useLoyaltyService = (): LoyaltyService => {
  const brand = useEoValue<Firm|null>(BRAND_STATE)
  const customer = useEoValue<Customer|null>(CUSTOMER_STATE)
  const customerVoucherBalance = useEoValue(CUSTOMER_LOYALTY_VOUCHERS_BALANCE_SELECTOR)
  const customerPointBalance = useEoValue(CUSTOMER_LOYALTY_POINT_BALANCE_SELECTOR)

  return new LoyaltyService(
    brand,
    customer,
    customerVoucherBalance,
    customerPointBalance
  )
}

export default useLoyaltyService
