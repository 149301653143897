import { LoyaltyPointTypeEnum, Product } from '@eo-storefronts/eo-core'
import { useEoValue } from '~/src/hooks/useEoState'
import { LOYALTY_IS_ENABLED_AND_CUSTOMER_IS_LOGGED_IN_SELECTOR, LOYALTY_POINT_TYPE_SELECTOR } from '~/src/stores/loyalty'
import ProductLoyaltyPercentage from '~/src/components/products/loyalty-value/ProductLoyaltyPercentage'
import ProductLoyaltyPoint from '~/src/components/products/loyalty-value/ProductLoyaltyPoint'

interface Props {
  product: Product | null,
  quantity?: number,
  size?: 'small' | 'large',
}

const ProductLoyaltyValue = ({ product, quantity, size = 'small' }: Props) => {
  const isLoyaltyEnabled = useEoValue(LOYALTY_IS_ENABLED_AND_CUSTOMER_IS_LOGGED_IN_SELECTOR)
  const loyaltyPointType = useEoValue<LoyaltyPointTypeEnum | undefined>(LOYALTY_POINT_TYPE_SELECTOR)

  if (!isLoyaltyEnabled || !loyaltyPointType || !product) {
    return null
  }

  if (loyaltyPointType === LoyaltyPointTypeEnum.PERCENT) {
    return (
      <ProductLoyaltyPercentage
        product={product}
        size={size}
        quantity={quantity}/>
    )
  }

  return (
    <ProductLoyaltyPoint
      product={product}
      size={size}
      quantity={quantity}/>
  )
}

export default ProductLoyaltyValue
