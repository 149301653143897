import { Location } from '@eo-storefronts/eo-core'
import { Box, BoxProps, LinearProgress } from '@mui/material'
import LocationCard from '~/src/components/marketplace/locations/location-card'
import LocationResponsiveDialog from '~/src/components/marketplace/locations/location-responsive-dialog'
import Title from '~/src/components/mui-wrappers/Title'
import SlideFromContainer from '~/src/components/mui-wrappers/animations/SlideFromContainer'
import useFetchLocations from '~/src/hooks/marketplace/locations/useFetchLocations'
import { useAsyncEffect } from '~/src/hooks/useAsyncEffect'
import { useEoState, useEoValue } from '~/src/hooks/useEoState'
import { MARKETPLACE_SEARCH_VALUE_STATE } from '~/src/stores/marketplace/filter'
import { MARKETPLACE_LOCATIONS_STATE } from '~/src/stores/marketplace/locations'

const SearchResults = ({ sx, ...boxProps }: BoxProps) => {
  const { fetch, loading } = useFetchLocations()
  const searchValue = useEoValue(MARKETPLACE_SEARCH_VALUE_STATE)
  const [ locations, setLocations ] = useEoState(MARKETPLACE_LOCATIONS_STATE)

  useAsyncEffect(async () => {
    if (!searchValue) {
      return
    }

    await fetch(
      {
        uid: '',
        updateLoader: true,
        updateState: true,
        search: searchValue,
        filters: []
      }
    )

    return () => {
      setLocations([])
    }
  }, [ searchValue ])

  if (loading) {
    return (
      <Box sx={{ width: '100%', pb: 1 }}>
        <LinearProgress/>
      </Box>
    )
  }

  if (!loading && !locations.length) {
    return (
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        pt: 2,
        pb: 1.5
      }}>
        <Title component={'h5'}>Ooups, we haven't found anything</Title>
      </Box>
    )
  }

  return (
    <Box
      sx={{
        minHeight: '50px',
        maxHeight: '50vh',
        overflowY: 'auto',
        height: '100%',
        ...sx
      }}
      {...boxProps}>
      <SlideFromContainer in appear direction={'up'}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1
        }}>
          {locations.map((location: Location) => (
            <LocationResponsiveDialog
              key={`${location.id}-${location.name}`}
              id={location.id}
              activator={(
                <LocationCard
                  location={location}
                  direction={'horizontal'}
                  sx={{
                    '&:hover': {
                      cursor: 'pointer'
                    }
                  }}
                />
              )}
            />
          ))}
        </Box>
      </SlideFromContainer>
    </Box>
  )
}

export default SearchResults
