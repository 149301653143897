import { useEoValue } from '~/src/hooks/useEoState'
import { FIRM_THEME_SELECTOR } from '~/src/stores/firm'
import getProductsModalListContainerTemplateService
  from '~/src/components/products/modals/products-list/container/styles/GetProductsModalListContainerTemplateService'
import StylesProductsModalListContainerServiceInterface
  from '~/src/components/products/modals/products-list/container/styles/StylesProductsModalListContainerServiceInterface'

const useGetProductsModalListContainerTemplate = (): StylesProductsModalListContainerServiceInterface => {
  const firmTheme = useEoValue(FIRM_THEME_SELECTOR)

  return getProductsModalListContainerTemplateService(firmTheme?.components?.productsList)
}

export default useGetProductsModalListContainerTemplate
