import { ModifierGroup } from '@eo-storefronts/eo-core'
import { Box, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslations } from '~/src/hooks/useTranslations'

interface Props {
  group: ModifierGroup,
}

const ModifierGroupSubTitle = ({ group }: Props) => {
  const { t } = useTranslations()
  const [ sentence, setSetence ] = useState<string>('')

  const _buildMinMaxSentence = () => {
    const sentences: string[] = []

    if (group.isRequired) {
      sentences.push(`${t('global.min')}: ${group.multipleChoiceMin ?? 1}`)
    }

    if (group.multipleChoiceMax) {
      sentences.push(`${t('global.max')}: ${group.multipleChoiceMax}`)
    }

    return sentences.join(', ')
  }

  useEffect(() => {
    setSetence(_buildMinMaxSentence())
  }, [ group ])

  if (!sentence) {
    return null
  }

  return (
    <Typography variant='body2'>
      <Box 
        component={'span'} 
        sx={{ fontWeight: 'bold' }}
      >
        {group.isRequired ? t('global.required') : t('global.optional')} &bull;
      </Box>
      <> {sentence}</>
    </Typography>
  )
}

export default ModifierGroupSubTitle
