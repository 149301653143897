import { Product } from '@eo-storefronts/eo-core'
import { Box } from '@mui/material'
import Image from '~/src/components/mui-wrappers/Image'
import useProductDetailsTemplateService from '~/src/components/products/product-details/styles/useProductDetailsTemplateService'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { useEoValue } from '~/src/hooks/useEoState'
import { productHasImage } from '~/src/services/ProductService'
import { LANGUAGE_STATE } from '~/src/stores/lang'

interface Props {
  product: Product,
}

const ProductDetailsImg = ({ product }: Props) => {
  const styles = useProductDetailsTemplateService()
  const lang = useEoValue(LANGUAGE_STATE)
  const muiTheme = useCustomMuiTheme()

  if (!productHasImage(product)) {
    return null
  }

  return (
    <Box sx={styles.getImageSx()}>
      <Image
        src={product.images[0]?.detail}
        alt={product.name[lang] || ''}
        isDefault={product.images[0]?.isDefault}
        sx={{
          width: '100%',
          [muiTheme.breakpoints.down('md')]: {
            height: '100%'
          }
        }}
      />
    </Box>
  )
}

export default ProductDetailsImg
