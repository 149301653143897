import { Id, ModifierGroup } from '@eo-storefronts/eo-core'
import { createSelectorFamily, createState } from '~/src/hooks/useEoState'
import { PRODUCTS_STATE } from '~/src/stores/product'

export const MODIFIER_GROUPS_STATE = createState<Record<string, ModifierGroup>>({
  key: 'modifierGroupsState',
  default: {}
})

export const PRODUCT_MODIFIER_GROUP_SELECTOR = createSelectorFamily<ModifierGroup[], Id>({
  key: 'productModifierGroupSelector',
  get: (productId: Id) => ({ get }) => {
    const products = get(PRODUCTS_STATE)
    const modifierGroups = get(MODIFIER_GROUPS_STATE)

    if (!products[productId]) {
      return []
    }

    const productModifierGroups: ModifierGroup[] = []

    for (const modifierGroupId of products[productId].modifierGroups) {
      if (!modifierGroups[modifierGroupId]) {
        continue
      }

      productModifierGroups.push(modifierGroups[modifierGroupId])
    }

    return productModifierGroups
  }
})
