import { Typography } from '@mui/material'
import Price from '~/src/components/Price'
import useModifiersService from '~/src/hooks/products/modifier-groups/modifiers/useModifiersService'
import { useEoValue } from '~/src/hooks/useEoState'
import { CartProductModifierStateInterface } from '~/src/stores/cart/cart-product-modifier-groups'
import { MODIFIER_FROM_CART_SELECTOR } from '~/src/stores/modifiers'

interface Props {
  cartModifier: CartProductModifierStateInterface,
  groupId: string,
}

const CartModifierInlinePrice = ({ cartModifier, groupId }: Props) => {
  const modifier = useEoValue(MODIFIER_FROM_CART_SELECTOR({ cartModifier, groupId }))
  const { calculatePrice } = useModifiersService(modifier)

  if (cartModifier.action === 'rem') {
    return null
  }

  return (
    <Typography variant="body2">
      <Price
        price={calculatePrice(cartModifier)}
        displayZeroAmount={ false }
      />
    </Typography>
  )
}

export default CartModifierInlinePrice
