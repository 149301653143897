import { DateUtils, Modifier, ModifierGroup, Price, PriceList, Product } from '@eo-storefronts/eo-core'
import PriceHelper from '~/src/helpers/priceHelper'
import {
  CartProductModifierGroupStateInterface,
  CartProductModifierStateInterface
} from '~/src/stores/cart/cart-product-modifier-groups'
import NumberUtils from '~/src/utils/NumberUtils'
import { convertProductToModifier } from './ProductService'

export default class ModifierService {
  private readonly _modifier: Modifier
  private readonly _allModifierGroups: Record<string, ModifierGroup>
  private readonly _allModifiers: Record<string, Modifier>
  private readonly _allProducts: Record<string, Product>

  constructor(
    modifier: Modifier,
    allModifierGroups: Record<string, ModifierGroup>,
    allModifiers: Record<string, Modifier>,
    allProducts: Record<string, Product>
  ) {
    this._modifier = modifier
    this._allModifierGroups = allModifierGroups
    this._allModifiers = allModifiers
    this._allProducts = allProducts
  }

  public isModifierInStock(): boolean {
    return this._modifier.inStock
  }

  public isModifierSnoozed(time: Date): boolean {
    if (!this._modifier.snooze?.start || !this._modifier.snooze?.end) {
      return false
    }

    return DateUtils.isAfter(time, new Date(this._modifier.snooze.start))
      && DateUtils.isBefore(time, new Date(this._modifier.snooze.end))
  }

  public isModifierLocked(): boolean {
    return this._modifier.isLocked
  }

  public calculatePrice(
    cartModifier: CartProductModifierStateInterface,
    priceLists: PriceList[]
  ): Price {
    let price: Price = getModifierPrice(this._modifier, priceLists)

    if (cartModifier.quantity) {
      Object.keys(this._modifier.price).forEach((key) => {
        const objectKey: keyof Price = key as keyof Price
        let basePrice = price[objectKey]

        cartModifier.modifierGroups.forEach((mg: CartProductModifierGroupStateInterface) => {
          mg.modifiers.forEach((m: CartProductModifierStateInterface) => {
            if (m.action === 'rem') {
              return
            }

            basePrice += (new ModifierService(
              m.type === 'product' ? convertProductToModifier(this._allModifierGroups[mg.id], this._allProducts[m.id]) : this._allModifiers[m.id],
              this._allModifierGroups,
              this._allModifiers,
              this._allProducts
            )).calculatePrice(m, priceLists)[key as keyof Price]
          })
        })

        price = {
          ...price,
          [objectKey]: NumberUtils.round(basePrice, 2) * cartModifier.quantity
        }
      })
    }

    return price
  }
}

export const getModifierPriceList = (modifier: Modifier, priceLists: PriceList[]) => {
  if ([ 'product', 'paid' ].includes(modifier.ingredientType)) {
    return null
  }

  const priceList = priceLists.find((pl: PriceList) => pl.item_uid === modifier.uid)

  return priceList ?? null
}

export const getModifierPrice = (modifier: Modifier, priceLists: PriceList[]): Price => {
  if ([ 'product', 'paid' ].includes(modifier.ingredientType)) {
    return modifier.price
  }

  const priceList = getModifierPriceList(modifier, priceLists)

  if (priceList) {
    return PriceHelper.transform(priceList.price)
  }

  return modifier.price
}

export const modifierHasPromotion = (modifier: Modifier, priceLists: PriceList[]): boolean => {
  const priceList = getModifierPriceList(modifier, priceLists)

  if (!priceList) {
    return false
  }

  return priceList.is_promotion
}

export const modifierHasImage = (modifier: Modifier): boolean => {
  return (modifier.images || []).some((image) => image.detail)
}
