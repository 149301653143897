import { Firm, OpeningHour } from '@eo-storefronts/eo-core'
import FirmTimeslot from '~/src/components/firms/FirmTimeslot'
import { useEoValue } from '~/src/hooks/useEoState'
import { buildOpeningHoursString } from '~/src/services/OpeningHourService'
import { LANGUAGE_STATE } from '~/src/stores/lang'

interface Props {
  firm: Firm,
}

const FirmTimeslotsUnGrouped = ({ firm }: Props) => {
  const { openingHours: openingHours } = firm.settings.periods
  const lang = useEoValue(LANGUAGE_STATE)

  if (!openingHours?.length) {
    return null
  }

  return (
    <>
      {openingHours.map((slot: OpeningHour, index: number) => (
        <FirmTimeslot
          key={index}
          day={slot.day[lang]}
          openingHours={buildOpeningHoursString(slot)}
        />
      ))}
    </>
  )
}

export default FirmTimeslotsUnGrouped
