import Title from '~/src/components/mui-wrappers/Title'
import { memo, MouseEvent } from 'react'
import { useTranslations } from '~/src/hooks/useTranslations'
import ProductsListModal from '~/src/components/products/modals/products-list'
import useCoupon from '~/src/hooks/cart/useCoupon'

export interface ProductModalsCouponProps {
  productsId: string[],
}

const ProductModalsCoupon = ({ productsId }: ProductModalsCouponProps) => {
  const { t } = useTranslations()
  const { chooseProduct } = useCoupon()

  const _handleOnProductChosen = (e: MouseEvent<HTMLElement>, productId: string) => {
    if (!productId) {
      return
    }

    e.stopPropagation()
    e.preventDefault()

    chooseProduct(productId)
  }

  return (
    <ProductsListModal
      openByDefault={true}
      name={'coupon-products-modal'}
      Title={<Title>{t('products.chooseFromList')}</Title>}
      productIds={productsId}
      onProductChoose={_handleOnProductChosen}
      listSx={{
        ItemsSx: {
          cursor: 'pointer',
          '*': {
            pointerEvents: 'none'
          }
        }
      }}
    />
  )
}

export default memo(ProductModalsCoupon)
