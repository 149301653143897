import { Availability, Category, DateUtils, Id } from '@eo-storefronts/eo-core'

const findCategoryByName = (name: string, categories: Record<Id, Category>): Category | undefined => {
  return Object.values(categories).find(
    (category: Category) => Object.values(category.name).includes(decodeURIComponent(name))
  )
}

const checkCategoryAvailability = (categoryId: Id | null, categories: Record<Id, Category>, time: Date): boolean => {
  if (!categoryId) {
    return true
  }

  const category = categories[categoryId]

  if (!category) {
    return true
  }

  if (category.availabilities?.length > 0) {
    const availabilities = category.availabilities.filter((a: Availability) => a.dayId === DateUtils.getDayIdLikeMomentJs(time))

    if (!availabilities.length) {
      return false
    }

    const timeBetween =  availabilities.reduce(
      (status: boolean, availability: Availability) => {
        return status || DateUtils.timeIsBetween(
          DateUtils.formatTime(time),
          {
            start: availability.fromTime,
            end: availability.toTime
          }
        )
      },
      false
    )

    if (!timeBetween) {
      return false
    }
  }

  if (category.parentId !== null) {
    return checkCategoryAvailability(category.parentId, categories, time)
  }

  return true
}

export {
  checkCategoryAvailability, findCategoryByName
}

