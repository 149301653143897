import { Address } from '@eo-storefronts/eo-core'
import { Box, Theme, useMediaQuery } from '@mui/material'
import { useContext, useState } from 'react'
import AddCustomerAddressDialog from '~/src/components/customers/addresses/AddCustomerAddressDialog'
import ChooseCustomerAddressActions from '~/src/components/customers/addresses/ChooseCustomerAddressActions'
import CustomerAddresses from '~/src/components/customers/addresses/CustomerAddresses'
import ResponsiveDialogContext, {
  ResponsiveDialogContextInterface
} from '~/src/components/mui-wrappers/responsive-dialog/ResponsiveDialogContext'
import Title from '~/src/components/mui-wrappers/Title'

interface Props {
  selected: Address | null,
  loading?: boolean,
  title?: string,
  closeOnSave?: boolean,
  onChoose(address: Address): void,
}

const ChooseCustomerAddress = ({ closeOnSave = false, loading, selected, onChoose, title }: Props) => {
  const [ address, setAddress ] = useState<Address | null>(selected)
  const responsiveDialogContext = useContext<ResponsiveDialogContextInterface>(ResponsiveDialogContext)
  const onSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  const handleOnSelect = (a: Address) => {
    setAddress(a)
  }

  const handleOnCancel = () => {
    responsiveDialogContext?.toggleClose && responsiveDialogContext.toggleClose()
  }

  const handleOnSave = () => {
    address && onChoose(address)
    closeOnSave && responsiveDialogContext.toggleClose && responsiveDialogContext.toggleClose()
  }

  return (
    <Box
      sx={{
        display: 'grid',
        maxHeight: onSmallScreen ? '100%' : '500px',
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'auto 1fr auto',
        gridTemplateAreas: '"title" "addresses" "actions"'
      }}
    >
      {title && (
        <Title
          variant='h5'
          component='p'
          sx={{ gridArea: 'title', mb: 2 }}
        >
          {title}
        </Title>
      )}
      <Box sx={{
        gridArea: 'addresses',
        overflow: 'auto',
        backgroundColor: 'wells.main',
        color: 'wells.contrastText',
        padding: '0.2rem'
      }}>
        <CustomerAddresses selected={selected} onSelect={handleOnSelect}/>
      </Box>
      <Box sx={{ gridArea: 'actions' }}>
        <AddCustomerAddressDialog/>
        <ChooseCustomerAddressActions
          sx={{ mt: 3 }}
          loading={loading}
          onSave={handleOnSave}
          onCancel={handleOnCancel}
        />
      </Box>
    </Box>
  )
}

export default ChooseCustomerAddress
