import { OrderingMethodNames, OrderOnSpotCustomMethod, OrderOnSpotCustomMethodLocation } from '@eo-storefronts/eo-core'
import { useEffect, useState } from 'react'
import { useEoValue } from '~/src/hooks/useEoState'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import { FIRM_SELECTOR } from '~/src/stores/firm'

interface ReturnsType {
  eatinLocations: Array<OrderOnSpotCustomMethodLocation>,
}

const useEatInLocationPickerDetails = (): ReturnsType => {
  const firm = useEoValue(FIRM_SELECTOR)
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)
  const [ eatinLocations, setEatinLocations ] = useState<Array<OrderOnSpotCustomMethodLocation>>([])

  useEffect(() => {
    if (!firm || checkoutForm.orderingMethod.tableMethod === null) {
      return
    }

    const customTableMethod = firm.settings.orderingMethods[OrderingMethodNames.ON_THE_SPOT]?.customMethods?.find(
      (method: OrderOnSpotCustomMethod) => method.id === checkoutForm.orderingMethod.tableMethod
    )

    if (!customTableMethod) {
      return
    }

    setEatinLocations(customTableMethod.locations)
  }, [ firm, checkoutForm.orderingMethod.tableMethod ])

  return {
    eatinLocations
  }
}

export {
  useEatInLocationPickerDetails
}
