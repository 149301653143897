import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import { ButtonProps } from '@mui/material/Button/Button'
import { useContext } from 'react'
import Button from '~/src/components/mui-wrappers/buttons/Button'
import ResponsiveDialogContext, {
  ResponsiveDialogContextInterface
} from '~/src/components/mui-wrappers/responsive-dialog/ResponsiveDialogContext'
import { useFirmPathResolver } from '~/src/hooks/router/useFirmPathResolver'
import { useRouterPush } from '~/src/hooks/router/useRouterPush'
import { useTranslations } from '~/src/hooks/useTranslations'
import routes from '~/src/router/enums/routes.enum'

interface Props extends Partial<Pick<ButtonProps, 'color' | 'variant' | 'sx'>> {
  dialog?: boolean,
  small?: boolean,
}

const GoBackToTheShopButton = ({ color = 'inherit', variant = 'text', dialog = false, small = false, sx } : Props) => {
  const { resolve } = useFirmPathResolver()
  const { push } = useRouterPush()
  const responsiveDialogContext = useContext<ResponsiveDialogContextInterface>(ResponsiveDialogContext)
  const { t } = useTranslations()

  const handleOnClick = () => {
    if (dialog) {
      return responsiveDialogContext?.toggleClose && responsiveDialogContext.toggleClose()
    }

    return push(resolve(routes.FIRM_HOME))
  }

  return (
    <Button
      small={small}
      color={color}
      variant={variant}
      startIcon={<KeyboardBackspaceIcon />}
      sx={{
        borderRadius: 'var(--btn-border-radius)',
        ...sx
      }}
      onClick={handleOnClick}
    >
      {t('firm.backToTheShop')}
    </Button>
  )
}

export default GoBackToTheShopButton
