import CustomerLoyaltyCurrentVoucherBalance from '~/src/components/profile/account/loyalty/current-voucher-balance'
import CustomerLoyaltyCurrentPointsBalance from '~/src/components/profile/account/loyalty/current-points-balance'
import { Box } from '@mui/material'

const CustomerLoyaltyPointsTresholdCurrentBalance = () => {
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: 1
    }}>
      <CustomerLoyaltyCurrentVoucherBalance />

      <CustomerLoyaltyCurrentPointsBalance />
    </Box>
  )
}

export default CustomerLoyaltyPointsTresholdCurrentBalance
