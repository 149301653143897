import { Allergen } from '@eo-storefronts/eo-core'
import { Box, Tooltip } from '@mui/material'
import { useEoValue } from '~/src/hooks/useEoState'
import { LANGUAGE_STATE } from '~/src/stores/lang'

interface Props {
  allergen: Allergen,
}

const AllergenItem = ({ allergen }: Props) => {
  const lang = useEoValue(LANGUAGE_STATE)

  return (
    <Tooltip title={allergen.name[lang]}>
      <Box
        component='img'
        sx={{
          maxWidth: '25px',
          maxHeight: '25px'
        }}
        src={allergen.image}
        key={allergen.id}
        alt={`allergen-${allergen.name[lang]}`}
      />
    </Tooltip>
  )
}

export default AllergenItem
