import { Box, Typography } from '@mui/material'
import { useLayoutEffect, useRef, useState } from 'react'
import ResponsiveDialog from '~/src/components/mui-wrappers/responsive-dialog'
import ShowMore from '~/src/components/products/product-details/product-details-description/ShowMore'

interface Props {
  description: string,
  lineNumber?: number,
}

const ProductDetailsDescription = ({ description, lineNumber = 3 }: Props) => {
  const descEl = useRef<HTMLElement | null>(null)
  const [ showMore, setShowMore ] = useState<boolean>(false)
  const [ maxHeight, setMaxHeight ] = useState<string>('110px')

  useLayoutEffect(() => {
    if (!descEl.current) {
      return
    }

    const computedElement: CSSStyleDeclaration = window.getComputedStyle(descEl.current, null)
    const fontSize: string = computedElement.getPropertyValue('font-size')
    const lineHeight: string = computedElement.getPropertyValue('line-height')

    setMaxHeight(`calc((${fontSize} + ${lineHeight}) * ${lineNumber})`)
    setShowMore(descEl.current?.scrollHeight > descEl.current?.clientHeight)
  }, [ descEl.current ])

  if (description === '') {
    return null
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        my: 2,
        px: 2
      }}
    >
      <Typography
        ref={descEl}
        variant='body2'
        sx={{
          display: '-webkit-box',
          WebkitLineClamp: `${lineNumber}`,
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          maxHeight: `${maxHeight}`,
          fontSize: '1em',
          whiteSpace: 'pre-line'
        }}
      >
        {description}
      </Typography>
      {showMore && (
        <ResponsiveDialog
          sx={{
            zIndex: 3000
          }}
          activator={<ShowMore />}
        >
          <Typography
            sx={{
              whiteSpace: 'pre-line'
            }}
          >
            {description}
          </Typography>
        </ResponsiveDialog>
      )}
    </Box>
  )
}

export default ProductDetailsDescription
