import { SyntheticEvent, useContext } from 'react'
import useGetHeaderMultiFirmSwitchTemplate
  from '~/src/components/layout/header/header-multi-firm-switch/styles/useGetHeaderMultiFirmSwitchTemplate'
import SideDrawerContext, { SideDrawerProps } from '~/src/components/layout/side-drawer/SideDrawerContext'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { BRAND_PAGE_SELECTOR } from '~/src/stores/router'
import { BRAND_STATE } from '~/src/stores/brand'
import { Button } from '@mui/material'
import { useRouterPush } from '~/src/hooks/router/useRouterPush'

const HeaderMultiFirmSwitch = () => {
  const styles = useGetHeaderMultiFirmSwitchTemplate()
  const { t } = useTranslations()
  const { push } = useRouterPush()
  const sideDrawerContext = useContext<SideDrawerProps>(SideDrawerContext)
  const brand = useEoValue(BRAND_STATE)
  const brandPage = useEoValue(BRAND_PAGE_SELECTOR)

  const handleOnClick = async ($event: SyntheticEvent) => {
    $event.preventDefault()
    $event.stopPropagation()

    if (sideDrawerContext && sideDrawerContext?.open) {
      sideDrawerContext.onToggle()
    }

    void push(brandPage)
  }

  if (brand?.locations?.length === 1) {
    return null
  }

  return (
    <Button
      variant='text'
      onClick={handleOnClick}
      sx={styles.getButtonSx()}
    >
      {t('header.actualShop.change')}
    </Button>
  )
}

export default HeaderMultiFirmSwitch
