import { CustomerLoyaltyVoucher } from '@eo-storefronts/eo-core'
import { Box } from '@mui/material'
import NextExpiringVoucherValue
  from '~/src/components/profile/account/loyalty/next-expiring-voucher/NextExpiringVoucherValue'
import NextExpiringVoucherDate
  from '~/src/components/profile/account/loyalty/next-expiring-voucher/NextExpiringVoucherDate'

interface NextExpiringVoucherProps {
  voucher?: CustomerLoyaltyVoucher,
}

const NextExpiringVoucher = ({ voucher }: NextExpiringVoucherProps) => {
  if (!voucher) {
    return null
  }

  return (
    <Box
      component='span'
      sx={{
        fontSize: '.85rem'
      }}>
      <NextExpiringVoucherValue voucher={voucher} />
      &nbsp;
      <NextExpiringVoucherDate voucher={voucher} />
    </Box>
  )
}

export default NextExpiringVoucher
