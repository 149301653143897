import DeliveryDiningOutlined from '@mui/icons-material/DeliveryDiningOutlined'
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined'
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined'
import { useTranslations } from '~/src/hooks/useTranslations'
import { useEoValue } from '~/src/hooks/useEoState'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import { OrderingMethodNames } from '@eo-storefronts/eo-core'
import { ReactNode } from 'react'

const OrderingMethodsSummaryMethodSentenceMethod = () => {
  const { t } = useTranslations()
  const { orderingMethod } = useEoValue(CHECKOUT_FORM_STATE)
  const orderingMethodIcon: Record<OrderingMethodNames, ReactNode> = {
    [OrderingMethodNames.DELIVERY]: <DeliveryDiningOutlined/>,
    [OrderingMethodNames.PICKUP]: <ShoppingBagOutlinedIcon/>,
    [OrderingMethodNames.ON_THE_SPOT]: <StorefrontOutlinedIcon/>
  }

  if (!orderingMethod.method) {
    return null
  }

  return (
    <>
      {orderingMethodIcon[orderingMethod.method]}
      <span>{t(`orderingMethods.${orderingMethod.method}`)}</span>
    </>
  )
}

export default OrderingMethodsSummaryMethodSentenceMethod
