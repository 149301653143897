import { DateUtils, OrderingMethod, OrderingMethodNames, Timeslot } from '@eo-storefronts/eo-core'
import { useEoValue } from '~/src/hooks/useEoState'
import { canOrderOnDayForTimeslots, canOrderOnDayIdForPeriod, isOnHoliday } from '~/src/services/OpeningHourService'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import { FIRM_WITH_LOCATOR_STATE_SELECTOR } from '~/src/stores/firm'

interface ReturnsType {
  canOrderOnDay(date: Date): boolean,
}

const useCanOrderOnDay = (): ReturnsType => {
  const firm = useEoValue(FIRM_WITH_LOCATOR_STATE_SELECTOR)
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)

  const canOrderOnDay = (date: Date): boolean => {
    if (
      !firm
      || !checkoutForm.orderingMethod.method
      || checkoutForm.orderingMethod.method === OrderingMethodNames.ON_THE_SPOT
      || !checkoutForm.orderingMethod.time
    ) {
      return false
    }

    // Firm is on holiday
    if (isOnHoliday(date, firm.settings.periods.holidayPeriod)) {
      return false
    }

    const dayId = DateUtils.getDayIdLikeMomentJs(date)
    const orderingMethod = firm.settings.orderingMethods[checkoutForm.orderingMethod.method] as OrderingMethod

    if (!orderingMethod) {
      return false
    }
    
    // Rest of the date, we first check if the date is before the min date, if yes we disable it
    if (
      checkoutForm.orderingMethod.minDate
      && DateUtils.isBefore(date, new Date(checkoutForm.orderingMethod.minDate) )
      && !DateUtils.isSameDate(date, new Date(checkoutForm.orderingMethod.minDate))
    ) {
      return false
    }

    if (
      (!checkoutForm.orderingMethod.pickupPoint || checkoutForm.orderingMethod.pickupPoint.mainBranch)
      && orderingMethod.orderTimeslots.active
    ) {
      const timeslots = orderingMethod.orderTimeslots.timeslots.map((t: Timeslot) => t.dayId as number)

      return canOrderOnDayForTimeslots(timeslots, dayId)
    }

    if (checkoutForm.orderingMethod.method === OrderingMethodNames.DELIVERY) {
      return canOrderOnDayIdForPeriod(firm.settings.periods.deliveryHours, dayId)
    }

    if (checkoutForm.orderingMethod.method === OrderingMethodNames.PICKUP) {
      if (
        checkoutForm.orderingMethod.pickupPoint
        && !checkoutForm.orderingMethod.pickupPoint.mainBranch
        && !checkoutForm.orderingMethod.pickupPoint.usePickupHours
      ) {
        return canOrderOnDayIdForPeriod(checkoutForm.orderingMethod.pickupPoint.periods.pickupHours, dayId)
      }

      return canOrderOnDayIdForPeriod(firm.settings.periods.pickupHours, dayId)
    }

    return false
  }

  return { canOrderOnDay }
}

export {
  useCanOrderOnDay
}
