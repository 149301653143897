import { Address } from '@eo-storefronts/eo-core'

interface Props {
  address: Address,
}

const InlineAddress = ({ address }: Props) => {
  const { street, zipCode, locality } = address

  return <>{`${street}, ${zipCode} - ${locality}`}</>
}

export default InlineAddress
