import {
  DateUtils,
  Firm,
  HolidayPeriod,
  HourDetail,
  OpeningHour,
  OrderingMethodNames,
  Period,
  PickupHour,
  Timeslot
} from '@eo-storefronts/eo-core'

const isClosed = (period: OpeningHour): boolean => {
  return period.times.length === 0
}

const buildOpeningHoursString = (period: OpeningHour): string => {
  if (isClosed(period)) {
    return 'closed'
  }

  return period.times
    .map((time: HourDetail) => `${time.fromTime} - ${time.toTime}`)
    .join(' & ')
}

const isBeforeOpeningHours = (date: Date, period: OpeningHour): boolean => {
  let isBeforeOpeningHours = true

  for (const time of period.times) {
    const [ fromHour, fromMinute ] = time.fromTime.split(':')
    const fromTime = (new Date()).setHours(Number(fromHour), Number(fromMinute))

    isBeforeOpeningHours = date.getTime() < fromTime
  }

  return isBeforeOpeningHours
}

const isBetweenOpeningHours = (date: Date, period: OpeningHour): boolean => {
  for (const time of period.times) {
    const [ fromHour, fromMinute ] = time.fromTime.split(':')
    const [ toHour, toMinute ] = time.toTime.split(':')

    const fromTime = (new Date()).setHours(Number(fromHour), Number(fromMinute))
    const toTime = (new Date()).setHours(Number(toHour), Number(toMinute))

    if (date.getTime() >= fromTime && date.getTime() < toTime) {
      return true
    }
  }

  return false
}

const isOnHoliday = (date: Date, holidayPeriods: HolidayPeriod[]): boolean => {
  if (!holidayPeriods?.length) {
    return false
  }

  for (const period of holidayPeriods) {
    const start = (new Date(period.fromDate)).getTime()
    const end = (new Date(period.toDate)).getTime()
    const time = date.getTime()

    if (start === end && DateUtils.isSameDate(new Date(period.fromDate), date)) {
      return true
    }

    if (time >= start && time < end) {
      return true
    }
  }

  return false
}

const isOffline = (firm: Firm): boolean => {
  return firm.settings.isOffline
}

const isOpened = (openingHours: OpeningHour[], date: Date = new Date()): boolean => {
  const todayId = DateUtils.getDayIdLikeMomentJs(date)

  const todayPeriod = openingHours.find(
    (openingHour: OpeningHour) => openingHour.dayId === todayId
  )

  if (!todayPeriod) {
    return false
  }

  return isBetweenOpeningHours(date, todayPeriod)
}

const isTimeBetweenOperationalTimeslot = (timeslots: Timeslot[], date: Date = new Date()): boolean => {
  const todayId = DateUtils.getDayIdLikeMomentJs(date)

  const todayTimeslots = timeslots.filter(
    (timeslot: Timeslot) => timeslot.dayId === todayId
  )

  if (!todayTimeslots.length) {
    return false
  }

  for (const todayTimeslot of todayTimeslots) {
    if (
      isBetweenOpeningHours(
        date,
        {
          day: {
            fr: '',
            pt: '',
            en: '',
            nl: '',
            de: '',
            es: '',
            it: '',
            no: ''
          },
          dayId: todayId,
          times: [
            {
              ...todayTimeslot
            }
          ]
        }
      )
    ) {
      return true
    }
  }

  return false
}

const canOrderOnDayIdForPeriod = (periods: PickupHour[], dayId: number): boolean => {
  const period = periods.find((p: PickupHour) => p.dayId === dayId)

  if (!period) {
    return false
  }

  return !isClosed(period)
}

const canOrderOnDayForTimeslots = (timeslotsDays: number[], dayId: number): boolean => {
  const day: number | undefined = timeslotsDays.find((t: number) => t === dayId)

  return Boolean(day)
}

const getFirstAvailablePeriodHourForDayId = (firm: Firm, dayId: number, orderingMethod?: OrderingMethodNames): string => {
  let periodName = 'openingHours'

  if (orderingMethod) {
    periodName = `${orderingMethod}_hours`
  }

  const period = ((firm.settings.periods[periodName as keyof Period] || []) as Array<OpeningHour|PickupHour>).find(
    (p: OpeningHour|PickupHour) => p.dayId === dayId
  )

  if (!period) {
    return ''
  }

  return getFirstAvailableHourForPeriod(period)
}

const getFirstAvailableTimeslotHourForDayId = (firm: Firm, dayId: number, orderingMethod: OrderingMethodNames, todayTimeslots: Timeslot[]): string => {
  if (orderingMethod === OrderingMethodNames.ON_THE_SPOT) {
    return ''
  }

  const todayDayId = DateUtils.getDayIdLikeMomentJs(new Date())
  const now = new Date()

  let timeslots = firm.settings.orderingMethods[orderingMethod]?.orderTimeslots.timeslots.filter(
    (t: Timeslot) => t.dayId === dayId
  ) || []

  if (dayId === todayDayId) {
    timeslots = todayTimeslots
  }

  for (const timeslot of timeslots) {
    if (
      timeslot.dayId === todayDayId
      && parseInt(timeslot.toTime.replace(':', '')) < parseInt(`${now.getHours()}${now.getMinutes()}`)
    ) {
      continue
    }

    return timeslot.fromTime
  }

  return ''
}

const getFirstAvailableHourForPeriod = (period: OpeningHour|PickupHour, filter = false): string => {
  return [ ...period.times ]
    .filter((time: HourDetail) => {
      if (!filter) {
        return true
      }

      const [ fromHour, fromMinute ] = time.fromTime.split(':')
      const fromTime = (new Date()).setHours(Number(fromHour), Number(fromMinute))

      return (new Date()).getTime() < fromTime
    })
    .sort((a: HourDetail, b: HourDetail) => Number(a.fromTime.replace(':', '')) - Number(b.fromTime.replace(':', '')))[0]
    .fromTime
}

const getCurrentPeriodTime = (date: Date, period: OpeningHour): HourDetail|null => {
  for (const time of period.times) {
    const [ fromHour, fromMinute ] = time.fromTime.split(':')
    const [ toHour, toMinute ] = time.toTime.split(':')

    const fromTime = (new Date()).setHours(Number(fromHour), Number(fromMinute))
    const toTime = (new Date()).setHours(Number(toHour), Number(toMinute))

    if (date.getTime() >= fromTime && date.getTime() < toTime) {
      return time
    }
  }

  return null
}

export {
  buildOpeningHoursString,
  canOrderOnDayForTimeslots,
  canOrderOnDayIdForPeriod,
  getCurrentPeriodTime,
  getFirstAvailableHourForPeriod,
  getFirstAvailablePeriodHourForDayId,
  getFirstAvailableTimeslotHourForDayId,
  isBeforeOpeningHours,
  isBetweenOpeningHours,
  isClosed,
  isOffline,
  isOnHoliday,
  isOpened,
  isTimeBetweenOperationalTimeslot
}

