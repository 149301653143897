import { SignInResult } from '@capacitor-firebase/authentication'
import { ThirdPartySignInVerifyResponse } from '@eo-storefronts/eo-core'
import { verifyApplePayload } from '~/src/services/AuthenticationService'

interface ReturnsType {
  verify({ user }: SignInResult): Promise<ThirdPartySignInVerifyResponse | undefined>,
}

const useSignInWithAppleVerify = (): ReturnsType => {
  const verify = async (result: SignInResult) => {
    return verifyApplePayload({
      user: result.user?.uid || null,
      email: result.user?.email || null,
      givenName: result.user?.displayName || null,
      familyName: result.user?.displayName || null,
      identityToken: result.credential?.idToken || '',
      authorizationCode: result.credential?.authorizationCode || ''
    })
  }

  return { verify }
}

export default useSignInWithAppleVerify
