import { Firm, Tracking, TrackingField } from '@eo-storefronts/eo-core'
import { useEffect } from 'react'
import { useEoValue } from '~/src/hooks/useEoState'
import { BRAND_STATE } from '~/src/stores/brand'

const useInitFacebookPixel = (): void => {
  const brand = useEoValue(BRAND_STATE)

  const _getFacebookPixelId = (firm: Firm | null): string | undefined => firm
    ?.settings
    ?.tracking
    ?.find((tracking: Tracking) => tracking.type === 'FACEBOOK_PIXEL')
    ?.fields.find((field: TrackingField) => field.id === 'facebook_pixel_id')
    ?.value

  useEffect(() => {
    if (!brand) {
      return
    }

    const facebookPixelId: string | undefined = _getFacebookPixelId(brand)

    if (!facebookPixelId) {
      return
    }

    import('react-facebook-pixel')
      .then(module => module.default)
      .then(ReactPixel => {
        ReactPixel.init(facebookPixelId, undefined, { debug: process.env.NODE_ENV !== 'production', autoConfig: true })
        ReactPixel.pageView()
      })
  }, [ brand ])
}

export default useInitFacebookPixel
