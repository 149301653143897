import { Firm } from '@eo-storefronts/eo-core'
import { ChangeEvent } from 'react'
import { useEoState, useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { PRODUCT_COMMENT_STATE } from '~/src/stores/product-comment'
import TextField from '~/src/components/mui-wrappers/styled/text-field'
import { formLabelClasses, inputBaseClasses, outlinedInputClasses } from '@mui/material'

const ProductDetailsComment = () => {
  const firm = useEoValue<Firm | null>(FIRM_SELECTOR)
  const { t } = useTranslations()
  const [ productComment, setProductComment ] = useEoState(PRODUCT_COMMENT_STATE)

  const handleOnChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setProductComment(event.target.value)
  }

  if (!firm?.settings.theme.showCommentsStepProductBasket) {
    return null
  }

  return (
    <TextField
      fullWidth
      variant='outlined'
      size='small'
      label={t('checkout.giveUsMoreInfo')}
      multiline
      rows={4}
      value={productComment}
      onChange={handleOnChange}
      sx={{
        [`label.${formLabelClasses.root}`]: {
          color: 'content.contrastText'
        },
        [`.${inputBaseClasses.root}`]: {
          color: 'content.contrastText',
          backgroundColor: 'content.main',

          [`&.${inputBaseClasses.focused}`]: {
            [`fieldset.${outlinedInputClasses.notchedOutline}`]: {
              borderColor: 'primary.main'
            }
          },
          [`fieldset.${outlinedInputClasses.notchedOutline}`]: {
            borderColor: 'transparent'
          }
        }
      }}
    />
  )
}

export default ProductDetailsComment
