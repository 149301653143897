import { OrderingMethodNames, Price } from '@eo-storefronts/eo-core'

abstract class PriceHelper {
  static transform(value: number): Price {
    return {
      [OrderingMethodNames.DELIVERY]: value,
      [OrderingMethodNames.PICKUP]: value,
      [OrderingMethodNames.ON_THE_SPOT]: value
    }
  }
}

export default PriceHelper
