import { GetTimeslotsResponse } from '@eo-storefronts/eo-core'
import { useEoValue } from '~/src/hooks/useEoState'
import { fetchFirmTimeslots } from '~/src/services/FirmService'
import { isOffline } from '~/src/services/OpeningHourService'
import { CHECKOUT_FORM_STATE, CHECKOUT_PICKUP_POINT_SELECTOR } from '~/src/stores/checkout'
import { FIRM_WITH_LOCATOR_STATE_SELECTOR } from '~/src/stores/firm'

interface ReturnsType {
  fetchTimeslots(): Promise<GetTimeslotsResponse | null>,
}

const useFetchTimeslots = (): ReturnsType => {
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)
  const firm = useEoValue(FIRM_WITH_LOCATOR_STATE_SELECTOR)
  const pickupPoint = useEoValue(CHECKOUT_PICKUP_POINT_SELECTOR)

  const fetchTimeslots = async (): Promise<GetTimeslotsResponse | null> => {
    if (
      !firm
      || isOffline(firm)
      || !checkoutForm.orderingMethod.method
      || !checkoutForm.orderingMethod.time
    ) {
      return null
    }

    return fetchFirmTimeslots(
      firm.id,
      new Date(checkoutForm.orderingMethod.time),
      checkoutForm.orderingMethod.method,
      pickupPoint && !pickupPoint.mainBranch ? pickupPoint.id : undefined
    )
  }

  return { fetchTimeslots }
}

export default useFetchTimeslots
