import { Modifier, ModifierGroup, Price, Product } from '@eo-storefronts/eo-core'
import { useCallback, useContext } from 'react'
import ProductConfigurationModalContext, {
  ProductConfigurationModalContextInterface
} from '~/src/components/products/modals/configuration/ProductConfigurationModalContext'
import { useEoValue } from '~/src/hooks/useEoState'
import CartService from '~/src/services/CartService'
import { MODIFIER_GROUPS_STATE } from '~/src/stores/modifier-groups'
import { MODIFIERS_STATE } from '~/src/stores/modifiers'
import { PRODUCTS_STATE } from '~/src/stores/product'
import { PRICE_LIST_STATE } from '~/src/stores/product/price-list'

interface ReturnsType {
  calculate(price: Price, quantity: number): Price,
}

const useCalculatePrice = (): ReturnsType => {
  const priceLists = useEoValue(PRICE_LIST_STATE)
  const modifierGroups = useEoValue<Record<string, ModifierGroup>>(MODIFIER_GROUPS_STATE)
  const modifiers = useEoValue<Record<string, Modifier>>(MODIFIERS_STATE)
  const products = useEoValue<Record<string, Product>>(PRODUCTS_STATE)
  const context = useContext<ProductConfigurationModalContextInterface>(ProductConfigurationModalContext)
  const service = new CartService(
    modifierGroups,
    modifiers,
    products
  )

  const calculate = useCallback((
    price: Price,
    quantity: number
  ): Price => {
    return service.calculateCartProductPrice(
      price,
      quantity,
      context.selectedModifierGroups,
      priceLists
    )
  }, [
    priceLists,
    modifiers,
    modifierGroups,
    context.selectedModifierGroups
  ])

  return { calculate }
}

export {
  useCalculatePrice
}
