import { CapacitorUpdater } from '@capgo/capacitor-updater'
import * as Sentry from '@sentry/react'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import Environment from '~/src/config/environment'
import router from '~/src/router'
import '~/src/styles/index.css'
import EnvUtils from '~/src/utils/EnvUtils'

if (EnvUtils.isDeviceApp) {
  void CapacitorUpdater.notifyAppReady()
}

if (Environment.isSentryEnabled()) {
  Sentry.init({
    dsn: 'https://24fe5c1d6367ab1806c733937c502bb0@o4507961866518528.ingest.de.sentry.io/4507961868222544',
    integrations: []
  })
}

// eslint-disable-next-line no-undef
ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <RecoilRoot>
    <RouterProvider router={router} />
  </RecoilRoot>
)
