import { useEoValue } from '~/src/hooks/useEoState'
import {
  LOYALTY_IS_ENABLED_SELECTOR,
  LOYALTY_PHYSICAL_VOUCHER_MAXIMUM_AMOUNT_USABLE_SELECTOR
} from '~/src/stores/loyalty'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import { Typography } from '@mui/material'
import Price from '~/src/components/Price'
import PriceHelper from '~/src/helpers/priceHelper'
import { useTranslations } from '~/src/hooks/useTranslations'
import { Currency } from '@eo-storefronts/eo-core'
import { FIRM_CURRENCY_SELECTOR } from '~/src/stores/firm'
import { Constants } from '~/src/helpers/constants'
import MotionBox from '~/src/components/mui-wrappers/animations/motion/MotionBox'

const ShoppingCartLoyaltySummary = () => {
  const { t } = useTranslations()
  const isLoyaltyEnabled = useEoValue<boolean>(LOYALTY_IS_ENABLED_SELECTOR)
  const firmCurrency = useEoValue<Currency | undefined>(FIRM_CURRENCY_SELECTOR)
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)
  const maximumUsableOfVoucher = useEoValue(LOYALTY_PHYSICAL_VOUCHER_MAXIMUM_AMOUNT_USABLE_SELECTOR)

  if (isLoyaltyEnabled || !checkoutForm.physicalVoucher?.amount || !firmCurrency) {
    return null
  }

  return (
    <MotionBox
      initial={{ y: `-${25}`, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: 25, opacity: 0 }}
      transition={{ duration: Constants.MOTION_ANIMATION_DURATION }}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}>
      <Typography
        color='primary'
        component='h6'
        variant='h6'>
        {t('checkout.loyalty.voucher.summary', { value: checkoutForm.physicalVoucher.barcode })}
      </Typography>
      <Typography
        color='primary'
        component='h6'
        variant='h6'>
        <Price price={PriceHelper.transform(-maximumUsableOfVoucher)}/>
      </Typography>
    </MotionBox>
  )
}

export default ShoppingCartLoyaltySummary
