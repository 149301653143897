import { Box } from '@mui/material'
import NextExpiringVoucher from '~/src/components/profile/account/loyalty/next-expiring-voucher/NextExpiringVoucher'
import { useEoValue } from '~/src/hooks/useEoState'
import { Currency, CustomerLoyaltyVoucher } from '@eo-storefronts/eo-core'
import { FIRM_CURRENCY_SELECTOR } from '~/src/stores/firm'
import {
  CUSTOMER_LOYALTY_NEXT_EXPIRING_VOUCHER_SELECTOR,
  CUSTOMER_LOYALTY_VOUCHERS_BALANCE_SELECTOR
} from '~/src/stores/customer'
import { useTranslations } from '~/src/hooks/useTranslations'

const CustomerLoyaltyCurrentVoucherBalancePercentLabel = () => {
  const { t } = useTranslations()
  const firmCurrency = useEoValue<Currency | undefined>(FIRM_CURRENCY_SELECTOR)
  const customerLoyaltyVouchersValue = useEoValue(CUSTOMER_LOYALTY_VOUCHERS_BALANCE_SELECTOR)
  const customerLoyaltyNextExpiringVoucher = useEoValue<CustomerLoyaltyVoucher | undefined>(CUSTOMER_LOYALTY_NEXT_EXPIRING_VOUCHER_SELECTOR)

  return (
    <>
      <Box
        component='span'
        sx={{
          fontSize: '1rem'
        }}>
        <Box component='b'>
          {firmCurrency?.symbol}{customerLoyaltyVouchersValue}
        </Box>
        &nbsp;
        {t('profile.loyalty.voucherBalance').toLowerCase()}
      </Box>

      <NextExpiringVoucher voucher={customerLoyaltyNextExpiringVoucher} />
    </>
  )
}

export default CustomerLoyaltyCurrentVoucherBalancePercentLabel
