import { Product } from '@eo-storefronts/eo-core'
import { Box } from '@mui/material'
import { useContext } from 'react'
import useProductDetailsFooterTemplateService from '~/src/components/products/product-details/product-details-footer/styles/useProductDetailsFooterTemplateService'
import ProductDetailsAddToCart from '~/src/components/products/ProductDetailsAddToCart'
import QuantitySelector from '~/src/components/products/QuantitySelector'
import { useAddToCartButtonStatus } from '~/src/hooks/cart/useAddToCartButtonStatus'
import useProductDetailQuantity from '~/src/hooks/products/useProductDetailQuantity'
import { useEoValue } from '~/src/hooks/useEoState'
import { COUPON_STATE } from '~/src/stores/checkout'
import ProductConfigurationModalContext, { ProductConfigurationModalContextInterface } from '../../modals/configuration/ProductConfigurationModalContext'

interface Props {
  product: Product,
}

const ProductDetailsFooter = ({ product }: Props) => {
  const coupon = useEoValue(COUPON_STATE)
  const productConfigurationContext = useContext<ProductConfigurationModalContextInterface>(ProductConfigurationModalContext)
  const { isAddToCartButtonEnabled } = useAddToCartButtonStatus(product)
  const styles = useProductDetailsFooterTemplateService()
  const {
    quantity: count,
    setQuantity: setCount,
    interval,
    minQuantity,
    maxQuantity
  } = useProductDetailQuantity(product)

  const isCoupon = coupon?.product?.id === product.id || coupon?.category?.id === product.categoryId

  return (
    <Box sx={styles.getContainerSx(productConfigurationContext.asModifier)}>
      <Box sx={styles.getQuantitySx()}>
        {!productConfigurationContext.asModifier && (
          <QuantitySelector
            quantity={count}
            onUpdate={setCount}
            unitInterval={interval}
            unit={product.unit}
            min={minQuantity}
            max={maxQuantity}
            disabled={count === 0 || isCoupon}
          />
        )}
      </Box>
      <Box sx={styles.getAddToCartSx()}>
        <ProductDetailsAddToCart
          product={product}
          quantity={count}
          disabled={!isAddToCartButtonEnabled() || count === 0}/>
      </Box>
    </Box>
  )
}

export default ProductDetailsFooter
