import { Coupon, Customer, Firm, HttpError, Id, PriceList } from '@eo-storefronts/eo-core'
import { useSnackbar } from 'notistack'
import ProductConfigurationModal, { ProductConfigurationModalProps } from '~/src/components/products/modals/configuration'
import ProductModalsCoupon, { ProductModalsCouponProps } from '~/src/components/products/modals/coupon'
import AlreadyInCart, { AlreadyInCartProps } from '~/src/components/products/modals/coupon/already-in-cart'
import useLogSelectPromotion from '~/src/hooks/analytics/ecommerce/useLogSelectPromotion'
import { useAddProductToCart } from '~/src/hooks/cart/useAddProductToCart'
import { useUpdateCartProducts } from '~/src/hooks/cart/useUpdateCartProducts'
import useGetProductQuantityToAdd from '~/src/hooks/products/useGetProductQuantityToAdd'
import useProductHasOptions from '~/src/hooks/products/useProductHasOptions'
import useDialog from '~/src/hooks/useDialog'
import { useEoState, useEoValue } from '~/src/hooks/useEoState'
import { checkCoupon } from '~/src/services/OrderService'
import { convertProductUnitKgInGr, getProductPrice } from '~/src/services/ProductService'
import { CART_OF_FIRM_SELECTOR, CART_SUBTOTAL_WITH_DISCOUNTS_SELECTOR } from '~/src/stores/cart'
import { CATEGORIES_STATE } from '~/src/stores/categories'
import { CHECKOUT_FORM_STATE, CheckoutFormState, COUPON_STATE } from '~/src/stores/checkout'
import { CUSTOMER_STATE } from '~/src/stores/customer'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { PRODUCTS_STATE } from '~/src/stores/product'
import { PRICE_LIST_STATE } from '~/src/stores/product/price-list'
import { CartProduct } from '~/src/types/CartProduct'

interface ReturnsType {
  checkCouponValidity(code: string): void,
  chooseProduct(productId: Id): void,
}

const useCoupon = (): ReturnsType => {
  const [ checkoutForm, setCheckoutForm ] = useEoState(CHECKOUT_FORM_STATE)
  const [ couponState, setCoupon ] = useEoState(COUPON_STATE)
  const firm = useEoValue<Firm | null>(FIRM_SELECTOR)
  const customer = useEoValue<Customer | null>(CUSTOMER_STATE)
  const cart = useEoValue(CART_OF_FIRM_SELECTOR)
  const subTotal = useEoValue(CART_SUBTOTAL_WITH_DISCOUNTS_SELECTOR)
  const categories = useEoValue(CATEGORIES_STATE)
  const products = useEoValue(PRODUCTS_STATE)
  const priceLists = useEoValue<PriceList[]>(PRICE_LIST_STATE)
  const { addToCart } = useAddProductToCart()
  const { hasOptions } = useProductHasOptions()
  const { getQuantity } = useGetProductQuantityToAdd()
  const { updateQuantity, updateIsCoupon } = useUpdateCartProducts()
  const { enqueueSnackbar } = useSnackbar()
  const { log: logEvent } = useLogSelectPromotion()

  const { present } = useDialog()

  const _triggerCouponModal = (productsId: string[]): void => {
    return present<ProductModalsCouponProps>(
      ProductModalsCoupon,
      'coupon-products-modal',
      {
        productsId: productsId
      }
    )
  }

  const checkCouponValidity = (code: string): void => {
    if (!firm) {
      return
    }

    checkCoupon(
      firm.id,
      customer?.id || 1,
      code,
      subTotal
    )
      .then((coupon: Coupon) => {
        if (!coupon.valid) {
          enqueueSnackbar({
            message: coupon.error,
            variant: 'error'
          })

          return
        }

        setCoupon({
          ...coupon,
          code
        })

        if (coupon.product && coupon.type === 'product') {
          return _triggerCouponModal([ coupon.product.id.toString() ])
        }

        if (coupon.category && coupon.type === 'category') {
          return _triggerCouponModal(categories[coupon.category.id].products)
        }

        setCheckoutForm((state: CheckoutFormState) => ({
          ...state,
          coupon: {
            code,
            chosen_product_id: null,
            value: coupon.value || 0,
            type: coupon.type,
            product: coupon.product,
            category: coupon.category
          }
        }))

        logEvent(code)
      })
      .catch((e: HttpError) => {
        enqueueSnackbar({ message: e.message, variant: 'error' })
      })
  }

  const chooseProduct = (productId: Id) => {
    if (!products[productId] || !checkoutForm.orderingMethod.method) {
      return
    }

    const cartProduct = cart.find((cp: CartProduct) => cp.id.toString() === productId.toString())

    if (
      cartProduct
      && (
        checkoutForm.loyaltyProductId?.toString() !== productId.toString()
        || cartProduct.quantity > convertProductUnitKgInGr(products[productId]).unitInterval
      )
      && !hasOptions(products[productId])
    ) {
      return present<AlreadyInCartProps>(
        AlreadyInCart,
        'already-in-cart',
        {
          onClose: (addNewProduct: boolean) => {
            if (addNewProduct) {
              addToCart(products[productId], getQuantity(products[productId]), [])
              return
            }

            if (cartProduct.quantity > 1) {
              updateQuantity(cartProduct, cartProduct.quantity - 1)
              addToCart(products[productId], getQuantity(products[productId]), [])
              return
            }

            updateIsCoupon(cartProduct, true)
            couponState && setCheckoutForm((state: CheckoutFormState) => ({
              ...state,
              coupon: {
                code: couponState.code,
                chosen_product_id: productId || null,
                value: productId ?
                  getProductPrice(products[productId], priceLists)[checkoutForm.orderingMethod.method!] :
                  (couponState.value || 0),
                type: couponState.type,
                product: couponState.product,
                category: couponState.category
              }
            }))
          }
        }
      )
    }

    if (hasOptions(products[productId])) {
      return present<ProductConfigurationModalProps>(
        ProductConfigurationModal,
        `product-${productId}`,
        {
          product: products[productId]
        }
      )
    }

    addToCart(products[productId], getQuantity(products[productId]), [])
  }

  return {
    checkCouponValidity,
    chooseProduct
  }
}

export default useCoupon
