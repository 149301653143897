import { OrderingMethodNames } from '@eo-storefronts/eo-core'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import { format, isToday } from 'date-fns'
import { useMemo } from 'react'
import OrderingMethodsSummaryMethodSentenceSplitter from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-method/ordering-methods-summary-method-sentence/OrderingMethodsSummaryMethodSentenceSplitter'
import { useEoValue } from '~/src/hooks/useEoState'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'

interface Props {
  showSplitter?: boolean,
}

const OrderingMethodsSummaryMethodSentenceDate = ({ showSplitter = true }: Props) => {
  const { orderingMethod } = useEoValue(CHECKOUT_FORM_STATE)

  const asapDateSentence = useMemo(() => {
    if (!orderingMethod.time) {
      return ''
    }

    return ` ${format(new Date(orderingMethod.time), 'dd/MM')}`
  }, [ orderingMethod.time ])

  if (
    orderingMethod.asap
    || orderingMethod.method === OrderingMethodNames.ON_THE_SPOT
    || (orderingMethod.time && isToday(new Date(orderingMethod.time)))
  ) {
    return null
  }

  return (
    <span>
      {showSplitter && <OrderingMethodsSummaryMethodSentenceSplitter/>}
      <CalendarMonthOutlinedIcon/>
      <>&nbsp;</>
      {asapDateSentence}
    </span>
  )
}

export default OrderingMethodsSummaryMethodSentenceDate
