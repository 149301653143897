import { Product, TemplateEnum } from '@eo-storefronts/eo-core'
import AddIcon from '@mui/icons-material/Add'
import { Box } from '@mui/material'
import { BaseSyntheticEvent, useContext, useMemo } from 'react'
import Button from '~/src/components/mui-wrappers/buttons/Button'
import ResponsiveDialogContext, {
  ResponsiveDialogContextInterface
} from '~/src/components/mui-wrappers/responsive-dialog/ResponsiveDialogContext'
import useGetProductItemTemplate from '~/src/components/products/product-item/styles/useGetProductItemTemplate'
import ProductUnitPrice from '~/src/components/products/product-unit-price'
import { useAddProductToCart } from '~/src/hooks/cart/useAddProductToCart'
import useGetProductQuantityToAdd from '~/src/hooks/products/useGetProductQuantityToAdd'
import { useProductAvailable } from '~/src/hooks/products/useProductAvailable'
import useProductHasOptions from '~/src/hooks/products/useProductHasOptions'
import useProductMaxReached from '~/src/hooks/products/useProductMaxReached'

export interface ProductItemCardActionsProps {
  product: Product,
  showAddToCartButton?: boolean,
  containerTemplate?: TemplateEnum,
}

const ProductItemCardActions = (
  {
    product,
    containerTemplate,
    showAddToCartButton = true
  }: ProductItemCardActionsProps
) => {
  const style = useGetProductItemTemplate(containerTemplate)
  const { addToCart } = useAddProductToCart()
  const { hasOptions } = useProductHasOptions()
  const { available } = useProductAvailable(product)
  const isMaxReached = useProductMaxReached(product)
  const { getQuantity } = useGetProductQuantityToAdd()
  const responsiveDialogContext = useContext<ResponsiveDialogContextInterface>(ResponsiveDialogContext)

  const handleAddToCart = (e: BaseSyntheticEvent) => {
    if (
      hasOptions(product)
      || !!product.subProductsId.length
      || isMaxReached
    ) {
      return
    }

    e.preventDefault()
    e.stopPropagation()

    responsiveDialogContext?.toggleClose && responsiveDialogContext.toggleClose({ addedToCart: true })

    addToCart(product, getQuantity(product), [])
  }

  return useMemo(() => (
    <>
      <Box sx={style.getProductPriceStyle(false)}>
        <ProductUnitPrice
          product={product}
          containerTemplate={containerTemplate}
        />
      </Box>
      {showAddToCartButton && !product.subProductsId.length && (
        <Button
          id="product_item_card_actions__add_to_cart"
          color="primary"
          variant="contained"
          sx={style.getAddProductButtonStyle()}
          onClick={handleAddToCart}
          disabled={!available || isMaxReached}
        >
          <AddIcon/>
        </Button>
      )}
    </>
  ), [ available, addToCart ])
}

export default ProductItemCardActions
