import { ModifierGroup } from '@eo-storefronts/eo-core'
import { Box } from '@mui/material'
import SingleChoiceModifiersContainer from '~/src/components/products/modifier-groups/modifier-group/modifier-group-single-choice/single-choice-modifiers-container'
import useModifierGroupSingleChoiceTemplateService from '~/src/components/products/modifier-groups/modifier-group/modifier-group-single-choice/styles/useModifierGroupSingleChoiceTemplateService'
import ModifierGroupTitle from '~/src/components/products/modifier-groups/modifier-group/modifier-group-title'
import { CartProductModifierGroupStateInterface } from '~/src/stores/cart/cart-product-modifier-groups'

interface Props {
  group: ModifierGroup,
  cartModifierGroups: CartProductModifierGroupStateInterface[],
  onChange(cartModifierGroups: CartProductModifierGroupStateInterface[]): void,
}

const ModifierGroupSingleChoice = ({ group, cartModifierGroups, onChange }: Props) => {
  const styles = useModifierGroupSingleChoiceTemplateService()

  return (
    <Box sx={styles.getContainerSx()}>
      <ModifierGroupTitle group={group} />
      <Box
        className='eo-hide-scrollbar'
        sx={styles.getModifiersContainerSx()}
      >
        <SingleChoiceModifiersContainer
          group={group}
          cartModifierGroups={cartModifierGroups}
          onChange={onChange}
        />
      </Box>
    </Box>
  )
}

export default ModifierGroupSingleChoice
