import { Customer } from '@eo-storefronts/eo-core'
import Title from '~/src/components/mui-wrappers/Title'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { CUSTOMER_STATE } from '~/src/stores/customer'

const AddressDialogTitle = () => {
  const { t } = useTranslations()
  const customer = useEoValue<Customer | null>(CUSTOMER_STATE)

  return (
    <Title
      variant='h5'
      component='p'
    >
      {customer ? t('orderingMethods.chooseAddress') : t('profile.addAddress')}
    </Title>
  )
}

export default AddressDialogTitle
