import { useEoValue } from '~/src/hooks/useEoState'
import { BRAND_STATE } from '~/src/stores/brand'
import Image from '~/src/components/mui-wrappers/Image'

const LocatorBrandPicture = () => {
  const brand = useEoValue(BRAND_STATE)

  return (
    <Image
      id='brand_shop_image'
      src={brand?.assets.shopImage}
      alt='brand_shop_image'
    />
  )
}

export default LocatorBrandPicture
