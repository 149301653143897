import { Product, TemplateEnum } from '@eo-storefronts/eo-core'
import { Box, CardActions, CardContent, Skeleton, Typography } from '@mui/material'
import CardMedia from '~/src/components/mui-wrappers/CardMedia'
import Title from '~/src/components/mui-wrappers/Title'
import Price from '~/src/components/Price'
import useGetProductItemTemplate from '~/src/components/products/product-item/styles/useGetProductItemTemplate'
import { Constants } from '~/src/helpers/constants'
import { useEoValue } from '~/src/hooks/useEoState'
import { LANGUAGE_STATE } from '~/src/stores/lang'
import { PRODUCT_CARD_THEME_COMPONENT_SELECTOR, SHOW_PRODUCT_IMG_SELECTOR_FAMILY } from '~/src/stores/theme'

interface Props {
  product: Product,
  containerTemplate?: TemplateEnum,
}

const ProductItemSkeleton = ({ product, containerTemplate }: Props) => {
  const style = useGetProductItemTemplate(containerTemplate)
  const productCardOptions = useEoValue(PRODUCT_CARD_THEME_COMPONENT_SELECTOR)
  const showImage = useEoValue(SHOW_PRODUCT_IMG_SELECTOR_FAMILY(product))
  const lang = useEoValue(LANGUAGE_STATE)

  return (
    <Box
      className='eo-h-full'
      sx={style.getCardLayout(showImage, productCardOptions)}>
      <Skeleton
        animation='wave'
        variant='rounded'
        sx={{
          gridArea: 'product-image'
        }}>
        <CardMedia
          src={Constants.TRANSPARENT_IMG_SRC}
          alt={'skeleton-img'}
          sx={style.getCardMediaStyle(showImage)}
        />
      </Skeleton>

      <CardContent sx={style.getCardContentStyle()}>
        <Skeleton
          variant='rounded'
          animation='wave'>
          <Title
            component='h6'
            className='eo-ellipsis'
            sx={style.getCardContentTitleSx()}
            variant={style.getCardContentTitleVariant()}
          >
            {product.name[lang]}
          </Title>
        </Skeleton>
        {style.getProductItemCardContentProps().showDescription && (
          <Skeleton
            variant='rounded'
            animation='wave'>
            <Typography component='p'>{product.description[lang]}</Typography>
          </Skeleton>
        )}
      </CardContent>

      <CardActions sx={style.getCardActionsStyle()}>
        <Box sx={style.getProductPriceStyle(false)}>
          <Skeleton
            component='div'
            variant='rounded'
            animation='wave'
          >
            <span>
              <Price
                price={product.price}
                displayZeroAmount={true}
              />
            </span>
          </Skeleton>
        </Box>
        {style.getProductActionsProps().showAddToCartButton && (
          <Skeleton
            variant='rounded'
            animation='wave'
            sx={style.getAddProductButtonStyle()}
          />
        )}
      </CardActions>
    </Box>
  )
}

export default ProductItemSkeleton
