import { App, AppState } from '@capacitor/app'
import { ConnectionStatus, Network } from '@capacitor/network'
import { useEffect } from 'react'
import useInitAnalyticsProviders from '~/src/hooks/analytics/useInitAnalyticsProviders'
import useCustomerFetchEffect from '~/src/hooks/auth/useCustomerFetchEffect'
import { useLoginAsGuestEffect } from '~/src/hooks/auth/useLoginAsGuestEffect'
import useCartCleanUpEffect from '~/src/hooks/cart/useCartCleanUp'
import useFetchCatalogEffect from '~/src/hooks/catalog/useFetchCatalogEffect'
import { usePreselectOrderingMethod } from '~/src/hooks/checkout/usePreselectOrderingMethod'
import useFetchLoyaltyHistoryEffect from '~/src/hooks/customer/useFetchLoyaltyHistoryEffect'
import useCustomLinksFetchEffect from '~/src/hooks/firm/useCustomLinksFetchEffect'
import useFetchAnnouncementsEffect from '~/src/hooks/firm/useFetchAnnouncementsEffect'
import useCalculateTimeAndCostsEffect from '~/src/hooks/ordering-methods/useCalculateTimeAndCostsEffect'
import usePushNotificationsActionEffect from '~/src/hooks/push-notifications/usePushNotificationsActionEffect'
import useRouterSlugEffect from '~/src/hooks/router/useRouterSlugEffect'
import useInitDownloadAppBanner from '~/src/hooks/useDownloadAppBanner'
import { useSetEoState } from '~/src/hooks/useEoState'
import { APP_STATE } from '~/src/stores/app'
import { APP_LOADER_STATE } from '~/src/stores/app-loader'
import useBrowsingEffect from './firm/useBrowsingEffect'
import useInitFirebaseApp from '~/src/hooks/firebase/useInitFirebaseApp'
import useSignalRCustomerEffect from '~/src/hooks/signalr/useSignalRCustomerEffect'

const useGlobalAppEffect = (): void => {
  const setAppState = useSetEoState(APP_STATE)
  const setAppLoader = useSetEoState(APP_LOADER_STATE)

  const _updateAppLoaderWithConnection = (status: ConnectionStatus) => {
    setAppLoader({
      state: !status.connected,
      type: 'connection'
    })
  }

  useEffect(() => {
    App.addListener('appStateChange', (state: AppState) => {
      setAppState(state)
      Network.getStatus().then(_updateAppLoaderWithConnection)
    })
    Network.addListener('networkStatusChange', _updateAppLoaderWithConnection)
  }, [])

  useInitFirebaseApp()
  usePushNotificationsActionEffect()
  useInitDownloadAppBanner()
  useRouterSlugEffect()
  useInitAnalyticsProviders()
  useCustomerFetchEffect()
  useLoginAsGuestEffect()
  useFetchLoyaltyHistoryEffect()
  useCalculateTimeAndCostsEffect()
  useBrowsingEffect()
  useCartCleanUpEffect()
  usePreselectOrderingMethod()
  useCustomLinksFetchEffect()
  useFetchAnnouncementsEffect()
  useFetchCatalogEffect()
  useSignalRCustomerEffect()
}

export default useGlobalAppEffect
