import { SxProps } from '@mui/material'
import SingleChoiceModifiersContainerTemplateServiceInterface from '~/src/components/products/modifier-groups/modifier-group/modifier-group-single-choice/single-choice-modifiers-container/styles/SingleChoiceModifiersContainerTemplateServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class SingleChoiceModifiersContainerTemplateServiceStyleTwo extends TemplateServiceStyleBase implements SingleChoiceModifiersContainerTemplateServiceInterface {
  public getContainerSx(hasImage: boolean): SxProps {
    return {
      '--item__width': '1fr',
      '--item__height': '150px',
      display: 'grid',
      gridTemplateColumns: `repeat(${hasImage ? 4 : 3}, minmax(0, var(--item__width)))`,
      gridAutoRows: 'var(--item__height)',
      justifyItems: 'stretch',
      gap: 1,
      p: 0,
      mt: 2,
      maxWidth: '100%',
      width: '100%',
      [this.muiTheme.breakpoints.up('2k')]: {
        '--item__height': '180px'
      },
      [this.muiTheme.breakpoints.down('md')]: {
        gridTemplateColumns: 'repeat(2, minmax(0, var(--item__width)))'
      }
    }
  }
}
