import { createSelector } from '~/src/hooks/useEoState'
import { LANGUAGE_STATE } from '~/src/stores/lang'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { Link } from '@eo-storefronts/eo-core'
import routes from '~/src/router/enums/routes.enum'

export const TERMS_AND_CONDITION_SELECTOR = createSelector({
  key: 'termsLinkSelector',
  get: ({ get }) => {
    const lang = get(LANGUAGE_STATE)
    const firm = get(FIRM_SELECTOR)

    let link = null

    if (firm) {
      link = firm.settings.links?.find((l: Link) => l.locale === lang && l.docType === 'terms')
    }

    return link?.link || routes.TERMS_AND_CONDITIONS
  }
})
