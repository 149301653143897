import { Product, TemplateEnum } from '@eo-storefronts/eo-core'
import { Card, CardActions, CardContent } from '@mui/material'
import { useMemo } from 'react'
import ProductItemCardActions from '~/src/components/products/product-item/ProductItemCardActions'
import ProductItemCardContent from '~/src/components/products/product-item/ProductItemCardContent'
import ProductItemCardMedia from '~/src/components/products/product-item/ProductItemCardMedia'
import ProductItemPromotion from '~/src/components/products/product-item/ProductItemPromotion'
import useGetProductItemTemplate from '~/src/components/products/product-item/styles/useGetProductItemTemplate'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { useEoValue } from '~/src/hooks/useEoState'
import { PRODUCT_CARD_THEME_COMPONENT_SELECTOR, SHOW_PRODUCT_IMG_SELECTOR_FAMILY } from '~/src/stores/theme'

interface Props {
  product: Product,
  containerTemplate?: TemplateEnum,
}

const ProductItem = ({ product, containerTemplate }: Props) => {
  const muiTheme = useCustomMuiTheme()
  const style = useGetProductItemTemplate(containerTemplate)
  const productCardOptions = useEoValue(PRODUCT_CARD_THEME_COMPONENT_SELECTOR)
  const showImage = useEoValue(SHOW_PRODUCT_IMG_SELECTOR_FAMILY(product))

  return useMemo(() => (
    <Card
      sx={style.getCardLayout(showImage, productCardOptions)}
      elevation={muiTheme.elevation}
    >
      <ProductItemCardMedia
        product={product}
        containerTemplate={containerTemplate}
      />
      {containerTemplate !== TemplateEnum.STYLE_TWO && <ProductItemPromotion product={product}/>}

      <CardContent sx={style.getCardContentStyle()}>
        <ProductItemCardContent
          product={product}
          containerTemplate={containerTemplate}
          {...style.getProductItemCardContentProps()}
        />
      </CardContent>

      <CardActions sx={style.getCardActionsStyle()}>
        <ProductItemCardActions
          product={product}
          containerTemplate={containerTemplate}
          {...style.getProductActionsProps()}
        />
      </CardActions>
    </Card>
  ), [ product, style ])
}

export default ProductItem
