import { Box } from '@mui/material'
import ForgotPasswordResponsiveDialog from '~/src/components/auth/forgot-password/ForgotPasswordResponsiveDialog'
import { useTranslations } from '~/src/hooks/useTranslations'
import { LoginFormInterface } from '~/src/types/forms/LoginFormInterface'
import { FormikProps } from 'formik'
import FormikTextField from '~/src/components/mui-wrappers/fields/FormikTextField'
import LoginForgotPasswordLink from '~/src/components/auth/login/LoginForgotPasswordLink'

interface Props {
  form: FormikProps<LoginFormInterface>,
  fieldsColor: 'container' | 'content',
}

const LoginFormFields = ({ form, fieldsColor = 'content' }: Props) => {
  const { t } = useTranslations()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 4
      }}
    >
      <FormikTextField
        TextFieldProps={{
          form,
          color: fieldsColor,
          name: 'email',
          inputMode: 'email',
          label: t('auth.whatsYourEmail')
        }}
      />

      <FormikTextField
        TextFieldProps={{
          form,
          color: fieldsColor,
          name: 'password',
          type: 'password',
          label: t('auth.yourStrongestPassword'),
          helperText: (
            <ForgotPasswordResponsiveDialog
              activator={
                <LoginForgotPasswordLink>
                  {t('auth.damnIForgotMyPassword')}
                </LoginForgotPasswordLink>
              }
            />
          )
        }}
      />
    </Box>
  )
}

export default LoginFormFields
