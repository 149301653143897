import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'
import StylesOrderingMethodsSummaryPickupPointsServiceInterface
  from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-pickup-points/styles/StylesOrderingMethodsSummaryPickupPointsServiceInterface'
import StylesOrderingMethodsSummaryPickupPointsServiceStyleTwo
  from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-pickup-points/styles/StylesOrderingMethodsSummaryPickupPointsServiceStyleTwo'
import StylesOrderingMethodsSummaryPickupPointsServiceStyleOne
  from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-pickup-points/styles/StylesOrderingMethodsSummaryPickupPointsServiceStyleOne'

const getOrderingMethodsSummaryPickupPointsTemplateService = (style: TemplateEnum | null | undefined, muiTheme: CustomMuiTheme): StylesOrderingMethodsSummaryPickupPointsServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesOrderingMethodsSummaryPickupPointsServiceStyleTwo(muiTheme)
    default:
      return new StylesOrderingMethodsSummaryPickupPointsServiceStyleOne(muiTheme)
  }
}

export default getOrderingMethodsSummaryPickupPointsTemplateService
