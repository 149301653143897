import { PaymentStateEnum } from '@eo-storefronts/eo-core'
import { useEoValue, useSetEoState } from '~/src/hooks/useEoState'
import {
  CHECKOUT_FORM_STATE,
  CHECKOUT_ORDER_ID,
  CHECKOUT_PAYMENT_STATE,
  CHECKOUT_SLOT_ID,
  checkoutFormInitialState,
  CheckoutFormState,
  OrderIdState
} from '~/src/stores/checkout'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { INTERVALS_STATE, TIMESLOTS_STATE } from '~/src/stores/timeslots'

interface ReturnsType {
  resetStates(resetCheckout?: boolean): void,
}

const useResetStates = (): ReturnsType => {
  const setCheckoutForm = useSetEoState(CHECKOUT_FORM_STATE)
  const setSlotId = useSetEoState(CHECKOUT_SLOT_ID)
  const setTimeslots = useSetEoState(TIMESLOTS_STATE)
  const setIntervals = useSetEoState(INTERVALS_STATE)
  const setPaymentState = useSetEoState<PaymentStateEnum|null>(CHECKOUT_PAYMENT_STATE)
  const setOrderId = useSetEoState<OrderIdState>(CHECKOUT_ORDER_ID)
  const firm = useEoValue(FIRM_SELECTOR)

  const resetStates = (resetCheckout = true): void => {
    localStorage.removeItem('checkoutForm')
    resetCheckout && setCheckoutForm((state: CheckoutFormState) => {
      const { orderingMethod: { method } } = state

      return {
        ...checkoutFormInitialState(),
        orderingMethod: {
          ...checkoutFormInitialState().orderingMethod,
          method
        }
      }
    })
    setSlotId(null)
    setPaymentState(null)

    if (firm !== null) {
      setOrderId((state: OrderIdState) => ({
        ...state,
        [firm.id]: null
      }))
    }

    setTimeslots({})
    setIntervals({})
  }

  return { resetStates }
}

export default useResetStates
