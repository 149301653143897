import { useEoValue } from '~/src/hooks/useEoState'
import {
  CUSTOMER_LOYALTY_POINT_BALANCE_SELECTOR,
  CUSTOMER_LOYALTY_POINT_TRESHOLD_NEXT_EXPIRING_VOUCHER_SELECTOR_FAMILY
} from '~/src/stores/customer'
import { useTranslations } from '~/src/hooks/useTranslations'
import { Box } from '@mui/material'
import NextExpiringVoucher from '~/src/components/profile/account/loyalty/next-expiring-voucher/NextExpiringVoucher'
import useLoyaltyService from '~/src/hooks/customer/useLoyaltyService'

const CustomerLoyaltyCurrentPointsLabel = () => {
  const { t } = useTranslations()
  const loyaltyService = useLoyaltyService()
  const customerLoyaltyPointsBalance = useEoValue(CUSTOMER_LOYALTY_POINT_BALANCE_SELECTOR)
  const nextPointVoucherExpiring = useEoValue(CUSTOMER_LOYALTY_POINT_TRESHOLD_NEXT_EXPIRING_VOUCHER_SELECTOR_FAMILY('point'))

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: 1
    }}>
      <Box
        component='span'
        sx={{
          fontSize: '1rem',
          fontWeight: 'bold'
        }}>
        {customerLoyaltyPointsBalance} {t('profile.loyalty.points').toLowerCase()}
      </Box>

      <Box component='span'
        sx={{
          fontSize: '.85rem'
        }}>
        {t('profile.loyalty.outOfxPoints', { value: loyaltyService.getRequiredPoints() }).toLowerCase()}
      </Box>

      <NextExpiringVoucher voucher={nextPointVoucherExpiring} />
    </Box>
  )
}

export default CustomerLoyaltyCurrentPointsLabel
