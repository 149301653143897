import { FirebaseMessaging, GetTokenResult } from '@capacitor-firebase/messaging'
import useInitNotificationPermission from '~/src/hooks/push-notifications/useInitNotificationPermission'
import useIsNotificationPermissionsAllowed from '~/src/hooks/push-notifications/useIsNotificationPermissionsAllowed'
import useSaveDeviceToken from '~/src/hooks/push-notifications/useSaveDeviceToken'
import EnvUtils from '~/src/utils/EnvUtils'

interface ReturnsType {
  initPushNotifications(customerUid?: string): Promise<void | undefined>,
}

const usePushNotifications = (): ReturnsType => {
  const { check: checkPermission } = useIsNotificationPermissionsAllowed()
  const { saveDeviceToken } = useSaveDeviceToken()
  const { initNotificationPermission } = useInitNotificationPermission()

  const initPushNotifications = async (customerUid?: string): Promise<void | undefined> => {
    if (EnvUtils.isDeviceWeb) {
      return
    }

    await initNotificationPermission()

    const permissionGranted: boolean | undefined = await checkPermission()

    if (!permissionGranted) {
      return
    }

    const result: GetTokenResult = await FirebaseMessaging.getToken()
    await saveDeviceToken(result.token, customerUid)
  }

  return { initPushNotifications }
}

export default usePushNotifications
