import { formControlLabelClasses, SxProps } from '@mui/material'
import SingleChoiceModifierTemplateServiceInterface from '~/src/components/products/modifier-groups/modifier-group/modifier-group-single-choice/single-choice-modifier/styles/SingleChoiceModifierTemplateServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class SingleChoiceModifierTemplateServiceTwo extends TemplateServiceStyleBase implements SingleChoiceModifierTemplateServiceInterface {
  public getContainerSx(disabled: boolean): SxProps {
    return {
      filter: disabled ? 'opacity(.5) grayscale(1)' : undefined,
      [this.muiTheme.breakpoints.down('md')]: {
        flexShrink: 0
      }
    }
  }

  public getFormControlSx(): SxProps {
    return {
      height: '100%',
      width: '100%'
    }
  }

  public getFormControlLabelSx(selected: boolean): SxProps {
    return {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: 'content.main',
      borderRadius: this.muiTheme.shape.borderRadius,
      color: 'content.contrastText',
      borderColor: !selected ? 'content.main' : 'primary.main',
      borderWidth: '3px',
      borderStyle: 'solid',
      boxShadow: 'none',
      px: 2,
      py: 1,
      m: 0,
      height: '100%',
      width: '100%',
      [`.${formControlLabelClasses.label}`]: {
        height: '100%'
      }
    }
  }

  public getRadioSx(): SxProps {
    return {
      visibility: 'hidden',
      p: 0,
      width: 0,
      height: 0
    }
  }
}
