import { FilterOption, HttpError, Location } from '@eo-storefronts/eo-core'
import { useState } from 'react'
import { useEoValue, useSetEoState } from '~/src/hooks/useEoState'
import { fetchMarketplaceLocations } from '~/src/services/MarketplaceService'
import { BRAND_STATE } from '~/src/stores/brand'
import { MARKETPLACE_SEARCH_VALUE_STATE, MARKETPLACE_SELECTED_FILTERS_STATE } from '~/src/stores/marketplace/filter'
import {
  MARKETPLACE_IS_FETCHING_LOCATIONS_STATE,
  MARKETPLACE_LOCATIONS_STATE
} from '~/src/stores/marketplace/locations'
import { useParams } from 'react-router-dom'

interface FetchOptions {
  updateState?: boolean,
  updateLoader?: boolean,
  uid?: string,
  search?: string,
  filters?: FilterOption[],
}

interface ReturnsType {
  data: Location[] | undefined,
  loading: boolean,
  error: HttpError | unknown | undefined,
  fetch(options?: FetchOptions): Promise<Location[] | undefined>,
}

const useFetchLocations = (): ReturnsType => {
  const { catId: categoryUUID } = useParams()
  const [ locations, setLocations ] = useState<Location[] | undefined>(undefined)
  const [ error, setError ] = useState<HttpError | unknown | undefined>(undefined)
  const [ loading, setLoading ] = useState<boolean>(false)
  const brand = useEoValue(BRAND_STATE)
  const selectedFilters = useEoValue(MARKETPLACE_SELECTED_FILTERS_STATE)
  const searchValue = useEoValue(MARKETPLACE_SEARCH_VALUE_STATE)
  const setLocationsState = useSetEoState(MARKETPLACE_LOCATIONS_STATE)
  const setIsFetchingLocations = useSetEoState(MARKETPLACE_IS_FETCHING_LOCATIONS_STATE)

  const fetch = async (
    {
      updateState = true,
      updateLoader = true,
      uid = categoryUUID,
      search = searchValue,
      filters = selectedFilters
    }: FetchOptions = {
      updateState: true,
      updateLoader: true,
      uid: categoryUUID,
      search: searchValue,
      filters: selectedFilters
    }
  ): Promise<Location[] | undefined> => {
    if (!brand?.id) {
      return
    }

    setLocations(undefined)
    setError(undefined)
    updateLoader && setLoading(true)
    updateLoader && setIsFetchingLocations(true)

    try {
      const response: Location[] = await fetchMarketplaceLocations(
        brand.id,
        uid ?? categoryUUID,
        search ?? searchValue,
        filters ?? selectedFilters
      )

      setLocations(response)
      updateState && setLocationsState(response)
    } catch (error: HttpError | unknown) {
      setError(error)
    } finally {
      updateLoader && setLoading(false)
      updateLoader && setIsFetchingLocations(false)
    }
  }

  return { data: locations, loading, error, fetch }
}

export default useFetchLocations
