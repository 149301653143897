import { Modifier } from '@eo-storefronts/eo-core'
import { Box, FormControl, FormControlLabel, Radio } from '@mui/material'
import useSingleChoiceModifierTemplateService from '~/src/components/products/modifier-groups/modifier-group/modifier-group-single-choice/single-choice-modifier/styles/useSingleChoiceModifierTemplateService'
import ModifierToggle from '~/src/components/products/modifier-groups/modifier-group/modifier/modifier-toggle'
import useModifiersService from '~/src/hooks/products/modifier-groups/modifiers/useModifiersService'

interface Props {
  modifier: Modifier,
  selected: boolean,
}

const SingleChoiceModifier = ({ modifier, selected }: Props) => {
  const { isModifierAvailable }  = useModifiersService(modifier)
  const styles = useSingleChoiceModifierTemplateService()

  return (
    <Box sx={styles.getContainerSx(!isModifierAvailable())}>
      <FormControl sx={styles.getFormControlSx()}>
        <FormControlLabel
          sx={styles.getFormControlLabelSx(selected)}
          control={<Radio
            sx={styles.getRadioSx()}
            value={modifier.id}
            disabled={!isModifierAvailable()}
          />}
          label={<ModifierToggle modifier={modifier} />}
        />
      </FormControl>
    </Box>
  )
}

export default SingleChoiceModifier
