import Button from '~/src/components/mui-wrappers/buttons/Button'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import EditIcon from '@mui/icons-material/Edit'
import { useEoValue } from '~/src/hooks/useEoState'
import { LAYOUT_STATE } from '~/src/stores/layout'
import OrderingMethodsSummaryMethodSentence
  from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-method/ordering-methods-summary-method-sentence'

interface Props {
  showAnimation: boolean,
}

const OrderingMethodsSummaryMethod = ({ showAnimation }: Props) => {
  const { isMobile } = useEoValue(LAYOUT_STATE)

  return (
    <Button
      className='ordering-methods-summary__button'
      variant={'contained'}
      fullWidth
      sx={{
        justifyContent: 'space-between',
        textTransform: 'none',
        borderRadius: 1
      }}
      endIcon={isMobile ? <KeyboardArrowDownIcon/> : <EditIcon/>}>
      <OrderingMethodsSummaryMethodSentence showAnimation={showAnimation}/>
    </Button>
  )
}

export default OrderingMethodsSummaryMethod
