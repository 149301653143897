import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { useTranslations } from '~/src/hooks/useTranslations'
import { useEoValue } from '~/src/hooks/useEoState'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import { differenceInMinutes, format, formatDistanceToNowStrict } from 'date-fns'
import { useMemo } from 'react'
import useDateFnsLocale from '~/src/hooks/ordering-methods/useDateFnsLocale'
import OrderingMethodsSummaryMethodSentenceSplitter
  from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-method/ordering-methods-summary-method-sentence/OrderingMethodsSummaryMethodSentenceSplitter'
import { OrderingMethodNames } from '@eo-storefronts/eo-core'

interface Props {
  showSplitter?: boolean,
}

const OrderingMethodsSummaryMethodSentenceTime = ({ showSplitter = true }: Props) => {
  const { t } = useTranslations()
  const { orderingMethod } = useEoValue(CHECKOUT_FORM_STATE)
  const { adapterLocale } = useDateFnsLocale()

  const asapTimeSentence = useMemo(() => {
    if (orderingMethod.asap && orderingMethod.minDate) {
      if (differenceInMinutes(new Date(orderingMethod.minDate), new Date()) <= 10) {
        return ''
      }

      return `(${formatDistanceToNowStrict(new Date(orderingMethod.minDate), { locale: adapterLocale })})`
    }

    if (orderingMethod.time) {
      return format(new Date(orderingMethod.time), 'HH:mm')
    }

    return ''
  }, [ orderingMethod.method, orderingMethod.asap, orderingMethod.time, adapterLocale ])

  if (orderingMethod.method === OrderingMethodNames.ON_THE_SPOT) {
    return null
  }

  return (
    <span>
      {showSplitter && <OrderingMethodsSummaryMethodSentenceSplitter/>}
      <AccessTimeIcon/>
      <>&nbsp;</>
      {orderingMethod.asap && t('orderingMethods.asap')}
      <>&nbsp;</>
      {asapTimeSentence}
    </span>
  )
}

export default OrderingMethodsSummaryMethodSentenceTime
