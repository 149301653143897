import { ProductConfigurationModalProps } from '~/src/components/products/modals/configuration'
import ProductConfigurationModalTemplateServiceInterface
  from '~/src/components/products/modals/configuration/styles/ProductConfigurationModalTemplateServiceInterface'
import { ProductListModalProps } from '~/src/components/products/modals/products-list'
import ResponsiveDialogProps from '~/src/types/ResponsiveDialogProps'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'
import { PlatformUtils } from '@eo-storefronts/eo-core'

export default class ProductConfigurationModalTemplateServiceStyleTwo extends TemplateServiceStyleBase implements ProductConfigurationModalTemplateServiceInterface {
  public getProductModalContainerProps(baseProps: ProductConfigurationModalProps): ResponsiveDialogProps {
    return {
      ...baseProps,
      breakpoint: 'md',
      showCloseButton: false,
      showPuller: (PlatformUtils().isCapacitorNative || PlatformUtils().isMobileWeb) && this.layout?.isMobile
    }
  }

  public getProductListModalProps(baseProps: ProductConfigurationModalProps): ProductListModalProps {
    return {
      ...baseProps,
      breakpoint: 'md',
      productIds: baseProps.product.subProductsId
    }
  }
}
