import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'
import StylesInternalErrorServiceStyleTwo
  from '~/src/pages/errors/internal-error/styles/StylesInternalErrorServiceStyleTwo'
import StylesInternalErrorServiceStyleOne
  from '~/src/pages/errors/internal-error/styles/StylesInternalErrorServiceStyleOne'
import StylesNotFoundServiceStyleOne from '~/src/pages/errors/not-found/styles/StylesNotFoundServiceStyleOne'
import StylesInternalErrorServiceInterface
  from '~/src/pages/errors/internal-error/styles/StylesInternalErrorServiceInterface'

const getInternalErrorTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): StylesInternalErrorServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesInternalErrorServiceStyleTwo(muiTheme)
    case TemplateEnum.STYLE_THREE:
      return new StylesInternalErrorServiceStyleOne(muiTheme)
    default:
      return new StylesNotFoundServiceStyleOne(muiTheme)
  }
}

export default getInternalErrorTemplateService
