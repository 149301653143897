import { SxProps } from '@mui/material'
import ProductDetailsFooterTemplateServiceInterface from '~/src/components/products/product-details/product-details-footer/styles/ProductDetailsFooterTemplateServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class ProductDetailsFooterTemplateServiceStyleTwo extends TemplateServiceStyleBase implements ProductDetailsFooterTemplateServiceInterface {
  public getContainerSx(asModifier: boolean): SxProps {
    return {
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'repeat(2, auto)',
      gridTemplateAreas: '"quantity" "button"',
      gap: asModifier ? 0 : 1,
      pb: 'var(--eo-safe-area-bottom)'
    }
  }

  public getQuantitySx(): SxProps {
    return {
      gridArea: 'quantity',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  }

  public getAddToCartSx(): SxProps {
    return {
      gridArea: 'button',
      width: '100%'
    }
  }
}
