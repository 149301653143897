import { App } from '@capacitor/app'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import EnvUtils from '~/src/utils/EnvUtils'
import { PlatformUtils } from '@eo-storefronts/eo-core'

const useAppBackButtonListener = (): void => {
  const navigate = useNavigate()

  useEffect(() => {
    if (!EnvUtils.isDeviceApp && !PlatformUtils().isCapacitorNative) {
      return
    }

    App.addListener('backButton', () => {
      navigate(-1)
    })
  }, [])
}

export default useAppBackButtonListener
