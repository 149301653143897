import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'
import StylesOrderingMethodsSummaryServiceInterface
  from '~/src/components/ordering-methods/ordering-methods-summary/styles/StylesOrderingMethodsSummaryServiceInterface'
import StylesOrderingMethodsSummaryServiceStyleTwo
  from '~/src/components/ordering-methods/ordering-methods-summary/styles/StylesOrderingMethodsSummaryServiceStyleTwo'
import StylesOrderingMethodsSummaryServiceStyleOne
  from '~/src/components/ordering-methods/ordering-methods-summary/styles/StylesOrderingMethodsSummaryServiceStyleOne'

const getOrderingMethodsSummaryTemplateService = (style: TemplateEnum | null | undefined, muiTheme: CustomMuiTheme): StylesOrderingMethodsSummaryServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesOrderingMethodsSummaryServiceStyleTwo(muiTheme)
    default:
      return new StylesOrderingMethodsSummaryServiceStyleOne(muiTheme)
  }
}

export default getOrderingMethodsSummaryTemplateService
