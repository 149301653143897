import { Firm, OrderingMethodNames, OrderOnSpotCustomMethodLocation } from '@eo-storefronts/eo-core'
import { Box, inputBaseClasses, TextField, Typography } from '@mui/material'
import { ChangeEvent } from 'react'
import { useEatInLocationPickerDetails } from '~/src/hooks/ordering-methods/useEatInLocationPickerDetails'
import { useEoState, useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { CHECKOUT_FORM_STATE, CheckoutFormState } from '~/src/stores/checkout'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { LANGUAGE_STATE } from '~/src/stores/lang'

const EatInLocationPicker = () => {
  const { t } = useTranslations()
  const firm = useEoValue<Firm|null>(FIRM_SELECTOR)
  const lang = useEoValue(LANGUAGE_STATE)
  const [ checkoutForm, setCheckoutForm ] = useEoState(CHECKOUT_FORM_STATE)
  const { eatinLocations } = useEatInLocationPickerDetails()

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const location = eatinLocations.find(l => l.name === event.target.value)

    if (!location) {
      return
    }

    setCheckoutForm((state: CheckoutFormState) => ({
      ...state,
      orderingMethod: {
        ...state.orderingMethod,
        tableNumber: location.name,
        tableUid: location.uid
      }
    }))
  }

  if (
    !firm?.settings.orderingMethods[OrderingMethodNames.ON_THE_SPOT]?.numberRequired
    && !checkoutForm.orderingMethod.tableMethod
  ) {
    return null
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Typography>{firm?.labels.table_number[lang] || ''}</Typography>
      <TextField
        select
        size='small'
        color='container'
        error={!checkoutForm.orderingMethod.tableNumber}
        SelectProps={{
          native: true
        }}
        variant='outlined'
        onChange={handleOnChange}
        value={checkoutForm.orderingMethod.tableNumber || ''}
        sx={{
          [`.${inputBaseClasses.root}`]: {
            [`&.${inputBaseClasses.error}`]: {
              color: 'error.main',
              fieldset: {
                color: 'error.main',
                borderColor: 'error.main'
              },
              svg: {
                color: 'error.main'
              },
              ['&:hover']: {
                color: 'error.main',
                fieldset: {
                  color: 'error.main',
                  borderColor: 'error.main'
                },
                svg: {
                  color: 'error.main'
                }
              }
            }
          }
        }}
      >
        <option value=''>{t('orderingMethods.chooseYourSpot')}</option>
        {
          eatinLocations.map((eatinLocation: OrderOnSpotCustomMethodLocation) => (
            <option
              key={eatinLocation.id}
              value={eatinLocation.name}
            >
              {eatinLocation.name}
            </option>
          ))
        }
      </TextField>
    </Box>
  )
}

export default EatInLocationPicker
