import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import useGetComponentStyle from '~/src/hooks/layout/useGetComponentStyle'
import StylesOrderingMethodsSummaryMethodButtonServiceInterface
  from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-method-button/styles/StylesOrderingMethodsSummaryMethodButtonServiceInterface'
import getOrderingMethodsSummaryMethodButtonTemplateService
  from '~/src/components/ordering-methods/ordering-methods-summary/ordering-methods-summary-method-button/styles/GetOrderingMethodsSummaryMethodButtonTemplateService'

const useGetOrderingMethodsSummaryMethodButtonTemplate = (): StylesOrderingMethodsSummaryMethodButtonServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const componentStyle = useGetComponentStyle('orderingMethodsSummary')

  return getOrderingMethodsSummaryMethodButtonTemplateService(componentStyle, muiTheme)
}

export default useGetOrderingMethodsSummaryMethodButtonTemplate
