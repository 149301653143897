import { SxProps } from '@mui/material'
import MultiChoiceModifierTemplateServiceInterface from '~/src/components/products/modifier-groups/modifier-group/modifier-group-multi-choice/multi-choice-modifier/styles/MultiChoiceModifierTemplateServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class MultiChoiceModifierTemplateServiceStyleOne extends TemplateServiceStyleBase implements MultiChoiceModifierTemplateServiceInterface {
  public getContainerSx(disabled: boolean): SxProps {
    return {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'space-between',
      filter: disabled ? 'opacity(.5) grayscale(1)' : undefined
    }
  }

  public getModifierSx(): SxProps {
    return {
      display: 'grid',
      alignItems: 'center',
      gridTemplateColumns: 'max-content auto',
      gridTemplateRows: 'auto',
      width: '100%',
      gridTemplateAreas: '"q-selector label"',
      ml: -1.5
    }
  }

  public getQuantitySelectorSx(): SxProps {
    return {
      gridArea: 'q-selector',
      p: 1,
      pl: 1.25
    }
  }

  public getModifierToggleSx(): SxProps {
    return {
      display: 'flex',
      cursor: 'pointer',
      alignItems: 'center',
      gridArea: 'label'
    }
  }

  public displayPriceAsChip(): boolean {
    return true
  }
}
