import { Box, Typography } from '@mui/material'
import ButtonLink from '~/src/components/mui-wrappers/buttons/ButtonLink'
import RoutesEnum from '~/src/router/enums/routes.enum'
import { useTranslations } from '~/src/hooks/useTranslations'

const SideDrawerGreetingsOffLine = () => {
  const { t } = useTranslations()
  
  return (
    <Box>
      <Typography sx={{ fontSize: '1.3rem' }}>
        {t('sideDrawer.greetings.notLoggedIn')}
      </Typography>
      <Typography sx={{ fontSize: '1.3rem', fontWeight: 'bold' }}>
        {t('sideDrawer.greetings.notLoggedInCta')}
      </Typography>
      <Box sx={{
        mt: 2,
        display: 'grid',
        gap: 2,
        gridTemplateColumns: 'repeat(2, 1fr)'
      }}>
        <ButtonLink
          variant='contained'
          disableElevation
          displayIcon={false}
          color='primary'
          linkProps={{
            href: RoutesEnum.LOGIN,
            className: 'eo-w-full'
          }}
          sx={{
            fontSize: '.8rem',
            textTransform: 'none',
            width: '100%'
          }}
        >
          {t('auth.logIn')}
        </ButtonLink>
        <ButtonLink
          variant='contained'
          disableElevation
          displayIcon={false}
          color='inherit'
          linkProps={{
            href: RoutesEnum.REGISTER,
            className: 'eo-w-full'
          }}
          sx={{
            fontSize: '.8rem',
            textTransform: 'none',
            color: 'text.black',
            width: '100%'
          }}
        >
          {t('auth.createAccount')}
        </ButtonLink>
      </Box>
    </Box>
  )
}

export default SideDrawerGreetingsOffLine
