import { PriceList, Product } from '@eo-storefronts/eo-core'
import { useMemo } from 'react'
import { useEoValue } from '~/src/hooks/useEoState'
import { getProductPrice } from '~/src/services/ProductService'
import { CHECKOUT_FORM_STATE, CheckoutFormState } from '~/src/stores/checkout'
import { PRICE_LIST_STATE } from '~/src/stores/product/price-list'

type ReturnsType = {
  getPrice: number,
}

export function useGetProductPrice(product: Product): ReturnsType {
  const priceLists: PriceList[] = useEoValue(PRICE_LIST_STATE)
  const checkoutFormState: CheckoutFormState = useEoValue(CHECKOUT_FORM_STATE)

  const getPrice = useMemo(
    () => {
      const prices = getProductPrice(product, priceLists)

      if (!checkoutFormState.orderingMethod.method || !(checkoutFormState.orderingMethod.method in prices)) {
        return 0
      }

      return prices[checkoutFormState.orderingMethod.method]
    },
    [ checkoutFormState.orderingMethod.method, product, priceLists ]
  )

  return {
    getPrice
  }
}

export default useGetProductPrice
