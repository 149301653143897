import getModifierToggleTemplateService
  from '~/src/components/products/modifier-groups/modifier-group/modifier/modifier-toggle/styles/getModifierToggleTemplateService'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import useGetComponentStyle from '~/src/hooks/layout/useGetComponentStyle'

const useModifierToggleTemplateService = () => {
  const muiTheme = useCustomMuiTheme()
  const componentStyle = useGetComponentStyle('modifiers')

  return getModifierToggleTemplateService(componentStyle, muiTheme)
}

export default useModifierToggleTemplateService
