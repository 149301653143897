import { Typography } from '@mui/material'
import { Unit } from '@eo-storefronts/eo-core'

interface Props {
  unit: Unit | null,
}

const ProductUnit = ({ unit }: Props) => {
  if (!unit) {
    return null
  }

  return (
    <Typography
      component='span'
      variant='body2'
    >
      {`/${unit.nameShort}`}
    </Typography>
  )
}

export default ProductUnit
