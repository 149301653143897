import getActivationLayout from '~/src/pages/auth/activation/layout/GetActivationLayout'
import getAuthLayout from '~/src/pages/auth/layout/GetAuthLayout'
import getCartLayout from '~/src/pages/cart/layout/GetCartLayout'
import getCategoriesLayout from '~/src/pages/categories/layout/GetCategoriesLayout'
import getProductLayout from '~/src/pages/category/[slug]/product/[slug]/layout/GetProductLayout'
import getCategoryProductsLayout from '~/src/pages/category/[slug]/products/layout/GetCategoryProductsLayout'
import getCheckoutLayout from '~/src/pages/checkout/layout/GetCheckoutLayout'
import getExplorerLayout from '~/src/pages/explorer/layout/GetExplorerLayout'
import getExtraInfoGuestLayout from '~/src/pages/extra-info/guest/layout/GetExtraInfoGuestLayout'
import getFirmInfosLayout from '~/src/pages/firm/infos/layout/GetFirmInfosLayout'
import getPrivacyPolicyLayout from '~/src/pages/legal/privacy-policy/layout/GetPrivacyPolicyLayout'
import getTermsAndConditionsLayout from '~/src/pages/legal/terms-and-conditions/layout/GetTermsAndConditionsLayout'
import getLocatorLayout from '~/src/pages/locator/layout/GetLocatorLayout'
import getOrderLayout from '~/src/components/orders/order-layout/layout/GetOrderLayout'
import getNotFoundLayout from '~/src/pages/errors/not-found/layout/GetNotFoundLayout'
import getProductsLayout from '~/src/pages/products/layout/GetProductsLayout'
import getProfileLayout from '~/src/components/profile/profile-layout/layout/GetProfileLayout'
import getSearchLayout from '~/src/pages/search/layout/GetSearchLayout'
import { LayoutState } from '~/src/stores/layout'
import { DeepPartial } from '~/src/types/DeepPartial'
import { TemplatePages } from '~/src/types/Templates'
import getLocationsLayout from '~/src/pages/category/[slug]/locations/layout/GetLocationsLayout'
import getNewsDetailsLayout from '~/src/pages/news/[slug]/details/layout/GetNewsDetailsLayout'
import { TemplateEnum } from '@eo-storefronts/eo-core'
import getNewsLayout from '~/src/pages/news/layout/GetNewsLayout'
import getUpdateLayout from '~/src/pages/update/layout/GetUpdateLayout'
import getExtraInfoCustomerLayout from '~/src/pages/extra-info/customer/layout/GetExtraInfoCustomerLayout'
import { GetLayoutArgs } from '~/src/utils/layout/LayoutServiceInterface'
import getResetPasswordLayout from '~/src/pages/auth/reset-password/layout/GetResetPasswordLayout'
import getActivateAccountLayout from '~/src/pages/auth/activate-account/layout/GetActivateAccountLayout'

type LayoutDispatcherCallback = (style: TemplateEnum, getLayoutArgs: GetLayoutArgs) => DeepPartial<LayoutState>

export const layoutDispatcherMap: Record<TemplatePages, LayoutDispatcherCallback> = {
  locator: getLocatorLayout,
  order: getOrderLayout,
  checkout: getCheckoutLayout,
  activateAccount: getActivateAccountLayout,
  activation: getActivationLayout,
  resetPassword: getResetPasswordLayout,
  product: getProductLayout,
  products: getProductsLayout,
  cart: getCartLayout,
  categories: getCategoriesLayout,
  privacyPolicy: getPrivacyPolicyLayout,
  termsAndConditions: getTermsAndConditionsLayout,
  login: getAuthLayout,
  register: getAuthLayout,
  search: getSearchLayout,
  category: getCategoryProductsLayout,
  extraInfoGuest: getExtraInfoGuestLayout,
  extraInfoCustomer: getExtraInfoCustomerLayout,
  profile: getProfileLayout,
  firmInfos: getFirmInfosLayout,
  notFound: getNotFoundLayout,
  update: getUpdateLayout,
  explorer: getExplorerLayout,
  // @TODO modify those below
  location: getLocatorLayout,
  locations: getLocationsLayout,
  news: getNewsLayout,
  newsDetails: getNewsDetailsLayout
}
