import { Country, Firm, LanguageType } from '@eo-storefronts/eo-core'
import { createSelector, createState } from '~/src/hooks/useEoState'
import { FIRM_WITH_LOCATOR_STATE_SELECTOR } from '~/src/stores/firm'

export const LANGUAGE_STATE = createState<LanguageType>({
  key: 'langState',
  default: 'en'
})

export const SUPPORTED_COUNTRIES_SELECTOR = createSelector<Country[]>({
  key: 'supportedCountriesSelector',
  get: ({ get }): Country[] => {
    const firm: Firm | null = get(FIRM_WITH_LOCATOR_STATE_SELECTOR)

    return firm?.countries || []
  }
})
