import { Box } from '@mui/material'
import Card from '~/src/components/mui-wrappers/Card'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { LOYALTY_IS_ENABLED_SELECTOR, LOYALTY_IS_POINT_SELECTOR, LOYALTY_IS_POINT_THRESHOLD_SELECTOR } from '~/src/stores/loyalty'
import Title from '~/src/components/mui-wrappers/Title'
import CustomerLoyaltyCurrentVoucherBalancePercentLabel
  from '~/src/components/profile/account/loyalty/current-voucher-balance/CustomerLoyaltyCurrentVoucherBalancePercentLabel'
import CustomerLoyaltyCurrentVoucherBalancePointsLabel
  from '~/src/components/profile/account/loyalty/current-voucher-balance/CustomerLoyaltyCurrentVoucherBalancePointsLabel'
import CustomerLoyaltyCurrentVoucherBalancePointsTresholdLabel
  from '~/src/components/profile/account/loyalty/current-voucher-balance/CustomerLoyaltyCurrentVoucherBalancePointsTresholdLabel'

const CustomerLoyaltyCurrentVoucherBalance = () => {
  const theme = useCustomMuiTheme()
  const { t } = useTranslations()
  const isLoyaltyEnabled = useEoValue(LOYALTY_IS_ENABLED_SELECTOR)
  const isLoyaltyPoint = useEoValue(LOYALTY_IS_POINT_SELECTOR)
  const isLoyaltyPointTreshold = useEoValue(LOYALTY_IS_POINT_THRESHOLD_SELECTOR)

  if (!isLoyaltyEnabled) {
    return null
  }

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: 1,
      cursor: 'pointer'
    }}>
      <Title color='primary'>{t('profile.loyalty.voucherBalanceTitle')}</Title>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 1,
          p: 1,
          cursor: 'pointer',
          '&:hover': {
            boxShadow: `0 0 5px ${theme.palette.primary.main}`
          }
        }}
      >
        {isLoyaltyPoint && <CustomerLoyaltyCurrentVoucherBalancePointsLabel />}
        {isLoyaltyPointTreshold && <CustomerLoyaltyCurrentVoucherBalancePointsTresholdLabel />}
        {!isLoyaltyPoint && !isLoyaltyPointTreshold && <CustomerLoyaltyCurrentVoucherBalancePercentLabel/>}
      </Card>
    </Box>
  )
}

export default CustomerLoyaltyCurrentVoucherBalance
