import { Address, Id } from '@eo-storefronts/eo-core'
import { FormControl, Radio, radioClasses, RadioGroup } from '@mui/material'
import { ChangeEvent, useEffect, useState } from 'react'
import InlineAddress from '~/src/components/customers/addresses/InlineAddress'
import FormControlLabel from '~/src/components/mui-wrappers/styled/FormControlLabel'
import { useEoValue } from '~/src/hooks/useEoState'
import { CUSTOMER_STATE } from '~/src/stores/customer'

interface Props {
  selected: Address | null,
  onSelect(address: Address): void,
}

const CustomerAddresses = ({ selected, onSelect }: Props) => {
  const customer = useEoValue(CUSTOMER_STATE)
  const [ selectedAddressId, setSelectedAddressId ] = useState<Id>(selected ? selected.id : 0)

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedAddressId(Number(event.target.value))

    const address = customer?.addresses?.find((address: Address) => address.id === Number(event.target.value))

    address && onSelect(address)
  }

  useEffect(() => {
    if (!customer || selectedAddressId !== 0) {
      return
    }

    const address = customer.addresses?.filter((address: Address) => address.isDefault)[0]

    if (!address) {
      return
    }

    setSelectedAddressId(address.id)
    onSelect(address)
  }, [ customer ])

  return (
    <FormControl sx={{ width: '100%' }}>
      <RadioGroup
        value={selectedAddressId}
        onChange={handleOnChange}
        sx={{
          display: 'flex',
          gap: '0.2rem'
        }}>
        {customer?.addresses?.map((address: Address) => (
          <FormControlLabel
            key={address.id}
            value={address.id}
            checked={selectedAddressId === address.id}
            control={
              <Radio sx={{
                [`&:not(.${radioClasses.checked})`]: {
                  color: 'wells.contrastText'
                }
              }} />
            }
            label={<InlineAddress address={address}/>}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}

export default CustomerAddresses
