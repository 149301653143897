import { Day, OpeningHour } from '@eo-storefronts/eo-core'
import Title from '~/src/components/mui-wrappers/Title'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { buildOpeningHoursString } from '~/src/services/OpeningHourService'
import { FIRM_SELECTOR } from '~/src/stores/firm'

interface Props {
  day: Day,
}

const FirmOpeningHour = ({ day }: Props) => {
  const { t } = useTranslations()
  const firm = useEoValue(FIRM_SELECTOR)
  const openingHour = firm?.settings.periods.openingHours.find((openingHour: OpeningHour) => openingHour.dayId === day.dayId)

  if (openingHour) {
    const openingHourSentence = buildOpeningHoursString(openingHour)
      .replace(/-/g, t('global.to').toLowerCase())
      .replace(/&/g, t('global.and').toLowerCase())

    if (openingHourSentence !== 'closed') {
      return <Title variant='subtitle2'>{t('firm.openFrom')} {openingHourSentence}</Title>
    }
  }

  return <Title variant='subtitle2'>{t('firm.closed')}</Title>
}

export default FirmOpeningHour
