import { CheckoutOption } from '@eo-storefronts/eo-core'
import { Box, Checkbox, checkboxClasses, FormControlLabel, formControlLabelClasses } from '@mui/material'
import Fee from '~/src/components/fees/Fee'
import { useEoState, useEoValue } from '~/src/hooks/useEoState'
import { CHECKOUT_FORM_STATE, CHECKOUT_OPTIONS_ID_SELECTOR, CheckoutFormState } from '~/src/stores/checkout'
import PriceHelper from '~/src/helpers/priceHelper'

interface Props {
  checkoutOption: CheckoutOption,
  enableEdit?: boolean,
}

const CheckoutOptionItem = ({ checkoutOption, enableEdit = true }: Props) => {
  const [ checkoutForm, setCheckoutForm ] = useEoState(CHECKOUT_FORM_STATE)
  const selectedOptions = useEoValue(CHECKOUT_OPTIONS_ID_SELECTOR)

  const handleOnChange = () => {
    const checkoutOptions: CheckoutOption[] = [ ...checkoutForm.checkoutOptions ]
    const index: number = checkoutForm.checkoutOptions.findIndex((option: CheckoutOption) => option.id === checkoutOption.id)

    if (index === -1) {
      checkoutOptions.push(checkoutOption)
    } else {
      checkoutOptions.splice(index, 1)
    }

    setCheckoutForm((state: CheckoutFormState) => ({
      ...state,
      checkoutOptions
    }))
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%'
      }}
    >
      <FormControlLabel
        control={<Checkbox
          disabled={!enableEdit}
          checked={selectedOptions.includes(checkoutOption.id)}
          onChange={handleOnChange}
          inputProps={{ 'aria-label': 'controlled' }}
        />}
        label={
          <Fee
            label={checkoutOption.name}
            value={PriceHelper.transform(checkoutOption.price)}
            sx={{
              width: 'inherit'
            }}
          />
        }
        sx={{
          width: '100%',
          mx: '0 !important',
          [`.${formControlLabelClasses.label}`]: {
            width: 'inherit'
          },
          [`.${checkboxClasses.root}`]: {
            color: 'inherit'
          }
        }}
      />
    </Box>
  )
}

export default CheckoutOptionItem
