import { CustomDay, OrderingMethodNames, Firm } from '@eo-storefronts/eo-core'
import { CheckoutFormState } from '~/src/stores/checkout'

const hasOrderTimeslots = (firm: Firm, checkoutFormState: CheckoutFormState): boolean => {
  if (
    checkoutFormState.orderingMethod.method === OrderingMethodNames.ON_THE_SPOT
    || !checkoutFormState.orderingMethod.method
  ) {
    return false
  }

  if (
    checkoutFormState.orderingMethod.pickupPoint
    && !checkoutFormState.orderingMethod.pickupPoint.mainBranch
    && checkoutFormState.orderingMethod.method === OrderingMethodNames.PICKUP
  ) {
    return checkoutFormState.orderingMethod.pickupPoint.useTimeslots
  }

  return firm.settings.orderingMethods[checkoutFormState.orderingMethod.method]?.orderTimeslots.active || false
}

const hasCustomDays = (firm: Firm, method: OrderingMethodNames): boolean => {
  if (method === OrderingMethodNames.ON_THE_SPOT) {
    return false
  }

  return firm.settings.orderingMethods[method]?.hasCustomDays || false
}

const getCustomDays = (firm: Firm, method: OrderingMethodNames): CustomDay[] => {
  if (method === OrderingMethodNames.ON_THE_SPOT) {
    return []
  }

  return firm.settings.orderingMethods[method]?.customDays || []
}

const isLimitedToSameDayOrdering = (firm: Firm, method: OrderingMethodNames): boolean => {
  if (method === OrderingMethodNames.ON_THE_SPOT) {
    return true
  }

  return firm.settings.orderingMethods[method]?.ordersOnlyDuringOpeningHours || firm.settings.orderingMethods[method]?.ordersOnlyDuringOperationalHours || false
}

const mustShowAsapButtonOnly = (firm: Firm, method: OrderingMethodNames): boolean => {
  if (method === OrderingMethodNames.ON_THE_SPOT) {
    return true
  }

  return firm.settings.orderingMethods[method]?.ordersOnlyDuringOperationalHours || false
}

export {
  hasOrderTimeslots,
  hasCustomDays,
  getCustomDays,
  isLimitedToSameDayOrdering,
  mustShowAsapButtonOnly
}
