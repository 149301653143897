import { useRef } from 'react'

type ReturnsType = {
  listen(onPopState: PopStateCallback): void,
  remove(): void,
}

type PopStateCallback = ($event: PopStateEvent) => void

const usePopStateListener = (): ReturnsType => {
  const popStateFunctionRef = useRef<PopStateCallback | null>(null)

  const listen = (onPopState: PopStateCallback) => {
    popStateFunctionRef.current && remove()
    popStateFunctionRef.current = onPopState

    window.addEventListener('popstate', onPopState)
  }

  const remove = () => {
    if (!popStateFunctionRef.current) {
      return
    }

    window.removeEventListener('popstate', popStateFunctionRef.current)
    popStateFunctionRef.current = null
  }

  return {
    listen,
    remove
  }
}

export default usePopStateListener
