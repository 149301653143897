import { Unit } from '@eo-storefronts/eo-core'

interface Props {
  quantity: number,
  unit?: Unit | null,
}

const ProductQuantity = ({ quantity, unit }: Props) => {
  const value: number | string = unit?.id === 4 ? quantity.toFixed(3) : quantity
  const unitText: string = unit ? unit.nameShort : ''

  return <>{value + (unitText ? ` ${unitText}` : '')}</>
}

export default ProductQuantity
