import { Customer, CustomerLoyaltyVoucher, Firm } from '@eo-storefronts/eo-core'
import { useCallback } from 'react'
import useLogout from '~/src/hooks/auth/useLogout'
import useCheckLocation from '~/src/hooks/customer/useCheckLocation'
import useFetchCustomer from '~/src/hooks/customer/useFetchCustomer'
import useFetchCustomerAddresses from '~/src/hooks/customer/useFetchCustomerAddresses'
import useFetchCustomerLoyalty from '~/src/hooks/customer/useFetchCustomerLoyalty'
import useLinkFirm from '~/src/hooks/customer/useLinkFirm'
import { useEoState, useEoValue, useSetEoState } from '~/src/hooks/useEoState'
import {
  API_TOKEN_STATE,
  AUTHENTICATION_STATE,
  AuthenticationState,
  BaseAuthenticationState
} from '~/src/stores/authentication'
import {
  CUSTOMER_LOADING_STATE,
  CUSTOMER_LOYALTY_VOUCHERS,
  CUSTOMER_STATE,
  CustomerLoadingState
} from '~/src/stores/customer'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { LOYALTY_IS_ENABLED_SELECTOR } from '~/src/stores/loyalty'

interface ReturnType {
  setCustomer(auth?: Partial<AuthenticationState>, force?: boolean): Promise<Customer | null>,
}

const useSetCustomerState = (): ReturnType => {
  const authState = useEoValue<AuthenticationState>(AUTHENTICATION_STATE)
  const apiToken = useEoValue<BaseAuthenticationState | null>(API_TOKEN_STATE)
  const firm: Firm | null = useEoValue<Firm | null>(FIRM_SELECTOR)
  const loyaltyEnabled = useEoValue<boolean>(LOYALTY_IS_ENABLED_SELECTOR)
  const [ customer, setCustomerState ] = useEoState(CUSTOMER_STATE)
  const setCustomerLoadingState = useSetEoState<CustomerLoadingState>(CUSTOMER_LOADING_STATE)
  const setCustomerLoyaltyVouchers = useSetEoState(CUSTOMER_LOYALTY_VOUCHERS)
  const { check: doLinkLocation } = useCheckLocation()
  const { fetch: fetchCustomer } = useFetchCustomer()
  const { doLinkFirm } = useLinkFirm()
  const { fetch: doFetchCustomerAddresses } = useFetchCustomerAddresses()
  const { fetchLoyalty } = useFetchCustomerLoyalty()
  const { logout } = useLogout()

  const setCustomer = useCallback(async (auth: Partial<AuthenticationState> = authState, force = false): Promise<Customer | null> => {
    if (!apiToken?.token || !auth.customer?.id || !auth.customer.token) {
      return null
    }

    await doLinkLocation(firm?.id, auth)
    await doLinkFirm(firm?.id, auth)

    if (customer && !force) {
      return null
    }

    setCustomerLoadingState((state: CustomerLoadingState) => ({
      ...state,
      customer: true
    }))

    let fetchedCustomer: Customer | undefined

    try {
      fetchedCustomer = await fetchCustomer(auth.customer.id)

      if (fetchedCustomer) {
        const addresses = await doFetchCustomerAddresses(fetchedCustomer)

        setCustomerState({
          ...fetchedCustomer,
          addresses
        })
      }

      if (loyaltyEnabled && fetchedCustomer) {
        const loyaltyVouchers: CustomerLoyaltyVoucher[] = await fetchLoyalty(fetchedCustomer.uid)
        setCustomerLoyaltyVouchers(loyaltyVouchers)
      }
    }
    catch 
    {
      logout()
    }

    setCustomerLoadingState((state: CustomerLoadingState) => ({
      ...state,
      customer: false
    }))

    return fetchedCustomer ?? null
  }, [ authState, apiToken, firm ])

  return { setCustomer }
}

export default useSetCustomerState
