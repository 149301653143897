import { Firm, Price as PriceModel } from '@eo-storefronts/eo-core'
import { Box, SxProps } from '@mui/system'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { CHECKOUT_FORM_STATE, CheckoutFormState } from '~/src/stores/checkout'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import NumberUtils from '~/src/utils/NumberUtils'

interface Props {
  price: PriceModel,
  basePrice?: PriceModel,
  sx?: SxProps,
  displayZeroAmount?: boolean,
  displayZeroAsFree?: boolean,
}

const Price = ({ price, basePrice, sx = {}, displayZeroAmount = true, displayZeroAsFree = false }: Props) => {
  const firm: Firm | null = useEoValue(FIRM_SELECTOR)
  const checkoutFormState: CheckoutFormState = useEoValue(CHECKOUT_FORM_STATE)
  const { t } = useTranslations()

  const getPrice = () => {
    if (!checkoutFormState.orderingMethod.method || !(checkoutFormState.orderingMethod.method in price)) {
      return 0
    }

    return price[checkoutFormState.orderingMethod.method]
  }

  const getBasePrice = () => {
    if (!checkoutFormState.orderingMethod.method || !(checkoutFormState.orderingMethod.method in price) || !basePrice) {
      return 0
    }

    return basePrice[checkoutFormState.orderingMethod.method]
  }

  if (!firm) {
    return null
  }

  if (!displayZeroAmount && !getPrice()) {
    return null
  }

  if (displayZeroAsFree && !getPrice()) {
    return <>{t('global.free')}</>
  }

  return (
    <Box component={'span'} sx={sx}>
      {!!basePrice && getBasePrice() > getPrice() && (
        <Box 
          component={'span'} 
          sx={{ textDecoration: 'line-through' }}
        >
          {firm?.settings.currency?.symbol}&nbsp;{NumberUtils.toFixed(getBasePrice(), 2)}
        </Box>
      )}
      &nbsp;
      {firm?.settings.currency?.symbol}&nbsp;{NumberUtils.toFixed(getPrice(), 2)}
    </Box>
  )
}

export default Price
