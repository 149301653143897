import ModifierToggleTemplateServiceInterface
  from '~/src/components/products/modifier-groups/modifier-group/modifier/modifier-toggle/styles/ModifierToggleTemplateServiceInterface'
import ModifierToggleTemplateServiceStyleOne
  from '~/src/components/products/modifier-groups/modifier-group/modifier/modifier-toggle/styles/ModifierToggleTemplateServiceStyleOne'
import ModifierToggleTemplateServiceStyleTwo
  from '~/src/components/products/modifier-groups/modifier-group/modifier/modifier-toggle/styles/ModifierToggleTemplateServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getModifierToggleTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): ModifierToggleTemplateServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new ModifierToggleTemplateServiceStyleTwo(muiTheme)
    default:
      return new ModifierToggleTemplateServiceStyleOne(muiTheme)
  }
}

export default getModifierToggleTemplateService
