import { Price as PriceModel } from '@eo-storefronts/eo-core'
import { Box, SxProps, Typography } from '@mui/material'
import { TypographyProps } from '@mui/material/Typography/Typography'
import Price from '~/src/components/Price'

interface Props {
  label: string,
  value: PriceModel,
  displayZeroAsFree?: boolean,
  sx?: SxProps,
  textProps?: TypographyProps,
  priceProps?: TypographyProps,
}

const Fee = ({ label, value, displayZeroAsFree, textProps, priceProps, sx }: Props) => (
  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', ...sx }}>
    <Typography {...textProps}>
      {label}
    </Typography>
    <Typography {...priceProps}>
      <Price price={value} displayZeroAsFree={displayZeroAsFree} />
    </Typography>
  </Box>
)

export default Fee
