import { SxProps } from '@mui/material'
import StylesLayoutServiceInterface from '~/src/components/layout/styles/StylesLayoutServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesLayoutServiceStyleThree extends TemplateServiceStyleBase implements StylesLayoutServiceInterface {
  public getContainerSx(_: boolean, offset = 0): SxProps {
    return {
      display: 'grid',
      gridTemplateAreas: '' +
        '"firmOfflineAlert"' +
        '"header"' +
        '"sub-header"' +
        '"main"' +
        '"footer"',
      gridTemplateRows: 'repeat(3, max-content) 1fr max-content',
      gridTemplateColumns: '100%',
      minHeight: `calc(100vh - ${offset}px)`,
      [this.muiTheme.breakpoints.down('md')]: {
        gridTemplateAreas: '' +
          '"firmOfflineAlert"' +
          '"header"' +
          '"sub-header"' +
          '"main"' +
          '"footer"' +
          '"mobile-navigation"',
        gridTemplateRows: 'repeat(3, max-content) 1fr repeat(2, max-content)'
      },
      [this.muiTheme.breakpoints.up('md')]: {
        overflow: 'hidden'
      }
    }
  }

  public getMainSx(): SxProps {
    return {
      width: '100vw',
      p: '0 15rem',
      [this.muiTheme.breakpoints.down('lg')]: {
        p: '0 5rem'
      },
      [this.muiTheme.breakpoints.down('md')]: {
        p: 0,
        width: '100%'
      }
    }
  }

  public getFooterSx(): SxProps {
    return {
      backgroundColor: 'transparent',
      color: 'background.contrastText',
      width: '100%',
      zIndex: 2,
      p: '2rem 15rem 5rem 15rem',
      [this.muiTheme.breakpoints.down('lg')]: {
        p: '2rem 5rem 2rem 5rem'
      },
      [this.muiTheme.breakpoints.down('md')]: {
        px: 0
      }
    }
  }
}
