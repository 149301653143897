import { LoyaltyHistory, OrderSummary } from '@eo-storefronts/eo-core'
import { useAsyncEffect } from '~/src/hooks/useAsyncEffect'
import { useEoValue, useSetEoState } from '~/src/hooks/useEoState'
import { getLoyaltyHistory } from '~/src/services/CustomerService'
import { CUSTOMER_STATE } from '~/src/stores/customer'
import { CUSTOMER_LOYALTY_HISTORY, LOYALTY_IS_ENABLED_AND_CUSTOMER_IS_LOGGED_IN_SELECTOR } from '~/src/stores/loyalty'
import { ORDER_HISTORY } from '~/src/stores/orders'

const useFetchLoyaltyHistoryEffect = () => {
  const customer = useEoValue(CUSTOMER_STATE)
  const allOrders = useEoValue<OrderSummary[]>(ORDER_HISTORY)
  const isLoyaltyEnabledAndCustomerLoggedIn = useEoValue<boolean>(LOYALTY_IS_ENABLED_AND_CUSTOMER_IS_LOGGED_IN_SELECTOR)
  const setLoyaltyHistory = useSetEoState(CUSTOMER_LOYALTY_HISTORY)

  useAsyncEffect(async () => {
    if (!isLoyaltyEnabledAndCustomerLoggedIn || !customer) {
      return
    }

    const loyaltyHistory: LoyaltyHistory[] = await getLoyaltyHistory(customer.uid)
    setLoyaltyHistory(loyaltyHistory)
  }, [ 
    isLoyaltyEnabledAndCustomerLoggedIn, 
    customer, 
    allOrders
  ])
}

export default useFetchLoyaltyHistoryEffect
