import { ModifierGroup, Product } from '@eo-storefronts/eo-core'
import { memo, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import ProductModalContainer from '~/src/components/products/modals/ProductModalContainer'
import ProductConfigurationModalContext, {
  ProductConfigurationModalContextInterface
} from '~/src/components/products/modals/configuration/ProductConfigurationModalContext'
import useProductConfigurationModalTemplateService
  from '~/src/components/products/modals/configuration/styles/useProductConfigurationModalTemplateService'
import ProductsListModal from '~/src/components/products/modals/products-list'
import ProductDetails from '~/src/components/products/product-details'
import { useAddProductToCart } from '~/src/hooks/cart/useAddProductToCart'
import useCartModifierGroupsStateService from '~/src/hooks/products/modifier-groups/useCartModifierGroupsStateService'
import { useFirmPathResolver } from '~/src/hooks/router/useFirmPathResolver'
import { useRouterPush } from '~/src/hooks/router/useRouterPush'
import useDialog from '~/src/hooks/useDialog'
import { useEoValue } from '~/src/hooks/useEoState'
import RoutesEnum from '~/src/router/enums/routes.enum'
import { convertProductToModifier } from '~/src/services/ProductService'
import { CartProductModifierGroupStateInterface } from '~/src/stores/cart/cart-product-modifier-groups'
import { NAVIGATE_BY_CATEGORIES_SELECTOR } from '~/src/stores/theme'
import { DialogNameEnum } from '~/src/types/DialogNameEnum'
import ResponsiveDialogProps from '~/src/types/ResponsiveDialogProps'

export interface ProductConfigurationModalProps extends ResponsiveDialogProps {
  product: Product,
  parentContext?: ProductConfigurationModalContextInterface | null,
  group?: ModifierGroup | null,
  asModifier?: boolean,
}

const ProductConfigurationModal = (
  {
    openByDefault = true,
    maxWidth = 'md',
    fullWidth = true,
    responsive = true,
    asModifier = false,
    parentContext = null,
    group = null,
    product,
    onClose,
    ...otherProps
  }: ProductConfigurationModalProps
) => {
  const { dismiss } = useDialog()
  const { push } = useRouterPush()
  const { resolve } = useFirmPathResolver()
  const location = useLocation()
  const params = useParams()
  const navigateOnCategory = useEoValue<boolean>(NAVIGATE_BY_CATEGORIES_SELECTOR)
  const styles = useProductConfigurationModalTemplateService()
  const { addToCart } = useAddProductToCart()
  const { setModifierToGroup } = useCartModifierGroupsStateService()
  const [ cartModifierGroups, setCartModifierGroups ] = useState<CartProductModifierGroupStateInterface[]>([])

  const _handleOnClose = () => {
    dismiss(`${DialogNameEnum.PRODUCT_BY_ID}${product.id}`)

    if (asModifier) {
      push(resolve(`${RoutesEnum.CATEGORY}/${params.catId}${RoutesEnum.PRODUCT}/${params.productId}`), { modal: true })
      return
    }

    if ((location.state?.location?.pathname || '').includes(RoutesEnum.SEARCH)) {
      push(location.state?.location?.pathname, { modal: true })
    } else if (!navigateOnCategory) {
      push(resolve(RoutesEnum.MENU), { modal: true })
    } else {
      push(resolve(`${RoutesEnum.CATEGORY}/${params.catId}${RoutesEnum.PRODUCTS}`), { modal: true })
    }
  }

  const handleUpdateModifierGroups = (cartModifierGroups: CartProductModifierGroupStateInterface[]) => {
    setCartModifierGroups(cartModifierGroups)
  }

  const handleSave = (quantity: number) => {
    if (!asModifier) {
      addToCart(product, quantity, cartModifierGroups)
    } else if (group && parentContext) {
      parentContext.updateModifierGroups(
        setModifierToGroup(
          [ ...parentContext.selectedModifierGroups ],
          group,
          convertProductToModifier(group, product),
          cartModifierGroups
        )
      )
    }
  }

  if (product.subProductsId.length) {
    return (
      <ProductsListModal
        {
          ...styles.getProductListModalProps(
            {
              openByDefault,
              onClose: onClose ?? _handleOnClose,
              product
            }
          )
        }/>
    )
  }

  useEffect(() => {
    if (!asModifier) {
      return
    }

    push(resolve(`${RoutesEnum.CATEGORY}/${params.catId}${RoutesEnum.PRODUCT}/${params.productId}?menu=true`), { modal: true })
  }, [ asModifier ])

  return (
    <ProductModalContainer
      {
        ...styles.getProductModalContainerProps(
          {
            openByDefault,
            maxWidth,
            fullWidth,
            responsive,
            onClose: onClose ?? _handleOnClose,
            product,
            ...otherProps
          }
        )
      }>
      <ProductConfigurationModalContext.Provider value={{
        asModifier,
        updateModifierGroups: handleUpdateModifierGroups,
        selectedModifierGroups: cartModifierGroups,
        save: handleSave
      }}>
        <ProductDetails product={product}/>
      </ProductConfigurationModalContext.Provider>
    </ProductModalContainer>
  )
}

export default memo(ProductConfigurationModal)
