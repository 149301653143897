import { SxProps } from '@mui/material'
import StylesLayoutServiceInterface from '~/src/components/layout/styles/StylesLayoutServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesLayoutServiceStyleOne extends TemplateServiceStyleBase implements StylesLayoutServiceInterface {
  public getContainerSx(isLocatorPage?: boolean): SxProps {
    const hasHeader: boolean = this.layout?.header.display ?? false
    const hasSubHeader: boolean = this.layout?.subHeader.display ?? false
    const hasMainSideBar: boolean = this.layout?.mainSideBar.display ?? false
    const hasFooter: boolean = this.layout?.footer.display ?? false
    const hasMobileNavigation: boolean = this.layout?.mobileNavigation.display ?? false
    const firmOfflineAlertRow = hasMainSideBar ? '"firmOfflineAlert firmOfflineAlert"' : '"firmOfflineAlert"'
    const subHeaderRow = hasMainSideBar ? '"sub-header sub-header"' : '"sub-header"'
    const footerRow = hasMainSideBar ? '"footer footer"' : '"footer"'

    return {
      display: 'grid',
      gridTemplateColumns: `${hasMainSideBar ? 'minmax(0, 1fr) minmax(500px, 35vw)' : 'minmax(0, 1fr)'}`,
      gridTemplateRows: '' +
        'max-content ' +
        `${hasHeader ? 'max-content' : ''} ` +
        `${hasSubHeader ? 'max-content' : ''} ` +
        '1fr ' +
        `${hasFooter ? 'max-content' : ''}`,
      gridTemplateAreas: '' +
        `${firmOfflineAlertRow} ` +
        `${hasMainSideBar ? '"header header"' : '"header"'} ` +
        `${hasSubHeader ? subHeaderRow : ''} ` +
        `${hasMainSideBar ? '"main main-sidebar"' : '"main"'} ` +
        `${hasFooter ? footerRow : ''} `,
      minHeight: isLocatorPage ? 'initial' : '100vh',
      color: 'background.contrastText',
      [this.muiTheme.breakpoints.down('md')]: {
        gridTemplateAreas: `
         "firmOfflineAlert"
         ${hasHeader ? '"header"' : ''}
         ${hasSubHeader ? '"sub-header"' : ''}
         "main"
         ${hasFooter ? '"footer"' : ''}
         ${hasMobileNavigation ? '"mobile-navigation"' : ''}
        `,
        gridTemplateColumns: '100%',
        gridTemplateRows: `
         'max-content '
         ${hasHeader ? 'max-content ' : ''}
         ${hasSubHeader ? 'max-content ' : ''}
         1fr
         ${hasFooter ? 'max-content' : ''}
         ${hasMobileNavigation ? 'max-content' : ''}
        `
      }
    }
  }

  public getMainSx(): SxProps {
    return {}
  }

  public getFooterSx(): SxProps {
    return {
      backgroundColor: 'container.main',
      color: 'container.contrastText',
      boxShadow: this.muiTheme.elevation,
      width: '100%',
      position: 'sticky',
      bottom: 0,
      zIndex: 2
    }
  }
}
