import ApiConfigUtils from '~/src/utils/ApiConfigUtils'
import { loginAsClient } from '~/src/services/AuthenticationService'
import { ApiConfiguration, EoInject, EoInjectable, FindFirmArgType } from '@eo-storefronts/eo-core'
import { BaseAuthenticationState } from '~/src/stores/authentication'
import HostResolver from '~/src/router/utils/HostResolver'

@EoInjectable()
export default class AppUtils {
  private _authResponse: BaseAuthenticationState | null = null

  constructor(
    @EoInject(HostResolver) private readonly _hostResolver: HostResolver
  ) {
  }

  public async init(): Promise<void> {
    if (this._authResponse) {
      return
    }
    
    await ApiConfigUtils.init()
    const findArg: FindFirmArgType = await this._hostResolver.resolve()
    const response = await loginAsClient(findArg)

    ApiConfiguration.addOrReplaceHeader('Authorization', `${response.tokenType} ${response.accessToken}`)
    this._authResponse = {
      token: response.accessToken,
      tokenType: response.tokenType,
      expiresIn: response.expiresIn
    }
  }

  public getAuthResponse(): BaseAuthenticationState | null {
    return this._authResponse
  }

  public setAuthResponse(authResponse: BaseAuthenticationState) {
    this._authResponse = authResponse
  }
}
