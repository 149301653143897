import { DateUtils, Firm, OrderingMethodNames, PickupHour, Timeslot } from '@eo-storefronts/eo-core'
import { isLimitedToSameDayOrdering } from '~/src/services/DeliveryTimeService'
import {
  canOrderOnDayForTimeslots,
  canOrderOnDayIdForPeriod,
  getFirstAvailablePeriodHourForDayId,
  getFirstAvailableTimeslotHourForDayId,
  isOffline,
  isOnHoliday,
  isOpened,
  isTimeBetweenOperationalTimeslot
} from '~/src/services/OpeningHourService'

export default class CanPlaceOrderService {
  private readonly _firm: Firm | null = null
  private readonly _orderingMethod: OrderingMethodNames | null = null
  private readonly _timeslots: Timeslot[] = []

  public constructor(firm: Firm|null, orderingMethod: OrderingMethodNames|null, timeslots: Timeslot[]) {
    this._firm = firm
    this._orderingMethod = orderingMethod
    this._timeslots = timeslots

    return this
  }

  public checkAll(): boolean {
    if (this._firm && isOffline(this._firm)) {
      return false
    }

    if (!this.checkOrdersOnlyDuringOpeningHours()) {
      return false
    }

    return this.checkOrdersOnlyDuringOperationalHours()
  }

  public checkOrdersOnlyDuringOpeningHours(): boolean {
    if (
      this._orderingMethod
      && this._orderingMethod !== OrderingMethodNames.ON_THE_SPOT
      && this._firm
      && this._firm?.settings.orderingMethods[this._orderingMethod]?.ordersOnlyDuringOpeningHours
    ) {
      return this._isFirmOpened()
    }

    if (this._orderingMethod === OrderingMethodNames.ON_THE_SPOT) {
      return this._isFirmOpened()
    }

    return true
  }

  public checkOrderIsLimitedToSameDay(): boolean {
    if (!this._firm || !this._orderingMethod) {
      return false
    }

    return isLimitedToSameDayOrdering(this._firm, this._orderingMethod)
  }

  public checkOrdersOnlyDuringOperationalHours(): boolean {
    if (
      !this._orderingMethod
      || this._orderingMethod === OrderingMethodNames.ON_THE_SPOT
      || !this._firm?.settings.orderingMethods[this._orderingMethod]?.ordersOnlyDuringOperationalHours
    ) {
      return true
    }

    if (!this._firm?.settings.orderingMethods[this._orderingMethod]?.orderTimeslots.active) {
      return isOpened(this._firm.settings.periods[`${this._orderingMethod}Hours`])
    }

    return isTimeBetweenOperationalTimeslot(
      this._timeslots
    )
  }

  public getNextOpeningHour(): string {
    if (!this._firm) {
      return ''
    }

    let addDay = 0
    let dayId = DateUtils.getDayIdLikeMomentJs(DateUtils.addDays(addDay))

    do {
      dayId = DateUtils.getDayIdLikeMomentJs(DateUtils.addDays(addDay))

      if (canOrderOnDayIdForPeriod(this._firm.settings.periods.openingHours, dayId)) {
        const hour = getFirstAvailablePeriodHourForDayId(this._firm, dayId)
        return this._formatNextDate(addDay, hour)
      }

      addDay++
    } while (addDay < 7)

    return ''
  }

  public getNextOperationalHour(): string {
    if (
      !this._firm
      || !this._orderingMethod
      || this._orderingMethod === OrderingMethodNames.ON_THE_SPOT
    ) {
      return ''
    }

    let addDay = 0
    let dayId: number

    do {
      dayId = DateUtils.getDayIdLikeMomentJs(DateUtils.addDays(addDay))

      if (this._firm?.settings.orderingMethods[this._orderingMethod]?.orderTimeslots.active) {
        const timeslotDays = this._firm?.settings.orderingMethods[this._orderingMethod]?.orderTimeslots
          .timeslots
          .map((t: Timeslot) => t.dayId as number) || []

        if (canOrderOnDayForTimeslots(timeslotDays, dayId)) {
          const hour = getFirstAvailableTimeslotHourForDayId(
            this._firm,
            dayId,
            this._orderingMethod,
            this._timeslots
          )
          return this._formatNextDate(addDay, hour)
        }
      } else if (addDay > 0 && canOrderOnDayIdForPeriod(this._firm.settings.periods[`${this._orderingMethod}Hours`], dayId)) {
        const hour = getFirstAvailablePeriodHourForDayId(this._firm, dayId, this._orderingMethod)
        return this._formatNextDate(addDay, hour)
      } else {
        const period = this._firm.settings.periods[`${this._orderingMethod}Hours`].find((p: PickupHour) => p.dayId === dayId)
        const now = new Date()

        if (!period) {
          continue
        }

        for (const time of period.times) {
          if (
            Number(time.fromTime.replace(':', '')) > Number(`${now.getHours()}${now.getMinutes()}`)
          ) {
            return this._formatNextDate(addDay, time.fromTime)
          }
        }
      }

      addDay++
    } while (addDay < 7)

    return ''
  }

  public hasTimeslots(): boolean {
    if (this._orderingMethod === OrderingMethodNames.ON_THE_SPOT) {
      return true
    }

    if (
      this._orderingMethod 
      && !this._firm?.settings.orderingMethods[this._orderingMethod]?.orderTimeslots.active
    ) {
      return true
    }

    return this._timeslots.length > 0
  }

  private _formatNextDate(days: number, hour: string): string {
    if (hour === '') {
      return ''
    }

    const [ h, m ] = hour.split(':')

    const nextDate = DateUtils.addDays(days)
    nextDate.setHours(parseInt(h), parseInt(m))

    return DateUtils.calendarSentence(new Date(), nextDate)
  }

  private _isFirmOpened(): boolean {
    if (!this._firm) {
      return false
    }

    return isOpened(this._firm.settings.periods.openingHours) && !isOnHoliday(new Date(), this._firm.settings.periods.holidayPeriod)
  }
}
