import AddIcon from '@mui/icons-material/Add'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import { Box, Button } from '@mui/material'
import { useContext } from 'react'
import InlineAddress from '~/src/components/customers/addresses/InlineAddress'
import ResponsiveDialogContext, {
  ResponsiveDialogContextInterface
} from '~/src/components/mui-wrappers/responsive-dialog/ResponsiveDialogContext'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import { CUSTOMER_STATE } from '~/src/stores/customer'

const DeliveryAddressButton = () => {
  const { t } = useTranslations()
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)
  const customer = useEoValue(CUSTOMER_STATE)
  const responsiveDialogContext = useContext<ResponsiveDialogContextInterface>(ResponsiveDialogContext)

  if (checkoutForm.orderingMethod.address) {
    return (
      <Button
        sx={{ textTransform: 'none' }}
        startIcon={<LocationOnIcon />}
        onClick={responsiveDialogContext.toggleOpen}
        color='inherit'
      >
        <Box
          component='span'
          sx={{
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
          }}
        >
          <InlineAddress address={checkoutForm.orderingMethod.address} />
        </Box>
      </Button>
    )
  }

  return (
    <Button
      variant='outlined'
      color='error'
      sx={{ textTransform: 'none' }}
      startIcon={<AddIcon color='inherit' />}
      onClick={responsiveDialogContext.toggleOpen}
    >
      {!customer && t('orderingMethods.addAddress')}
      {customer && t('orderingMethods.chooseAddress')}
    </Button>
  )
}

export default DeliveryAddressButton
