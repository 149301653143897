import { Address } from '@eo-storefronts/eo-core'
import { useEoValue, useSetEoState } from '~/src/hooks/useEoState'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { CHECKOUT_BUTTON_LOADING_STATE, CHECKOUT_FORM_STATE, CheckoutFormState } from '~/src/stores/checkout'
import { useSnackbar } from 'notistack'
import { useTranslations } from '~/src/hooks/useTranslations'
import { fetchDeliveryAddressCosts } from '~/src/services/OrderService'

const useFetchDeliveryAddressCost = () => {
  const firm = useEoValue(FIRM_SELECTOR)
  const setCheckoutForm = useSetEoState(CHECKOUT_FORM_STATE)
  const setCheckoutButtonLoading = useSetEoState(CHECKOUT_BUTTON_LOADING_STATE)
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslations()

  const fetchDeliveryCost = async (address: Address) => {
    if (!firm || firm.isBrand || firm.isMultifirm) {
      return
    }

    setCheckoutButtonLoading(true)
    let costs: any

    try {
      costs = await fetchDeliveryAddressCosts(firm.id, address)
      enqueueSnackbar(t('orderingMethods.addressChosen'), { variant: 'success' })

      setCheckoutForm((state: CheckoutFormState) => ({
        ...state,
        orderingMethod: {
          ...state.orderingMethod,
          deliveryFreeFromPrice: costs.freeFromPrice,
          initialDeliveryCost: costs.price,
          deliveryCost: costs.price,
          minAmount: costs.minOrderPrice,
          deliveryItemUid: costs.itemUid
        }
      }))

    } catch (e: any) {
      enqueueSnackbar(t('orders.addressNoLongerValid'), { variant: 'error' })

      setCheckoutForm((state: CheckoutFormState) => ({
        ...state,
        orderingMethod: {
          ...state.orderingMethod,
          deliveryFreeFromPrice: null,
          initialDeliveryCost: 0,
          deliveryCost: 0,
          minAmount: 0,
          deliveryItemUid: null,
          address: null
        }
      }))
    }

    setCheckoutButtonLoading(false)

    return costs
  }

  return {
    fetchDeliveryCost
  }
}

export default useFetchDeliveryAddressCost
