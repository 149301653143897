import ReactGA from 'react-ga4'
import { useNavigate } from 'react-router-dom'

interface ReturnsType {
  push(path: string, state?: Record<string, any>): void,
  back(): void,
}

const useRouterPush = (): ReturnsType => {
  const navigate = useNavigate()

  const trackRouting = (page: string) => {
    ReactGA.send({ hitType: 'pageview', page })
  }

  const push = (path: string, state?: Record<string, any>) => {
    trackRouting(path)
    navigate(path, { state })
  }

  const back = () => {
    navigate(-1)
  }

  return { push, back }
}

export {
  useRouterPush
}
