import { useEoValue } from '~/src/hooks/useEoState'
import { useAsyncEffect } from '~/src/hooks/useAsyncEffect'
import { DateUtils, Firm } from '@eo-storefronts/eo-core'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { useFetchCatalog } from '~/src/hooks/catalog/useFetchCatalog'
import { useEffect } from 'react'
import { CHECKOUT_FORM_STATE, CheckoutFormState } from '~/src/stores/checkout'
import useFetchPriceList from '~/src/hooks/catalog/useFetchPriceList'

const useFetchCatalogEffect = () => {
  const firm = useEoValue<Firm | null>(FIRM_SELECTOR)
  const { fetch: fetchCatalog } = useFetchCatalog()
  const { fetch: fetchPriceList } = useFetchPriceList()
  const checkoutForm: CheckoutFormState = useEoValue(CHECKOUT_FORM_STATE)

  useAsyncEffect(async () => {
    if (!firm || firm?.isMultifirm) {
      return
    }

    await fetchCatalog(firm.id)
  }, [ firm?.id ])

  useEffect(() => {
    if (!checkoutForm.orderingMethod.method || !checkoutForm.orderingMethod.time) {
      return
    }

    fetchPriceList(
      checkoutForm.orderingMethod.method,
      DateUtils.toDate(checkoutForm.orderingMethod.time)
    )
  }, [
    checkoutForm.orderingMethod.method,
    checkoutForm.orderingMethod.time
  ])
}

export default useFetchCatalogEffect
