import { useEoValue, useSetEoState } from '~/src/hooks/useEoState'
import { CART_STATE, CartState } from '~/src/stores/cart'
import { getCartProductIndex } from '~/src/helpers/getCartProductIndex'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { CartProduct } from '~/src/types/CartProduct'

interface ReturnsType {
  updateQuantity(cartProduct: CartProduct, quantity: number): void,
  updateIsCoupon(cartProduct: CartProduct, isCoupon: boolean): void,
}

const useUpdateCartProducts = (): ReturnsType => {
  const firm = useEoValue(FIRM_SELECTOR)
  const setCarts = useSetEoState(CART_STATE)

  const _update = (cartProduct: CartProduct, field: keyof CartProduct, value: unknown) => {
    if (!firm) {
      return
    }

    setCarts((state: CartState) => {
      const cartProducts: CartProduct[] = [
        ...state[firm.id] || []
      ]
      const index: number = getCartProductIndex(cartProducts, cartProduct)

      if (index !== -1) {
        const cartProduct: CartProduct = {
          ...cartProducts[index]
        }

        // @ts-ignore
        cartProduct[field] = value
        cartProducts[index] = cartProduct
      }

      return {
        ...state,
        [firm.id]: cartProducts
      }
    })
  }

  const updateQuantity = (cartProduct: CartProduct, quantity: number): void => {
    _update(cartProduct, 'quantity', quantity)
  }

  const updateIsCoupon = (cartProduct: CartProduct, isCoupon: boolean) => {
    _update(cartProduct, 'isCoupon', isCoupon)
  }

  return { updateQuantity, updateIsCoupon }
}

export {
  useUpdateCartProducts
}
