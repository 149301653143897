import { SxProps } from '@mui/material'
import ModifierToggleTemplateServiceInterface
  from '~/src/components/products/modifier-groups/modifier-group/modifier/modifier-toggle/styles/ModifierToggleTemplateServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class ModifierToggleTemplateServiceStyleTwo extends TemplateServiceStyleBase implements ModifierToggleTemplateServiceInterface {
  public getContainerSx(): SxProps {
    return {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      height: '100%',
      gap: 1,
      cursor: 'pointer'
    }
  }

  public getPriceContainerSx(): SxProps {
    return {
      display: 'inline-flex',
      position: 'relative',
      alignItems: 'center',
      justifyContent: 'flex-end',
      opacity: '.6'

    }
  }

  public displayPriceAsChip(): boolean {
    return false
  }
}
